var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",staticStyle:{"max-width":"1280px","margin":"0 auto"}},[_vm._m(0),_c('div',{staticClass:"tableList"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.list,"align":"center","header-cell-style":{
        background: '#eaeaea',
        color: '#606266',
        fontWeight: 400,
      }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","align":"center","width":"50"}}),_c('el-table-column',{attrs:{"prop":"tableName","align":"center","width":"150"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("志愿表")])])])],2),_c('el-table-column',{attrs:{"label":"批次","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(_vm._s(scope.row.batchName))])])]}}])}),_c('el-table-column',{attrs:{"prop":"score","align":"center"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("成绩")])])])],2),_c('el-table-column',{attrs:{"prop":"subjectClaim","align":"center"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("科目")])])])],2),_c('el-table-column',{attrs:{"label":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(_vm._s(scope.row.type))])])]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","align":"center","width":"180"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("最后编辑时间")])])])],2),_c('el-table-column',{attrs:{"prop":"cIdNickName","align":"center","width":"180"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("归属用户")])])])],2),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goTY(scope.row.id, scope.row.cId,scope.row.locationCode)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.getExport(
                scope.row.id,
                scope.row.locationCode,
                scope.row.cIdNickName
              )}}},[_vm._v("下载")]),_c('el-popconfirm',{staticStyle:{"margin-left":"10px"},attrs:{"title":"删除后无法恢复，确定删除吗？"},on:{"confirm":function($event){return _vm.getDelete(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text"},slot:"reference"},[_vm._v("删除")])],1)]}}])})],1),_c('div',{staticStyle:{"height":"60px","margin-top":"10px"}},[_c('el-pagination',{attrs:{"layout":"total, sizes,prev, pager, next, jumper","page-size":_vm.obj.PageSize,"page-sizes":[10, 20, 50],"total":_vm.total},on:{"current-change":_vm.hCurrentChange,"size-change":_vm.hSizeChange}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-zytable-page-title page-title-bl4 mt60 mb15"},[_c('span',{staticClass:"pull-left"},[_vm._v("专家志愿表")])])
}]

export { render, staticRenderFns }