<template>
    <div class="box">
        <div style="max-width: 1280px;margin: 0 auto;">
            <div class="articleCont clearfix">
                <div class="articleCont_left">
                    <div class="article_bar">
                        <div class="article_bar_title clearfix">
                            <p>高考资讯</p>
                            <!-- <div class="inputt hoverbc">
                                <input placeholder="请输入文章名称" id="sear" autocomplete="off">
                                <div class="anniu">
                                    <img id="fang"
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAA21JREFUWEfNWO1RGzEQ3ZULCFQQbqT/IRUEKghUEKeChAogFQRXEFMBUAHuIPBfmrMriClAUuZddJqzrPsyJsn+YIa5lfT2+62ZRkhZlkfW2g9EdEZEB8x8QETHRLT03i9xFTPfCSHui6Ko/h8rPOSA1vqMmS/D40OOQAeArqWUs6EHKoO6lMuyPLHWXjLzyZhLE11470opdTPkjlZAxpgvsHDIJQN15kKIi6Io1l36WUDGmB9ENG05+Oy9v2PmRyHEY61jrT1iZuQT8utty1mcOe0CtQWoA8wquH7e55EQ6itmRgGk0glqA5Ax5isRfc9cciGlHB2+UAww4E3zTnhYKXWeMywCglXOuYdECeGZKqXu+rzS9r0sy2PnHEC9S3S+SSmv0nMRkNb6Ia0m7/35S8DUj5VleeCcQxtoemothCjSfKoAaa2nzIxEbspOYerwVC4CMykl0iRKBcgY8zNpeisp5dGuYWo7p7WeM/On5nchxGHTS4xx4Jwrk6TbS6hSYEPe4kxlPUspMaNeRbTW6GEf68u99zdKqdjzOFUgoq247hNZJl/XUsrD+g0AWiQNbK/JnAkb2gByNoqUMlY7QvYLVKL+Glr7Yp9eSe8yxvgksVH+f+hL5iNmzd8GFN/8XwC9L4qiGtRbOeS9/6yU6h2gu4Y0dG2kST6HMkn92lUG9nnbldQYcKCntSyllMWuHug7Z4wBawD5q8R7f6+UAoeqBJ16qwxfq9JCuDAVmo13o83UswwlF1me936hlDrts3bs9xzfChM/big1oDRstG8vhTmGhhi9k46NKmT4E1yJsmtyYfAV9IfIm8d6pNYP94P8gXNHyRndJGg5TpQlUWOBaa1vmTkmbjifreYNTp3jK0S0Dr1pNI0NYUKJb3iGiFZCiOPc9rEBKLgWYyPlvzDqWggxG7Iih3tQ2mCDWSrT1oC31qAeUOgbADyfTCaojKfaSngDhjjnEJpq9+8LbQ5UdlEEKGst6GYkUn2XD/i+yi2QKajO3d4Yg3YAt2/sVQMeT1VmQogray3GRrpMwOtxfvb++hFCCGCx3Q8FhD4zmUzmTTrTsuFEUL2Amr3EWotfQWAlciTnNSyWYKALIcRdWwF0gRoMKOcVeC+U9HpsA20BtXwRoKGha9PLgHr6p4AANIBCjmIqTH8Ds+Hp0m/DWpIAAAAASUVORK5CYII=">
                                </div>
                            </div> -->
                        </div>
                        <div class="richTxt new_detail">
                            <div class="article_fenlei clearfix">
                                <div class="article_fenlei_list" style="overflow: hidden;">
                                    <li :class="typeIndex == idx ? 'fenlei_li li_bian' : 'fenlei_li'"
                                        v-for="(it, idx) in  typeList" :key="idx" @click="qiehuan(it.Id, idx)">{{
                                            it.CategoryName
                                        }}</li>
                                </div>
                                <!-- <div class="look_all">
                                    <span>展开&nbsp;
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAMCAYAAAC5tzfZAAAAAXNSR0IArs4c6QAAAQxJREFUKFOdkrFKxEAQhv/ZdHbxFezt0+8mXkw6X8GHUETyACKHHIhvoBGxC8lu0tpYWNrbCB6HYCEkbHaOE070DBaZcuD/Z+b7hzCiaIQGVBTFTt/3r2mafv5nkOe55/v+rlLqiaqqeiAiYa1N4zieDwmzLBNBEDwC2Grb9oDKspSe510x85sQIpFSLjaFxph7ABLAkVJq9nWT1joEcAlg3nXdJEmS97XQGHPHzJEQ4lhKebHqf4Oo63oPwMw5t7DWqjiOP7TWtwAmRHSilJqujX7RM8bsA7hh5mciellNAHAahuH5z5X/IDfGHDLzlIjYOZdFUXS2eeNgTk3ThMy8LaW8HqI5LtwxH7EEMglqTipY1Y8AAAAASUVORK5CYII="
                                            class="expand-more-icon2">
                                    </span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="article_list clearfix" v-if="list !== null">
                        <li class="clearfix" v-for="(it, idex) in list" :key="idex" @click="goArticlelist(it.Id)">
                            <!-- <div class="article_list_left">
                                <img :src="it.CoverImg">
                            </div> -->
                            <div class="article_list_right">
                                <p class="article_list_right_title">{{ it.Title }}</p>
                                <p class="article_list_right_cont">
                                    {{ it.Summary }}
                                </p>
                                <p class="article_list_right_shuxing">
                                    <span>
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAVNJREFUOE91UsFtwkAQnEHcm6QDpwNKMB3ghxfxClQQqIB0EKgg5oV8fpgOQgl0EEpI3iA2WsuHTlZ8r9Ptzu3szBDRqev66Xq9vgKYAkjb0pnkcTgc7rIs+wntDJeyLKckPwH8qmoxGAxOVrvf7ynJBYARgLWIFPbeAFtQDWAnIquYRfTxO8kNgKWB2dL7BrCPQVVVNR/keb7tgN+ccy+0BlVdiUgST/LeN1RFJOzalL33F1uFbcO5S7EPWJalUU4NaErZxGbpcPqAVVWlqvrVCwyCqWo2m82OHfXrXqrtPiaM+ToRkXP0Ng7ibEyp2OBoQkEyCSKZOCS3wY4LgFpElv95eDgckvl8fvHeG4OFcy5pAhAWBlA459bdya3XHwYiOcnz/PSInPd+DMBEGDU+kcYCqmr+WhyfSU4N9IhcoGc/3263hapayEMgbCcLeREz+QPaqsOrC8wsrAAAAABJRU5ErkJggg=="
                                            alt="">
                                        <span style="margin-left: 10px;">{{ it.CreateTime }}</span>
                                    </span>
                                </p>
                            </div>
                        </li>
                    </div>
                    <div class="article_list clearfix" v-else>
                        <el-empty description="暂无数据"></el-empty>
                    </div>
                    <div class="pagination">
                        <el-pagination background layout="total, sizes,prev, pager, next, jumper" :page-size="obj.PageSize"
                            :page-sizes="[10, 20, 50]" :total="total" @current-change="hCurrentChange"
                            @size-change="hSizeChange" />
                    </div>
                </div>
                <!--  -->
                <!-- <div class="right-slide-container">
                    <div class="articleCont_right">
                        <div class="articleCont_right_zhengce" v-if="typeIndex == 0 || typeIndex == 1">
                            <p>高考头条</p>
                            <li class="policy clearfix" v-for="(it, idex) in list" :key="idex"
                                @click="goArticlelist(it.Id)">
                                <div class="policy_pai">{{ idex + 1 }}</div>
                                <p class="policy_title">{{ it.Title }}</p>
                            </li>
                        </div>
                        <div class="articleCont_right_zhengce" v-if="typeIndex == 0 || typeIndex == 2">
                            <p>最新政策</p>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1038"
                                    title="2023强基计划13大变化趋势！给初高中低年级哪些重大启示？"
                                    class="hidden-alink-spider-only">2023强基计划13大变化趋势！给初高中低年级哪些重大启示？</a>
                                <div class="policy_pai">1</div>
                                <p class="policy_title">2023强基计划13大变化趋势！给初高中低年级哪些重大启示？</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1037" title="多个省份公布2024年高考报名时间"
                                    class="hidden-alink-spider-only">多个省份公布2024年高考报名时间</a>
                                <div class="policy_pai">2</div>
                                <p class="policy_title">多个省份公布2024年高考报名时间</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1036" title="强基计划物理专业考生就业前景如何？"
                                    class="hidden-alink-spider-only">强基计划物理专业考生就业前景如何？</a>
                                <div class="policy_pai">3</div>
                                <p class="policy_title">强基计划物理专业考生就业前景如何？</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1035" title="全国院校层次划分解读"
                                    class="hidden-alink-spider-only">全国院校层次划分解读</a>
                                <div class="policy_pai">4</div>
                                <p class="policy_title">全国院校层次划分解读</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1034" title="想报考综评强基高中三年如何准备？提前规划才不后悔"
                                    class="hidden-alink-spider-only">想报考综评强基高中三年如何准备？提前规划才不后悔</a>
                                <div class="policy_pai">5</div>
                                <p class="policy_title">想报考综评强基高中三年如何准备？提前规划才不后悔</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1033"
                                    title="上海纽约大学2024年综合评价报名系统填报指南&amp;常见问题"
                                    class="hidden-alink-spider-only">上海纽约大学2024年综合评价报名系统填报指南&amp;常见问题</a>
                                <div class="policy_pai">6</div>
                                <p class="policy_title">上海纽约大学2024年综合评价报名系统填报指南&amp;常见问题</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1032" title="2024年综合评价报名正式启动！报名流程梳理！"
                                    class="hidden-alink-spider-only">2024年综合评价报名正式启动！报名流程梳理！</a>
                                <div class="policy_pai">7</div>
                                <p class="policy_title">2024年综合评价报名正式启动！报名流程梳理！</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1031" title="2023年度海军招飞全检定选工作安排"
                                    class="hidden-alink-spider-only">2023年度海军招飞全检定选工作安排</a>
                                <div class="policy_pai">8</div>
                                <p class="policy_title">2023年度海军招飞全检定选工作安排</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1030"
                                    title="2024年度海军招收选拔飞行学员简章（面向普通高中毕业生）"
                                    class="hidden-alink-spider-only">2024年度海军招收选拔飞行学员简章（面向普通高中毕业生）</a>
                                <div class="policy_pai">9</div>
                                <p class="policy_title">2024年度海军招收选拔飞行学员简章（面向普通高中毕业生）</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1029"
                                    title="2024年度西南五省（市区）空军招收飞行学员简章发布"
                                    class="hidden-alink-spider-only">2024年度西南五省（市区）空军招收飞行学员简章发布</a>
                                <div class="policy_pai">10</div>
                                <p class="policy_title">2024年度西南五省（市区）空军招收飞行学员简章发布</p>
                            </li>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { GetPageNews, GETNewsCategory } from "@/api/News";
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            typeIndex: 0,
            total: 2,
            obj: {
                CategoryId: 1,
                PageIndex: 1,
                PageSize: 10,
            },
            list: [],
            typeList: []
        };
    },

    mounted() {
        this.getTab()
    },

    methods: {
        hSizeChange(curSize) {
            console.log(1);
            this.obj.PageSize = curSize
            this.obj.PageIndex = 1
            this.getList()
        },
        hCurrentChange(curSize) {
            // 1. 更新每页的条数
            console.log(2);

            this.obj.PageIndex = curSize
            this.getList()
        },
        async getTab() {
            GETNewsCategory
            const res = await GETNewsCategory()
            console.log(res);
            this.typeList = res.data.response
            this.getList()
        },
        // 跳转到资讯详情
        goArticlelist(id) {
            this.$router.push({ path: '/Home/Articlelist', query: { id } })
        },
        qiehuan(type, index) {
            this.typeIndex = index
            this.obj.CategoryId = type
            this.getList()
        },
        async getList() {
            const res = await GetPageNews({
                ...this.obj
            })
            this.list = res.data.response.data
            this.total = res.data.response.PageSize * res.data.response.pageCount
            console.log(res);
        }
    },
};
</script>

<style lang="less" scoped>
* {
    font-family: Microsoft YaHei;
}

.box {
    background: #F5F4F8;
    padding-bottom: 10px;
}

.clearfix {
    zoom: 1;
}

.hidden-alink-spider-only {
    display: none;
}

a,
a:hover {
    color: #999;
    text-decoration: none;
}

li {
    list-style: none;
}

.articleCont {
    padding-top: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    .articleCont_left {
        width: 880px;
        height: 100%;
        border-radius: 14px;
        float: left;
        padding-bottom: 150px;

        .article_bar {
            width: 100%;
            background-color: #fff;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            overflow: hidden;

            .article_bar_title {
                height: 38px;
                margin-top: 33px;
                margin-left: 30px;
                line-height: 38px;

                p {
                    width: 100px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #333;
                    float: left;
                }

                .inputt {
                    width: 274px;
                    height: 38px;
                    float: right;
                    margin-right: 30px;
                    border: 1px solid #eee;
                    position: relative;
                    border-radius: 8px;

                    #sear {
                        width: 236px;
                        height: 100%;
                        background: none;
                        outline: none;
                        border: 0;
                        font-size: 14px;
                        text-indent: 20px;
                        float: left;
                    }

                    .anniu {
                        width: 38px;
                        height: 38px;
                        float: right;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;

                        #fang {
                            width: 18px;
                            height: 18px;
                            position: absolute;
                            right: 10px;
                            top: 10px;
                        }
                    }
                }
            }

            .article_fenlei {
                margin-top: 26px;
                margin-left: 30px;
                margin-right: 30px;
                position: relative;
                border-bottom: 1px solid #eee;

                .article_fenlei_list {
                    width: 760px;
                    height: 60px;
                    margin: 0 auto;
                    float: left;
                    list-style: none;
                    padding-bottom: 10px;

                    .li_bian {
                        color: #187cff !important;
                        border: 1px solid #187cff !important;
                    }

                    .fenlei_li:hover {
                        color: #187cff !important;
                    }

                    .fenlei_li {
                        cursor: pointer;
                        padding: 6px 10px;
                        border-radius: 6px;
                        background-color: #fff;
                        font-size: 16px;
                        text-align: center;
                        float: left;
                        color: #333;
                        margin-right: 10px;
                        border: 1px solid transparent;
                        margin-top: 25px;
                    }
                }

                .look_all span {
                    font-size: 16px;
                    color: #333;
                    display: inline-block;
                    float: right;
                    margin-top: 32px;
                    cursor: pointer;

                    // 箭头
                    .expand-more-icon2 {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        .article_list {
            width: 100%;
            background-color: #fff;
            padding-bottom: 20px;
            border-bottom-left-radius: 14px;
            border-bottom-right-radius: 14px;
            min-height: 300px;

            li {
                width: 100%;
                height: 212px;
                position: relative;
                list-style: none;





                .article_list_left {
                    width: 220px;
                    height: 138px;
                    float: left;
                    margin-top: 28px;
                    margin-left: 30px;
                    border-radius: 8px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                    }
                }

                .article_list_right {
                    width: 572px;
                    height: 202px;
                    float: left;
                    margin-left: 28px;
                    border-bottom: 1px solid #eee;
                    position: relative;

                    .article_list_right_title:hover {
                        color: #187cff;
                    }

                    .article_list_right_title {
                        width: 570px;
                        font-size: 20px;
                        color: #333;
                        margin-top: 34px;
                        white-space: nowrap;
                        overflow: hidden;
                        cursor: pointer;
                        text-overflow: ellipsis;
                        font-weight: bold;
                    }

                    .article_list_right_cont {
                        width: 570px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #888;
                        line-height: 26px;
                        margin-top: 16px;
                        display: -webkit-box;
                        /* 默认最多显示3行 */
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                    }

                    .article_list_right_shuxing {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        color: #888;
                        position: absolute;
                        bottom: 10px;
                    }
                }
            }

        }

        .pagination {
            margin: 20px 0;

            .el-pagination {
                text-align: center;



            }
        }
    }

    .articleCont_right {
        width: 300px;
        float: left;
        margin-left: 20px;

        .articleCont_right_zhengce>p {
            height: 21px;
            font-size: 20px;
            font-weight: 700;
            color: #333;
            margin-left: 24px;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .articleCont_right_zhengce {
            width: 100%;
            background-color: #fff;
            border-radius: 14px;
            overflow: hidden;
            margin-bottom: 20px;
            padding-bottom: 6px;

            .policy_title:hover {
                color: #187cff !important;
                cursor: pointer;
            }


            .policy {
                width: 280px;
                margin-left: 26px;
                margin-bottom: 20px;

                .policy_pai {
                    font-size: 14px;
                    font-weight: 500;
                    width: 16px;
                    height: 16px;
                    background: transparent;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 16px;
                    color: #888;
                    float: left;
                    margin-top: 3px;
                }

                .policy_title {
                    width: 244px;
                    float: left;
                    margin-left: 8px;
                    font-size: 16px;
                    color: #333;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

        }
    }
}
</style>