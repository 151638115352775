<template>
  <div class="box">
    <div class="swp">
      <Swiper />
      <div class="choiceW">
        <div class="choiceBox">
          <div class="year">
            <span style="font-size: 20px; font-weight: bold">智能模拟志愿填报</span>
          </div>
          <div class="year" style="margin-top: 14px">
            <span>高考年份</span>
            <el-select value-key="code" v-model="value" placeholder="请选择年份" @change="selectChanged">
              <el-option v-for="(item, index) in ProvinceObj.lizations" :key="index" :label="item.code" :value="item">
              </el-option>
            </el-select>
          </div>
          <!-- 政策0 文理二选一 -->
          <div class="subjects" v-if="Policy == 0">
            <div class="title">
              <div>首选科目</div>
              <div style="font-size: 12px; color: red">(2选1)</div>
            </div>
            <div class="subject">
              <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in kemLists" :key="index"
                @click="cliSub(it)">
                {{ it.name }}
              </div>
            </div>
          </div>

          <!-- 政策1 物 化 生 史 政 地 六选三 -->
          <div class="subjects" v-if="Policy == 1">
            <div class="title">
              <div>选择科目</div>
              <div style="font-size: 12px; color: red">(6选3)</div>
            </div>
            <div class="subject">
              <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in kemLists" :key="index"
                @click="cliSub(it)">
                {{ it.name }}
              </div>
            </div>
          </div>

          <!-- 政策2 物 化 生 史 政 地 技 七选三 -->
          <div class="subjects" v-if="Policy == 2">
            <div class="title">
              <div>选择科目</div>
              <div style="font-size: 12px; color: red">(7选3)</div>
            </div>
            <div class="subject">
              <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in kemLists" :key="index"
                @click="cliSub(it)">
                {{ it.name }}
              </div>
            </div>
          </div>

          <!-- 政策3 物 化 生 史 政 地 （物，历必选一个，其他任选两门） -->
          <div class="policySubjects" v-if="Policy == 3">
            <div class="subjects" style="margin-bottom: 5px">
              <div class="title">
                <div>首选科目</div>
                <div style="font-size: 12px; color: red">(2选1)</div>
              </div>
              <div class="subject">
                <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in kemLists" :key="index"
                  v-show="it.isfirst == 'true'" @click="cliSub(it)">
                  {{ it.name }}
                </div>
              </div>
            </div>
            <div class="subjects">
              <div class="title">
                <div>次选科目</div>
                <div style="font-size: 12px; color: red">(4选2)</div>
              </div>
              <div class="subject">
                <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in kemLists" :key="index"
                  v-show="it.isfirst == 'false'" @click="cliSub(it)">
                  {{ it.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="grade">
            <span>预估总分</span>
            <el-input :disabled="ScoreCount == 0" v-model="grade" type="number" placeholder="请输入分数1-750"></el-input>
            <div class="f">分</div>
          </div>
          <div :class="ProvinceName == '浙江省' ? 'btnss btn' : 'btn'">
            <div class="but" @click="submitSelection(1)">
              <img src="./images/btn1.png" alt="" />
            </div>
            <div class="but1" @click="submitSelection(2)">
              <img src="./images/btn2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="item" @click="goDiplomaMill('/Home/SearchUniversity')">
        <div class="imgs">
          <img src="./images/找大学@2x.png" alt="" />
        </div>
        <div class="text">找大学</div>
      </div>
      <div class="item" @click="goDiplomaMill('/Home/CheckTheMajor')">
        <div class="imgs">
          <img src="./images/查专业@2x.png" alt="" />
        </div>
        <div class="text">查专业</div>
      </div>
      <div class="item" @click="goDiplomaMill('/Home/Occupation')">
        <div class="imgs">
          <img src="./images/看职业@2x.png" alt="" />
        </div>
        <div class="text">看职业</div>
      </div>
      <div class="item" @click="goDiplomaMill('/Home/BatchLine')">
        <div class="imgs">
          <img src="./images/批次线@2x.png" alt="" />
        </div>
        <div class="text">批次线</div>
      </div>
      <div class="item" @click="goDiplomaMill('/Home/RankLookup')">
        <div class="imgs">
          <img src="./images/查位次@2x.png" alt="" />
        </div>
        <div class="text">查位次</div>
      </div>
      <div class="item" @click="goDiplomaMill('/Home/ExpansionAndContraction')">
        <div class="imgs">
          <img src="./images/查扩@2x.png" alt="" />
        </div>
        <div class="text">查扩缩招</div>
      </div>
      <!-- <div class="item" @click="goCp()">
        <div class="imgs">
          <img src="./images/icon-专业测评@2x.png" alt="" />
        </div>
        <div class="text">专业测评</div>
      </div> -->
      <div class="item" @click="goDiplomaMill('/Home/DiplomaMill')">
        <div class="imgs">
          <img src="./images/野鸡大学@2x.png" alt="" />
        </div>
        <div class="text">大学甄别</div>
      </div>
    </div>
    <div class="contentList">
      <div class="liveStreaming">
        <div class="title">
          <div class="text">
            <!-- <span
                style="
                  margin-left: 24px;
                  font-size: 20px;
                  color: #6e6e6e;
                  font-weight: normal;
                "
                >直播</span -->
            <span>精选课程</span>
          </div>
          <div class="more" @click="
              goH5('https://kp.ycymedu.com/web/special/special_cate.html')
              ">
            查看更多
            <i class="el-icon-arrow-right" style="color: #aeaeae; font-size: 14px"></i>
          </div>
        </div>
        <div class="list">
          <div class="item" style="cursor: pointer" v-for="(it, idx) in Specialist" :key="idx" @click="goH5(it.url)">
            <div class="tp">
              <img :src="it.image" alt="" />
            </div>
            <div class="name">{{ it.title }}</div>
            <div class="ISvip" v-if="!it.isTRUE">免费</div>
            <div class="ISNoVip" v-else>VIP</div>
          </div>
        </div>
      </div>
      <div class="cptx">
        <div class="title">
          <div class="text">
            <span>测评体系</span>
          </div>
          <div class="more">
            <div class="cptxTabs">
              <div :class="showCp == 0 ? 'tabssitem isActiveTabssitem' : 'tabssitem'
              " @click="clickShowCP(0)">
                <span>学职定位</span>
                <span class="split-vline"></span>
              </div>
              <div :class="showCp == 1 ? 'tabssitem isActiveTabssitem' : 'tabssitem'
              " @click="clickShowCP(1)">
                <span>学习状态</span>
                <span class="split-vline"></span>
              </div>
              <div :class="showCp == 2 ? 'tabssitem isActiveTabssitem' : 'tabssitem'
              " @click="clickShowCP(2)">
                <span>心理测评</span>
                <span class="split-vline"></span>
              </div>
            </div>
            <span @click="goNew('/Home/Assessment')">查看更多</span>
            <i class="el-icon-arrow-right" style="color: #aeaeae; font-size: 14px"></i>
          </div>
        </div>
        <div class="list" v-if="showCp == 0">
          <div class="cp1" style="cursor: pointer" v-for="(it, idx) in CpList" :key="idx" @click="goH5(it.url, 1)">
            <div class="left">
              <div class="tit">{{ it.title }}</div>
              <div class="jc">
                {{ it.introduce }}
              </div>
            </div>
            <div class="right">
              <img :src="it.image" alt="" />
            </div>
          </div>
        </div>
        <div class="list" v-if="showCp == 1">
          <div class="cp2" style="cursor: pointer" v-for="(it, idx) in CpList1" :key="idx" @click="goH5(it.url, 1)">
            <div class="left">
              <div class="tit">{{ it.title }}</div>
              <div class="jc">
                {{ it.introduce }}
              </div>
            </div>
            <div class="right">
              <img :src="it.image" alt="" />
            </div>
          </div>
        </div>
        <div class="list" v-if="showCp == 2">
          <div class="cp3" style="cursor: pointer" v-for="(it, idx) in CpList2" :key="idx" @click="goH5(it.url, 1)">
            <div class="left">
              <div class="tit">{{ it.title }}</div>
              <div class="jc">
                {{ it.introduce }}
              </div>
            </div>
            <div class="right">
              <img :src="it.image" alt="" />
            </div>
          </div>
          <div class="cp4">
            <div class="top">
              <img src="./images/kk.png" alt="" />
            </div>
            <div class="btn">更多测评敬请期待</div>
          </div>
        </div>
      </div>
      <div class="counselor">
        <div class="title">
          <div class="text">高考志愿规划专家</div>
          <div class="more" @click="goNew('/Home/ZhanJiaList')">
            查看更多
            <i class="el-icon-arrow-right" style="color: #aeaeae; font-size: 14px"></i>
          </div>
        </div>
        <div class="list">
          <div class="teacheritem" v-for="(it, idx) in busSpecialistGrouList" :key="idx">
            <div class="teachername">{{ it.name }}</div>
            <div class="expertisebox">
              <div class="expertiseitem" v-for="(its, idxs) in it.majorTags" :key="idxs">
                {{ its }}
              </div>
            </div>
            <div class="introbox">
              <div class="introboxo">
                <div class="introitem">
                  <span class="greypoint"></span><span class="greytxt">{{ it.businessScope }}</span>
                </div>
              </div>
            </div>
            <div class="yuyuenow" @click="startCountdown()">立即预约</div>
            <!---->
            <img :src="it.pic" alt="" class="absoimg" />
          </div>
        </div>
      </div>
      <div class="gkzx">
        <div class="title">
          <div class="text">高考资讯</div>
          <div class="more" @click="goNew('/Home/News')">
            查看更多
            <i class="el-icon-arrow-right" style="color: #aeaeae; font-size: 14px"></i>
          </div>
        </div>
        <div class="centerright">
          <ul>
            <li v-for="(it, idx) in Newslist" :key="idx">
              <a :href="`/Home/Articlelist?id=` + it.Id" :title="it.Title" class="til">{{ it.Title }}</a>
              <div class="time">{{ it.CreateTime.split(" ")[0] }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="phb">
        <div class="title">
          <div class="text">热门排行榜</div>
          <div class="more" @click="goNew('/Home/ListRanking')">
            查看更多
            <i class="el-icon-arrow-right" style="color: #aeaeae; font-size: 14px"></i>
          </div>
        </div>
        <div class="center">
          <div class="schoolItem1" style="background: linear-gradient(180deg, #dee7f8 0%, #fff 30%)">
            <div class="tit">武书联大学排名</div>
            <div class="school-box">
              <div class="school-item" v-for="(it, index) in Rankinglist" v-show="index <= 4" :key="index"
                @click="goSchoolDetails(it.Uid)">
                <div class="school-index">{{ it.Sort }}</div>
                <div class="schoolPhoto">
                  <img :src="it.Logo" alt="" />
                </div>
                <div class="schoolName">
                  <span class="name"> {{ it.Name }}</span>
                  <span class="type">北京.综合类</span>
                </div>
              </div>
            </div>
          </div>
          <div class="schoolItem2" style="background: linear-gradient(180deg, #ddf7ea 0%, #fff 30%)">
            <div class="tit">校友会大学排名</div>
            <div class="school-box">
              <div class="school-item" v-for="(it, index) in Rankinglist1" v-show="index <= 4" :key="index"
                @click="goSchoolDetails(it.Uid)">
                <div class="school-index">{{ it.Sort }}</div>
                <div class="schoolPhoto">
                  <img :src="it.Logo" alt="" />
                </div>
                <div class="schoolName">
                  <span class="name"> {{ it.Name }}</span>
                  <span class="type">北京.综合类</span>
                </div>
              </div>
            </div>
          </div>
          <div class="schoolItem3" style="background: linear-gradient(180deg, #f5eada 0%, #fff 30%)">
            <div class="tit">软科大学排名</div>
            <div class="school-box">
              <div class="school-item" v-for="(it, index) in Rankinglist2" v-show="index <= 4" :key="index"
                @click="goSchoolDetails(it.Uid)">
                <div class="school-index">{{ it.Sort }}</div>
                <div class="schoolPhoto">
                  <img :src="it.Logo" alt="" />
                </div>
                <div class="schoolName">
                  <span class="name"> {{ it.Name }}</span>
                  <span class="type">北京.综合类</span>
                </div>
              </div>
            </div>
          </div>
          <div class="schoolItem4" style="background: linear-gradient(180deg, #f4dada 0%, #fff 30%)">
            <div class="tit">QS大学排名</div>
            <div class="school-box">
              <div class="school-item" v-for="(it, index) in Rankinglist3" v-show="index <= 4" :key="index"
                @click="goSchoolDetails(it.Uid)">
                <div class="school-index">{{ it.Sort }}</div>
                <div class="schoolPhoto">
                  <img :src="it.Logo" alt="" />
                </div>
                <div class="schoolName">
                  <span class="name"> {{ it.Name }}</span>
                  <span class="type">北京.综合类</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="subscribe" width="304px" :before-close="handleClose" center>
      <div class="diaB">
        <div class="icimg">
          <img src="./images/cg.png" alt="" />
        </div>
        <div class="ictext">预约成功</div>
        <div class="ictext1">老师会联系您，请稍后！</div>
        <div class="miao">{{ countdown }}秒后自动关闭</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request2, resetUnauthorizedHandled } from "@/utils/request";
import { GetPcUniversityRank } from "@/api/ListRanking";
import {
  GetCustomerInfoById,
  Callback,
  GetSeVolunteerInitialization,
} from "@/api/home";
import { GetPageNews, busSpecialistGroup, speciallist } from "@/api/News";
import Swiper from "@/components/swiper";
import { parseToken } from '@/utils/token';
import { getToken } from "@/utils/auth.js";
export default {
  name: "VoluntaryReportingIndex",
  components: {
    Swiper,
  },
  data() {
    return {
      countdown: 3, // 倒计时初始值
      subscribe: false, //预约显示隐藏
      timer: null, // 定时器

      isInitialLoad: true, // 用于标记是否是初始化加载
      showCp: 0, //测评显示
      kemLists: [
        {
          simplename: "物",
          name: "物理",
          code: 0,
        },
        {
          simplename: "化",
          name: "化学",
          code: 1,
        },
        {
          simplename: "生",
          name: "生物",
          code: 2,
        },
        {
          simplename: "史",
          name: "历史",
          code: 4,
        },
        {
          simplename: "政",
          name: "政治",
          code: 3,
        },
        {
          simplename: "地",
          name: "地理",
          code: 5,
        },
      ], //政策数据
      FHkemLists: [], //政策数据
      Policy: 0, //政策类型

      ProvinceName: "",
      userInfo: "",
      code: "",
      ScoreCount: 10,
      ProvinceObj: {},
      listInit: [],
      value: 2025, //年份
      grade: "", //分数
      ranking: "", //排名

      RankingType: [
        { Id: 0, Name: "校友会" },
        { Id: 1, Name: "武书连" },
        { Id: 2, Name: "软科" },
        { Id: 3, Name: "QS" },
        // { Id: 4, Name: "泰晤士河报" },
      ],

      Rankinglist: [],
      Rankinglist1: [],
      Rankinglist2: [],
      Rankinglist3: [],
      busSpecialistGrouList: [],
      CpList: [
        {
          url: "https://cp.ycymedu.com/carrerpostion",
          image: require("./images/1.png"),
          title: "性格测评（MBTI）",
          introduce:
            "帮助学生根据个人特点科学选择高考志愿，匹配兴趣与职业规划，提升录取机会。",
        },
        {
          url: "https://cp.ycymedu.com/carrerpostion",
          image: require("./images/2.png"),
          title: "兴趣测评（Holland）",
          introduce:
            "基于职业兴趣类型，帮助学生了解自身兴趣，指导志愿选择，提升职业匹配度。",
        },
        {
          url: "https://cp.ycymedu.com/carrerpostion",
          image: require("./images/3.png"),
          title: "能力测评（MIDAS）",
          introduce:
            "多元智能理论的测评工具，旨在评估个体在八种不同智能领域的能力。",
        },
        {
          url: "https://cp.ycymedu.com/carrerpostion",
          image: require("./images/4.png"),
          title: "职业锚（Career ）",
          introduce:
            "个体在职业生涯中最重要的价值观和动机，这些价值观和动机会影响其职业决策。",
        },
      ],
      CpList1: [
        {
          url: "https://cp.ycymedu.com/psychology",
          image: require("./images/11.png"),
          title: "SAS焦虑测评",
          introduce:
            "常用于评估个体焦虑水平的心理测量工具。帮助测量个体在一段时间内的焦虑症状",
        },
        {
          url: "https://cp.ycymedu.com/psychology",
          image: require("./images/22.png"),
          title: "SDS抑郁测评",
          introduce:
            "帮助学生识别潜在的抑郁症状，特别是在高考等压力较大的时期。",
        },
        {
          url: "https://cp.ycymedu.com/psychology",
          image: require("./images/33.png"),
          title: "SCL-90症状自评",
          introduce:
            "学生能够及时发现潜在的心理问题，采取有效的干预措施，改善心理健康状态。",
        },
        {
          url: "https://cp.ycymedu.com/psychology",
          image: require("./images/44.png"),
          title: "中学生心理健康自评",
          introduce:
            "能有效帮助学生提高心理健康意识，预防和应对学业、成长等带来的心理困扰。",
        },
      ],
      CpList2: [
        {
          url: "https://cp.ycymedu.com/studystatus",
          image: require("./images/111.png"),
          title: "Solomon学习风格",
          introduce:
            "了解自己最有效的学习方式，选择符合自己学习风格的专业和职业方向。",
        },
        {
          url: "https://cp.ycymedu.com/studystatus",
          image: require("./images/222.png"),
          title: "学生考试焦虑症测试",
          introduce:
            "了解自己在的焦虑水平，及时采取应对措施，帮助缓解考试压力，提高考试表现。",
        },
        {
          url: "https://cp.ycymedu.com/studystatus",
          image: require("./images/333.png"),
          title: "学习技能",
          introduce:
            "学习过程中使用的各种策略和技巧，帮助他们更高效地获取、处理和应用知识。",
        },
      ],
      Specialist: [
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "志愿填报家长辅导班，填报技巧逐一击破",
          isTRUE: false,
          image: require("./images/zzb1.png"),
        },
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "你真的了解志愿填报的策略吗？进阶指南",
          isTRUE: true,
          image: require("./images/zzb2.png"),
        },
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "掌握志愿填报策略如何避坑？",
          isTRUE: true,
          image: require("./images/zzb3.png"),
        },
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "新手填报必看手把手带你入门",
          isTRUE: true,
          image: require("./images/zzb4.png"),
        },
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "教你如何布局志愿填报，名校不难进选对专业是关键",
          isTRUE: true,
          image: require("./images/zzb5.png"),
        },
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "规划正当时如何权衡分数与专业兴趣",
          isTRUE: true,
          image: require("./images/zzb6.png"),
        },
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "如何根据未来发展选择专业？六纬专家帮您深度洞察",
          isTRUE: true,
          image: require("./images/zzb7.png"),
        },
        {
          url: "https://kp.ycymedu.com/web/special/special_cate.html?cate_id=17&subject_id=19",
          title: "你真的了解志愿填报的策略吗？进阶指南",
          isTRUE: true,
          image: require("./images/zzb8.png"),
        },
      ],

      objs: {

        CategoryId: 1,
        PageIndex: 1,
        PageSize: 18,
      },

      Newslist: [],
    };
  },
  //1.在computed中把数据返回
  computed: {
    provinceShow() {
      return this.$store.state.user.provinceShow;
    },
    isProvinceName() {
      return this.$store.state.user.ProvinceName;
    },
    formattedDate() {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formatter = new Intl.DateTimeFormat("zh-CN", options);
      const parts = formatter.formatToParts(new Date(this.dateString));
      let formatted = "";
      for (const part of parts) {
        if (
          part.type === "year" ||
          part.type === "month" ||
          part.type === "day"
        ) {
          formatted += part.value;
        }
      }
      return formatted;
    },
  },
  // 在watch中监听
  watch: {
    provinceShow: {
      immediate: true,
      handler(newVal) {
        if (newVal !== "") {
          this.provinceIsShow = newVal;
        }
      },
    },
    // 监听省份变化
    isProvinceName: {
      immediate: true,
      handler(newVal) {
        if (newVal !== "") {
          console.log("newVal", newVal);
          this.ProvinceName = newVal;
          var index = this.listInit.findIndex(
            (item) => item.ProvinceName == newVal
          );
          if (index !== -1) {
            this.ProvinceObj = this.listInit[index];
            if (index !== -1) {
              if (this.isInitialLoad) {
                this.isInitialLoad = false;
              } else {
                this.rest();
              }
              this.Policy = this.ProvinceObj.lizations[0].Policy;
              this.value = this.ProvinceObj.lizations[0].code;
              this.getRules(); //获取全国选科规则
            }
          }
        }
      },
    },
  },
  created() {

  },
  mounted() {
    const token = getToken();
    const { isExpired } = parseToken(token);
    if (isExpired == false) {
      this.getUserInfo();
    }
    this.first();
    if (this.$route.query.code !== undefined) {
      this.getCallback(this.$route.query);
    }
    if (
      sessionStorage.getItem("visited") &&
      this.$route.query.code == undefined
    ) {
      this.getUserInfo();
    }
  },

  methods: {
    // 检查 Token 是否过期
    checkTokenExpiration(token) {
      try {
        const parts = token.split('.');
        if (parts.length !== 3) throw new Error('Token 格式无效');

        // 解码 Payload 部分
        const payload = JSON.parse(
          atob(parts[1].replace(/-/g, '+').replace(/_/g, '/'))
        );

        // 获取当前时间和 Token 的过期时间
        const currentTime = Math.floor(Date.now() / 1000); // 当前时间戳 (秒)
        const expirationTime = payload.exp;

        if (!expirationTime) throw new Error('Token 中未包含过期时间');

        if (currentTime >= expirationTime) {
          this.isTokenExpired = true;
        } else {
          this.isTokenExpired = false;
          this.tokenInfo = {
            expirationTime: new Date(expirationTime * 1000).toLocaleString(), // 转换为本地时间
          };
          console.log('过期时间', this.tokenInfo.expirationTime);
        }
      } catch (error) {
        console.error('Token 解析失败:', error.message);
        this.isTokenExpired = true; // 无法解析时视为过期
      }
    },
    //基本信息接口
    first() {
      this.getList(); // 获取榜单排行

      this.BusSpecialistGroup(); // 获取专家列表

      this.getNewsList(); //获取高考资讯

      // this.getSpeciallist(); //H5产品列表

      // this.getCallback(this.$route.query);
    },
    handleClose(done) {
      done();
    },
    //测评体系切换
    clickShowCP(num) {
      this.showCp = num;
    },
    startCountdown() {
      if (this.userInfo == "") {
        this.getUserInfo();
        return;
      }
      // 如果有正在进行的倒计时，清除它
      if (this.timer) {
        clearInterval(this.timer);
      }

      // 重置倒计时和状态
      this.countdown = 3;
      this.subscribe = true;

      // 开始倒计时
      this.timer = setInterval(() => {
        this.countdown--;

        // 倒计时结束
        if (this.countdown <= 0) {
          clearInterval(this.timer); // 清除定时器
          this.timer = null; // 重置定时器引用
          this.subscribe = false; // 设置订阅状态为 false
        }
      }, 1000);
    },
    beforeDestroy() {
      // 确保组件销毁时清除定时器
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    //获取全国选科规则
    getRules() {
      // 0 = 文理二选一
      // 1 = 物 化 生 史 政 地 六选三
      // 2 = 物 化 生 史 政 地 技 七选三
      // 3 = 物 化 生 史 政 地 （物，历必选一个，其他任选两门）
      request2
        .get("https://api.static.ycymedu.com/api/v1/base/rules.json")
        .then((res) => {
          const list = res.data.result;
          // 筛选出 p=0 对应的 rule
          if (this.Policy == 0) {
            this.kemLists = list
              .filter((item) => item.p === 0)
              .map((item) => item.rule)
              .flat()
              .map((rule) => ({ ...rule, CR: false }));
          } else if (this.Policy == 1) {
            this.kemLists = list
              .filter((item) => item.p === 1)
              .map((item) => item.rule)
              .flat()
              .map((rule) => ({ ...rule, CR: false }));
          } else if (this.Policy == 2) {
            this.kemLists = list
              .filter((item) => item.p === 2)
              .map((item) => item.rule)
              .flat()
              .map((rule) => ({ ...rule, CR: false }));
          } else {
            this.kemLists = list
              .filter((item) => item.p === 3)
              .map((item) => item.rule)
              .flat()
              .map((rule) => ({ ...rule, CR: false }));
          }

          // 判断是否有回显数据
          if (this.FHkemLists.length !== 0) {
            // 将科目组字符串拆分成数组
            const subjectGroupArray = this.FHkemLists.split(",");

            // 遍历科目列表，将 CR 设置为 true 如果科目简写在 subjectGroup 中
            const updatedSubjectList = this.kemLists.map((subject) => {
              // 如果科目简写在 subjectGroupArray 中，则设置 CR 为 true
              if (subjectGroupArray.includes(subject.simplename)) {
                return { ...subject, CR: true };
              }
              return subject; // 否则，返回原科目信息
            });
            this.kemLists = updatedSubjectList;
          }

          console.log("政策是", this.Policy, "的科目", this.kemLists);
        })
        .catch((error) => {
          console.error("Request failed:", error); // 打印错误信息
        });
    },

    // 跳转学校详情
    goSchoolDetails(Id) {
      const routeData = this.$router.resolve({
        path: "/Home/SchoolDetails",
        query: { Id },
      });
      window.open(routeData.href, "_blank");
    },

    //高考资讯查看更多
    goNew(url) {
      this.$router.push(url);
    },

    // 跳转到资讯详情
    goArticlelist(id) {
      this.$router.push({ path: "/Home/Articlelist", query: { id } });
    },

    // 高考资讯
    async getNewsList() {
      const res = await GetPageNews({
        ...this.objs,
      });
      this.Newslist = res.data.response.data;
    },

    // 数据回填
    async backfill(item) {
      if (item.userExtend !== null) {
        this.value = item.userExtend.year || 2025;
        this.Policy = item.userExtend.sp || 0;
        this.grade = item.userExtend.expectedScore || "";
        this.FHkemLists = item.userExtend.subjectGroup || "";
      }

      await this.getSeVolunteerList(); //获取省份年份
      await this.getRules(); //获取全国选科规则
    },

    // 保存修改学生分数信息
    async commitUserInfo(obj, page) {
      request2
        .post("/api/weChatUserEx/perfectInfo", obj)
        .then((res) => {
          console.log(res.data.code == 200);
          if (res.data) {
            // 提示提交成功
            this.$message({
              showClose: true,
              message: "选择通过，数据已提交。",
              type: "success",
            });
            this.navigateToPage(page);
          }
        })
        .catch((error) => {
          console.error("Request failed:", error); // 打印错误信息
        });
    },
    // 专家列表信息
    async BusSpecialistGroup() {
      const res = await busSpecialistGroup();
      this.busSpecialistGrouList = res.data.result.slice(0, 6);
    },
    // 专家列表信息
    async getSpeciallist() {
      const res = await speciallist();
      this.Specialist = res.data.result.slice(0, 4);
    },

    // 获取榜单排行
    async getList() {
      const res = await GetPcUniversityRank({
        Type: 0,
        PageIndex: 1,
        PageSize: 4,
      });
      this.Rankinglist = [];
      this.Rankinglist = res.data.response;
      this.getList1();
    },
    async getList1() {
      const res = await GetPcUniversityRank({
        Type: 1,
        PageIndex: 1,
        PageSize: 4,
      });
      this.Rankinglist1 = [];
      this.Rankinglist1 = res.data.response;
      this.getList2();
    },
    async getList2() {
      const res = await GetPcUniversityRank({
        Type: 2,
        PageIndex: 1,
        PageSize: 4,
      });
      this.Rankinglist2 = [];
      this.Rankinglist2 = res.data.response;
      this.getList3();
    },
    async getList3() {
      const res = await GetPcUniversityRank({
        Type: 3,
        PageIndex: 1,
        PageSize: 4,
      });
      this.Rankinglist3 = [];
      this.Rankinglist3 = res.data.response.slice(0, 4);
    },
    // 回跳获取id
    async getCallback(query) {
      if (this.isObjectNotEmpty(query)) {
        const res = await Callback({
          code: query.code,
          state: query.state,
          scope: "",
        });
        if (res.data.success) {
          this.$store.commit(
            "user/setSerToken",
            res.data.response.servicetoken
          );
          this.$store.commit("user/setTokens", res.data.response.token);
          this.$store.commit("user/setIds", res.data.response.Id);
          this.$store.commit(
            "user/setthridtoken",
            res.data.response.servicetoken
          );
        }
      }
      await this.getUserInfo();
    },

    //检查回跳参数不为空
    isObjectNotEmpty(obj) {
      return Object.keys(obj).length > 0;
    },

    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.userInfo = res.data.result;

      // 用户登录成功后清除 unauthorizedHandled 标志
      resetUnauthorizedHandled();

      if (this.userInfo.batchDataUrl == "") {
        this.$store.commit("user/setProvinceShow", true);
      }

      this.$store.dispatch("user/removeStudentInfo");
      this.$store.commit("user/setStudentInfo", res.data.result);
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
      sessionStorage.setItem("visited", "true"); // 设置标记
      setTimeout(() => {
        this.backfill(res.data.result);
      }, 200);
      // // 判断用户完善信息是否完整
      // if (!res.data.result.userExtend) {
      //   this.$router.push("/Home/Register");
      // }
    },
    goCp() {
      window.open("https://cp.ycymedu.com/carrerpostion", "_blank");
    },
    //tabs 跳转页面
    goDiplomaMill(path) {
      this.$router.push(path);
    },

    // 选择年份
    selectChanged(e) {
      this.value = e.code;
      this.Policy = e.Policy;
      this.getRules(); //获取全国选科规则
    },

    // 获取省份年份
    async getSeVolunteerList() {
      const res = await GetSeVolunteerInitialization();
      const { response } = res.data; // 解构赋值简化访问
      this.listInit = response;
      this.$store.commit(
        "user/setProvinceName",
        this.userInfo.userExtend.provinceName
      );
    },
    goH5(url, type) {
      if (type == 1) {
        if (this.userInfo == "") {
          this.getUserInfo();
          return;
        }
        window.open(url, "_blank");
      } else {
        window.open(url, "_blank");
      }
    },
    // 点击科目进行选择
    cliSub(it) {
      // 0 = 文理二选一
      // 1 = 物 化 生 史 政 地 六选三
      // 2 = 物 化 生 史 政 地 技 七选三
      // 3 = 物 化 生 史 政 地 （物理，历史必选一个，其他任选两门）

      if (this.Policy == 0) {
        if (it.CR) {
          // 如果当前已选中，取消选中
          it.CR = false;
        } else {
          // 如果当前未选中，取消其他项的选中状态，并选中当前项
          this.kemLists.forEach((i) => (i.CR = false)); // 取消所有项的选中状态
          it.CR = true; // 选中当前项
        }
      } else if (this.Policy == 1) {
        // 计算当前选中的元素数量
        const selectedItemsCount = this.kemLists.filter((i) => i.CR).length;
        if (it.CR) {
          // 如果当前是选中状态，允许取消选中
          it.CR = false;
        } else {
          // 如果当前不是选中状态，判断是否可以选中
          if (selectedItemsCount < 3) {
            it.CR = true; // 允许选中
          } else {
            // 超过选择限制
            this.$message({
              showClose: true,
              message: `最多只能选择3个科目`,
              type: "warning",
            });
          }
        }
      } else if (this.Policy == 2) {
        // 获取当前已选中的科目数量
        const selectedItems = this.kemLists.filter((i) => i.CR);

        // 如果当前科目没有被选中
        if (!it.CR) {
          // 如果已选择的科目数量小于3，允许选择
          if (selectedItems.length < 3) {
            it.CR = true;
          } else {
            this.$message({
              showClose: true,
              message: `最多只能选择3个科目`,
              type: "warning",
            });
          }
        } else {
          // 如果当前科目已经被选中，点击后取消选择
          it.CR = false;
        }
      } else if (this.Policy == 3) {
        // 获取已选择的首选科目（物理、历史）
        const selectedFirstItems = this.kemLists.filter(
          (i) => i.isfirst === "true" && i.CR
        );
        // 获取已选择的次选科目（化学、生物、政治、地理）
        const selectedOtherItems = this.kemLists.filter(
          (i) => i.isfirst === "false" && i.CR
        );

        if (it.isfirst === "true") {
          // 如果点击的是首选科目（物理或历史）
          if (it.CR) {
            // 如果该首选科目已经被选中，点击后取消选择
            it.CR = false;
          } else {
            // 如果该科目没有选中，则需要将其他选中的首选科目取消，并选中当前科目
            if (selectedFirstItems.length > 0) {
              // 如果已经选择了一个首选科目（物理或历史），取消它的选中状态
              selectedFirstItems[0].CR = false;
            }
            it.CR = true; // 设置当前点击的科目为选中状态
          }
        } else {
          // 处理次选科目的选择，最多只能选两个
          if (it.CR) {
            // 如果取消选择，直接取消
            it.CR = false;
          } else {
            // 如果当前选择的科目数小于2个，可以继续选
            if (selectedOtherItems.length < 2) {
              it.CR = true;
            } else {
              this.$message({
                showClose: true,
                message: `最多只能选择2个科目`,
                type: "warning",
              });
            }
          }
        }
      }
    },

    // 检查用户选择是否符合Policy的要求
    checkSelection() {
      const selectedItems = this.kemLists.filter((i) => i.CR); // 获取已选中的科目

      // 验证value和grade是否为空
      if (!this.value || !this.grade) {
        this.$message({
          showClose: true,
          message: "年份和分数不能为空。",
          type: "warning",
        });
        return false;
      }

      // Policy 0: 文理二选一
      if (this.Policy === 0) {
        const selectedSubjects = selectedItems.map((i) => i.name); // 获取已选择的科目名
        if (
          selectedSubjects.includes("文科") &&
          selectedSubjects.includes("理科")
        ) {
          this.$message({
            showClose: true,
            message: "文理科只能选择一个，请重新选择。",
            type: "warning",
          });
          return false;
        }
        if (selectedItems.length !== 1) {
          this.$message({
            showClose: true,
            message: "请选一个科目（文科或理科）。",
            type: "warning",
          });
          return false;
        }
      }

      // Policy 1: 物、化、生、史、政、地 六选三
      if (this.Policy === 1) {
        if (selectedItems.length !== 3) {
          this.$message({
            showClose: true,
            message: "请从物理、化学、生物、历史、政治、地理中选择3个科目。",
            type: "warning",
          });
          return false;
        }
      }

      // Policy 2: 物、化、生、史、政、地、技 七选三
      if (this.Policy === 2) {
        if (selectedItems.length !== 3) {
          this.$message({
            showClose: true,
            message:
              "请从物理、化学、生物、历史、政治、地理、信息技术中选择3个科目。",
            type: "warning",
          });
          return false;
        }
      }

      // Policy 3: 物、化、生、史、政、地（物理和历史必选一个，其他任选两个）
      if (this.Policy === 3) {
        const selectedFirstItems = selectedItems.filter(
          (i) => i.isfirst === "true"
        ); // 首选科目（物理、历史）
        const selectedOtherItems = selectedItems.filter(
          (i) => i.isfirst === "false"
        ); // 其他科目

        // 必须选择物理或历史
        if (selectedFirstItems.length !== 1) {
          this.$message({
            showClose: true,
            message: "必须选择物理或历史其中一个。",
            type: "warning",
          });
          return false;
        }

        // 其他科目最多只能选择两个
        if (selectedOtherItems.length > 2) {
          this.$message({
            showClose: true,
            message: "最多只能选择两个次选科目（化学、生物、政治、地理）。",
            type: "warning",
          });
          return false;
        }

        // 选择的总科目数必须为3个
        if (selectedItems.length !== 3) {
          this.$message({
            showClose: true,
            message: "必须选择3个科目。",
            type: "warning",
          });
          return false;
        }
      }

      // 如果没有问题，返回true
      return true;
    },

    // 在用户提交前调用该方法来进行验证
    async submitSelection(page) {
      if (this.userInfo == "") {
        this.getUserInfo();
        return;
      }
      if (this.ProvinceName == null) {
        console.log(this.ProvinceName);
        this.$store.commit("user/setProvinceShow", true);
        return false;
      }
      // 检查选择是否符合要求
      if (this.checkSelection()) {
        // 如果验证通过，收集数据并打印

        // 获取已选中的科目名称（simplename字段）
        const selectedSimplenames = this.kemLists
          .filter((i) => i.CR)
          .map((i) => i.simplename)
          .join(",");

        // 打印结果
        console.log("提交的年份:", this.value);
        console.log("提交的分数:", this.grade);
        console.log("已选择的科目:", selectedSimplenames);

        const obj = {
          year: this.value,
          subjectGroup: selectedSimplenames,
          score: this.grade,
          provinceCode: this.ProvinceObj.Code,
          sp: this.Policy,
        };
        // 提交接口
        await this.commitUserInfo(obj, page);
      } else {
        console.log("选择不符合要求");
      }
    },

    // 提取一个通用的页面跳转函数
    navigateToPage(page) {
      const path = page === 1 ? "/Home/Homepage" : "/Home/OneKey";
      this.$router.push(path);
    },

    // 重置
    rest() {
      this.value = "";
      this.kemLists = [];
      this.FHkemLists = [];
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  border-radius: 12px 12px 12px 12px;
}

::v-deep .el-submenu__icon-arrow {
  font-size: 16px;
  font-weight: bold;
}

::v-deep .el-dialog {
  display: flex;
  display: -ms-flex;
  /* 兼容IE */
  flex-direction: column;
  -ms-flex-direction: column;
  /* 兼容IE */
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  border-radius: 12px 12px 12px 12px;
}

::v-deep .el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  /* 兼容IE */
  overflow-y: auto;
  overflow-x: hidden;
}

.diaB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icimg {
    width: 72px;
    height: 72px;
  }

  .ictext {
    margin: 16px 0 8px 0;
    font-size: 24px;
    color: #000000;
  }

  .ictext1 {
    font-size: 16px;
    color: #888888;
  }

  .miao {
    font-size: 14px;
    color: #187cff;
    margin-top: 8px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.vipDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  padding: 24px 25px 30px 25px;
  background-image: url("./images/编组 3@2x.png");
  background-size: 100%;
  border-radius: 25px;
  z-index: 1999;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;

      .photo {
        width: 80px;
        height: 80px;
        margin-right: 14px;
        border-radius: 50%;
        overflow: hidden;
      }

      .right {
        .phone {
          width: 205px;
          font-size: 30px;
          font-weight: 600;

          color: #333333;
        }

        .text {
          text-align: center;
          line-height: 34px;
          font-size: 18px;
          font-weight: normal;
          color: #ffffff;
          width: 205px;
          height: 34px;
          background: linear-gradient(151deg, #387bff 0%, #6b4dff 100%);
          border-radius: 17px;
        }
      }
    }

    .jhm {
      cursor: pointer;
      width: 169px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background: linear-gradient(to right, #595263, #282537);
      border-radius: 10px;
      font-size: 20px;
      color: #ffffff;
    }
  }

  .vipContent {
    display: flex;
    justify-content: space-between;

    .vipLeft {
      .vipEquity {
        width: 600px;
        height: 353px;
      }

      .vipEquityText {
        box-sizing: border-box;
        width: 560px;
        height: 258px;
        background: #ffffff;
        box-shadow: 0px 3px 20px 0px rgba(171, 122, 24, 0.18);
        border-radius: 21px;
        margin-left: 20px;
        padding: 23px 20px;
        font-size: 20px;
        color: #666666;

        div {
          margin-bottom: 15px;

          .tt {
            color: #333333;
            font-weight: 600;
          }
        }
      }

      .details {
        cursor: pointer;
        width: 170px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        background: linear-gradient(to right, #595263, #282537);
        border-radius: 10px;
        font-size: 20px;
        margin-left: (50%-20px);
        margin-top: 24px;
        color: #ffffff;
      }
    }

    .vipright {
      position: relative;
      width: 358px;
      height: 715px;
      background-image: url("./images/编组 7@2x.png");
      background-size: 100%;

      .money {
        position: absolute;
        top: 132px;
        width: 260px;
        left: 50%;
        transform: translateX(-50%);
        height: 88px;
        font-size: 60px;
        font-family: AaHouDiHei;
        color: #f5d09b;
        line-height: 45px;
        text-align: center;
      }

      .payment {
        position: absolute;
        top: 280px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .payTab {
          width: 260px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 22px;
          margin-bottom: 59px;
          font-size: 18px;

          .wx {
            cursor: pointer;
            width: 124px;
            height: 43px;
            background: #28c445;
            border-radius: 10px;
            color: #ffffff;
            text-align: center;
            line-height: 43px;
          }

          .zfb {
            cursor: pointer;
            width: 124px;
            height: 43px;
            background: #ffffff;
            border-radius: 10px;
            text-align: center;
            line-height: 43px;
            color: #1677ff;
          }
        }

        .palyCode {
          width: 158px;
          height: 158px;
        }
      }
    }
  }
}

.box {
  background: #f5f4f8;
  padding-bottom: 20px;
}

img {
  width: 100%;
  height: 100%;
}

::v-deep .el-input__inner {
  padding: 1px 40px !important;
}

.swp {
  margin-bottom: 20px;
  position: relative;
  height: 440px;

  .choiceW {
    width: 1280px;
    margin: 0 auto;
    position: relative;
  }

  .choiceBox {
    position: absolute;
    top: -400px;
    right: 0;
    width: 338px;
    height: 350px;
    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    span {
      min-width: 64px;
      font-size: 16px;
      margin-right: 18px;
    }

    .year {
      display: flex;
      align-items: center;

      .el-select {
        height: 40px;

        ::v-deep .el-input__inner {
          border-radius: 10px;
          padding: 1px 40px !important;
          border: 1px solid #e1e1e1 !important;
        }
      }
    }

    .mold {
      display: flex;
      align-items: center;

      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color: #187cff !important;
        background: #187cff !important;
      }

      ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: #187cff;
      }
    }

    .subjects {
      display: flex;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 64px;
        font-size: 16px;
        margin-right: 18px;
      }

      .subject {
        cursor: pointer;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .active {
          color: #ffffff !important;
          background: linear-gradient(to right, #3370ff, #5bb0ff) !important;
        }

        .item {
          min-width: 54px;
          text-align: center;
          line-height: 29px;
          height: 29px;
          border-radius: 5px;
          border: 1px solid #e1e1e1;
          margin-bottom: 6px;
          font-size: 16px;
          font-weight: 400;
          padding: 0 5px;
          margin-right: 6px;
        }
      }
    }

    .grade {
      display: flex;
      align-items: center;
      position: relative;

      .el-input {
        position: relative;
        font-size: 14px;
        display: inline-block;
      }

      .f {
        position: absolute;
        top: 8px;
        right: 5px;
        font-size: 16px;
        color: #8a8a8a;
      }
    }

    .btnss {
      bottom: 0px !important;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .but {
        width: 98px;
        height: 44px;
        cursor: pointer;
      }

      .but1 {
        width: 184px;
        height: 44px;
        cursor: pointer;
      }
    }
  }
}

.tabs {
  box-sizing: border-box;
  width: 1280px;
  margin: 0 auto;
  padding: 0 40px 12px 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;

  .item:hover .imgs {
    transform: scale(1.1) translateY(-10px);
    /* 放大 1.1 倍并上移 10px */
  }

  .item:hover .text {
    color: #3370ff;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .imgs {
      width: 80px;
      height: 80px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      /* 添加过渡效果 */
    }

    .text {
      margin-top: 5px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.appraisal {
  width: 1343px;
  height: 657px;
  background: #ffffff;
  padding: 34px 29px 48px 36px;
  margin: 20px auto;
  box-sizing: border-box;

  .title {
    font-size: 22px;
    font-weight: 600;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .item {
      cursor: pointer;
      margin-top: 37px;
      width: 614px;
      height: 239px;
      transition: all 0.5s ease-in-out;
    }

    .item:hover {
      transform: scale(1.11);
    }
  }
}

.contentList {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;

  .liveStreaming {
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 0 20px 0;
      font-weight: bold;
      font-size: 24px;

      .text {
        font-weight: 600;
        font-size: 22px;
      }

      .more {
        cursor: pointer;
        font-size: 18px;
        color: #aeaeae;
        font-weight: normal;
        display: flex;
        align-items: center;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .item:nth-child(4n) {
        margin-right: 0;
        /* 每行的最后一个元素去掉右边距 */
      }

      .item:hover {
        transform: translateY(-10px);
        /* 向上浮起 */
      }

      .item {
        position: relative;
        flex: 1 0 calc(25% - 16px);
        /* 每个元素占用容器的 25% 减去间距 */
        box-sizing: border-box;
        box-shadow: 0px 4px 4px 0px rgba(226, 226, 226, 0.25);
        border-radius: 8px;
        box-sizing: border-box;
        width: calc(25% - 16px);
        /* 每个元素的宽度，减去间距 */
        background: #ffffff;
        height: 260px;
        margin-right: 16px;
        margin-bottom: 16px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        /* 添加过渡效果 */
        overflow: hidden;

        /* 隐藏超出部分 */
        .tp {
          height: 174px;
          background: #d9d9d9;
          border-radius: 4px 4px 0px 0px;
        }

        .name {
          font-weight: bold;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 16px;
          box-sizing: border-box;
          border-radius: 8px 8px 8px 8px;
        }

        .ISvip {
          position: absolute;
          top: 0;
          right: 0;
          width: 40px;
          height: 18px;
          background: linear-gradient(51deg, #00c3fc 0%, #00acf7 100%);
          border-radius: 0px 8px 0px 8px;
          text-align: center;
          line-height: 18px;
          font-weight: 600;
          font-size: 12px;
          color: #ffffff;
        }

        .ISNoVip {
          position: absolute;
          top: 0;
          right: 0;
          width: 40px;
          height: 18px;
          background: linear-gradient(229deg, #fab55e 0%, #ffeec3 100%);
          border-radius: 0px 8px 0px 8px;
          text-align: center;
          line-height: 18px;
          font-weight: 600;
          font-size: 12px;
          color: #663800;
        }
      }
    }
  }

  .cptx {
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 0 20px 0;
      font-weight: bold;
      font-size: 24px;

      .text {
        font-weight: 600;
        font-size: 22px;
      }

      .cptxTabs {
        font-size: 18px;
        color: #aeaeae;
        font-weight: normal;
        margin-right: 12px;
        display: flex;

        .isActiveTabssitem {
          color: #187cff !important;
          font-weight: bold;
        }

        .tabssitem {
          display: flex;
          align-items: center;
          padding-right: 16px;

          .split-vline {
            display: inline-block;
            width: 1px;
            height: 14px;
            background: #dddddd;
            margin: 0 15px;
          }
        }
      }

      .more {
        cursor: pointer;
        font-size: 18px;
        color: #aeaeae;
        font-weight: normal;
        display: flex;
        align-items: center;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .cp1:nth-child(4n) {
        margin-right: 0;
        /* 每行的最后一个元素去掉右边距 */
      }

      .cp1:hover {
        transform: translateY(-10px);
        /* 向上浮起 */
      }

      .cp2:hover {
        transform: translateY(-10px);
        /* 向上浮起 */
      }

      .cp3:hover {
        transform: translateY(-10px);
        /* 向上浮起 */
      }

      .cp1 {
        background: linear-gradient(90deg, #e5f2fa 0%, #dae8fa 100%);
      }

      .cp2 {
        background: linear-gradient(268deg, #caf4f0 0%, #e6f4f2 100%);
      }

      .cp3 {
        background: linear-gradient(270deg, #c2cefe 0%, #eff3fe 100%),
          linear-gradient(270deg, #cbd2f0 0%, #eff3fe 100%);
      }

      .cp1,
      .cp2,
      .cp3 {
        box-shadow: 0px 4px 4px 0px rgba(226, 226, 226, 0.25);
        border-radius: 8px;
        box-sizing: border-box;
        width: calc(25% - 16px);
        /* 每个元素的宽度，减去间距 */
        height: 152px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        padding: 16px 14px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        /* 添加过渡效果 */
        overflow: hidden;

        /* 隐藏超出部分 */
        .left {
          flex: 1;

          .tit {
            font-size: 18px;
            font-weight: bold;
          }

          .jc {
            margin-top: 12px;
            font-size: 12px;
            color: #999999;
          }
        }

        .right {
          width: 100px;
          height: 100px;
        }
      }

      .cp4 {
        cursor: pointer;
        width: calc(25% - 16px);
        /* 每个元素的宽度，减去间距 */
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 152px;
        background: #fff;
        border-radius: 8px 8px 8px 8px;
        padding: 16px 14px;
        box-sizing: border-box;
        margin-right: 16px;

        .top {
          width: 184.65px;
          height: 114px;
        }

        .btn {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }

  .counselor {
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 0 20px 0;
      font-weight: bold;
      font-size: 22px;

      .text {
        font-weight: 600;
        font-size: 22px;
      }

      .more {
        cursor: pointer;
        font-size: 18px;
        color: #aeaeae;
        font-weight: normal;
        display: flex;
        align-items: center;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .teacheritem:nth-child(3n) {
        margin-right: 0;
        /* 每行的最后一个元素去掉右边距 */
      }

      .teacheritem:nth-child(1) {
        background-image: url("./images/bg1.png");
      }

      .teacheritem:nth-child(2) {
        background-image: url("./images/bg2.png");
      }

      .teacheritem:nth-child(3) {
        background-image: url("./images/bg3.png");
      }

      .teacheritem:nth-child(4) {
        background-image: url("./images/bg4.png");
      }

      .teacheritem:nth-child(5) {
        background-image: url("./images/bg5.png");
      }

      .teacheritem:nth-child(6) {
        background-image: url("./images/bg6.png");
      }

      .teacheritem {
        flex: 1 0 calc(33.333% - 16px);
        /* 每个元素占用容器的 25% 减去间距 */
        box-sizing: border-box;
        width: calc(25% - 16px);
        /* 每个元素的宽度，减去间距 */
        margin-right: 16px;
        margin-bottom: 16px;
        width: 416px;
        height: 316px;
        background-size: 100%;
        border-radius: 16px;
        margin-right: 16px;
        position: relative;
        box-shadow: 0 14px 20px 1px rgba(0, 0, 0, 0.04);

        // background-image: url("./images/bg1.png");
        .teachername {
          margin-top: 20px;
          font-weight: 600;
          font-size: 20px;
          color: #333;
          width: 48%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
        }

        .expertisebox {
          position: absolute;
          right: 13%;
          width: 226px;
          display: flex;
          flex-wrap: wrap;

          .expertiseitem {
            width: 108px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            margin-right: 4px;
            border-radius: 4px;
            background: hsla(0, 0%, 100%, 0.5);
            margin-top: 4px;
          }
        }

        .introbox {
          position: absolute;
          bottom: 55px;
          left: 0;
          height: 133px;
          background: #fff;
          overflow: hidden;
          padding: 20px;
          box-sizing: border-box;
          width: 100%;

          .introboxo {
            height: 94px;
            overflow-y: auto;

            .introitem {
              font-size: 14px;
              color: #888;
              margin-top: 6px;
              position: relative;

              .greypoint {
                width: 4px;
                height: 4px;
                background: #aaa;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 7px;
                left: 2px;
              }

              .greytxt {
                margin-left: 12px;
                width: 350px;
                display: inline-block;
                line-height: 18px;
                overflow: hidden;
              }
            }
          }
        }

        .yuyuenow:hover {
          background: linear-gradient(91deg, #187cff, #076cef);
          color: #fff;
        }

        .yuyuenow {
          width: 100%;
          height: 54px;
          line-height: 54px;
          border-top: 1px solid #eee;
          font-size: 16px;
          color: #333;
          text-align: center;
          border-radius: 0 0 16px 16px;
          position: absolute;
          bottom: 0;
          left: 0;
          cursor: pointer;
        }

        .absoimg {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
          left: 32px;
          bottom: 215px;
        }
      }

      .subscribe {
        cursor: pointer;
        margin-top: 21px;
        width: 356px;
        height: 45px;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        color: #333333;
        border: 1px solid #e1e1e1;

        a {
          display: block;
          text-decoration: none;
          color: #333333;
        }
      }

      .subscribe:hover {
        cursor: pointer;
        margin-top: 21px;
        width: 356px;
        height: 45px;
        background: #187cff;
        border-radius: 10px;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        color: #ffffff;

        a {
          color: #ffffff;
        }
      }
    }
  }

  .gkzx {
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 0 20px 0;
      font-weight: bold;
      font-size: 22px;

      .text {
        font-weight: 600;
        font-size: 22px;
      }

      .more {
        cursor: pointer;
        font-size: 18px;
        color: #aeaeae;
        font-weight: normal;
        display: flex;
        align-items: center;
      }
    }

    .centerright {
      height: 438px;
      border-radius: 14px;
      background-color: #fff;
      padding: 28px 24px 0 24px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          display: flex;
          margin-bottom: 27px;
          cursor: pointer;

          .til {
            width: 280px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #333;
            display: inline-block;
            margin-right: 19px;
          }

          a:hover {
            text-decoration: none;
            color: #187cff !important;
          }

          .time {
            display: inline-block;
            font-size: 16px;
            color: #aaa;
          }
        }
      }
    }
  }

  .phb {
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 0 20px 0;
      font-weight: bold;
      font-size: 22px;

      .text {
        font-weight: 600;
        font-size: 22px;
      }

      .more {
        cursor: pointer;
        font-size: 18px;
        color: #aeaeae;
        font-weight: normal;
        display: flex;
        align-items: center;
      }
    }

    .center {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .school-item:hover .name {
      color: #187cff !important;
    }

    .schoolItem1,
    .schoolItem2,
    .schoolItem3,
    .schoolItem4 {
      width: 308px;
      min-height: 293px;
      border-radius: 8px 8px 8px 8px;
      width: 306px;

      .tit {
        margin-top: 28px;
        margin-left: 24px;
        line-height: 27px;
        font-weight: 700;
        font-size: 20px;
      }

      .school-box {
        margin-top: 38px;
        padding: 0 24px;

        div,
        .school-box .school-item {
          display: flex;
          align-items: center;
        }

        .school-item {
          margin-bottom: 24px;
          cursor: pointer;

          .school-index {
            font-size: 18px;
            color: #888;
            display: inline-block;
            width: 30px;
            text-align: left;
          }

          .schoolPhoto {
            width: 48px;
            height: 48px;
            margin-right: 12px;
          }

          .schoolName {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            .name {
              color: #333;
              font-size: 18px;
              margin-bottom: 10px;
              max-width: 180px;
              overflow: hidden;
              word-break: break-all;
            }

            .type {
              font-size: 14px;
              color: #888;
            }
          }
        }
      }
    }
  }

  .rightContent {
    width: 220px;
    height: 100%;

    .Banner {
      width: 100%;
      height: 341px;
      background: #979797;
      border-radius: 0px 0px 0px 0px;
    }

    .bdpm {
      margin-top: 19px;
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .ranking {
        font-weight: 600;
        font-size: 22px;
        display: flex;

        .icon {
          width: 28px;
          height: 27px;
          margin-right: 11px;
        }
      }

      .items {
        display: flex;
        padding: 27px 0;
        font-size: 12px;
        align-items: center;
        justify-content: space-between;

        .it {
          cursor: pointer;
        }

        .it:hover {
          color: #187cff;
        }

        .itemsActive {
          border-bottom: #187cff solid 2px;
        }
      }

      .schoolList {
        .its:hover {
          color: #187cff;
        }

        .its {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .number {
            text-align: center;
            line-height: 45px;
            width: 28px;
            height: 34px;
            margin-right: 17px;
            font-size: 16px;
          }

          .one {
            background-image: url("./images/火苗@2x.png");
            background-size: 100% 100%;
            color: #ffffff;
          }

          .two {
            background-image: url("./images/火苗备份@2x.png");
            background-size: 100% 100%;
            color: #ffffff;
          }

          .three {
            background-image: url("./images/火苗备份 2@2x.png");
            background-size: 100% 100%;
            color: #ffffff;
          }

          .schoolName {
            width: 60%;
            height: 34px;
            line-height: 45px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
