<template>
    <div>
        <div class="list">
            <div class="item">
                <div class="title">就业方向</div>
                <div class="introduceTit">
                    <div class="content">
                        {{ list3.jobs }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">具体职位</div>
                <div class="introduceTit">
                    <div class="tit">主要就业行业</div>
                    <div class="content">
                        {{ list3.profession }}
                    </div>
                </div>
                <!-- <div class="introduceTit">
                    <div class="tit">专业对口率</div>
                    <div class="content">
                        -
                    </div>
                </div> -->
            </div>
            <div class="item">
                <div class="title">从事行业</div>
                <div class="Canvas">
                    <CSHY :list3="list3.rates"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CSHY from "@/components/CSHY";
export default {
    props: {
        list3: {
            type: Object,
            required: true,
        },
    },
    name: "VoluntaryReportingIndex",
    components: { CSHY },
    data() {
        return {
            tableData: [{
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }, {
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }, {
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }, {
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }]
        };
    },

    mounted() { },

    methods: {},
};
</script>

<style lang="less">
.list {
    .item {
        .title {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 36px;
        }

        .introduceTit {
            .tit {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 16px;
            }

            .content {
                font-size: 16px;
                color: #666666;
                margin-bottom: 32px;
            }
        }

        .table {
            margin-bottom: 50px;
        }
    }
}
</style>
