<template>
    <div class="compBox" style="max-width: 1280px;margin: 0 auto;">
        <div class="table" v-loading="loading">
            <div class="tit">院校招生计划对比</div>
            <div class="selectBox">
                <el-select size="small" style="width: 100px;margin-right: 5px;" v-model="value" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select size="small" style="width: 100px;margin-right: 5px;" v-model="value1" placeholder="请选择">
                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select size="small" style="width: 250px;margin-right: 5px;" v-model="value2" placeholder="请选择">
                    <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="height: 31px;">
                <div class="pl20 dir-td f14 fw600">
                    招生方向：清华大学(A003)
                </div>
            </div>
            <div style="height: 36px;">
                <table class="my-table head" style="top: 154px; width: 100%;">
                    <thead class="my-table-head fw400">
                        <th width="100">代码</th>
                        <th class="text-left pl20">专业名称</th>
                        <th width="120">23年招生</th>
                        <th width="80">22年招生</th>
                    </thead>
                </table>
            </div>
            <div class="tab">
                <table class="my-table body">
                    <tbody class="my-table-body">
                        <tr class="stripe">
                            <td width="100" class="text-center"><span class="cntext fw600">A003</span></td>
                            <td class="pt20 pb20 pl20 "><span class="cntext fw600 f14 text-color-title">清华大学(A003)</span>
                            </td>
                            <!-- <td width="100" class="text-center"><span class="cntext fw600">1段</span></td>
                            <td width="100" class="text-center"><span class="cntext fw600">一</span></td>
                            <td width="130" class="text-center"><span class="cntext fw600">73</span></td> -->
                            <td width="120" class="text-center"><span class="cntext fw600">一</span></td>
                            <td width="80" class="text-center"><span class="cntext fw600">一</span></td>
                        </tr>
                        <tr v-for="it in 10" :key="it">
                            <td width="100" class="text-center"><span class="cntext">1A</span></td>
                            <td class="pt20 pb20 pl20 ">
                                <a href="http://zy.ycymedu.com/majors/small?code=140101" target="_blank"
                                    class="flex-column text-color-title">
                                    <span class="cntext fw600 f14">理科实验班类(1A)</span>
                                    <span class="cntext  f12 text-999 mt5">(物理基础类)物理学(等专业)</span>
                                </a>
                            </td>
                            <td width="120" class="text-center"><span class="cntext">10人</span><span class="tag">-5</span>
                            </td>
                            <td width="80" class="text-center"><span class="cntext">199人</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            tableData2: [],
            loading: false,
            options: [{
                value: '2023',
                label: '2023'
            }, {
                value: '2022',
                label: '2022'
            }, {
                value: '2021',
                label: '2021'
            }],
            value: '2023',
            options1: [{
                value: '1',
                label: '一段'
            }, {
                value: '2',
                label: '二段'
            }],
            value1: '1',
            options2: [{
                value: '001',
                label: '清华大学'
            }],
            value2: '001',
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.compBox {
    margin-top: 60px;

    .table {
        .tit {
            font-weight: bold;
            font-size: 28px;
            margin-bottom: 30px;
        }

        .selectBox {
            margin-bottom: 10px;
        }

        .dir-td {
            padding: 5px 12px;
            background: #fafafa;
            line-height: 20px;
            color: #1d2129;
            border: 1px solid #e8e8e8;
            border-bottom: none;
            font-size: 14px;
            padding-left: 20px;
            font-weight: bold;
        }

        .my-table {
            background-color: #fff;
            border-bottom: 1px dashed #e9e9e9;
            border-right: 1px solid #e8e8e8;
            border-top: 1px solid #e8e8e8;
            border-collapse: collapse;
            border-spacing: 0;

            .my-table-head th:first-child {
                border-left: 1px solid #e8e8e8;
            }

            .my-table-head th {
                border-bottom: 1px solid #e8e8e8;
                padding: 9px 0;
                font-size: 12px;
                color: #86909c;
            }

            .text-left {
                text-align: left !important;
            }

            .pl20 {
                padding-left: 20px !important;
            }
        }

        .tab {
            .my-table.body {
                border-top: none;
                width: 100%;

                tr.stripe {
                    background: #f7f7f7;

                }

                .text-color-title {
                    color: #1d2129;
                }

                .text-center {
                    text-align: center !important;

                    .tag {
                        display: inline-block;
                        width: 25px;
                        height: 15px;
                        background: #E6F8E7;
                        color: #38AE70;
                        font-size: 12px;
                        text-align: center;
                        line-height: 15px;
                        margin-left: 5px;
                    }
                }

                td {
                    font-size: 12px !important;
                    border-bottom: 1px solid #e8e8e8;
                    border-left: 1px solid #e8e8e8;
                    color: #333;
                }

                .pl20 {
                    padding-left: 20px !important;
                }

                .pb20 {
                    padding-bottom: 20px !important;
                }

                .pt20 {
                    padding-top: 20px !important;
                }

                .flex-column {
                    display: flex;
                    text-decoration: none;
                    flex-direction: column;

                    .fw600 {
                        font-weight: 600 !important;
                    }

                    .f14 {
                        font-size: 14px !important;
                    }

                    .text-999 {
                        color: #999 !important;
                    }

                    .f12 {
                        font-size: 12px !important;
                    }

                    .mt5 {
                        margin-top: 5px !important;
                    }

                    .cntext {
                        font-family: cntext !important;

                    }


                }
            }
        }
    }
}
</style>