import {request,request2} from "@/utils/request";


// 院校详情
export const GetUniversityDetails = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetUniversityDetails`,
    params,
  });
};
// 获取招生计划 院校详情
export const GetPlanBySchollId = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetPlanBySchollId`,
    params,
  });
};

// 获取院校各年份省控线 (Auth)
export const GetQualificationLine = (params) => {
  return request({
    method: "get",
    url: `/api/QualificationLine/Get`,
    params,
  });
};
// 获取学校专业分数线 (Auth)
export const GetPlanMajorScoreLinee = (params) => {
  return request({
    method: "get",
    url: `/api/PlanMajorScoreLine/Get`,
    params,
  });
};
// 查询扩缩招院校招生计划详情 (Auth)
export const GetMajorPlanPro = (params) => {
  return request({
    method: "get",
    url: `/api/D_PlanMajorDesc/GetMajorPlanPro`,
    params,
  });
};
// 获取批次
export const GetUniversityPlanBatch = (params) => {
  return request({
    method: "get",
    url: `/api/front/Volunteer/GetUniversityPlanBatch?Year`,
    params,
  });
};
// 获取学校各类型排名情况
export const GetUniversityRank = (params) => {
  return request({
    method: "get",
    url: `/api/front/UniversityRank/Single`,
    params,
  });
};

// 获取学科评估
export const GetSubjectEvaluate = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetSubjectEvaluate`,
    params,
  });
};
// 获取特色专业
export const GetFeaturedMajor = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetFeaturedMajor`,
    params,
  });
};
// 获取院系设置
export const GetDepartment = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetDepartment`,
    params,
  });
};
// 获取招生简章列表
export const Getrecruits = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/recruits`,
    params,
  });
};
// 获取招生简章列表
export const recruitdetail = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/recruitdetail`,
    params,
  });
};
export const subjectIntroducelist = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/subjectIntroducelist`,
    params,
  });
};
export const planListPro = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/planListPro/V2`,
    params,
  });
};
