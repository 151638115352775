<template>
    <div class="box" ref="container">
        <div class="toPBox">
            <div class="card">
                <div class="category">
                    <div class="name">{{ list.zymc }}</div>
                    <div class="collect">
                        <i class="el-icon-star-off"></i>
                        <span style="margin-left: 5px;">收藏</span>
                    </div>
                </div>
                <div class="tag">
                    <div class="tagItem">
                        <div class="TIt">{{ list.xk }}</div>
                        <div class="BIt">类别</div>
                    </div>
                    <div class="tagItem">
                        <div class="TIt">{{ list.zydm }}</div>
                        <div class="BIt">专业代码</div>
                    </div>
                    <div class="tagItem">
                        <div class="TIt">{{ list5.subject_year }}</div>
                        <div class="BIt">学制</div>
                    </div>
                    <div class="tagItem">
                        <div class="TIt">{{ list.xlcc }}</div>
                        <div class="BIt">学历层次</div>
                    </div>
                    <!-- <div class="tagItem">
                        <div class="TIt">-</div>
                        <div class="BIt">专业排名</div>
                    </div> -->
                    <div class="tagItem">
                        <div class="TIt">{{ list5.degree }}</div>
                        <div class="BIt">授予学位</div>
                    </div>
                    <div class="tagItem">
                        <div class="TIt">{{ list5.money_zxt.average }}</div>
                        <div class="BIt">预期薪资</div>
                    </div>
                    <div class="tagItem">
                        <div class="TIt">{{ list5.ratio }}</div>
                        <div class="BIt">男女比例</div>
                    </div>
                    <!-- <div class="tagItem">
                        <div class="TIt">-</div>
                        <div class="BIt">文理比例</div>
                    </div> -->
                </div>
            </div>
            <div class="tabs">
                <div :class="tabsIndex == index ? 'tabItem active' : 'tabItem'" v-for="(item, index) in tabList"
                    :key="index" @click="tabsIndex = index">
                    <span class="Icon" v-if="tabsIndex == index"><img :src="item.Acitveicon" alt="" /></span>
                    <span class="Icon" v-else><img :src="item.icon" alt="" /></span>
                    <span> {{ item.name }}</span>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="leftContent">
                <div class="ListContent">
                    <Introduce v-if="tabsIndex == 0 && list !== '' && list1 !== '' && list5 !== ''" :list="list"
                        :list1="list1" :list5="list5" />
                    <Academy v-if="tabsIndex == 1 && list2 !== '' && list4 !== ''" :list2="list2" :list4="list4" />
                    <Prospect v-if="tabsIndex == 2 && list3 !== ''" :list3="list3" />
                </div>
            </div>

            <div class="rightContent">
                <div class="banes">
                    <div class="item" @click="goUrl('/Home/FirstPage')">
                        <img src="./images/1111.png" alt="" />
                    </div>
                    <div class="item" @click="goUrl('/Home/ZhanJiaList')">
                        <img src="./images/2222.png" alt="" />
                    </div>
                </div>
                <div class="articleCont_right_zhengce">
                    <div class="title">
                        <div class="tit">相关专业</div>
                    </div>
                    <div class="school-box">
                        <div class="school-item" v-for="(it, index) in 8" v-show="index <= 8" :key="index"
                            @click="goSchoolDetails(it.Uid)">
                            <div class="schoolName">
                                <span class="name"> {{ index + 1 }}</span>
                                <span class="type">软件工程</span>
                            </div>
                            <div class="Rd">138236 热度</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Introduce from "@/components/introduce";
import Academy from "@/components/academy";
import Prospect from "@/components/prospect";
import { GetmajorDetail, GetzkskcMajor, GetzksyxMajor, GetcareerProspects, GetmajorBase } from "@/api/CheckTheMajor";

export default {
    name: "SearchUniversityIndex",
    components: { Introduce, Academy, Prospect },
    data() {
        return {
            tabsIndex: 0,
            listObj: {
                SpecId: '',
            },
            listObj1: {
                zydm: '',
            },
            list: '',
            list1: '',
            list2: '',
            list3: '',
            list4: '',
            list5: '',
            tabList: [
                {
                    name: "基本信息",
                    icon: require("./images/111.png"),
                    Acitveicon: require("./images/11.png"),
                },
                {
                    name: "开设院校",
                    icon: require("./images/222.png"),
                    Acitveicon: require("./images/22.png"),
                },
                {
                    name: "就业前景",
                    icon: require("./images/333.png"),
                    Acitveicon: require("./images/33.png"),
                },
            ],
        };
    },

    mounted() {
        console.log(this.$route.query.id);
        if (this.$route.query.id !== undefined) {
            this.listObj.SpecId = this.$route.query.id;
            this.listObj1.zydm = this.$route.query.zydm;
            this.getList()
        }
    },

    methods: {
        goUrl(url) {
            this.$router.push(url);
        },
        async getList() {
            const res = await GetmajorDetail({
                ...this.listObj
            })
            this.list = res.data.result

            const ress = await GetzkskcMajor({
                ...this.listObj
            })
            this.list1 = ress.data.result.kskcList
            const resss = await GetzksyxMajor({
                ...this.listObj
            })
            this.list2 = resss.data.result.ssdmList.filter(item => item.ssmc != '全国')
            this.list4 = resss.data.result.schSpecList

            const ressss = await GetcareerProspects({
                ...this.listObj1
            })
            this.list3 = ressss.data.result
            const resssss = await GetmajorBase({
                ...this.listObj1
            })
            this.list5 = resssss.data.result
        },
    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    margin: 0 auto;
    min-height: 100vh;
    background: #ffffff;
}

.toPBox {
    background-image: url("./images/bac.png");
    background-size: 100% 100%;
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
        margin-top: 88px;
        width: 1280px;
        height: 239px;
        background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0.7) 100%);
        border-radius: 24px;
        padding: 40px 46px 43px 44px;
        box-sizing: border-box;

        .category {
            display: flex;
            margin-bottom: 52px;
            align-items: center;
            justify-content: space-between;

            .name {
                font-weight: 600;
                font-size: 36px;
                margin-right: 32px;
            }

            .collect {
                width: 80px;
                height: 34px;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #eeeeee;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
            }
        }

        .tag {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .tagItem {
                display: flex;
                flex-direction: column;
                align-items: center;

                .TIt {
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 4px;
                }

                .BIt {
                    font-size: 16px;
                    color: #86909c;
                }
            }
        }
    }

    .tabs {
        width: 1280px;
        display: flex;
        align-items: center;

        .tabItem {
            cursor: pointer;
            color: #86909c;
            border-radius: 8px 8px 0px 0px;
            text-align: center;
            font-size: 18px;
            margin-right: 40px;
            display: flex;
            align-items: center;

            .Icon {
                display: inline-block;
                width: 32px;
                height: 28px;
            }
        }

        .active {
            color: #187cff;
            font-weight: bold;
        }
    }
}

.content {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    margin-top: 20px;
}

.leftContent {
    flex: 1;
    min-width: 956px;
    margin-right: 16px;

    .ListContent {
        padding-top: 50px;

        .ListItemTitle {
            display: flex;
            align-items: center;
            margin-bottom: 32px;

            .Zy {
                font-weight: 600;
                font-size: 20px;
                margin-right: 16px;
            }

            .Js {
                font-size: 14px;
                color: #86909c;
            }
        }

        .ListItemList {
            margin-bottom: 42px;

            .ListItemItem {
                display: flex;
                align-items: top;

                .Dl {
                    margin-right: 104px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 96px;
                }

                .right {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 16px;

                    .Xl {
                        cursor: pointer;
                        width: 25%;
                        margin-bottom: 16px;
                    }

                    .Xl:hover {
                        color: #187cff;
                    }
                }
            }
        }
    }
}

.rightContent {
    width: 308px;
    overflow: hidden;
    float: left;

    .banes {
        width: 100%;

        .item {
            cursor: pointer;
            width: 308px;
            height: 154px;
            margin-bottom: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .articleCont_right_zhengce {
        width: 100%;
        background: #fff;
        border-radius: 8px 8px 8px 8px;
        box-sizing: border-box;
        border: 1px solid #EEEEEE;
        padding: 24px 20px;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .tit {
                font-weight: 600;
                font-size: 20px;
            }

        }

        .school-box {
            margin-top: 38px;

            div,
            .school-box .school-item {
                display: flex;
                align-items: center;
            }

            .school-item:hover .type {
                color: #187cff !important;
            }

            .school-item {
                margin-bottom: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                .schoolName {
                    font-size: 14px;
                    display: flex;

                    .type {
                        margin-left: 10px;
                    }
                }

                .Rd {
                    font-size: 14px;
                    color: #888;
                }
            }
        }

        .policy_title:hover {
            color: #187cff !important;
            cursor: pointer;
        }
    }
}

// ------loading样式---------
.loading_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    padding: 20px 0;

    .loading {
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border: 2px solid #ddd;
        border-radius: 50%;
        animation: rotate 1s linear infinite;
        margin-right: 10px;
    }

    .container {
        position: relative;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background-color: #fff;
    }
}

/*------loading动画---------*/
@keyframes rotate {
    from {
        transform-origin: center center;
        transform: rotate(0deg);
    }

    to {
        transform-origin: center center;
        transform: rotate(360deg);
    }
}
</style>
