import Vue from "vue";
import VueRouter from "vue-router";
// import { Message } from "element-ui";
// import store from "../store";

// 引入组件
// import Login from "@/views/Login";
import Home from "@/views/Home";
import Homepage from "@/views/Homepage";
import HomepageZJ from "@/views/HomepageZJ";
import zybEditor from "@/views/zybEditor";
import zyTable from "@/views/zyTable";
import expertZyTable from "@/views/expertZyTable";
import specialist from "@/views/specialist";
import FirstPage from "@/views/FirstPage";
import SearchUniversity from "@/views/SearchUniversity";
import SchoolDetails from "@/views/SchoolDetails";
import BatchLine from "@/views/BatchLine";
import RankLookup from "@/views/RankLookup";
import ListRanking from "@/views/ListRanking";
import News from "@/views/News";
import CheckTheMajor from "@/views/CheckTheMajor";
import Detail from "@/views/Detail";
import DiplomaMill from "@/views/DiplomaMill";
import ExpansionAndContraction from "@/views/ExpansionAndContraction";
import Comparison from "@/views/Comparison";
import Occupation from "@/views/Occupation";
import Register from "@/views/Register";
import OneKey from "@/views/OneKey";
import zybEditorTwo from "@/views/zybEditorTwo";
import Articlelist from "@/views/Articlelist";
import VipParticulars from "@/views/VipParticulars";
import PersonalCenter from "@/views/PersonalCenter";
import ZhanJiaList from "@/views/ZhanJiaList";
import Assessment from "@/views/Assessment";
import ClubCard from "@/views/ClubCard";
import CareerDetails from "@/views/CareerDetails";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: "/Home/FirstPage" },
    {
      path: "/Home",
      component: Home,
      meta: { isHiddenFooter: true },
      children: [
        {
          path: "FirstPage",
          component: FirstPage,
          meta: { title: "首页" },
        },
        {
          path: "Homepage",
          component: Homepage,
          meta: { title: "AI志愿" },
        },
        {
          path: "HomepageZJ",
          component: HomepageZJ,
          meta: { title: "专家志愿" },
        },
        {
          path: "zyTable",
          component: zyTable,
          meta: { title: "志愿表" },
        },
        {
          path: "expertZyTable",
          component: expertZyTable,
          meta: { title: "专家志愿表" },
        },
        {
          path: "specialist",
          component: specialist,
          meta: { title: "专家端口" },
        },
        {
          path: "zybEditor",
          component: zybEditor,
          meta: { title: "志愿表详情" },
        },
        {
          path: "SearchUniversity",
          component: SearchUniversity,
          meta: { title: "找大学" },
        },
        {
          path: "SchoolDetails",
          component: SchoolDetails,
          meta: { title: "找大学" },
        },
        {
          path: "BatchLine",
          component: BatchLine,
          meta: { title: "批次线" },
        },
        {
          path: "RankLookup",
          component: RankLookup,
          meta: { title: "查位次" },
        },
        {
          path: "ListRanking",
          component: ListRanking,
          meta: { title: "榜单排名" },
        },
        {
          path: "News",
          component: News,
          meta: { title: "高考资讯" },
        },
        {
          path: "CheckTheMajor",
          component: CheckTheMajor,
          meta: { title: "查专业" },
        },
        {
          path: "Detail",
          component: Detail,
          meta: { title: "专业详情" },
        },
        {
          path: "DiplomaMill",
          component: DiplomaMill,
          meta: { title: "野鸡大学" },
        },
        {
          path: "ExpansionAndContraction",
          component: ExpansionAndContraction,
          meta: { title: "查扩缩招" },
        },
        {
          path: "Comparison",
          component: Comparison,
          meta: { title: "院校招生计划对比" },
        },
        {
          path: "Occupation",
          component: Occupation,
          meta: { title: "看职业" },
        },
        {
          path: "Register",
          component: Register,
          meta: { title: "注册" },
        },
        {
          path: "OneKey",
          component: OneKey,
          meta: { title: "一键填报" },
        },
        {
          path: "zybEditorTwo",
          component: zybEditorTwo,
          meta: { title: "填报查看" },
        },
        {
          path: "Articlelist",
          component: Articlelist,
          meta: { title: "资讯详情" },
        },
        {
          path: "VipParticulars",
          component: VipParticulars,
          meta: { title: "vip详情" },
        },
        {
          path: "PersonalCenter",
          component: PersonalCenter,
          meta: { title: "账号信息" },
        },
        {
          path: "ZhanJiaList",
          component: ZhanJiaList,
          meta: { title: "专家详情" },
        },
        {
          path: "Assessment",
          component: Assessment,
          meta: { title: "测评列表" },
        },
        {
          path: "ClubCard",
          component: ClubCard,
          meta: { title: "VIP志愿卡" },
        },
        {
          path: "CareerDetails",
          component: CareerDetails,
          meta: { title: "职业详情" },
        },
      ],
    },
    {
      path: "/404",
      component: () => import("@/views/404.vue"),
      hidden: true,
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
  // 每次路由跳转触发函数
  // scrollBehavior() {
  //   return {
  //     x: 0,
  //     y: 0,
  //   };
  // },
});

// 设置路由导航守卫
/*
  路由导航守卫：当路由跳转的时候触发的狗子函数
    1. 全局盗号守卫(对所有路由生效)
      beforeEach
      beforeResolve
      afterEach
    2. 路由导航守卫(只对当前路由生效)
    3. 组件导航守卫(只对当前组件生效)
*/

// 定义白名单
// const whiteList = ["/login", "/404"];

// 路由守卫
// router.beforeEach((to, from, next) => {
//   const token = store.state.user.token;
//   if (token) {
//     // 登录了
//     if (to.path === "/login") {
//       next("/");
//     } else {
//       // 登录之后访问其他页面都正常
//       next();
//     }
//   } else {
//     // 没有登录不能访问whiteList，要登录在进行访问
//     if (whiteList.includes(to.path)) {
//       Message.error("请登录在进行访问");
//       next();
//     } else {
//       // sessionStorage.clear();
//       next("/login");
//     }
//   }
// });
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
