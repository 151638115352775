<template>
    <div>
        <div class="list">
            <div class="item">
                <div class="title">专业点分布</div>
                <div class="Canvas">
                    <ZYDFB :list2="list2" />
                </div>

            </div>

            <div class="item" style="padding-top: 50px;">
                <div class="title">专业院校分布</div>
                <div class="table">
                    <el-table :data="list4" style="width: 100%"
                        :header-cell-style="{ background: '#EEEEEE', color: '#333333' }">
                        <el-table-column align="center" prop="yxmc" label="院校名称">
                        </el-table-column>
                        <el-table-column label="推荐指数" align="left">
                            <template slot-scope="scope">
                                <div><span style="color: #187cff;">{{ scope.row.zytjRank.toFixed(1) }}</span> <span>({{
                        scope.row.zytjCount }})</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="综合情况" align="left">
                            <template slot-scope="scope">
                                <div><span style="color: #187cff;">{{ scope.row.mydList[0].rank.toFixed(1) }}</span>
                                    <span>({{
                        scope.row.mydList[0].count }})</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="办学条件" align="left">
                            <template slot-scope="scope">
                                <div><span style="color: #187cff;">{{ scope.row.mydList[1].rank.toFixed(1) }}</span>
                                    <span>({{
                        scope.row.mydList[1].count }})</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="教学质量" align="left">
                            <template slot-scope="scope">
                                <div><span style="color: #187cff;">{{ scope.row.mydList[2].rank.toFixed(1) }}</span>
                                    <span>({{
                        scope.row.mydList[2].count }})</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="就业" align="left">
                            <template slot-scope="scope">
                                <div><span style="color: #187cff;">{{ scope.row.mydList[3].rank.toFixed(1) }}</span>
                                    <span>({{
                                        scope.row.mydList[3].count }})</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ZYDFB from "@/components/ZYDFB";

export default {
    name: "VoluntaryReportingIndex",
    components: { ZYDFB },
    props: {
        list2: {
            type: Array,
            required: true,
        },
        list4: {
            type: Array,
            required: true,
        },
    },
    computed: {
        toFixed(num) {
            return (Math.round(num * 100) / 100).toFixed(1);
        }
    },
    data() {
        return {
            tableData: [{
                date: '[0140]辽宁大学',
                name: '0140',
                address: '综合类',
                add: '哲学',
                add1: '104',
                add2: '再选科目要求：不限 专业代码: 06'
            }, {
                date: '[0140]辽宁大学',
                name: '0140',
                address: '综合类',
                add: '哲学',
                add1: '104',
                add2: '再选科目要求：不限 专业代码: 06'
            }, {
                date: '[0140]辽宁大学',
                name: '0140',
                address: '综合类',
                add: '哲学',
                add1: '104',
                add2: '再选科目要求：不限 专业代码: 06'
            }, {
                date: '[0140]辽宁大学',
                name: '0140',
                address: '综合类',
                add: '哲学',
                add1: '104',
                add2: '再选科目要求：不限 专业代码: 06'
            }]
        };
    },

    mounted() { },

    methods: {},
};
</script>

<style lang="less">
.list {
    .item {
        .title {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 36px;
        }

        .introduceTit {
            .tit {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 16px;
            }

            .content {
                font-size: 16px;
                color: #666666;
                margin-bottom: 32px;
            }
        }

        .table {
            margin-bottom: 50px;
        }
    }
}
</style>
