import {request,request2} from "@/utils/request";


//获取左侧职业tree
export const PcGetOccupationInfo = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/PcGetOccupationInfo`,
    params,
  });
};
//获取职业详情-职业介绍
export const GetRecommendIntroduce = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetRecommendIntroduce`,
    params,
  });
};
//获取职业详情-职业介绍
export const GetbaseProfessione = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/baseProfession`,
    params,
  });
};
//获取职业详情-职业介绍
export const Getprofessiondetails = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/professiondetails`,
    params,
  });
};
