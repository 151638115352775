<template>
  <div class="compBox">
    <div class="table" v-loading="loading">
      <!-- <div class="tit">招生计划</div> -->
      <div class="selectBox">
        <el-select
          size="small"
          style="width: 100px; margin-right: 5px"
          v-model="Year"
          placeholder="请选择"
          @change="YearsChange($event)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-select size="small" style="width: 100px;margin-right: 5px;" v-model="value1" placeholder="请选择">
                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> -->
        <el-select
          size="small"
          style="width: 250px; margin-right: 5px"
          v-model="Location"
          placeholder="请选择"
          @change="YearsChange($event)"
        >
          <el-option
            v-for="item in options2"
            :key="item.ProvinceName"
            :label="item.ProvinceName"
            :value="item.ProvinceName"
          >
          </el-option>
        </el-select>
      </div>
      <div style="height: 31px">
        <div class="pl20 dir-td f14 fw600">
          招生方向：{{ name }}({{ code }})
        </div>
      </div>
      <div style="height: 36px">
        <table class="my-table head" style="top: 154px; width: 100%">
          <thead class="my-table-head fw400">
            <th width="100">代码</th>
            <th class="text-left pl20">院校/专业名称</th>
            <th width="80">{{ list[0].Years }}年招生</th>
            <th width="80">{{ list[0].OldYears }}年招生</th>
          </thead>
        </table>
      </div>
      <div class="tab">
        <table class="my-table body">
          <tbody class="my-table-body">
            <tr v-for="(it, idx) in list" :key="idx">
              <td width="100" class="text-center">
                <span class="cntext">{{ it.MajorCode }}</span>
              </td>
              <td class="pt20 pb20 pl20">
                <a
                  href="http://zy.ycymedu.com/majors/small?code=140101"
                  target="_blank"
                  class="flex-column text-color-title"
                >
                  <span class="cntext fw600 f14"
                    >{{ it.Major }}({{ it.MajorCode }})</span
                  >
                  <span class="cntext f12 text-999 mt5"
                    >{{ it.MajorRemark }};({{ it.SubjectClam }})</span
                  >
                </a>
              </td>
              <td width="80" class="text-center">
                <span class="cntext" style="font-weight: bold; font-size: 14px"
                  >{{ it.planCount }}人</span
                >
                <span
                  v-if="it.NewCount < it.OldPlanCount || it.NewCount == 0"
                  style="
                    margin-left: 5px;
                    background: rgb(175, 220, 183);
                    color: rgb(24, 173, 51);
                    font-size: 12px;
                    padding: 2px 5px;
                  "
                  >{{ it.NewCount }}</span
                >
                <span
                  v-else
                  style="
                    margin-left: 5px;
                    background: rgb(220, 191, 175);
                    color: rgb(173, 24, 81);
                    font-size: 12px;
                    padding: 2px 5px;
                  "
                  >{{ it.OldPlanCount }}</span
                >
              </td>
              <td width=" 80" class="text-center">
                <span class="cntext">{{ it.OldPlanCount }}人</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMajorPlanPro } from "@/api/schoolDetails";
import { GetSeVolunteerInitialization } from "@/api/home";

export default {
  name: "VoluntaryReportingIndex",
  props: {
    schoolId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Location: "",
      Year: "",
      list: [],
      loading: false,
      options: [
        {
          value: 2025,
          label: 2025,
        },
        {
          value: 2024,
          label: 2024,
        },
        {
          value: 2023,
          label: 2023,
        },
      ],
      options1: [
        {
          value: "1",
          label: "一段",
        },
      ],
      value1: "1",
      options2: [
        {
          value: "001",
          label: "--",
        },
      ],
      SearchCount: 0,
      isVipSearchShow: false,
    };
  },

  mounted() {
    this.Location =
      this.$store.state.user.studentInfo.userExtend.provinceName || "山东省";
    this.Year = this.$store.state.user.studentInfo.userExtend.year || 2024;
    this.getList();
    this.getSeVolunteerList();
  },

  methods: {
    // 获取省份
    async getSeVolunteerList() {
      const res = await GetSeVolunteerInitialization();
      this.options2 = res.data.response;
    },
    YearsChange() {
      this.getList();
    },
    async getList() {
      const res = await GetMajorPlanPro({
        UId: this.schoolId,
        Location: this.Location,
        Year: this.Year,
      });
      this.options2[0].label = res.data.response.UniversityName;
      this.list = res.data.response.itemViews;
      console.log(res.data.response.itemViews);
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.bb {
  position: relative;
  width: 100%;

  button {
    position: absolute;
    top: 153px;
    left: 50%;
    transform: translateX(-50%);
    width: 142px;
    height: 49px;
    background: #28c445;
    border-radius: 10px;
    border: none;
    font-size: 24px;
    font-weight: 500;
    line-height: 49px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}

.compBox {
  margin-top: 60px;

  .table {
    .tit {
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 30px;
    }

    .selectBox {
      margin-bottom: 10px;
    }

    .dir-td {
      padding: 5px 12px;
      background: #fafafa;
      line-height: 20px;
      color: #1d2129;
      border: 1px solid #e8e8e8;
      border-bottom: none;
      font-size: 14px;
      padding-left: 20px;
      font-weight: bold;
    }

    .my-table {
      background-color: #fff;
      border-bottom: 1px dashed #e9e9e9;
      border-right: 1px solid #e8e8e8;
      border-top: 1px solid #e8e8e8;
      border-collapse: collapse;
      border-spacing: 0;

      .my-table-head th:first-child {
        border-left: 1px solid #e8e8e8;
      }

      .my-table-head th {
        border-bottom: 1px solid #e8e8e8;
        padding: 9px 0;
        font-size: 12px;
        color: #86909c;
      }

      .text-left {
        text-align: left !important;
      }

      .pl20 {
        padding-left: 20px !important;
      }
    }

    .tab {
      .my-table.body {
        border-top: none;
        width: 100%;

        tr.stripe {
          background: #f7f7f7;
        }

        .text-color-title {
          color: #1d2129;
        }

        .text-center {
          text-align: center !important;
        }

        td {
          font-size: 12px !important;
          border-left: 1px solid #e8e8e8;
          border-bottom: 1px solid #e8e8e8;
          color: #333;
        }

        .pl20 {
          padding-left: 20px !important;
        }

        .pb20 {
          padding-bottom: 20px !important;
        }

        .pt20 {
          padding-top: 20px !important;
        }

        .flex-column {
          display: flex;
          text-decoration: none;
          flex-direction: column;

          .fw600 {
            font-weight: 600 !important;
          }

          .f14 {
            font-size: 14px !important;
          }

          .text-999 {
            color: #999 !important;
          }

          .f12 {
            font-size: 12px !important;
          }

          .mt5 {
            margin-top: 5px !important;
          }

          .cntext {
            font-family: cntext !important;
          }
        }
      }
    }
  }
}
</style>
