import {request} from "@/utils/request";
import {request2} from "@/utils/request";

// 专业库 一级二级列表
export const uniGetMajorInfo = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/uniGetMajorInfo`,
    params,
  });
};

// 获取第三级
export const uniGetMajorThree = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/uniGetMajorThree`,
    params,
  });
};

// 专业搜索
export const SeachMajor = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/SeachMajor`,
    params,
  });
};
// 专业搜索
export const Getmajors = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/majors`,
    params,
  });
};
// 专业搜索
export const GetmajorDetail = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/majorDetail`,
    params,
  });
};
// 专业搜索
export const GetzkskcMajor = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/zkskcMajor`,
    params,
  });
};
export const GetzksyxMajor = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/zksyxMajor`,
    params,
  });
};
export const GetcareerProspects = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/careerProspects`,
    params,
  });
};
export const GetmajorBase = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/majorBase`,
    params,
  });
};