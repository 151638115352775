<template>
  <div class="content">
    <!-- 分类导航 -->
    <TypeNav />
    <!--路由出口-->
    <transition name="el-fade-in-linear">
      <div class="main-content">
        <router-view></router-view>
      </div>
    </transition>
    <!-- 底部导航 -->
    <div class="typenav">
      <Particulars />
    </div>
  </div>
</template>

<script>
import Particulars from "@/components/Particulars";

import TypeNav from "@/components/TypeNav";
export default {
  name: "OCRrecognitionIndex",
  components: { TypeNav, Particulars },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.main-content {
  margin-top: 74px;
  min-height: 100vh;
  margin-bottom: 5px;
  // background: #fff;
  overflow-x: auto;
  overflow-y: auto;
}
</style>
