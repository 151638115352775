<template>
  <div class="box" ref="container">
    <div class="leftContent">
      <div class="tabs">
        <div class="tabTitle">
          <div class="tit">批次线查询</div>
          <div class="zk" v-if="isTabarShow" @click="isTabarShow = false">
            展开 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="zk" v-else @click="isTabarShow = true">
            收起 <i class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div
          :class="
            isTabarShow
              ? 'isTabarShow tzy-filter-wrapper'
              : 'tzy-filter-wrapper'
          "
        >
          <div class="filter-list">
            <span class="filter-list-title"> 选择地区 </span>
            <div class="filter-list-tags">
              <span
                :class="it.check == true ? 'tag active' : 'tag'"
                v-for="it in regionList"
                :key="it.Id"
                @click="selectRegion(it)"
              >
                {{ it.Name }}
              </span>
            </div>
          </div>
          <div class="filter-list">
            <span class="filter-list-title"> 年份 </span>
            <div class="filter-list-tags">
              <span
                :class="it.check == true ? 'tag active' : 'tag'"
                v-for="(it, index) in yearList"
                :key="it.Id"
                @click="selectYear(it, index)"
              >
                {{ it.year }}
              </span>
            </div>
          </div>
          <div class="filter-list">
            <span class="filter-list-title"> 类别 </span>
            <div class="filter-list-tags">
              <span
                :class="it.check == true ? 'tag active' : 'tag'"
                v-for="(it, index) in typeList"
                :key="it.Id"
                @click="selectType(it, index)"
              >
                {{ it.subject_name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="TableList">
        <div class="title">
          最近{{ yearList.length - 1 }}年批次线统计({{ name }})
        </div>
        <el-col :span="24" class="table-row">
          <table class="pcl-table">
            <thead>
              <th width="20%" align="center">地区</th>
              <th width="20%" align="center">年份</th>
              <th width="20%" align="center">类别</th>
              <th width="20%" align="center">批次</th>
              <th width="20%" align="center">分数线</th>
            </thead>
          </table>
          <table class="pcl-table">
            <tbody>
              <tr v-for="(item, index) in list" :key="index">
                <td width="20%" align="center">
                  {{ item.province_name }}
                </td>
                <td width="20%" align="center">{{ item.year }}</td>
                <td width="20%" align="center">{{ item.subject_name }}</td>
                <td width="20%" align="center">{{ item.batch_name }}</td>
                <td width="20%" align="center">{{ item.score }}</td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </div>
      <div class="sm">
        <div class="smTitle">*说明</div>
        <div class="smContent">
          <p>1.批次线查询结果根据各省教育考试院发布的批次线/省控线进行更新。</p>
          <p>
            2.在正式填报时，请以各省教育考试院发布的数据为准，本平台数据仅供参考。
          </p>
        </div>
      </div>
    </div>

    <!-- bander -->
    <div class="rightContent">
      <div class="banes">
        <div class="item" @click="goUrl('/Home/FirstPage')">
          <img src="./images/Frame 1000015157@2x.png" alt="" />
        </div>
        <div class="item" @click="goUrl('/Home/ZhanJiaList')">
          <img src="./images/Frame 1000015157@2x(1).png" alt="" />
        </div>
        <div class="item" @click="goUrl('/Home/RankLookup')">
          <img src="./images/Group 1000015165@2x.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetRegionList, GteBatchList } from "@/api/home";
export default {
  name: "SearchUniversityIndex",
  components: {},
  data() {
    return {
      isTabarShow: false,
      listObj: {
        locationCode: "",
        year: "",
        type: "",
      },
      name: "",
      list: [],
      regionArr: [],
      typeArr: [],

      regionList: [],
      yearList: [],
      typeList: [],
    };
  },
  mounted() {
    this.getRegionList();
  },
  destroyed() {},
  methods: {
    // 获取TYPE
    async getList() {
      const res = await GteBatchList({
        ...this.listObj,
      });
      this.yearList = res.data.result.configList.yearList;
      this.typeList = res.data.result.configList.subjectList;
      this.list = res.data.result.list;
    },
    selectYear(it) {
      this.yearList.forEach((item) => {
        item.check = item.year === it.year;
      });
      if (it.year == "不限") {
        this.listObj.year = "";
      } else {
        this.listObj.year = it.year;
      }
      // 获取列表数据
      this.getList();
    },
    // 选择院校省份
    selectRegion(it) {
      this.regionList.forEach((item) => {
        item.check = item.Name === it.Name;
      });
      this.listObj.locationCode = it.Code;
      this.name = it.Name;
      // 获取列表数据
      this.getList();
    },

    // 选择院校类型
    selectType(it) {
      this.typeList.forEach((item) => {
        item.check = item.subject_name === it.subject_name;
      });
      if (it.year == "不限") {
        this.listObj.type = "";
      } else {
        this.listObj.type = it.subject_id;
      }
      // 获取列表数据
      this.getList();
    },

    // 获取省份数据
    async getRegionList() {
      try {
        // 调用接口获取区域列表
        const res = await GetRegionList({ code: 0 });
        this.regionList = res.data.response || [];

        // 获取用户的省份名称，默认为 "山东省"
        const isProvinceName =
          this.$store.state.user.studentInfo.userExtend.provinceName ||
          "山东省";

        // 查找匹配的省份信息
        const matchingRegion = this.regionList.find(
          (item) => item.Name === isProvinceName
        );

        // 遍历 regionList 设置 check 属性，并同步更新 locationCode
        this.regionList.forEach((item) => {
          this.$set(item, "check", item === matchingRegion);
        });

        // 如果匹配的省份存在，更新 locationCode
        if (matchingRegion) {
          this.listObj.locationCode = matchingRegion.Code;
          this.name = matchingRegion.Name;
        } else {
          console.warn(`未找到匹配的省份: ${isProvinceName}`);
        }

        // 获取列表数据
        this.getList();
      } catch (error) {
        console.error("获取区域列表失败:", error);
      }
    },

    goUrl(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  display: flex;
  width: 1280px;
  margin: 0 auto;
  min-height: 100vh;
  margin-top: 20px;
}

.leftContent {
  flex: 1;
  min-width: 956px;
  margin-right: 16px;
  .tabs {
    position: relative;
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    border-radius: 8px 8px 8px 8px;
    background: #ffffff;
    .tabTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #eeeeee;
      .tit {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
      }
      .zk {
        font-size: 14px;
        cursor: pointer;
      }
    }
    .isTabarShow {
      display: none !important;
    }
    .tzy-filter-wrapper {
      box-sizing: border-box;
      display: block;
      .filter-list {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        .filter-list-title {
          min-width: 56px;
          font-size: 14px;
          text-align: left;
          font-weight: bold;
        }

        .filter-list-tags {
          margin-left: 20px;
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          .tag {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            font-style: normal;
            margin: 0 0 8px 10px;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
            box-sizing: border-box;
            min-width: 58px;
            text-align: left;
          }

          .tag:hover {
            color: #187cff;
          }

          .active {
            color: #187cff;
          }
        }
      }

      .college-level-wrapper {
        display: flex;

        .sx {
          font-weight: 100;
          height: 47px;
          display: flex;
          align-items: center;
          color: #e6e6e6;
          transform: translateY(-2px);
        }
      }
    }
  }

  .TableList {
    margin: 20px 0;
    padding: 24px;
    border-radius: 8px 8px 8px 8px;
    background: #fff;
    .title {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 24px;
    }
    .table-row {
      margin-bottom: 13px;
      float: inherit !important;
      .pcl-title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }

      .pcl-table {
        width: 100%;
        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-bottom: none;
        background-color: #fff;
      }

      thead th {
        height: 42px;
        background: #f6f6f6;

        th {
          border-right: 1px solid #ebeef5;
          border-bottom: 1px solid #ebeef5;
          padding: 14px 20px;
          color: #000;
          font-size: 14px;
        }
      }

      .pcl-table {
        width: 100%;
        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-bottom: none;
        background-color: #fff;

        tbody tr {
          height: 48px;

          td {
            border-right: 1px solid #ebeef5;
            border-bottom: 1px solid #ebeef5;
            padding: 13px 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .sm {
    margin: 23px 0;
    width: 100%;
    background: #fef0f0;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #ffeaea;
    padding: 16px;
    box-sizing: border-box;
    color: #f56c6c;
    .smTitle {
      margin-bottom: 12px;
      font-size: 20px;
    }
    .smContent {
      p {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
}
.rightContent {
  width: 308px;
  overflow: hidden;
  .banes {
    width: 100%;
    .item {
      cursor: pointer;
      width: 308px;
      height: 154px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
