<template>
    <div class="compBox">
        <div class="guide-list" v-loading="loading" v-show="show == 0">
            <div class="title">清华大学-招生简章</div>
            <div class="guide-item " v-for="(it, idx) in list" :key="idx" @click="particulars(it)">
                <el-row :gutter="20">
                    <el-col :span="20">
                        <div class="grid-content bg-purple" style="cursor: pointer;">
                            {{ it.title }}
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple">
                            {{ it.updatedAt }}
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="guide-list" v-loading="loading" v-show="show == 1">
            <p class="f30 text-333 lh40 text-overflow-ellipsis mt20" style="font-weight: 700;">
                {{ htmlContent.title }}</p>
            <div class="news-detail-header clearfix">
                <div class="pull-left"><span>一周前</span> <span class="ml30 aa">{{ Random(500, 1200) }}次浏览</span> <span
                        class="ml30 aa">来源：{{
            htmlContent.college_name }}</span></div>
                <div class="pull-right">
                    <div class="news-fonts-minus news-icon" @click="jian()"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEZ0lEQVRoQ+1YXWhcRRQ+52azkkpQSG0NrWi2/qC1/jAzuwktug+aEkT6aBEf/EO0IAWrIFW0UlTEVqSofRD1SVTWhyIIGhBSo1k2M0ekrT5YrWhVbEpQWfRhsztHbsguk9tN7t17N9kWdt52zt/3zTlz95xBuMAXXuD4oUug0xnsZqCbgYQn0C2hVg9QCPE4AByKaDdMRKXldFc9A0qp3dba16MQ8Dxvq9Z6asUJCCHWeJ53HwCk3GC9vb2fTk1N/eLuZbPZ7bVa7aEoBNLp9N5isfjjihNQSu2y1r4ZDISIHxljdkYBG1enLSUkhJgEgG1NQPw3MDCwbnx8/N+4AMPsEhPI5XIbq9XqrwDN2xJEvNcY80EYkLjyxASEEHsA4MBSABDxE2PMjrgAw+wSE5BSamaW9UCI+Bszb3QCV/r7+9dPTEz8HQYmjjwRgZGRkasrlcpJN7DneXdaaz8HAK++73neg1rr9+IADLNJREAI8SwA7HeCnCSia6WUXzHzVmd/nIi2h4GJI09EQEp5gpk3Oyf9itb6aSHEEwBw0CmrWl9f3+Dk5OTZOCBX5H9ASnkjMx8PlE9Wa62VUhlr7U+BwLuI6PD5ROBFZt7rnPJprfWViMj+nhDiWwC42ZF/aYy5/bwhIITw/+I3OYAOEdHu+m8p5fPMvM+RczqdvqJYLP7eThKx7oBSSllrpwPlk9daH63vCSG2AMCxANg9RPRaxwlIKQ8ys39R6+tsJpMZLBQKNRdcMEuIqI0x2Y4SYGaUUp4GgA0OkLeJ6JEgMCHEqwDwZCBTm7TWp9pFouUSUkrdZq1tlIoPxPO8Ma31Z0FQ2Wx2pFarLernEfEZY8xLHSMghHgLAB5zAPyTyWTWFQqFShDUQrb8SzvoyI4T0U0dIZDP51PlcvkPALjMAfA+EfnDTNMlpTzMzI+6wlQqtblUKn3fDhItldDCNHVOqQDA/Ld/idUsxn4iem7VCQgh/Ibs/jYEnu+Z2uAn+tvo2NjYRTMzM2cA4JJ2BE6lUrJUKlFSX5FLSEq5g5mPuAER8QQA/BkBxFpmviWgd4CInopgu6xKKwQ+ZOZ7XG/pdPqasFcDX18pdbm11v8aNWYERFzUO8UlEonA6OjoxbOzszMAsMYJdIyIGs1aGIAmMwIg4jZjzNdhtsvJIxGQUu5k5kWDOSLuM8a8EDV4cEbw7RDxDWOM/1IXe0UlcISZFw3miLjFGOPfgUhreHj4qrm5uZ8Dd+jM0NDQhmAPFcnhglIogXw+f2m5XPa/Pum6Y0T8wRhzXSuBfF0p5TfMfKtr53neHVrrL1r11cASZqiUesBa+25A72UiagwzYT7q8iYztF9G7xhjHo7qI6gXmgEhhP/CMOoa9vT0qOnpadNq0Fwud0O1Wv0uYPcXAKwnorlW/c3fozAjKeVRZl7r6J0iorvD7JaSSyk/ZubrnXK0zHwXEfmvey2vUAIte1xlgy6BVT7wc8J1M9DNQMIT6JZQwgNMbP4/OcaMQGZBoe4AAAAASUVORK5CYII="
                            alt=""></div>
                    <div class="news-fonts-plus news-icon" @click="jia()"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEy0lEQVRoQ+1YXWhcRRQ+Z7JZSUtQSG0NrdRs/UFr/WHO3SRY6j5oShDtk1ilDyoiWhTFKoiKRouK2AqKtQ+iIkX8iQ9FKGigkBhN2MycIG30wWpFo2JTgkpQMMneIzd0w+Rmkzs3afMDmbed852fb86ZuecswjJfuMzjhxUCi53BJZEBIsqLyEal1JAxpivNoSwVAodEZJeIdPX39xeWLQFE/NJae+MKgTQncDawRDRRQssiA0R0NQDcGSN+m4hE+4OIeMiVIeIRY0zPTAd1Vi6x1nqVUmoXAGRcR9XV1Ud6enp+dve01rcDwCe+mVNKPWqMef2cEgiCYHcYhgfiThDxY2vtTnefiFoB4ICIKGd/DQCsBoD/AOAPZ18AoI2Z3z+nBLTW3QCwtYKTf+vq6tZ2dHT8M9uJL+odaGxs3DA+Pv4LQOW2BBHvstZ+uGQJaK33AMC+GVOM+Jm1dseSJUBERkSoHCAi/ioiG5yAR2tra9d1dnb+NROJRSuh5ubmS0dHR0+4gSmlbg7D8AsAmLykSql7jTHvzULgIRHZppQaMMa84PtCRbh5PaNa62cAYK/j8AQzX05EX4nIDc5+BzNvTxOYL3ZeBIhoQEQ2l50ppV4xxjyptX4MAPY7ZVWqqamp7+7uPu0bmC9uzgSiL6qIHI+VT94YY4IgyIVh+GMsiN3MfNA3MF/cfAi8KCJPOac8aIzZiIjRxwe01t8AwLWOPHWn6UNizgS01j8AwCbHyRvM/Ej5NxE9JyJt7lc1m81e3Nvb+5tPYL6YOREIgiAIw7AvVj4Fd5rSWm8BgGOxQPYw82u+wfng5kSAiPaLSHRRy+t0Lperb29vL7lO41lCRGOtzfsE5otJTUBEkIgGAWC94+RtZr4/7lRr/SoAPB7L1CZjzEnfAJNwqQkEQbAtDMMpg7dSqtUY83ncWT6fby6VSlN6eUR82lr7UlJgvvLUBLTWbwHAg46Dv3O53Nr29vbRuNMz2Youbb0jO87M1/gGmIRLRaBQKGRGRkZ+B4ALHcMfMHM0zFRcRHRQRB5whZlMZnOxWPwuKTgfeSoC+Xx+e6lUmlYqADDx9s+wKvnYy8zP+gSYhElFQGsdNWR3Jxn1kE/0TB64RIg3gdbW1vOGhoZOAcD5iVY9AJlMhorFIntAZ4V4EyCiHSJy2LWGiAOxGXYmZ2tE5LqYcB8zP7GQBD4SkTtch9ls9rLe3t6opZh1BUFwURiG0Ws0OSMg4pTeKclGmgs2DdvS0rJ6eHh4CABWOcJjzDzZrCUFUGFGAETcaq39Okl3NrlXCRHRThGZMpgjYpu19nlf5/EZIdJDxDettQ/72qiE8yVwWESmDOaIuMVaG90Br9XU1HTJ2NjYT7E7dKqhoWF9vIfyMngGlEigUChcMDIyEr0+2bJhRPzeWntFGkcRloj6ReR6V08pdZMx5mhaW5OxJCkGQXBPGIbvxnAvM/PkMJNkoyyvMENHZfSOtfY+XxtxXGIGtNbRPwwtrmJVVVXQ19dn0zptbGy8anx8/NuY3p8AsI6Zx9Lam7hHSUpE1CUi0X+X5XWSmW9N0ptJTkSfisiVTjmGInILM0f/7qVeiQRSW1xghRUCC3zg09ytZGAlA/M8gZUSmucBzlv9f7tJ5ED4A3TGAAAAAElFTkSuQmCC"
                            alt=""></div>
                    <!-- <div class="news-share news-icon"><span><img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABzlBMVEUAAAD///+AgIBVVVVAQEAzMzNVVVVJSUlAQEA5OTlGRkY7Ozs3NzczMzM8PDw5OTk2NjY3NzczMzM7Ozs5OTk1NTUzMzM6Ojo4ODg2NjY1NTU5OTk3Nzc2NjY0NDQ4ODgzMzM3Nzc2NjY1NTUzMzM2NjY0NDQzMzM3Nzc2NjY1NTU0NDQzMzM2NjY1NTU2NjY0NDQzMzM2NjY1NTU0NDQzMzM0NDQ0NDQzMzM2NjY0NDQ0NDQzMzM1NTU0NDQ1NTU1NTU0NDQ0NDQzMzM0NDQzMzM1NTUzMzMzMzM0NDQ0NDQzMzM1NTUzMzM0NDQ0NDQzMzMzMzM0NDQ0NDQzMzM0NDQ0NDQzMzM0NDQzMzMzMzM0NDQ0NDQzMzM0NDQzMzM0NDQ0NDQzMzMzMzM0NDQ0NDQ0NDQzMzM0NDQ0NDQzMzM0NDQzMzM0NDQ0NDQzMzM0NDQ0NDQzMzMzMzM0NDQzMzM0NDQzMzMzMzMzMzM0NDQ0NDQzMzMzMzM0NDQzMzMzMzM0NDQzMzMzMzM0NDQ0NDQzMzMzMzM0NDQ0NDQzMzMzMzM0NDQ0NDQzMzMzMzM0NDQ0NDQzMzMzMzMzMzM0NDQ0NDQzMzMzMzMzMzN/jDm5AAAAmXRSTlMAAQIDBAUGBwgJCw0ODxESExcZGhsdHh8gISIkJSYnKS0uLzAyNDY3ODk6Ozw9PkJFRkdISktOT1BRWFlaXF1gYWJjZGZob3N3ent8foKEhYeMjo+RkpOVmZqbnJ2goaWmp6qusLGytLW2uLu+v8HDxcbHyMvMztDR0tPU1tfY2tvj5Obn6Onq7O3v8PHy8/X29/j5+vv8/f7afm6MAAACGklEQVQYGeXB+UOLcQAG8Gezo5ZpHax6Q44WXeRazB2bRK5iKElEi5BGWuUbaza2llltz39ra0fbbO3dr3w++KfVTywMVKIE70mKRshWQ3+bk952yCVRQDfOkAUySRSAcpDRXsgjUSDGGuGQGnJIFIgzBzmphwwSBTY0u+kyYmu1F+xvvRRIMLrobkZhrQPzjAtNI0k/yaAZ+ZVf+kjS/9x6pBqb1EOMWJGHyuYjPbdbVch1PcpBJXJ1zJHT5zTIxxLiuA5Ztt0nv3ShkHYvnVXIsN3BX31aFNYoKIxIq3FxuQVbMrzha6SUz/CzEUWU+alH0ihFJYqx0adAgpX+vShC9ZBRCxIMP3gKRVQ4uHoaSfc4gSJqP9HTgqRdvyNN2Nq+JS42IOUaR5DBcNB8orUBmboCnKpE2gxPImVPv4sbPGMXkXJ5jU+1SKtjQIuEuuF1cmX25at330iBBEU/eUuBTVc4ioSzAYYfH1UjrpMCGzQjXOtBpju0IU7xgHxWjySJAnE7phg4hiwv2I0Y5TBXe5AmUSCmfoFf9yPbPJsQY+dKBzZJFABMHs7WIoefFQCuMtiGDBIF0B2kowK5flIHtIR5Bpmq6FPbInykwl981EPj4l1km6OP0V7k8Z0G3OCiFtkOL3PuOPJxs3pnKNqJXGUGBfJy0mTnCOQbZV94fTfku8kgn6AE50keQAlM5AeUQjG2dAj/lT+QK6OFTLqnEQAAAABJRU5ErkJggg=="
                                alt="" class="el-popover__reference" aria-describedby="el-popover-7945" tabindex="0"></span>
                    </div> -->
                </div>
            </div>
            <div class="container ueditor" :style="{ fontSize: fontSize + 'px' }" v-html="htmlContent.content"></div>
        </div>
    </div>
</template>

<script>
// import htmlContent from "./aa";
import { Getrecruits, recruitdetail } from "@/api/schoolDetails";
export default {
    name: 'VoluntaryReportingIndex',
    props: {
        schoolId: {
            type: String,
            required: true
        },
        isShow: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            list: [],
            show: 0,
            showTitle: '',
            Content: '',
            fontSize: 16,
            htmlContent: ''
        };
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function (newval) {
                this.show = 0
                console.log(newval, '_______');
            }
        },
    },
    mounted() {
        this.getList()
    },

    methods: {
        Random(min, max) {
            return Math.round(Math.random() * (max - min)) + min;
        },
        jia() {
            this.fontSize = this.fontSize + 2
        },
        jian() {
            this.fontSize = this.fontSize - 2
        },
        async getList() {
            const res = await Getrecruits({
                uid: this.schoolId
            })
            console.log(res.data.result);
            this.list = res.data.result
        },

        async particulars(it) {
            this.show = 1
            const res = await recruitdetail({
                id: it.id
            })
            this.htmlContent = res.data.result
        }
    },
};
</script>

<style lang="less" scoped>
a {
    color: #187cff;
}

.container {
    font-size: 16px;
    color: #333 !important
}

.container p {
    line-height: 1.75 !important;
    color: #333 !important;
    margin: 20px 0 !important
}

.compBox {
    margin-top: 48px;
}

.guide-list {
    margin-bottom: 50px;

    .title {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 16px;
    }

    .guide-item {
        border-color: #f2f2f2;
        line-height: 60px !important;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;

        .grid-content {
            font-size: 16px;
            a {
                text-decoration: none
            }
        }
    }

    :hover.guide-item {
        color: #187cff;
    }
}

.mr40 {
    margin-right: 40px !important;
}

.lh40 {
    line-height: 40px !important;
}

.f30 {
    font-size: 30px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.news-detail-header {
    margin: 20px 0;
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
}

.pull-left {
    float: left !important;
}

.news-detail-header span {
    color: #999;
    font-size: 14px;
}

.ml30 {
    margin-left: 30px !important;
}

.pull-right {
    float: right !important;
}

.news-detail-header .news-icon {
    float: left;
    cursor: pointer;
    width: 24px;
    line-height: 24px;
    margin-top: 8px;
    margin-left: 20px;
}

.news-detail-header .news-icon img {
    width: 100%;
    float: left;
}
</style>