<template>
  <div ref="main" class="container" />
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "SportsFrontendWebMasterAge",
  props: {
    homeDatas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },

  watch: {
    homeDatas(newVal) {
      if (newVal !== '') {
        this.init()
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var myChart = echarts.init(this.$refs.main);
      const circularGraph = {
        data: [
          ...this.homeDatas
        ],
        sum: {
          name: ["适合的大学"],
          number: 0,
        },
      };
      var color = ["#15C496", "#FA8E23", "#EB5241"];
      let sum = 0;
      circularGraph.data.map((item) => {
        sum += item.value;
      });
      circularGraph.sum.number = sum;
      const int = sum;
      var option = {
        title: {
          text: "{a|" + int + "}\n{c|" + circularGraph.sum.name + "}",
          x: "center",
          y: "50%",
          textStyle: {
            rich: {
              a: {
                fontSize: 28,
                color: "#363D54",
                fontWeight: "600",
              },
              c: {
                fontSize: 20,
                color: "#999999",
                padding: [5, 0],
              },
            },
          },
        },
        color: color,
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            top: 50,
            label: {
              show: true,
              position: "outside",
              formatter: function (val) {
                return "{b|" + val.value + "}{a|\n" + val.name + "}";
              },
              textStyle: {
                rich: {
                  a: {
                    color: "#333333",
                    fontSize: "16",
                  },
                  b: {
                    color: "inherit",
                    fontSize: "28",
                    padding: [4, 0, 0, 0],
                  },
                },
              },
            },
            labelLine: {
              show: true,
              length: 40,
              length2: 30,
              minTurnAngle: 105,
              lineStyle: {
                width: 2,
              },
            },
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              textColor: "#000",
            },
            itemStyle: {
              borderCap: "round",
            },
            data: circularGraph.data, // 数据
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 350px;
  // background: skyblue;
  background: #fff;
}
</style>
