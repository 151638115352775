<template>
  <div class="box" style="max-width: 1280px; margin: 0 auto">
    <!-- 用户成绩 -->
    <div class="tzy-user-info">
      <div class="toys">
        <div class="f12 el-row">
          <span>成绩：</span>
          <span style="margin-right: 10px"
            >{{ basicInfo.expectedScore }}分</span
          >
          <span>{{ basicInfo.subjectGroup }}</span>
          <!-- <i class="el-icon-edit tzy-main-color"
            style="cursor: pointer;color: #187cff;"></i> -->
        </div>
        <span style="margin-left: 40px">
          {{ leibiestr }}
          <!-- <el-dropdown @command="handleCommand" trigger="click">
            <span class="el-dropdown-link" style="cursor: pointer">
              {{ leibiestr }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="it.batch"
                v-for="(it, idx) in batchDataUrlList"
                :key="idx"
                >{{ it.batch }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown> -->
        </span>
      </div>
    </div>
    <div class="tabs">
      <!-- 搜索 -->
      <div class="customer-college">
        <el-input
          size="small"
          v-model="studentData.Name"
          placeholder="请输入学校搜索"
          @keyup.enter.native="getList()"
        ></el-input>
        <span @click="getList()">查找院校</span>
      </div>
      <el-tabs type="card">
        <el-tab-pane>
          <span slot="label"
            ><i class="el-icon-office-building"></i> 院校优先</span
          >
          <div class="tzy-filter-wrapper">
            <div class="filter-list">
              <span class="filter-list-title"> 院校省份 </span>
              <div class="filter-list-tags">
                <span
                  :class="it.chenck == true ? 'tag active' : 'tag'"
                  v-for="(it, index) in Type1List"
                  :key="it.Id"
                  @click="selectRegion(it, index)"
                >
                  {{ it.Name }}
                </span>
              </div>
            </div>
            <div class="filter-list">
              <span class="filter-list-title"> 院校类型 </span>
              <div class="filter-list-tags">
                <span
                  :class="it.chenck == true ? 'tag active' : 'tag'"
                  v-for="(it, index) in Type2List"
                  :key="it.Id"
                  @click="selectType(it, index)"
                >
                  {{ it.Name }}
                </span>
              </div>
            </div>
            <div class="flex college-level-wrapper">
              <div class="filter-list filter-list-college">
                <span class="filter-list-title"> 院校层次 </span>
                <div class="filter-list-tags">
                  <span
                    :class="it.chenck == true ? 'tag active' : 'tag'"
                    v-for="(it, index) in Type31List"
                    :key="it.Id"
                    @click="selectGradation(it, index)"
                  >
                    {{ it.Name }}
                  </span>
                </div>
              </div>
              <span class="sx">|</span>
              <div class="filter-list tzy-filter-list-college">
                <div class="filter-list-tags">
                  <span
                    :class="it.chenck == true ? 'tag active' : 'tag'"
                    v-for="(it, index) in Type32List"
                    :key="it.Id"
                    @click="selectGradation2(it, index)"
                  >
                    {{ it.Name }}
                  </span>
                </div>
              </div>
              <span class="sx">|</span>
            </div>
          </div>
          <div class="myFilterRecordBlockRow">
            <div class="customer-selected-tags myFilterRecordBlock">
              <span class="title">已选条件：</span>
              <div class="tags" style="min-height: 40px">
                <el-tag
                  v-for="tag in Type1"
                  :key="tag.Name"
                  closable
                  @close="regionClose(tag)"
                >
                  <i class="el-icon-office-building"></i>
                  {{ tag.Name }}
                </el-tag>
                <el-tag
                  v-for="tag in Type2"
                  :key="tag.Name"
                  closable
                  @close="typeClose(tag)"
                >
                  <i class="el-icon-office-building"></i>
                  {{ tag.Name }}
                </el-tag>
                <el-tag
                  v-for="tag in Type31"
                  :key="tag.Name"
                  closable
                  @close="gradationClose(tag)"
                >
                  <i class="el-icon-office-building"></i>
                  {{ tag.Name }}
                </el-tag>
                <el-tag
                  v-for="tag in Type32"
                  :key="tag.Name"
                  closable
                  @close="gradation2Close(tag)"
                >
                  <i class="el-icon-office-building"></i>
                  {{ tag.Name }}
                </el-tag>
              </div>
            </div>
            <div class="button-actions pr10" @click="rest()">重置已选条件</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="tzy-page-center">
      <div class="left">
        <div
          :class="
            typShow == 0
              ? 'el-dropdown tzy-page-center_button-default'
              : 'el-dropdown'
          "
          @click="cwb(0)"
        >
          <span> 全部 {{ dataCount }} </span>
        </div>
        <div
          :class="
            typShow == 1
              ? 'el-dropdown tzy-page-center_button-default'
              : 'el-dropdown'
          "
          @click="cwb(1)"
        >
          <span> 可冲击 {{ c }} </span>
        </div>
        <div
          :class="
            typShow == 2
              ? 'el-dropdown tzy-page-center_button-default'
              : 'el-dropdown'
          "
          @click="cwb(2)"
        >
          <span> 较稳妥 {{ w }} </span>
        </div>
        <div
          :class="
            typShow == 3
              ? 'el-dropdown tzy-page-center_button-default'
              : 'el-dropdown'
          "
          @click="cwb(3)"
        >
          <span> 可保底 {{ b }} </span>
        </div>
      </div>
      <div class="right">
        <el-form ref="form" label-width="80px">
          <el-form-item style="margin: 0" label="推荐范围:">
            <input-number-range
              :disabled="false"
              :precision="0"
              v-model="numberRange"
            ></input-number-range>
          </el-form-item>
        </el-form>
        <div class="btn" @click="getList()">确定</div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="tableBox">
      <div class="recommend-data-eu-table___J2Nqt">
        <div class="recommend-data-eu-table-hd-box___UexWu">
          <div class="recommend-data-eu-table-hd___Ep8R8">
            <div class="recommend-data-eu-table-eu-name___fRm_H">
              <span>学校</span>
              <i
                v-if="sort3 && isSort == 2"
                class="el-icon-sort"
                style="color: #187cff; cursor: pointer"
                @click="PxSort2(!sort3)"
              ></i>
              <i
                v-else
                class="el-icon-sort"
                style="cursor: pointer"
                @click="PxSort2(!sort3)"
              ></i>
            </div>
            <div class="recommend-data-eu-table-ratio___TwQdX">
              <span>录取概率</span>
              <i
                v-if="sort1 && isSort == 0"
                class="el-icon-sort"
                style="color: #187cff; cursor: pointer"
                @click="PxSort(!sort1)"
              ></i>
              <i
                v-else
                class="el-icon-sort"
                style="cursor: pointer"
                @click="PxSort(!sort1)"
              ></i>
            </div>
            <div class="recommend-data-eu-table-plan___sNM8l">
              <span>2024年招生计划</span>
              <i
                v-if="sort2 && isSort == 1"
                class="el-icon-sort"
                style="color: #187cff; cursor: pointer"
                @click="PxSort1(!sort2)"
              ></i>
              <i
                v-else
                class="el-icon-sort"
                style="cursor: pointer"
                @click="PxSort1(!sort2)"
              ></i>
            </div>
            <div class="recommend-data-eu-table-admission-data___a50VQ">
              <div class="year-hd___eE2lN">往年</div>
              <div class="year-item___NKiGo">2023</div>
              <div class="year-item___NKiGo">2022</div>
              <div class="year-item___NKiGo">2021</div>
            </div>
            <div class="recommend-data-eu-table-major-count___LhMrX">专业</div>
          </div>
        </div>
        <div class="aa">
          <template v-if="list.length !== 0">
            <div v-for="(it, idx) in list" :key="idx">
              <div class="recommend-data-eu-table-item___BXnAP element">
                <div class="recommend-data-eu-table-eu-name___fRm_H">
                  <div
                    class="sch-name___afuQF"
                    @click="goSchoolDetails(it.uId)"
                  >
                    {{ it.name }}
                  </div>
                  <div class="sch-line___oPJFW">
                    排名{{ it.rank }} {{ it.educationCategory }} {{ it.city }}
                    {{ it.ownership }}
                  </div>
                  <div class="sch-line___oPJFW">
                    <span v-for="(its, idxs) in it.features" :key="idxs">{{
                      its
                    }}</span>
                  </div>
                  <div class="sch-line___oPJFW">代码 {{ it.collegeCode }}</div>
                </div>
                <div class="recommend-data-eu-table-ratio___TwQdX">
                  <div class="ratio-num___gAwOo">{{ it.percentAge }}</div>
                </div>
                <div class="recommend-data-eu-table-plan___sNM8l">
                  <div class="plan-count___UrtQc">
                    <div class="plan-count-num___IspKS">
                      {{ it.planCount }}人
                    </div>
                  </div>
                </div>
                <div class="recommend-data-eu-table-admission-data___a50VQ">
                  <div class="admission-line___q58Dc">
                    <div class="year-hd___eE2lN">计划</div>
                    <div
                      class="year-item___NKiGo"
                      v-for="(item, index) in it.childItems"
                      :key="index"
                    >
                      {{ item.planCount }}人
                    </div>
                  </div>
                  <div class="admission-line___q58Dc">
                    <div class="year-hd___eE2lN">底分</div>
                    <div
                      class="year-item___NKiGo"
                      v-for="(item, index) in it.childItems"
                      :key="index"
                    >
                      {{ item.score }}分
                    </div>
                  </div>
                  <div class="admission-line___q58Dc">
                    <div class="year-hd___eE2lN">底位</div>
                    <div
                      class="year-item___NKiGo"
                      v-for="(item, index) in it.childItems"
                      :key="index"
                    >
                      {{ item.rankLine }}名
                    </div>
                  </div>
                </div>
                <div class="recommend-data-eu-table-major-count___LhMrX">
                  <div class="count-box___NYb3B">
                    <div class="count___QftTC" @click="fn(it.chenck, idx)">
                      <span>
                        专业 {{ it.items.length }}&nbsp;
                        <i
                          class="el-icon-arrow-down major-icon active"
                          v-if="!it.chenck"
                        ></i>
                        <i
                          class="el-icon-arrow-up major-icon active"
                          v-else
                        ></i>
                      </span>
                    </div>
                    <div class="fill-btn___EEFGE">已报{{ it.registered }}</div>
                  </div>
                </div>
              </div>
              <transition
                name="fade"
                v-for="(item, index) in it.items"
                :key="index"
              >
                <div
                  class="recommend-data-eu-table-item___BXnAP"
                  style="background-color: #fbfbfb; margin-bottom: 0"
                  v-if="it.chenck"
                >
                  <div class="recommend-data-eu-table-eu-name___fRm_H">
                    <div
                      style="
                        font-size: 14px;
                        font-weight: 400;
                        color: #272727;
                        line-height: 20px;
                        padding: 17px 0;
                      "
                    >
                      {{ item.major }}
                    </div>
                    <div class="sch-line___oPJFW">
                      代码 {{ item.majorCode }}
                    </div>
                  </div>
                  <div class="recommend-data-eu-table-ratio___TwQdX">
                    <div class="ratio-num___gAwOo">
                      {{ item.percentAge }}
                    </div>
                  </div>
                  <div class="recommend-data-eu-table-plan___sNM8l">
                    <div class="plan-count___UrtQc">
                      <div class="plan-count-num___IspKS">
                        {{ item.planCount }}人
                      </div>
                    </div>
                    <div class="plan-info___kz4Ip">
                      <!-- {{
                        item.AcademicYear == undefined
                          ? "数据待更新"
                          : item.AcademicYear
                      }} -->
                      年 ￥{{ item.fee }}/元
                    </div>
                    <div class="plan-subject___wsd5H">
                      {{ item.SubjectClam == null ? "不限" : item.SubjectClam }}
                    </div>
                  </div>
                  <div class="recommend-data-eu-table-admission-data___a50VQ">
                    <div class="admission-line___q58Dc">
                      <div class="year-hd___eE2lN">录取</div>
                      <div
                        class="year-item___NKiGo"
                        v-for="(itt, idd) in item.items"
                        :key="idd"
                      >
                        {{ itt.count }}
                      </div>
                    </div>
                    <!-- <div class="admission-line___q58Dc">
                      <div class="year-hd___eE2lN">线差</div>
                      <div class="year-item___NKiGo">243分</div>
                      <div class="year-item___NKiGo">264分</div>
                      <div class="year-item___NKiGo">284分</div>
                    </div> -->
                    <div class="admission-line___q58Dc">
                      <div class="year-hd___eE2lN">底分</div>
                      <div
                        class="year-item___NKiGo"
                        v-for="(itt, idd) in item.items"
                        :key="idd"
                      >
                        {{ itt.score }}
                      </div>
                    </div>
                    <div class="admission-line___q58Dc">
                      <div class="year-hd___eE2lN">底位</div>
                      <div
                        class="year-item___NKiGo"
                        v-for="(itt, idd) in item.items"
                        :key="idd"
                      >
                        {{ itt.rankLine }}
                      </div>
                    </div>
                  </div>
                  <div class="recommend-data-eu-table-major-count___LhMrX">
                    <div class="count-box___NYb3Btb">
                      <div
                        v-if="!item.chencks"
                        class="fill-btn___EEFGE"
                        @click="informant(item, idx, '增加')"
                      >
                        <i class="el-icon-plus"></i>
                        填报
                      </div>
                      <div
                        v-else
                        class="fill-btn___EEFGE"
                        @click="informant(item, idx, '减少')"
                      >
                        <i class="el-icon-minus"></i>
                        已填报
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </template>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>

        <!-- 右侧边栏 -->
        <div
          id="tzy-selected-pop"
          :class="isshopCar ? '' : 'slide-out'"
          cart-list=""
        >
          <div class="shoppingCarLeft pull-left">
            <div class="foldBtn" @click="shopCar()">
              <p class="f16">展开志愿表</p>
              <span class="f18">{{ filteredList.length }}</span>
            </div>
            <button class="yzy-button save" @click="gozybEditor()">保存</button>
            <button
              class="yzy-button save"
              style="border-top: 0px"
              @click="empty()"
            >
              清空
            </button>
            <button
              class="yzy-button save safe"
              style="border-top: 0px"
              @click="toTop()"
            >
              顶部
            </button>
          </div>
          <div class="shoppingCarRight pull-right" style="height: 87vh">
            <div class="searchInput el-input el-input--suffix">
              <input
                type="text"
                v-model="keyword"
                autocomplete="off"
                placeholder="院校/专业搜索"
                class="el-input__inner"
              /><span class="el-input__suffix"
                ><span class="el-input__suffix-inner">
                  <i
                    class="el-input__icon el-icon-search f16"
                    style="cursor: pointer"
                  ></i> </span
              ></span>
            </div>
            <div
              class="cartList"
              style="height: 70vh"
              v-if="filteredList.length !== 0"
            >
              <div
                class="cartListItem"
                v-for="(its, idxs) in filteredList"
                :key="idxs"
              >
                <div class="cartCollege">
                  <div class="college-title dataType pull-left chong">
                    <span class="college-num">{{ idxs + 1 }}</span>
                  </div>
                  <span class="cartCollegeName">{{ its.name }}</span>
                  <i
                    class="el-icon-delete delicon"
                    style="cursor: pointer; color: rgb(155, 155, 155)"
                    @click="deleteSelective(idxs, its.name, its.major)"
                  ></i>
                </div>
                <div class="professionItem">
                  <p class="professionName ml30">
                    <span>{{ its.major }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="cartList" style="height: 70vh" v-else>
              <el-empty description="暂时还没有哦~"></el-empty>
            </div>
            <button class="yzy-button creatsuccess" @click="gozybEditor()">
              <p>保存志愿表</p>
              <span>( 已填{{ filteredList.length }}个志愿 )</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetRegionList,
  getAiUniversity,
  GetCustomerInfoById,
} from "@/api/home";
import inputNumberRange from "@/components/inputNumberRange";
import { UserMapCartsput } from "@/api/zybEditor";
import { request2 } from "@/utils/request";
import { VolunteerGetZJ } from "@/api/zybEditor";
export default {
  name: "VoluntaryReportingIndex",
  components: {
    inputNumberRange,
  },
  data() {
    return {
      ZJZYBId: "", //志愿表的id
      ZYlocatCode: "",
      cId: "",
      TYPE: false, //是否是清空
      batchDataUrl: "",
      batchDataUrlList: [],
      leibiestr: "",
      userInfoObj: {},
      keyword: "", // 存放输入的关键字
      isSort: 0,
      sort1: false,
      sort2: false,
      sort3: false,
      // tab切换
      typShow: 0,

      // 冲击 稳妥 保底
      c: 0,
      w: 0,
      b: 0,

      //列表数量
      dataCount: 0,
      basicInfo: "",
      //提交获取学校及专业列表数据
      studentData: {
        Year: 2023,
        Name: "",
        Province: [],
        SchoolType: [],
        Nature: [],
        Ascription: [],
      },

      // 范围选择框
      numberRange: [null, null],

      // 选择列表
      selectiveListing: [],

      // 学校专业列表
      list: [],

      // 全部学校专业列表
      listQ: [],

      // 可冲击学校专业列表
      listC: [],

      // 较稳妥学校专业列表
      listW: [],

      // 可保底学校专业列表
      listB: [],

      // 已选择的院校省份
      Type1: [],

      // 已选择的院校类型
      Type2: [],

      // 已选择的院校层次（1）
      Type31: [],

      // 已选择的院校层次（2）
      Type32: [],

      // 已选条件数组
      tags: [],

      // 省份类型
      Type1List: [],

      // 院校类型
      Type2List: [
        { Id: -2, Code: "-2", Name: "不限", chenck: true },
        { Id: 0, Code: "0", Name: "综合", chenck: false },
        { Id: 1, Code: "1", Name: "理工类", chenck: false },
        { Id: 2, Code: "2", Name: "医学类", chenck: false },
        { Id: 3, Code: "3", Name: "军事类", chenck: false },
        { Id: 4, Code: "4", Name: "语言类", chenck: false },
        { Id: 5, Code: "5", Name: "师范类", chenck: false },
        { Id: 6, Code: "6", Name: "财经类", chenck: false },
        { Id: 7, Code: "7", Name: "政法类", chenck: false },
        { Id: 8, Code: "8", Name: "民族类", chenck: false },
        { Id: 9, Code: "9", Name: "农林类", chenck: false },
        { Id: 10, Code: "10", Name: "艺术类", chenck: false },
        { Id: 11, Code: "11", Name: "体育类", chenck: false },
        { Id: 12, Code: "12", Name: "其他", chenck: false },
      ],

      // 院校层次（1）
      Type31List: [
        { Id: -3, Code: "-1", Name: "不限", chenck: true },
        { Id: 0, Code: "0", Name: "公办", chenck: false },
        { Id: 1, Code: "1", Name: "民办", chenck: false },
        { Id: 2, Code: "2", Name: "中外合作", chenck: false },
        { Id: 3, Code: "3", Name: "港澳台", chenck: false },
      ],

      // 院校层次（2）
      Type32List: [
        { Id: 1, Code: "1", Name: "教育部", chenck: false },
        { Id: 2, Code: "2", Name: "省政府", chenck: false },
        { Id: 3, Code: "3", Name: "其他部委", chenck: false },
        { Id: 4, Code: "4", Name: "军校", chenck: false },
      ],

      // 右侧侧边栏展开收起
      isshopCar: true,
    };
  },
  computed: {
    filteredList() {
      if (this.keyword === "") {
        return this.selectiveListing; // 如果没有输入关键字则返回全部数据
      } else {
        const regExp = new RegExp(this.keyword, "i"); // 创建正则表达式对象，不区分大小写

        return this.selectiveListing.filter((item) => {
          return regExp.test(item.major) || regExp.test(item.name); // 根据名称进行匹配
        });
      }
    },
  },
  mounted() {
    this.ZJZYBId = this.$route.query.ZJZYBId || "";
    this.ZYlocatCode = this.$route.query.locatCode || "";
    this.TYPE = this.$route.query.TYPE || false;
    this.cId = this.$route.query.cId || "";
    this.getVolunteerList();
    this.getRegionList();
  },

  methods: {
    // Pc查询单个志愿表详情信息
    async getVolunteerList() {
      const res = await VolunteerGetZJ({
        id: this.ZJZYBId,
        locationcode: this.ZYlocatCode,
      });
      console.log("志愿表数据", res.data.result);
      this.basicInfo = {};
      this.basicInfo.expectedScore = res.data.result.voTable.score;
      this.basicInfo.sp = res.data.result.voTable.sp;
      this.basicInfo.subjectGroup = res.data.result.voTable.subjectClaim;
      this.basicInfo.provinceCode = this.ZYlocatCode;
      this.batchDataUrl =
        `https://api.static.ycymedu.com/api/v1/batch/s/` +
        this.ZYlocatCode +
        `.json`;

      const mergedItems = [];
      // 遍历 rows 数组
      res.data.result.tbDetails.forEach((university) => {
        // 获取大学的基本信息，去除 uId 和 type
        const { vItems, ...universityData } = university;

        // 将每个专业信息与大学的基本信息合并
        vItems.forEach((major) => {
          // 合并大学的基本信息与每个专业项，添加到 mergedItems 中
          mergedItems.push({
            ...universityData,
            ...major,
          });
        });
      });
      if (this.TYPE) {
        this.selectiveListing = [];
      } else {
        var arr = mergedItems;
        console.log(arr);
        arr.forEach((it) => {
          it.name = it.unName;
          it.uId = it.unId;
          it.collegeCode = it.unCode;
          delete it.unName;
          delete it.unId;
          delete it.unCode;
        });
        console.log(1, JSON.stringify(arr));
        this.selectiveListing = arr;
      }

      this.getBatchDataUrlList();
      this.setDate();
    },
    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.basicInfo = res.data.result.userExtend;
      this.batchDataUrl = res.data.result.batchDataUrl;
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
      this.getBatchDataUrlList();
      this.setDate();
    },
    //获取批次线政策
    getBatchDataUrlList() {
      var that = this;
      request2
        .get(this.batchDataUrl)
        .then((res) => {
          let list = res.data.result[0].batches;
          if (that.basicInfo.sp == 0 || that.basicInfo.sp == 3) {
            // 使用正则表达式提取 "物" 或 "史"
            let regex = /(物|史)/; // 正则表达式匹配 "物" 或 "史"
            let match = that.basicInfo.subjectGroup.match(regex);
            if (match) {
              console.log(match[0]); // 输出匹配到的第一个字符串，如 "物"
            } else {
              console.log("没有找到匹配的科目");
            }

            if (match[0] === "物") {
              // 筛选出 '理科' 数据
              that.batchDataUrlList = list.filter(
                (item) => item.course === "理科"
              );
            } else if (match[0] === "史") {
              // 筛选出 '文科' 数据
              that.batchDataUrlList = list.filter(
                (item) => item.course === "文科"
              );
            }
          } else {
            that.batchDataUrlList = list;
          }
          let stop = false; // 停止标志

          that.batchDataUrlList.forEach((its) => {
            if (stop) return; // 如果已经找到符合条件的分数，停止遍历
            if (that.basicInfo.expectedScore >= its.score) {
              that.leibiestr = its.batch;
              its.recommend = true;
              stop = true; // 设置停止标志
            }
          });
          that.batchDataUrlList.forEach((its) => {
            if (that.basicInfo.expectedScore < its.score) {
              its.price =
                `线差` + (its.score - that.basicInfo.expectedScore) + `分`;
            } else {
              its.price =
                `线高` + (that.basicInfo.expectedScore - its.score) + `分`;
            }
          });
          that.getList();
        })
        .catch((error) => {
          console.error("Request failed:", error); // 打印错误信息
        });
    },

    //切换段位类别
    handleCommand(command) {
      this.leibiestr = command;
      this.getList();
      this.$message("click on item " + command);
    },

    // 跳转学校详情
    goSchoolDetails(Id) {
      const routeData = this.$router.resolve({
        path: "/Home/SchoolDetails",
        query: { Id },
      });
      window.open(routeData.href, "_blank");
    },

    // 清空右侧以选择的列表数据
    empty() {
      // 清空 selectiveListing
      this.selectiveListing = [];

      // 遍历 list，清除 chenck 和 registered，并重置 items 中的 chencks
      this.list.forEach((item) => {
        this.$set(item, "chenck", false);
        this.$set(item, "registered", 0);

        // 重置每个 item 中的 chencks
        item.items.forEach((it) => this.$set(it, "chencks", false));
      });
    },

    // 回填数据
    setDate() {
      // let studentInfo = this.userInfoObj
      // 志愿表数据回填
      this.numberRange = [
        this.basicInfo.expectedScore - 15,
        this.basicInfo.expectedScore + 15,
      ];
    },

    // 切换
    cwb(typ) {
      this.typShow = typ;

      // 使用对象映射方式
      const listMap = {
        0: this.listQ,
        1: this.listC,
        2: this.listW,
        3: this.listB,
      };

      // 设置 list 为对应的数组，默认值是 this.listQ
      this.list = listMap[typ] || this.listQ;
    },

    // 保存志愿提交
    async gozybEditor() {
      if (this.selectiveListing.length === 0) {
        return this.$message.error("请选择专业后再保存");
      }

      const obj = {
        score: this.basicInfo.expectedScore,
        cId: this.cId,
        subjectClaim: this.basicInfo.subjectGroup,
        batchName: this.leibiestr,
        type: "智能填报",
        vId: this.ZJZYBId,
        vTbDetails: this.selectiveListing.map((it, idx) => {
          const objItem = {
            sort: idx + 1,
            unCode: it.collegeCode,
            unId: it.uId,
            unName: it.name,
            vItems: [
              {
                sort: idx + 1,
                code: it.majorCode,
                name: it.major,
                percentAge: it.percentAge,
                type: it.type,
                _pId: it._pId || it.planId,
              },
            ],
          };
          return objItem;
        }),
      };

      try {
        console.log(obj);
        const res = await UserMapCartsput(obj);
        this.$router.push({
          path: "/Home/zybEditorTwo",
          query: {
            VId: res.data.result,
            expert: true,
            locatCode: this.ZYlocatCode,
            cId: this.cId,
          },
        });
      } catch (error) {
        console.error("Error during request:", error);
      }
    },

    // 右侧列表删除
    deleteSelective(idx, Name, major) {
      // 删除 selectiveListing 中的元素
      this.selectiveListing.splice(idx, 1);

      // 查找对应的 list 项
      const listIndex = this.list.findIndex((item) => item.name === Name);
      if (listIndex !== -1) {
        const listItem = this.list[listIndex];
        listItem.registered -= 1; // 减少注册人数

        // 查找对应的 major 项
        const itemIndex = listItem.items.findIndex(
          (item) => item.major === major
        );
        if (itemIndex !== -1) {
          listItem.items[itemIndex].chencks = false; // 取消选中
        }
      }
    },

    // 点击填报加入到右侧列表中
    informant(item, idx, type) {
      if (type === "增加") {
        this.isshopCar = false;

        // 查找是否已经存在于 selectiveListing
        const index = this.selectiveListing.findIndex(
          (itt) => itt.major === item.major
        );

        item.chencks = true;

        // 如果不存在，则添加到 selectiveListing
        if (index === -1) {
          const obj = {
            ...item, // 展开 item 属性
            collegeCode: this.list[idx].collegeCode,
            name: this.list[idx].name,
            uId: this.list[idx].uId,
          };

          this.selectiveListing.push(obj);
          this.list[idx].registered += 1; // 增加已注册数量
        }
      } else if (type === "减少") {
        // 查找是否在 selectiveListing 中
        const index = this.selectiveListing.findIndex(
          (itt) => itt.major === item.major
        );

        // 如果找到，则移除并更新状态
        if (index !== -1) {
          this.selectiveListing.splice(index, 1); // 移除对应的项
          this.list[idx].registered -= 1; // 减少已注册数量
          item.chencks = false; // 取消选中状态
        }
      }
    },

    // 获取冲稳保院校
    async getList() {
      const { subjectGroup, provinceCode, sp, expectedScore } = this.basicInfo;
      const obj = {
        batchname: this.leibiestr,
        location: provinceCode,
        majors: this.studentData.SchoolType,
        nature: [...this.studentData.Nature, ...this.studentData.Ascription],
        p: sp,
        pageIndex: 1,
        pageSize: 999,
        province: this.studentData.Province,
        score: expectedScore,
        subjects: subjectGroup.split(","),
        type: "",
        startscore: this.numberRange[0],
        endscore: this.numberRange[1],
      };

      try {
        const res = await getAiUniversity(obj);
        const rows = res.data.result.rows;
        this.list = rows;
        this.dataCount = rows.length;

        // 初始化分类数组
        this.listB = [];
        this.listW = [];
        this.listC = [];
        this.listQ = [];

        // 遍历数据并分类处理
        rows.forEach((item) => {
          // 使用 $set 保证 chenck 和 registered 的响应式
          this.$set(item, "chenck", false);
          this.$set(item, "registered", 0);
          this.listQ.push(item);

          // 根据 item.type 分类
          if (item.type === 0) {
            this.listB.push(item);
          } else if (item.type === 1) {
            this.listW.push(item);
          } else {
            this.listC.push(item);
          }

          // 遍历 item.items 并初始化 chencks
          item.items.forEach((it) => {
            this.$set(it, "chencks", false);
          });
        });
        console.log(3, JSON.stringify(this.list));

        // 更新分类后的计数
        this.c = this.listC.length;
        this.w = this.listW.length;
        this.b = this.listB.length;
        this.fn1();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    fn1() {
      // 遍历 dataset1 的每个对象
      this.list.forEach((item1) => {
        let registeredCount = 0; // 用于记录匹配到的数量

        // 遍历 item1.items
        item1.items.forEach((subItem1) => {
          const planId1 = subItem1.planId;

          // 遍历 dataset2 找到 _pId 匹配的项
          const matchedItem = this.selectiveListing.find(
            (item2) => item2._pId === planId1
          );

          // 如果找到匹配项
          if (matchedItem) {
            subItem1.chencks = true; // 设置 chencks 为 true
            registeredCount++; // 增加计数
          }
        });

        // 更新 item1 的 registered 值
        item1.registered = registeredCount;
      });

      console.log(this.list);
    },
    // 选择院校省份
    selectRegion(it, idx) {
      this.disport(it, idx, this.Type1List, this.Type1, 0);
    },

    // 选择院校类型
    selectType(it, idx) {
      this.disport(it, idx, this.Type2List, this.Type2, 0);
    },

    // 选择院校层次1
    selectGradation(it, idx) {
      this.disport(it, idx, this.Type31List, this.Type31, 1);
    },

    // 选择院校层次2
    selectGradation2(it, idx) {
      this.disport(it, idx, this.Type32List, this.Type32, 1);
    },

    // 获取省份数据
    async getRegionList() {
      const res = await GetRegionList({
        code: 0,
      });
      this.Type1List = res.data.response;
      this.Type1List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type1List.unshift({
        Id: -1,
        Code: "-1",
        Name: "不限",
        chenck: true,
      });
    },

    // 录取概率排序
    PxSort(text) {
      this.isSort = 0;
      this.sort1 = text;
      if (text) {
        this.list = this.list.sort(
          (a, b) => parseFloat(b.percentAge) - parseFloat(a.percentAge)
        );
      } else {
        this.list = this.list.sort(
          (a, b) => parseFloat(a.percentAge) - parseFloat(b.percentAge)
        );
      }
    },

    // 人数排序
    PxSort1(text) {
      this.isSort = 1;
      this.sort2 = text;
      if (text) {
        this.list = this.list.sort((a, b) => b.planCount - a.planCount);
      } else {
        this.list = this.list.sort((a, b) => a.planCount - b.planCount);
      }
    },

    //排名排序
    PxSort2(text) {
      this.isSort = 2;
      this.sort3 = text;
      if (text) {
        this.list = this.list.sort((a, b) => b.rank - a.rank);
      } else {
        this.list = this.list.sort((a, b) => a.rank - b.rank);
      }
    },

    // 合并
    mergeARR() {
      this.studentData.Province = [];
      this.studentData.SchoolType = [];
      this.studentData.Nature = [];
      this.studentData.Ascription = [];
      this.Type1.forEach((item) => {
        this.studentData.Province.push(item.Name);
      });
      this.Type2.forEach((item) => {
        this.studentData.SchoolType.push(item.Name);
      });
      this.Type31.forEach((item) => {
        this.studentData.Nature.push(item.Name);
      });
      this.Type32.forEach((item) => {
        this.studentData.Ascription.push(item.Name);
      });
      this.tags = [
        ...this.Type1,
        ...this.Type31,
        ...this.Type32,
        ...this.Type2,
      ];
      this.list = [];
      this.getList();
    },

    // 重置
    rest() {
      this.tags = [];
      this.Type1 = [];
      this.Type31 = [];
      this.Type32 = [];
      this.Type2 = [];
      this.studentData.Province = [];
      this.studentData.SchoolType = [];
      this.studentData.Nature = [];
      this.studentData.Ascription = [];
      this.numberRange = [
        this.$store.state.user.studentInfo.Expectedscore - 15,
        this.$store.state.user.studentInfo.Expectedscore,
      ];
      this.Type1List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type2List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type31List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type32List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type1List[0].chenck = true;
      this.Type2List[0].chenck = true;
      this.Type31List[0].chenck = true;
      this.getList();
    },

    // 处理
    disport(it, idx, list, Arr, type) {
      if (it.Name !== "不限") {
        list[0].chenck = false;
        var index = Arr.findIndex((item) => item.Name == it.Name);
        // 没有就添加
        if (index == -1) {
          Arr.push(it);
          list[idx].chenck = true;
          this.mergeARR();
          // 有就删除
        } else {
          Arr.splice(index, 1);
          list[idx].chenck = false;
          this.mergeARR();
        }
        if (type == 1) {
          const b = this.Type31List.every((item) => item.chenck == false);
          const c = this.Type32List.every((item) => item.chenck == false);
          if (b && c) {
            this.Type31List[0].chenck = true;
          } else {
            this.Type31List[0].chenck = false;
          }
        } else {
          const b = list.some((item) => item.chenck == true);
          if (b) {
            list[0].chenck = false;
          } else {
            list[0].chenck = true;
          }
        }
      } else {
        if (type == 1) {
          this.Type31 = [];
          this.Type32 = [];
          this.Type31List.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          this.Type32List.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          this.Type31List[0].chenck = true;
          this.mergeARR();
        } else {
          Arr.splice(0, Arr.length); //清空数组
          list.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          list[0].chenck = true;
          this.mergeARR();
        }
      }
    },

    // 标签删除处理
    Close(List, Arr, Tag, Type) {
      List[List.indexOf(Tag)].chenck = false;
      Arr.splice(Arr.indexOf(Tag), 1);
      this.mergeARR();
      if (Type == 1) {
        const b = this.Type31List.every((item) => item.chenck == false);
        const c = this.Type32List.every((item) => item.chenck == false);
        if (b && c) {
          this.Type31List[0].chenck = true;
        } else {
          this.Type31List[0].chenck = false;
        }
      } else {
        const b = List.every((item) => item.chenck == false);
        if (b) {
          List[0].chenck = true;
        }
      }
    },

    regionClose(tag) {
      this.Close(this.Type1List, this.Type1, tag, 0);
    },
    typeClose(tag) {
      this.Close(this.Type2List, this.Type2, tag, 0);
    },
    gradationClose(tag) {
      this.Close(this.Type31List, this.Type31, tag, 1);
    },
    gradation2Close(tag) {
      this.Close(this.Type32List, this.Type32, tag, 1);
    },

    fn(check, idx) {
      this.list[idx].chenck = !check;
    },

    shopCar() {
      this.isshopCar = !this.isshopCar;
    },

    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.el-checkbox {
  padding: 10px;
  margin-right: 10px;
  width: 100%;
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  width: 160px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1.5s;
}

.fade-leave-active {
  transition: transform 0.5s;
}

.fade-leave-to {
  // transform: translateY(-190px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.element {
  animation: fadeIn 1s;
}

.tzy-user-info {
  .toys {
    display: flex;
  }
}

.tabs {
  position: relative;
  margin-top: 10px;

  .customer-college {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 18px;
    z-index: 2;
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      width: 70px;
      height: 20px;
      border-radius: 4px;
      text-align: center;
      line-height: 20px;
      background-color: #fff;
      color: #187cff;
      border: 1px solid #187cff;
      margin-left: 20px;
    }
  }

  ::v-deep .el-tabs__item {
    background-color: #f2f2f2;
    width: 200px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    margin-right: -1px;
    border-bottom: 1px solid #e5e5e5;
  }

  ::v-deep .el-tabs__item:hover {
    color: #187cff;
  }

  ::v-deep .is-active {
    border-bottom: 0 solid #e5e5e5;
    background-color: #fff;
    color: #187cff;
  }

  ::v-deep .el-tabs__header {
    margin: 0;
  }

  .tzy-filter-wrapper {
    padding: 24px;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;

    .filter-list {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px dashed #e6e6e6;

      .filter-list-title {
        font-size: 16px;
        text-align: center;
        color: #9b9b9b;
        margin-top: 13px;
      }

      .filter-list-tags {
        margin-left: 10px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .tag {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          font-style: normal;
          margin: 10px;
          padding: 2px 6px;
          margin-right: 10px;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
          box-sizing: border-box;
        }

        .tag:hover {
          color: #187cff;
        }

        .active {
          border: 1px solid #187cff;
          color: #187cff;
        }
      }
    }

    .college-level-wrapper {
      display: flex;
      border-bottom: 1px dashed #e6e6e6;

      .sx {
        font-weight: 100;
        height: 47px;
        display: flex;
        align-items: center;
        color: #e6e6e6;
        transform: translateY(-2px);
      }
    }
  }

  .myFilterRecordBlockRow {
    overflow: hidden;
    background: #fafafa;
    border: 1px solid #e5e5e5;
    display: flex;
    height: auto;
    transition: all 2s ease-in-out;
    display: flex;
    align-items: center;

    .button-actions {
      font-size: 16px;
      color: #187cff;
      margin-right: 20px;
      cursor: pointer;
    }

    .customer-selected-tags {
      flex: 1;
      min-height: 50px;
      padding: 6px 14px 6px 20px;
      overflow: hidden;
      display: flex;
      align-items: center;

      .title {
        font-size: 14px;
        color: #9b9b9b;
      }

      .tags {
        display: flex;
        justify-content: center;
        align-items: center;

        ::v-deep .el-tag {
          margin-right: 10px;
          font-size: 14px;
        }
      }
    }
  }
}

.tzy-page-center {
  position: relative;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #187cff;

  .el-dropdown {
    display: inline-block;
    position: relative;
    color: #606266;
    font-size: 14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-block;
    height: 40px;
    padding: 0 10px;
    min-width: 120px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    border: #f2f2f2 solid 1px;
    border-bottom: none;
    color: #187cff;
  }

  .tzy-page-center_button-default {
    background-color: #187cff;
    color: #fff;
  }

  .btn {
    position: absolute;
    right: 120px;
    top: 5px;
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 10px;
    color: #fff;
    background: #187cff;
    cursor: pointer;
  }
}

.tableBox {
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 22px 15px 20px;
}

.recommend-data-eu-table___J2Nqt {
  height: 100%;
  position: relative;

  .recommend-data-eu-table-hd-box___UexWu {
    position: relative;
    height: 39px;

    .recommend-data-eu-table-hd___Ep8R8 {
      display: flex;
      width: 100%;
      height: 39px;
      background-color: #f4f6f8;
      border: solid #efefef;
      border-width: 1px 1px 0;
      line-height: 39px;
      font-size: 14px;
      font-weight: 400;
      color: #9d9d9d;

      .recommend-data-eu-table-eu-name___fRm_H {
        flex: 3.9 1;
      }

      .recommend-data-eu-table-major-count___LhMrX {
        flex: 2 1;
        justify-content: center;
      }

      .recommend-data-eu-table-ratio___TwQdX {
        width: 125px;
      }

      .recommend-data-eu-table-plan___sNM8l {
        width: 155px;
      }

      .recommend-data-eu-table-admission-data___a50VQ {
        flex: 4 1;
        min-width: 339px;
        display: flex;
        padding: 0 !important;
        border-right-width: 0 !important;

        .year-hd___eE2lN {
          flex: 58 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }

        .year-item___NKiGo {
          flex: 89 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }
      }
    }

    .recommend-data-eu-table-hd___Ep8R8 > div {
      padding: 0 15px;
      border-right: 1px solid #efefef;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .recommend-data-eu-table-item___BXnAP > div:first-of-type {
    align-items: flex-start;
  }

  .recommend-data-eu-table-item___BXnAP {
    width: 100%;
    min-height: 190px;
    border: 1px solid #efefef;
    margin-bottom: 11px;
    position: relative;
    display: flex;

    .recommend-data-eu-table-eu-name___fRm_H {
      flex: 5 1;

      .sch-name___afuQF {
        font-size: 18px;
        font-weight: 600;
        color: #272727;
        line-height: 25px;
        margin-bottom: 5px;
        cursor: pointer;
      }

      .sch-name___afuQF:hover {
        color: #187cff;
      }

      .sch-line___oPJFW {
        font-size: 14px;
        font-weight: 400;
        color: #646464;
        line-height: 20px;
        margin-bottom: 5px;
        white-space: pre-wrap;
      }
    }

    .recommend-data-eu-table-major-count___LhMrX {
      flex: 2 1;
      justify-content: center;
      min-width: 145px;

      .count-box___NYb3Btb {
        width: 80px;
        border-radius: 6px;
        border: 1px solid #efefef;
        margin-bottom: 5px;

        .fill-btn___EEFGE {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #44b979;
          line-height: 1;
          border-top: 1px solid #efefef;
          cursor: pointer;
          user-select: none;
          height: 29px;
        }
      }

      .count-box___NYb3B {
        width: 105px;
        height: 62px;
        border-radius: 6px;
        border: 1px solid #efefef;
        margin-bottom: 5px;

        .count___QftTC {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 1;
          font-size: 14px;
          font-weight: 400;
          color: #9d9d9d;
          line-height: 31px;
          white-space: pre;

          span {
            cursor: pointer;
            color: #272727;
          }
        }

        .fill-btn___EEFGE {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #187cff;
          line-height: 1;
          border-top: 1px solid #efefef;
          cursor: pointer;
          user-select: none;
          height: 29px;
        }
      }
    }

    .recommend-data-eu-table-ratio___TwQdX {
      width: 125px;

      .ratio-num___gAwOo {
        font-size: 20px;
        font-family: DIN Alternate Bold;
        color: #272727;
        line-height: 24px;
        margin-bottom: 5px;
      }
    }

    .recommend-data-eu-table-plan___sNM8l {
      width: 155px;

      .plan-count___UrtQc {
        display: flex;
        width: max-content;

        .plan-count-num___IspKS {
          flex: 1 1;
          font-size: 20px;
          font-family: DIN Alternate Bold;
          color: #272727;
          line-height: 39px;
          text-align: right;
          padding-right: 3px;
          word-break: keep-all;
        }
      }

      .plan-info___kz4Ip,
      .plan-subject___wsd5H {
        font-size: 14px;
        font-weight: 400;
        color: #646464;
        line-height: 20px;
      }
    }

    .recommend-data-eu-table-admission-data___a50VQ {
      flex: 4 1;
      min-width: 339px;
      display: flex;
      padding: 0 !important;
      border-right-width: 0 !important;
      justify-content: space-between;

      .year-hd___eE2lN,
      .year-item___NKiGo {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .admission-line___q58Dc {
        border-bottom: 1px solid #efefef;
        flex: 1 1;
        display: flex;
        width: 100%;

        .year-item___NKiGo {
          flex: 89 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }

        .year-hd___eE2lN {
          flex: 58 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }
      }
    }
  }

  .recommend-data-eu-table-item___BXnAP > div {
    padding: 15px;
    border-right: 1px solid #efefef;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

#tzy-selected-pop.slide-out {
  transform: translateX(0);
}

#tzy-selected-pop {
  right: 0;
  position: fixed;
  top: 120px;
  width: 408px;
  height: 270px;
  z-index: 2000;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-transform: translateX(366px);
  transform: translateX(366px);
  display: flex;

  .shoppingCarLeft {
    width: 42px;
    height: 270px;
    cursor: pointer;

    .foldBtn {
      color: #fff;
      background: #2577e3;
      display: inline-block;
      border: 1px solid transparent;
      border-right: none;
      border-top-left-radius: 4px;

      .f16 {
        font-size: 16px !important;
        line-height: 22px;
        padding: 16px 13px;
        display: inline-block;
      }

      .f18 {
        font-size: 18px !important;
        line-height: 25px;
        text-align: center;
        margin-bottom: 16px;
        display: inline-block;
        width: 100%;
      }
    }

    .save {
      border: 1px solid #2577e3;
      background-color: #fff;
      color: #2577e3;
      font-size: 14px;
      width: 42px;
      height: 42px;
      cursor: pointer;
    }

    .safe {
      border-bottom-left-radius: 4px;
    }
  }

  .shoppingCarRight {
    background: #fff;
    border: 1px solid #e6e6e6;
    width: 366px;
    background: #fff;
    height: 100%;
    border-radius: 0 0 0 4px;
    overflow: hidden;

    .searchInput {
      margin: 15px 20px 0;
      height: 40px;
      font-size: 12px;
      line-height: 18px;
      width: 326px;
    }

    .el-input {
      position: relative;
      font-size: 14px;
      display: inline-block;
    }

    .cartList {
      width: 100%;
      padding: 0 20px;
      overflow-y: auto;

      .cartListItem {
        border-bottom: 1px solid #e6e6e6;
        padding: 20px 0;

        .cartCollege {
          width: 100%;
          line-height: 18px;
          overflow: hidden;
          height: 18px;
          display: flex;

          .delicon {
            font-size: 14px;
          }

          .chong.dataType {
            color: #fff;
            border-color: #e9302d;
            background-color: #e9302d;
            border: 1px solid #e6e6e6;
            height: 18px;
            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            padding: 0 4px;

            .college-num {
              display: block;
              min-width: auto;
              min-height: 16px;
              line-height: normal;
            }
          }

          .college-title {
            min-width: 18px;
          }

          .cartCollegeName {
            display: inline-block;
            margin-left: 12px;
            width: 280px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #212121;
          }
        }

        .professionItem {
          font-size: 14px;
          line-height: 22px;
          margin-top: 12px;

          .professionName {
            width: 294px;
            display: inline-block;
            display: flex;
            flex-wrap: nowrap;
            margin-left: 40px !important;
          }
        }
      }
    }

    .creatsuccess {
      cursor: pointer;
      margin-left: 20px;
      width: 336px;
      height: 44px;
      border: none;
      line-height: 44px;
      display: flex;
      justify-content: center;
      border-radius: 4px;
      color: #fff;
      background-color: #2577e3;
      margin-top: 30px;
    }
  }
}
</style>
