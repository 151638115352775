<template>
  <div>
    <router-view />
    <!-- 返回顶部 -->
    <back-to-top />
  </div>
</template>

<script>
import { GetCustomerInfoById, Callback } from "@/api/home";
import { getId } from "@/utils/auth.js";
export default {
  name: "App",
  mounted() {
    // if (this._isMobile()) {
    //   // 跳转至手机端路由
    //   // alert("手机端");
    //   window.location.href = "http://h5.ycymedu.com/";
    // } else {
    //   // 跳转至 pc 端路由
    //   // alert("pc端");
    //   this.$router.replace("/");
    // }
    // this.getCallback()
    // this.getUserInfo()
  },
  methods: {
    // _isMobile() {
    //   let flag = navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   );
    //   return flag;
    // },
    //
    // 回跳获取id
    async getCallback() {
      const res = await Callback({
        code: this.$route.query.code,
        state: this.$route.query.state,
        scope: "",
      });
      if (res.data.success) {
        this.$store.commit("user/setSerToken", res.data.response.servicetoken);
        this.$store.commit("user/setTokens", res.data.response.token);
        this.$store.commit("user/setIds", res.data.response.Id);
        this.$store.commit(
          "user/setthridtoken",
          res.data.response.servicetoken
        );
        this.getUserInfo(res.data.response.Id);
      } else {
        this.getUserInfo(getId());
      }
    },
    async getUserInfo(Id) {
      const res = await GetCustomerInfoById({
        Id: Id,
      });
      this.$store.commit("user/setStudentInfo", res.data.response);
      // 存放对象到sessionStorage
      const setStudentInfo = JSON.stringify(res.data.response);
      sessionStorage.setItem("setStudentInfo", setStudentInfo);

      // 判断用户完善信息是否完整
      if (!res.data.response.Datainit) {
        this.$router.push("/Home/Register");
      }
    },
  },
};
</script>

<style lang="less">
.el-popover {
  background: #fff !important;
  // box-shadow: 0px 3px 12px 0px rgba(48, 48, 48, 0.19) !important;
  border-radius: 10px !important;
  border: none;
  width: 110px !important;
  min-width: 110px !important;
}
.el-menu--popup {
  min-width: 130px !important;
  border-radius: 8px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popverClass {
  max-width: 560px !important;
  min-width: 140px !important;
  max-height: 650px !important;
}

body {
  background: #f3f3f3;
  font-family: PingFangSC-Regular, PingFang SC !important;
}

/* 滚动条样式 */
/* 滚动条的整体样式 */
/* 用于设置滚动条的整体样式
在这里设置宽高，以控制滚动条尺寸，且必须要设置宽高，否则不生效
宽高分别对应 y轴 和 x轴 的滚动条尺寸
若宽高为0，则可隐藏滚动条，但仍可保持滚动 */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

/* 滚动条内的轨道 */
/* 滚动条轨道
不设置则不出现轨道 */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

/* 滚动条内的滑块 */
/* 滚动条滑块，即滚动条滚动的部分
必须要设置，否则不会出现滑块 */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.15);
  transition: color 0.2s ease;
}

/* X轴滚动条和Y轴滚动条的交接处
不设置，默认为白色小方块，宽高随X轴和Y轴滚动条尺寸 */
/* ::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.1);
} */
/* ::-webkit-scrollbar-track-piece
没有滑块的滚动条轨道，或者说是内层轨道
同滚动条轨道 */
/* ::-webkit-scrollbar-button
滚动条两端的箭头按钮
不设置则不出现 */
</style>
