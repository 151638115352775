<template>
  <div class="box" ref="container">
    <div class="searchBox">
      <div class="searchTitle">高考专业查询</div>
      <div class="search">
        <span class="header-search-input___gKtlk">
          <input v-model="listObj.KeyWord" placeholder="搜索你感兴趣的专业" @keyup.enter="serve()" />
          <span class="prefix">
            <i class="el-icon-search"></i>
          </span>
        </span>
      </div>
      <div class="searchTag">
        <div class="tag" v-for="(item, index) in rcList" :key="index" @click="rcClick(item.name)">{{ item.name }}</div>
      </div>
    </div>
    <div class="content">
      <div class="leftContent">
        <div class="tabs">
          <div :class="listObj.Type == item.type ? 'tabItem active' : 'tabItem'" v-for="(item, index) in tabList"
            :key="index" @click="QhTabs(item)">
            {{ item.name }}
          </div>
        </div>
        <div class="list">
          <div class="ListItemBox" v-for="(it, idx) in list" :key="idx">
            <div class="ListItemTitle">
              <div class="Zy">{{ it.name }}</div>
              <div class="Js">{{ it.items.length }}个专业类，{{ it.itemsCount }}个{{ zyName }}专业</div>
            </div>
            <div class="ListItemList" v-for="(its, idxs) in it.items" :key="idxs">
              <div class="ListItemItem">
                <div class="Dl">{{ its.name }}</div>
                <div class="right">
                  <div class="Xl" v-for="(itss, idxss) in its.childMajors" :key="idxss"
                    @click="goDetail(itss.specId, itss.zydm)">
                    {{ itss.zymc
                    }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rightContent">
        <div class="banes">
          <div class="item" @click="goUrl('/Home/Assessment')">
            <img src="./images/11.png" alt="" />
          </div>
          <div class="item" @click="goUrl('/Home/ZhanJiaList')">
            <img src="./images/22.png" alt="" />
          </div>
        </div>
        <div class="articleCont_right_zhengce">
          <div class="title">
            <div class="tit">热门专业排行</div>
            <div class="more">
              <div class="moTab actives">本科</div>
              <div class="moTab">专科</div>
            </div>
          </div>
          <div class="school-box">
            <div class="school-item" v-for="(it, index) in 8" v-show="index <= 8" :key="index"
              @click="goSchoolDetails(it.Uid)">
              <div class="schoolName">
                <span class="name"> {{ index + 1 }}</span>
                <span class="type">软件工程</span>
              </div>
              <div class="Rd">138236 热度</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Getmajors } from "@/api/CheckTheMajor";
export default {
  name: "SearchUniversityIndex",
  components: {},
  data() {
    return {
      tabList: [
        {
          name: "本科 （普通教育）",
          type: '1050'
        },
        {
          name: "专科 （职业教育）",
          type: '1060'
        },
        {
          name: "高职 （专科）",
          type: '1070'
        },
      ],
      rcList: [
        {
          name: '哲学'
        },
        {
          name: '经济学'
        },
        {
          name: '法学'
        },
        {
          name: '教育学'
        },
        {
          name: '历史学'
        },
        {
          name: '理学'
        },
        {
          name: '工学'
        },
        {
          name: '农学'
        },
        {
          name: '医学'
        },
        {
          name: '管理学'
        },
        {
          name: '艺术学'
        },
        {
          name: '军事学'
        },
      ],
      list: [],
      listObj: {
        Type: '1050',
        KeyWord: '',
      },
      zyName: '本科'
    };
  },

  mounted() {
    this.getList()
  },

  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    rcClick(name) {
      this.listObj.KeyWord = name
      this.getList()
    },
    serve() {
      this.getList()
    },
    QhTabs(it) {
      this.listObj.Type = it.type
      this.zyName = it.name.split(' ')[0]

      this.getList()
    },
    async getList() {
      const res = await Getmajors({
        ...this.listObj
      })
      this.list = res.data.result[0].rootDtos
      console.log(res.data.result[0].rootDtos);
    },
    goDetail(id, zydm) {
      const routeData = this.$router.resolve({
        path: "/Home/Detail",
        query: { id, zydm },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  margin: 0 auto;
  min-height: 100vh;
  background: #ffffff;
}

.searchBox {
  background-image: url("./images/bac.png");
  background-size: 100% 100%;
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .searchTitle {
    margin-top: 77px;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .search {
    .header-search-input___gKtlk {
      position: relative;
      align-items: center;
      width: 658px;
      height: 46px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #a3cbff;
      font-size: 14px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      transition: all 0.3s;
      display: inline-flex;

      .prefix {
        margin-top: 5px;
        margin-right: 5px;
      }

      .prefix>i {
        font-size: 18px;
      }
    }
  }

  .searchTag {
    margin-top: 20px;
    display: flex;
    font-size: 16px;
    align-items: center;

    .tag {
      margin-right: 24px;
      cursor: pointer;
    }

    .tag:hover {
      color: #187cff;
    }
  }

  input {
    height: 50px;
    width: 100%;
    padding: 0;
    font-size: inherit;
    border: none;
    outline: none;
  }
}

.content {
  display: flex;
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
}

.leftContent {
  flex: 1;
  min-width: 956px;
  margin-right: 16px;

  .tabs {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #187cff;

    .tabItem {
      cursor: pointer;
      min-width: 104px;
      height: 38px;
      color: #187cff;
      border-radius: 8px 8px 0px 0px;
      text-align: center;
      line-height: 38px;
      font-size: 16px;
      padding: 0 5px;
    }

    .active {
      background: #187cff;
      color: #ffffff;
    }
  }

  .list {
    .ListItemBox {
      padding: 40px 0;
      border-bottom: 1px solid #eeeeee;

      .ListItemTitle {
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        .Zy {
          font-weight: 600;
          font-size: 20px;
          margin-right: 16px;
        }

        .Js {
          font-size: 14px;
          color: #86909c;
        }
      }

      .ListItemList {
        margin-bottom: 42px;

        .ListItemItem {
          display: flex;
          align-items: top;

          .Dl {
            margin-right: 104px;
            font-size: 16px;
            font-weight: 600;
            width: 96px;
          }

          .right {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;

            .Xl {
              cursor: pointer;
              width: 25%;
              margin-bottom: 16px;
            }

            .Xl:hover {
              color: #187cff;
            }
          }
        }
      }
    }
  }
}

.rightContent {
  width: 308px;
  overflow: hidden;
  float: left;

  .banes {
    width: 100%;

    .item {
      cursor: pointer;
      width: 308px;
      height: 154px;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .articleCont_right_zhengce {
    width: 100%;
    background: linear-gradient(#d9ebfb 0%, #ffffff 20%);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #ddecff;
    box-sizing: border-box;
    padding: 24px 20px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .tit {
        font-weight: 600;
        font-size: 20px;
        color: #187cff;
      }

      .more {
        display: flex;
        align-items: center;
        padding: 4px;
        height: 33px;
        background: #cbdfff;
        border-radius: 6px 6px 6px 6px;

        .moTab {
          width: 40px;
          height: 25px;
          border-radius: 4px 4px 4px 4px;
          font-size: 12px;
          text-align: center;
          line-height: 25px;
          cursor: pointer;
        }

        .actives {
          background: #ffffff;
          color: #187cff;
          font-weight: bold;
        }
      }
    }

    .school-box {
      margin-top: 38px;

      div,
      .school-box .school-item {
        display: flex;
        align-items: center;
      }

      .school-item:hover .type {
        color: #187cff !important;
      }

      .school-item {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .schoolName {
          font-size: 14px;
          display: flex;

          .type {
            margin-left: 10px;
          }
        }

        .Rd {
          font-size: 14px;
          color: #888;
        }
      }
    }

    .policy_title:hover {
      color: #187cff !important;
      cursor: pointer;
    }
  }
}

// ------loading样式---------
.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  padding: 20px 0;

  .loading {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
    margin-right: 10px;
  }

  .container {
    position: relative;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #fff;
  }
}

/*------loading动画---------*/
@keyframes rotate {
  from {
    transform-origin: center center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center center;
    transform: rotate(360deg);
  }
}
</style>
