<template>
  <div class="box" ref="container">
    <div class="leftContent">
      <div class="tabs">
        <div class="tabTitle">
          <div class="tit">院校库</div>
          <div class="zk" v-if="isTabarShow" @click="isTabarShow = false">
            展开 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="zk" v-else @click="isTabarShow = true">
            收起 <i class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div
          :class="
            isTabarShow
              ? 'isTabarShow tzy-filter-wrapper'
              : 'tzy-filter-wrapper'
          "
        >
          <div class="filter-list">
            <span class="filter-list-title"> 院校省份 </span>
            <div class="filter-list-tags">
              <span
                :class="it.chenck == true ? 'tag active' : 'tag'"
                v-for="(it, index) in regionList"
                :key="it.Id"
                @click="selectRegion(it, index)"
              >
                {{ it.Name }}
              </span>
            </div>
          </div>
          <div class="filter-list">
            <span class="filter-list-title"> 院校类型 </span>
            <div class="filter-list-tags">
              <span
                :class="it.chenck == true ? 'tag active' : 'tag'"
                v-for="(it, index) in typeList"
                :key="it.Id"
                @click="selectType(it, index)"
              >
                {{ it.Name }}
              </span>
            </div>
          </div>
          <div class="flex college-level-wrapper">
            <div class="filter-list filter-list-college">
              <span class="filter-list-title"> 院校层次 </span>
              <div class="filter-list-tags">
                <span
                  :class="it.chenck == true ? 'tag active' : 'tag'"
                  v-for="(it, index) in gradationList"
                  :key="it.Id"
                  @click="selectGradation(it, index)"
                >
                  {{ it.Name }}
                </span>
              </div>
            </div>
            <div class="filter-list tzy-filter-list-college">
              <div class="filter-list-tags" style="margin-left: 0">
                <span
                  :class="it.chenck == true ? 'tag active' : 'tag'"
                  v-for="(it, index) in gradationList2"
                  :key="it.Id"
                  @click="selectGradation2(it, index)"
                >
                  {{ it.Name }}
                </span>
              </div>
            </div>
          </div>
          <div class="filter-list">
            <span class="filter-list-title"> 院校搜索 </span>
            <div class="filter-list-tags">
              <!-- 搜索 -->
              <div class="customer-college">
                <el-input
                  size="small"
                  v-model="listObj.Name"
                  placeholder="请输入学校名称"
                  @keyup.enter.native="servelist()"
                  suffix-icon="el-icon-search"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="schoolList"
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div class="tableTit">
          <div class="Tits" style="width: 250px; text-align: left">
            院校名称
          </div>
          <div class="Tit">所在地区</div>
          <div class="Tit">排名</div>
          <div class="Tit">招生专业</div>
          <div class="Tit">2024计划</div>
          <div class="Tit">较2023年</div>
        </div>
        <div class="tableItem">
          <div
            class="Item"
            v-for="it in list"
            :key="it.Id"
            @click="goSchoolDetails(it.UId)"
          >
            <div class="school" style="width: 250px; text-align: left">
              <div class="schoolImg">
                <img :src="it.Logo" />
              </div>
              <div class="name">{{ it.Name }}</div>
            </div>
            <div class="province">{{ it.AreaName }}</div>
            <div class="province">{{ it.Rank }}</div>
            <div class="province">{{ it.MajorCount }}</div>
            <div class="province">{{ it.ALLCount }}人</div>
            <div class="province" v-if="it.NewCount == 0">
              <span>相同</span>
            </div>
            <div class="province" v-if="it.NewCount < 0">
              <span class="ic"><img src="./images/x.png" alt="" /></span>
              <span>{{ it.NewCount }}</span>
            </div>
            <div class="province" v-if="it.NewCount > 0">
              <span class="ic"><img src="./images/s.png" alt="" /></span>
              <span>{{ it.NewCount }}</span>
            </div>
          </div>
        </div>
        <!-- 加载 -->
        <div class="loading_wrap" v-show="isLoading">
          <div class="loading">
            <div class="container"></div>
          </div>
          <div>正在加载中</div>
        </div>
      </div>
    </div>
    <div class="rightContent">
      <div class="banes">
        <div class="item" @click="goUrl('/Home/FirstPage')">
          <img src="./images/Frame 1000015157@2x.png" alt="" />
        </div>
        <div class="item" @click="goUrl('/Home/ZhanJiaList')">
          <img src="./images/Frame 1000015157@2x(1).png" alt="" />
        </div>
      </div>
      <div class="articleCont_right_zhengce">
        <div class="title">
          <div class="tit">院校推荐</div>
          <div class="more" @click="goUrl('/Home/ListRanking')">
            查看更多
            <i
              class="el-icon-arrow-right"
              style="color: #aeaeae; font-size: 14px"
            ></i>
          </div>
        </div>
        <div class="school-box">
          <div
            class="school-item"
            v-for="(it, index) in list"
            v-show="index <= 8"
            :key="index"
            @click="goSchoolDetails(it.Uid)"
          >
            <div class="schoolPhoto">
              <img :src="it.Logo" alt="" />
            </div>
            <div class="schoolName">
              <span class="name"> {{ it.Name }}</span>
              <span class="type">北京.综合类</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetRegionList } from "@/api/home";
import { GetUniversityPlanPro } from "@/api/ExpansionAndContraction";
export default {
  name: "SearchUniversityIndex",
  components: {},
  data() {
    return {
      isTabarShow: false,
      isLoading: true, // 展示loading
      ZPage: 0,
      listObj: {
        Name: "",
        AreaName: [],
        Nature: [],
        Type: [],
        PageIndex: 1,
        PageSize: 10,
      },
      list: [],
      // 已选择的院校省份
      regionArr: [],

      // 已选择的院校类型
      typeArr: [],

      // 已选择的院校层次（1）
      gradationArr: [],

      // 已选择的院校层次（2）
      gradationArr2: [],

      // 已选条件数组
      tags: [],

      // 院校类型
      regionList: [],

      // 院校类型
      typeList: [
        { Id: -2, Code: "-2", Name: "不限", chenck: true },
        { Id: 0, Code: "0", Name: "综合", chenck: false },
        { Id: 1, Code: "1", Name: "理工类", chenck: false },
        { Id: 2, Code: "2", Name: "医学类", chenck: false },
        { Id: 3, Code: "3", Name: "军事类", chenck: false },
        { Id: 4, Code: "4", Name: "语言类", chenck: false },
        { Id: 5, Code: "5", Name: "师范类", chenck: false },
        { Id: 6, Code: "6", Name: "财经类", chenck: false },
        { Id: 7, Code: "7", Name: "政法类", chenck: false },
        { Id: 8, Code: "8", Name: "民族类", chenck: false },
        { Id: 9, Code: "9", Name: "农林类", chenck: false },
        { Id: 10, Code: "10", Name: "艺术类", chenck: false },
        { Id: 11, Code: "11", Name: "体育类", chenck: false },
        { Id: 12, Code: "12", Name: "其他", chenck: false },
      ],

      // 院校层次（1）
      gradationList: [
        { Id: -3, Code: "-1", Name: "不限", chenck: true },
        { Id: 0, Code: "0", Name: "公办", chenck: false },
        { Id: 1, Code: "1", Name: "民办", chenck: false },
        { Id: 2, Code: "2", Name: "中外合作", chenck: false },
        { Id: 3, Code: "3", Name: "港澳台", chenck: false },
      ],

      // 院校层次（2）
      gradationList2: [
        { Id: 1, Code: "1", Name: "教育部", chenck: false },
        { Id: 2, Code: "2", Name: "省政府", chenck: false },
        { Id: 3, Code: "3", Name: "其他部委", chenck: false },
        { Id: 4, Code: "4", Name: "军校", chenck: false },
      ],
    };
  },
  scrollBottom() {
    //加载更多
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    //变量windowHeight是可视区的高度
    var windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    //变量scrollHeight是滚动条的总高度
    var scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight;
    //滚动条到底部的条件
    if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
      //写后台加载数据的函数
      console.log(111);
      // this.getList();
    }
  },
  mounted() {
    setTimeout(() => {
      this.listObj.Location =
        this.$store.state.user.studentInfo.userExtend.provinceName || "山东省";
      this.listObj.Year =
        this.$store.state.user.studentInfo.userExtend.year || 2025;
      this.getRegionList();
      this.getList();
      window.addEventListener("scroll", this.handleScroll);
    }, 300);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    servelist() {
      this.listObj.PageIndex = 1;
      this.list = [];
      this.getList();
    },
    handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      // 判断是否接近底部
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (this.listObj.PageIndex == this.ZPage) {
          this.isLoading = false;
          this.$message({
            message: "没有更多了~",
            type: "warning",
          });
        } else {
          //调用后台接口
          setTimeout(() => {
            this.listObj.PageIndex += 1;
            this.isLoading = true;
            this.getList();
          }, 1000);
        }
      }
    },
    // 跳转学校详情
    goSchoolDetails(Id) {
      this.$router.push({
        path: "/Home/SchoolDetails",
        query: { Id, showName: "查扩缩招" },
      });
    },
    // 获取冲稳保院校
    async getList() {
      const res = await GetUniversityPlanPro({
        ...this.listObj,
      });
      this.list.push(...res.data.response.data);
      this.listObj.Name = "";
      this.ZPage = res.data.response.pageCount;
      this.isLoading = false;
    },
    // 选择院校省份
    selectRegion(it, idx) {
      this.disport(it, idx, this.regionList, this.regionArr, 0);
    },

    // 选择院校类型
    selectType(it, idx) {
      this.disport(it, idx, this.typeList, this.typeArr, 0);
    },

    // 选择院校层次1
    selectGradation(it, idx) {
      this.disport(it, idx, this.gradationList, this.gradationArr, 1);
    },

    // 选择院校层次2
    selectGradation2(it, idx) {
      this.disport(it, idx, this.gradationList2, this.gradationArr2, 1);
    },

    // 获取省份数据
    async getRegionList() {
      const res = await GetRegionList({
        code: 0,
      });
      this.regionList = res.data.response;
      this.regionList.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.regionList.unshift({
        Id: -1,
        Code: "-1",
        Name: "不限",
        chenck: true,
      });
    },

    // 合并
    mergeARR() {
      this.listObj.AreaName = [];
      this.listObj.Nature = [];
      this.listObj.Type = [];
      this.regionArr.forEach((item) => {
        this.listObj.AreaName.push(item.Name);
      });
      this.typeArr.forEach((item) => {
        this.listObj.Nature.push(item.Id);
      });
      this.gradationArr.forEach((item) => {
        this.listObj.Type.push(item.Id);
      });
      this.gradationArr2.forEach((item) => {
        this.listObj.Type.push(item.Id);
      });
      this.list = [];
      this.getList();
    },

    // 重置
    rest() {
      this.tags = [];
      this.regionArr = [];
      this.gradationArr = [];
      this.gradationArr2 = [];
      this.typeArr = [];
      this.regionList.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.typeList.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.gradationList.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.gradationList2.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.regionList[0].chenck = true;
      this.typeList[0].chenck = true;
      this.gradationList[0].chenck = true;
    },
    goUrl(url) {
      this.$router.push(url);
    },
    // 处理
    disport(it, idx, list, Arr, type) {
      if (it.Name !== "不限") {
        list[0].chenck = false;
        var index = Arr.findIndex((item) => item.Name == it.Name);
        // 没有就添加
        if (index == -1) {
          Arr.push(it);
          list[idx].chenck = true;
          this.mergeARR();
          // 有就删除
        } else {
          Arr.splice(index, 1);
          list[idx].chenck = false;
          this.mergeARR();
        }
        if (type == 1) {
          const b = this.gradationList.every((item) => item.chenck == false);
          const c = this.gradationList2.every((item) => item.chenck == false);
          if (b && c) {
            this.gradationList[0].chenck = true;
          } else {
            this.gradationList[0].chenck = false;
          }
        } else {
          const b = list.some((item) => item.chenck == true);
          if (b) {
            list[0].chenck = false;
          } else {
            list[0].chenck = true;
          }
        }
      } else {
        if (type == 1) {
          this.gradationArr = [];
          this.gradationArr2 = [];
          this.gradationList.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          this.gradationList2.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          this.gradationList[0].chenck = true;
          this.mergeARR();
        } else {
          Arr.splice(0, Arr.length); //清空数组
          list.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          list[0].chenck = true;
          this.mergeARR();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  height: 0;
}

img {
  width: 100%;
  height: 100%;
}
.box {
  display: flex;
  width: 1280px;
  margin: 0 auto;
  min-height: 100vh;
  margin-top: 20px;
}

.leftContent {
  flex: 1;
  min-width: 956px;
  margin-right: 16px;
  .customer-college {
    font-size: 12px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 18px;
    z-index: 2;
    display: flex;
    align-items: center;
    background: #fff;
    ::v-deep .el-input__inner {
      height: 28px;
    }
    span {
      border-radius: 6px;
      width: 103px;
      height: 39px;
      color: rgb(255, 255, 255);
      background: #187cff;
      border: 1px solid #187cff;
      font-size: 14px;
      cursor: pointer;
      text-align: center;
      line-height: 39px;
      margin-left: 20px;
    }
  }

  .tabs {
    position: relative;
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    border-radius: 8px 8px 8px 8px;
    background: #ffffff;
    .tabTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #eeeeee;
      .tit {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
      }
      .zk {
        font-size: 14px;
        cursor: pointer;
      }
    }
    .isTabarShow {
      display: none !important;
    }
    .tzy-filter-wrapper {
      box-sizing: border-box;
      display: block;
      .filter-list {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        .filter-list-title {
          font-size: 14px;
          text-align: center;
          font-weight: bold;
        }

        .filter-list-tags {
          margin-left: 20px;
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          .tag {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            font-style: normal;
            margin: 0 0 8px 10px;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
            box-sizing: border-box;
            min-width: 58px;
            text-align: left;
          }

          .tag:hover {
            color: #187cff;
          }

          .active {
            color: #187cff;
          }
        }
      }

      .college-level-wrapper {
        display: flex;

        .sx {
          font-weight: 100;
          height: 47px;
          display: flex;
          align-items: center;
          color: #e6e6e6;
          transform: translateY(-2px);
        }
      }
    }
  }

  .schoolList {
    margin: 20px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    .tableTit {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 14px 24px 14px 24px;
      background: #f7f7f7;
      border-radius: 8px 8px 0px 0px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      font-size: 16px;
      .Tit {
        flex: 1;
        text-align: center;
      }
    }
    .tableItem {
      width: 100%;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      height: 100%;
    }
    .Item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px 24px 20px 24px;
      box-sizing: border-box;
      height: 88px;
      border-bottom: 1px solid #eeeeee;
      font-size: 16px;
      .school {
        display: flex;
        align-items: center;
        cursor: pointer;
        .schoolImg {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }
        .name:hover {
          color: #187cff;
        }
      }
      .province {
        flex: 1;
        text-align: center;
        .ic {
          display: inline-block;
          width: 13px;
          height: 10px;
          margin-right: 4px;
        }
      }
    }
  }
}
.rightContent {
  width: 308px;
  overflow: hidden;
  float: left;
  .banes {
    width: 100%;
    .item {
      cursor: pointer;
      width: 308px;
      height: 154px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .articleCont_right_zhengce > p {
    height: 21px;
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .articleCont_right_zhengce {
    width: 100%;
    background-color: #fff;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 6px;
    padding: 24px;
    box-sizing: border-box;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .tit {
        font-weight: 600;
        font-size: 20px;
        color: #000000;
      }
      .more {
        font-size: 14px;
        color: #aeaeae;
        cursor: pointer;
      }
    }
    .school-box {
      margin-top: 38px;
      padding: 0 24px;
      div,
      .school-box .school-item {
        display: flex;
        align-items: center;
      }
      .school-item:hover .name {
        color: #187cff !important;
      }
      .school-item {
        margin-bottom: 24px;
        cursor: pointer;
        .schoolPhoto {
          width: 48px;
          height: 48px;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .schoolName {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          .name {
            color: #333;
            font-size: 18px;
            margin-bottom: 10px;
            max-width: 180px;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
          .type {
            font-size: 14px;
            color: #888;
          }
        }
      }
    }
    .policy_title:hover {
      color: #187cff !important;
      cursor: pointer;
    }
  }
}

// ------loading样式---------
.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  padding: 20px 0;

  .loading {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
    margin-right: 10px;
  }

  .container {
    position: relative;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #fff;
  }
}

/*------loading动画---------*/
@keyframes rotate {
  from {
    transform-origin: center center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center center;
    transform: rotate(360deg);
  }
}
</style>
