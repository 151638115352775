var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"tableList"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.list,"align":"center","header-cell-style":{
          background: '#eaeaea',
          color: '#606266',
          fontWeight: 400,
        }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","align":"center","width":"50"}}),_c('el-table-column',{attrs:{"prop":"tableName","align":"center","width":"150"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("志愿表")])])])],2),_c('el-table-column',{attrs:{"label":"批次","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(_vm._s(scope.row.batchName))])])]}}])}),_c('el-table-column',{attrs:{"prop":"score","align":"center"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("成绩")])])])],2),_c('el-table-column',{attrs:{"prop":"subjectClaim","align":"center"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("科目")])])])],2),_c('el-table-column',{attrs:{"label":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(_vm._s(scope.row.type))])])]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","align":"center","width":"180"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v("最后编辑时间")])])])],2),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"tj-button",attrs:{"type":"text"},on:{"click":function($event){return _vm.goTY(scope.row.vId)}}},[_vm._v("查看")]),(_vm.studentInfo.customerType == 0 && scope.row.isPush == true)?_c('el-button',{staticClass:"yj-button",attrs:{"type":"text"}},[_vm._v("已交")]):_vm._e(),(
                _vm.studentInfo.customerType == 0 && scope.row.isPush !== true
              )?_c('el-button',{staticClass:"tj-button",attrs:{"type":"text"},on:{"click":function($event){return _vm.goTJ(scope.row.vId)}}},[_vm._v("提交")]):_vm._e()]}}])})],1),_c('div',{staticStyle:{"height":"60px","margin-top":"10px"}},[_c('el-pagination',{attrs:{"layout":"total, sizes,prev, pager, next, jumper","page-size":_vm.obj.PageSize,"page-sizes":[10, 20, 50],"total":_vm.total},on:{"current-change":_vm.hCurrentChange,"size-change":_vm.hSizeChange}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-zytable-page-title page-title-bl4 mt60 mb15"},[_c('span',{staticClass:"pull-left"},[_vm._v("我的志愿表")])])
}]

export { render, staticRenderFns }