<template>
  <div class="box">
    <div class="bane">
      <img src="./images/zjbaner.png" alt="" />
    </div>
    <div class="content">
      <div class="list">
        <div class="assessItem">
          <div class="title">学职定位</div>
          <div class="cpList">
            <div class="cp1" @click="goUrl('carrerpostion')">
              <div class="left">
                <div class="tit">性格测评（MBTI）</div>
                <div class="jc">
                  帮助学生根据个人特点科学选择高考志愿，匹配兴趣与职业规划，提升录取机会。
                </div>
              </div>
              <div class="right">
                <img src="./images/1.png" alt="" />
              </div>
            </div>
            <div class="cp1" @click="goUrl('carrerpostion')">
              <div class="left">
                <div class="tit">兴趣测评（Holland）</div>
                <div class="jc">
                  基于职业兴趣类型，帮助学生了解自身兴趣，指导志愿选择，提升职业匹配度。
                </div>
              </div>
              <div class="right">
                <img src="./images/2.png" alt="" />
              </div>
            </div>
            <div class="cp1" @click="goUrl('carrerpostion')">
              <div class="left">
                <div class="tit">能力测评（MIDAS）</div>
                <div class="jc">
                  多元智能理论的测评工具，旨在评估个体在八种不同智能领域的能力。
                </div>
              </div>
              <div class="right">
                <img src="./images/3.png" alt="" />
              </div>
            </div>
            <div class="cp1" @click="goUrl('carrerpostion')">
              <div class="left">
                <div class="tit">职业锚（Career ）</div>
                <div class="jc">
                  个体在职业生涯中最重要的价值观和动机，这些价值观和动机会影响其职业决策。
                </div>
              </div>
              <div class="right">
                <img src="./images/4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="assessItem">
          <div class="title">心理健康</div>
          <div class="cpList">
            <div class="cp2" @click="goUrl('psychology')">
              <div class="left">
                <div class="tit">SAS焦虑测评</div>
                <div class="jc">
                  常用于评估个体焦虑水平的心理测量工具。帮助测量个体在一段时间内的焦虑症状
                </div>
              </div>
              <div class="right">
                <img src="./images/11.png" alt="" />
              </div>
            </div>
            <div class="cp2" @click="goUrl('psychology')">
              <div class="left">
                <div class="tit">SDS抑郁测评</div>
                <div class="jc">
                  帮助学生识别潜在的抑郁症状，特别是在高考等压力较大的时期。
                </div>
              </div>
              <div class="right">
                <img src="./images/22.png" alt="" />
              </div>
            </div>
            <div class="cp2" @click="goUrl('psychology')">
              <div class="left">
                <div class="tit">SCL-90症状自评</div>
                <div class="jc">
                  学生能够及时发现潜在的心理问题，采取有效的干预措施，改善心理健康状态。
                </div>
              </div>
              <div class="right">
                <img src="./images/33.png" alt="" />
              </div>
            </div>
            <div class="cp2" @click="goUrl('psychology')">
              <div class="left">
                <div class="tit">中学生心理健康自评</div>
                <div class="jc">
                  能有效帮助学生提高心理健康意识，预防和应对学业、成长等带来的心理困扰。
                </div>
              </div>
              <div class="right">
                <img src="./images/44.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="assessItem">
          <div class="title">学习状态</div>
          <div class="cpList">
            <div class="cp3" @click="goUrl('studystatus')">
              <div class="left">
                <div class="tit">Solomon学习风格</div>
                <div class="jc">
                  了解自己最有效的学习方式，选择符合自己学习风格的专业和职业方向。
                </div>
              </div>
              <div class="right">
                <img src="./images/111.png" alt="" />
              </div>
            </div>
            <div class="cp3" @click="goUrl('studystatus')">
              <div class="left">
                <div class="tit">学生考试焦虑症测试</div>
                <div class="jc">
                  了解自己在的焦虑水平，及时采取应对措施，帮助缓解考试压力，提高考试表现。
                </div>
              </div>
              <div class="right">
                <img src="./images/222.png" alt="" />
              </div>
            </div>
            <div class="cp3" @click="goUrl('studystatus')">
              <div class="left">
                <div class="tit">学习技能</div>
                <div class="jc">
                  学习过程中使用的各种策略和技巧，帮助他们更高效地获取、处理和应用知识。
                </div>
              </div>
              <div class="right">
                <img src="./images/333.png" alt="" />
              </div>
            </div>
            <div class="cp4">
              <div class="top">
                <img src="./images/kk.png" alt="" />
              </div>
              <div class="btn">更多测评敬请期待</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCustomerInfoById } from "@/api/home";
import { resetUnauthorizedHandled } from "@/utils/request";
export default {
  name: "VoluntaryReportingIndex",
  data() {
    return {
      busSpecialistGrouList: [],
      userInfo: "",
    };
  },

  mounted() {},

  methods: {
    goUrl(url) {
      if (this.userInfo == "") {
        this.getUserInfo();
        return;
      }
      window.open(`https://cp.ycymedu.com/` + url, "_blank");
    },
    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.userInfo = res.data.result;

      // 用户登录成功后清除 unauthorizedHandled 标志
      resetUnauthorizedHandled();

      if (this.userInfo.batchDataUrl == "") {
        this.$store.commit("user/setProvinceShow", true);
      }

      this.$store.dispatch("user/removeStudentInfo");
      this.$store.commit("user/setStudentInfo", res.data.result);
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
      sessionStorage.setItem("visited", "true"); // 设置标记
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}
.box {
  background: #fff;
  min-height: 100vh;
  width: 100%;
  .bane {
    min-width: 1280px;
    height: 330px;
  }
  .content {
    width: 1280px;
    margin: 0 auto;
    padding-bottom: 20px;
    .list {
      min-height: 90vh;
      .assessItem {
        margin-top: 48px;
        .title {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .cpList {
          display: flex;
          .cpX:nth-child(4) {
            margin: 0;
          }
          .cp1:hover {
            transform: translateY(-10px); /* 向上浮起 */
          }
          .cp2:hover {
            transform: translateY(-10px); /* 向上浮起 */
          }
          .cp3:hover {
            transform: translateY(-10px); /* 向上浮起 */
          }
          .cp1 {
            background: linear-gradient(90deg, #e5f2fa 0%, #dae8fa 100%);
          }
          .cp2 {
            background: linear-gradient(268deg, #caf4f0 0%, #e6f4f2 100%);
          }
          .cp3 {
            background: linear-gradient(270deg, #c2cefe 0%, #eff3fe 100%),
              linear-gradient(270deg, #cbd2f0 0%, #eff3fe 100%);
          }
          .cp1,
          .cp2,
          .cp3 {
            width: calc(25% - 16px); /* 每个元素的宽度，减去间距 */
            display: flex;
            align-items: center;
            height: 152px;
            border-radius: 8px 8px 8px 8px;
            padding: 16px 14px;
            box-sizing: border-box;
            margin-right: 16px;
            cursor: pointer;
            box-shadow: 0px 4px 4px 0px rgba(226, 226, 226, 0.25);
            overflow: hidden; /* 隐藏超出部分 */
            transition: transform 0.3s ease, box-shadow 0.3s ease; /* 添加过渡效果 */
            .left {
              flex: 1;
              .tit {
                font-size: 18px;
                font-weight: bold;
              }
              .jc {
                margin-top: 12px;
                font-size: 12px;
                color: #999999;
              }
            }
            .right {
              width: 100px;
              height: 100px;
            }
          }
          .cp4 {
            cursor: pointer;
            min-width: 308px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 152px;
            background: #f4f4f6;
            border-radius: 8px 8px 8px 8px;
            padding: 16px 14px;
            box-sizing: border-box;
            margin-right: 16px;
            .top {
              width: 184.65px;
              height: 114px;
            }
            .btn {
              font-size: 14px;
              color: #666666;
            }
          }
        }
      }
    }
  }
}
</style>
