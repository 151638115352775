<template>
    <div>
        <div class="schoolParticulars">
            <div class="synopsisBox">
                <div class="title">院校概览</div>
                <div class="synopsis">
                    <div class="introductory">
                        <p class="text-content" :class="{ expanded: isExpanded }" ref="textContent">{{
                            Description.Description }}</p>
                        <span v-if="isOverflowing && !isExpanded" class="expand-placeholder" @click="toggleExpand">展开<i
                                class="el-icon-arrow-down"></i></span>
                        <span v-if="isExpanded" class="expand-btn" @click="toggleExpand">收起<i
                                class="el-icon-arrow-up"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="YXFG">
            <div class="synopsisBox">
                <div class="title">
                    <div class="Sname">院校风光</div>
                    <div class="gd" @click="gd()">更多<i class=" el-icon-arrow-right"></i></div>
                </div>
                <div class="compBox">
                    <a class="image-item" v-for="it in Imglist.slice(0, 4)" :key="it">
                        <div v-if="it.isSHow" class="cover-image" @click="dialogVisible = true"
                            :style="{ 'background': 'url(' + require('./images/9d02bb82.png') + ')' }">
                            <div class="colleges-imgs-mask"><i class="f28 iconfont iconvr"></i></div>
                        </div>
                        <div v-else class="el-image cover-image">
                            <el-image :src="it" :preview-src-list="Imglist" style="width: 216px; height: 120px;">
                            </el-image>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="table">
            <div class="tit">
                <div class="fw">双一流学科</div>
            </div>

            <div class="tab" v-if="tableData.length !== 0">
                <el-table border :data="tableData3" stripe style="width: 100%"
                    :header-cell-style="{ background: '#F7F7F7', color: '#A2AAB4', 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }">
                    <el-table-column label="数量" width="180">
                        <template>
                            {{ tableData3[0].length }}
                        </template>
                    </el-table-column>
                    <el-table-column label="专业">
                        <template slot-scope="scope">
                            <span v-for="(it, idx) in scope.row" :key="idx"
                                style="display: inline-block; margin:10px 20px 20px ;">
                                {{ it.name }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else>
                <el-empty description="暂无推荐数据"></el-empty>
            </div>
        </div>
        <div class="table">
            <div class="tit">
                <div class="fw">学科评估</div>
                <el-select size="mini" style="width: 100px;" v-model="value22" placeholder="请选择"
                    @change="provinceChange($event)">
                    <el-option v-for="item in options22" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>

            <div class="tab" v-if="tableData.length !== 0">
                <el-table border :data="tableData" stripe style="width: 100%"
                    :header-cell-style="{ background: '#F7F7F7', color: '#A2AAB4', 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }">
                    <el-table-column label="数量" width="180">
                        <template>
                            {{ tableData[0].length }}
                        </template>
                    </el-table-column>
                    <el-table-column label="学科">
                        <template slot-scope="scope">

                            <span v-for="(it, idx) in scope.row" :key="idx"
                                style="display: inline-block; margin:10px 20px 20px ;">
                                {{ it.name }} {{ it.level }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else>
                <el-empty description="暂无推荐数据"></el-empty>
            </div>
        </div>
        <div class="table">
            <div class="tit">特色专业</div>
            <div class="tab" v-if="tableData1.length !== 0">
                <el-table border :data="tableData1" stripe style="width: 100%"
                    :header-cell-style="{ background: '#F7F7F7', color: '#A2AAB4', 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }">

                    <el-table-column label="专业层次" width="180">
                        <template>
                            {{ tableData1[0][0].professionType }}
                        </template>
                    </el-table-column>
                    <el-table-column label="数量" width="180">
                        <template>
                            {{ tableData1[0].length }}
                        </template>
                    </el-table-column>
                    <el-table-column label="专业">
                        <template slot-scope="scope">
                            <span v-for="(it, idx) in scope.row" :key="idx"
                                style="display: inline-block; margin:10px 20px 20px ;">
                                {{ it.name }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else>
                <el-empty description="暂无推荐数据"></el-empty>
            </div>
        </div>
        <div class="table">
            <div class="tit">院系设置</div>
            <div class="tab" v-if="tableData2.length !== 0">
                <el-table border :data="tableData2" stripe style="width: 100%"
                    :header-cell-style="{ background: '#F7F7F7', color: '#A2AAB4', 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }">
                    <el-table-column prop="name" label="学院" width="180">
                    </el-table-column>
                    <el-table-column label="所含专业">
                        <template slot-scope="scope">
                            <span v-for="(it, idx) in scope.row.departmentMajors" :key="idx"
                                style="display: inline-block; margin:10px 20px 20px ;">
                                {{ it.name }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else>
                <el-empty description="暂无推荐数据"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import { GetUniversityRank, GetSubjectEvaluate, GetFeaturedMajor, GetDepartment, subjectIntroducelist } from "@/api/schoolDetails";
export default {
    name: 'SchoolParticularsIndex',

    props: {
        Description: {
            type: Object,
            required: true
        },
        Imgs: {
            required: true
        }
    },
    watch: {
        Description(newVal) {
            if (newVal !== '') {
                this.fn()
            }
        }
    },
    data() {
        return {
            isExpanded: false, // 是否展开
            isOverflowing: false, // 文本是否溢出
            options22: [{
                value: '第一轮',
                label: '第一轮'
            },
            {
                value: '第二轮',
                label: '第二轮'
            },
            {
                value: '第三轮',
                label: '第三轮'
            },
            {
                value: '第四轮',
                label: '第四轮'
            },
            ],
            value22: '第四轮',
            imgs: [],

            rankingList: [
            ],
            arr: [],

            tableData: [],


            tableData1: [],


            tableData2: [],

            tableData3: [],
            Imglist: [],
            dialogVisible: false,
        };
    },

    mounted() {
        this.checkOverflow();
    },
    created() {
    },
    methods: {
        fn() {
            this.imgs = JSON.parse(this.Description.Imglist)
            if (this.imgs.length > 8) {
                this.imgs.splice(8, this.imgs.length - 8)
            }
            this.Imglist = JSON.parse(this.Imgs)
            this.getRank()
        },
        gd() {
            this.$emit('clickYxfg', true)
        },
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        checkOverflow() {
            const el = this.$refs.textContent;
            this.isOverflowing = el.scrollHeight > el.offsetHeight;
        },
        async getRank() {
            const res = await GetUniversityRank({
                UniversityName: this.Description.Name
            })
            this.rankingList = res.data.response
            this.rankingList.forEach(it => {
                if (it.Type == 0) {
                    it.name = '校友会'
                } else if (it.Type == 1) {
                    it.name = '武书连'
                } else if (it.Type == 2) {
                    it.name = '软科 '
                } else if (it.Type == 3) {
                    it.name = 'QS'
                } else if (it.Type == 4) {
                    it.name = '泰晤士河报'
                } else if (it.Type == 5) {
                    it.name = '热度'
                }
            });
            this.getSubjectEvaluateList()
            this.getFeaturedMajor()
            this.getDepartment()
            this.getSubjectIntroducelist()
        },
        async getSubjectEvaluateList() {
            const res = await GetSubjectEvaluate({
                collegeCode: this.Description.collegeCode,
                extTypeId: this.value22,
            })
            this.tableData = []
            var obj = {}
            obj = res.data.response.subjectEvaluates
            this.tableData.push(obj)
        },
        async getFeaturedMajor() {
            const res = await GetFeaturedMajor({
                collegeCode: this.Description.collegeCode
            })
            var obj = {}
            obj = res.data.response.countries
            this.tableData1.push(obj)
        },
        async getDepartment() {
            const res = await GetDepartment({
                collegeCode: this.Description.collegeCode
            })
            this.tableData2 = res.data.response
        },
        async getSubjectIntroducelist() {
            const res = await subjectIntroducelist({
                id: this.Description.Id
            })
            var arr = res.data.result.double_subject_list.map((name, index) => ({
                id: index + 1, // 从1开始编号
                name: name
            }));
            this.tableData3.push(arr)
        },
        provinceChange() {
            this.getSubjectEvaluateList()
        },
    },
};
</script>

<style lang="less" scoped>
::v-deep .el-carousel__button {
    width: 20px !important;
}

::v-deep .el-carousel__container {
    height: 270px;
}

img {
    width: 100%;
    height: 100%;
}

.schoolParticulars {
    .synopsisBox {
        margin-top: 48px;
    }

    .title {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 20px;
    }

    .synopsis {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .introductory {
            margin-top: 10px;
            position: relative;
            line-height: 1.5;
            font-size: 14px;
            width: 100%;

            /* 容器的最大宽度 */
            .text-content {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                /* 显示的行数 */
                -webkit-box-orient: vertical;
                /* 为展开按钮留出缩进位置 */
                font-size: 16px;
                color: #666666;
            }

            .text-content.expanded {
                overflow: visible;
                white-space: normal;
                -webkit-line-clamp: unset;
                -webkit-box-orient: unset;
                padding-right: 0;
                /* 展开时取消缩进 */
            }

            .expand-placeholder {
                position: absolute;
                right: 0;
                bottom: -1.5;
                background: #fff;
                /* 避免文字叠加 */
                padding-left: 5px;
                color: #007bff;
                cursor: pointer;
                font-size: 14px;
            }

            .expand-btn {
                color: #007bff;
                cursor: pointer;
                font-size: 14px;
            }
        }
    }


}

.YXFG {
    height: 100%;

    .synopsisBox {
        margin-top: 48px;
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Sname {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 20px;
        }

        .gd {
            font-size: 18px;
            color: #187CFF;
            cursor: pointer;
        }
    }

    .compBox {
        width: 100%;
        height: 100%;

        ::v-deep .el-image-viewer__canvas img {
            width: 0 !important;
            height: 0 !important;
        }

        ::v-deep .el-image-viewer__img {
            width: 50%;
            height: auto;
        }

        ::v-deep .el-image-viewer__canvas img {
            transform: scale(0.4) rotate(0deg) !important;
        }

        ::v-deep .el-dialog__headerbtn {
            top: 12px !important;
            cursor: pointer;
        }

        ::v-deep .el-dialog {
            background: #333 !important;
        }

        ::v-deep .el-dialog__body {
            padding: 0 !important;
            height: 540px !important;
        }



        .image-item:nth-of-type(4n) {
            margin-right: 0;
        }

        .image-item {
            width: 20%;
            margin-right: 30px;
            margin-top: 20px;
            color: #333;

            .el-image {
                display: inline-block;
                overflow: hidden;
            }

            .cover-image {
                width: 216px;
                height: 120px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: 50%;
                border-radius: 4px;
                position: relative;
                cursor: pointer;

                .colleges-imgs-mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 216px;
                    height: 120px;
                    background: rgba(0, 0, 0, .5);
                    color: #fff;
                    text-align: center;
                    line-height: 120px;
                    border-radius: 4px;
                    overflow: hidden;
                }
            }

            .text-overflow-ellipsis {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .lh20 {
                line-height: 20px !important;
            }

            .f14 {
                font-size: 14px !important;
            }

            .mt15 {
                margin-top: 15px !important;
            }
        }

        a {
            cursor: pointer;
        }
    }

}

.table {
    margin-top: 48px;

    .tit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 20px;
    }
}
</style>