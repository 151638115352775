<template>
  <div class="box">
    <div style="opacity: 0; height: 20px">/</div>
    <div class="content">
      <div class="left" v-if="userInfo.customerType == 0">
        <div class="userInfo">
          <div class="photo">
            <img :src="userInfo.avatar" />
          </div>
          <div class="phone">{{ userInfo.nickName }}</div>
        </div>
        <div :class="IsShow == 0 ? 'itemActive item' : 'item'" @click="go(0)">
          <span></span>
          <span>账号信息</span>
        </div>
        <div :class="IsShow == 1 ? 'itemActive item' : 'item'" @click="go(1)">
          <span></span>
          <span>我的订单</span>
        </div>
        <div class="item" @click="go('zyTable')">
          <span></span>
          <span>我的志愿表</span>
        </div>
      </div>

      <div class="left" v-else>
        <div class="userInfo">
          <div class="photo">
            <img :src="userInfo.avatar" />
          </div>
          <div class="phone">{{ userInfo.nickName }}</div>
        </div>
        <div :class="IsShow == 0 ? 'itemActive item' : 'item'" @click="go(0)">
          <span></span>
          <span>账号信息</span>
        </div>
        <div :class="IsShow == 1 ? 'itemActive item' : 'item'" @click="go(1)">
          <span></span>
          <span>我的订单</span>
        </div>
        <div class="item" @click="go('expertZyTable')">
          <span></span>
          <span>专家志愿表</span>
        </div>
        <!-- <div class="item" @click="go('zyTable')">
          <span></span>
          <span>我的志愿表</span>
        </div> -->
      </div>

      <div class="right">
        <UserInfoModification v-if="IsShow == 0" />
        <MyOrder v-if="IsShow == 1 && userInfo.customerType == 0" />
        <!-- 专家 -->
        <MyOrderZJ v-if="IsShow == 1 && userInfo.customerType == 1" />
      </div>
    </div>
  </div>
</template>

<script>
import { GetCustomerInfoById } from "@/api/home";
import MyOrder from "./components/MyOrder";
import MyOrderZJ from "./components/MyOrderZJ";
import UserInfoModification from "./components/UserInfoModification";
import { parseToken } from '@/utils/token';
import { getToken } from "@/utils/auth.js";
export default {
  name: "VoluntaryReportingIndex",
  components: { UserInfoModification, MyOrder, MyOrderZJ },
  data() {
    return {
      IsShow: 0,
      userInfo: {},
    };
  },
  watch: {
    $route(to, from) {
      // 检查参数变化并手动更新数据
      if (to.query.IsShow !== from.query.IsShow) {
        this.updatePageData(to.query.IsShow);
      }
    },
  },
  mounted() {
    this.userInfo = this.$store.state.user.studentInfo || "";
    this.updatePageData(this.$route.query.IsShow);
    const token = getToken();
    const { isExpired } = parseToken(token);
    if (isExpired == false) {
      this.getUserInfo();
    }
  },

  methods: {
    updatePageData(IsShow) {
      // 根据新的参数值更新页面内容
      console.log("参数 IsShow 更新为：", IsShow);
      this.IsShow = IsShow;
      // 执行你需要的逻辑来更新数据
    },
    go(url) {
      if (url == "zyTable" || url == "expertZyTable") {
        this.$router.push("/Home/" + url);
      } else {
        this.IsShow = url;
      }
    },
    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.userInfo = res.data.result;

      this.$store.dispatch("user/removeStudentInfo");
      this.$store.commit("user/setStudentInfo", res.data.result);
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
      sessionStorage.setItem("visited", "true"); // 设置标记
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  box-sizing: border-box;
  background: #f5f4f8;
  width: 100vw;
  min-height: 100vh;
}

.content {
  width: 1164px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    width: 338px;
    height: 802px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 114px 0 0 63px;
    margin-right: 16px;

    .userInfo {
      display: flex;
      align-items: center;
      margin-bottom: 75px;

      .photo {
        width: 80px;
        height: 80px;
        overflow: hidden;
        margin-right: 15px;
        border: none;
      }

      .phone {
        font-weight: 600;
        font-size: 18px;
        color: #333333;
      }
    }

    .item {
      cursor: pointer;
      font-size: 20px;
      font-weight: 500;
      color: #989898;
      margin-bottom: 28px;
    }

    .itemActive {
      color: #000000 !important;
    }
  }

  .right {
    width: 1046px;
    height: 802px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 30px 11px 24px 20px;
  }
}
</style>
