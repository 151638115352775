<template>
  <div class="box">
    <div class="content">
      <div class="tabs">
        <div class="tabTitle">
          <div class="tabTitleLeft">
            <div :class="istitActive == 0 ? 'tit istitActive' : 'tit'" @click="istitActive = 0">
              院校条件
            </div>
            <div :class="istitActive == 1 ? 'tit istitActive' : 'tit'" @click="istitActive = 1">
              地域条件
            </div>
          </div>
          <!-- 用户成绩 -->
          <div class="tzy-user-info">
            <div class="toys">
              <div class="f12 el-row">
                <span>{{ basicInfo.expectedScore }}分</span>
                ·
                <span>{{
              basicInfo.subjectGroup !== undefined
                ? basicInfo.subjectGroup.split(",").join("/")
                : ""
            }}</span>
                ·
              </div>
              <span style="margin-left: 5px">
                <el-dropdown @command="handleCommand" trigger="hover">
                  <span class="el-dropdown-link" style="cursor: pointer">
                    {{ leibiestr
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="it.batch" v-for="(it, idx) in batchDataUrlList" :key="idx">{{ it.batch
                      }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
          </div>
        </div>

        <div class="tzy-filter-wrapper">
          <div class="filter-list" v-show="istitActive == 1">
            <span class="filter-list-title"> 院校省份 </span>
            <div class="filter-list-tags">
              <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in Type1List" :key="it.Id"
                @click="selectRegion(it, index)">
                {{ it.Name }}
              </span>
            </div>
          </div>
          <div class="filter-list" v-show="istitActive == 0">
            <span class="filter-list-title"> 院校类型 </span>
            <div class="filter-list-tags">
              <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in Type2List" :key="it.Id"
                @click="selectType(it, index)">
                {{ it.Name }}
              </span>
            </div>
          </div>
          <div class="flex college-level-wrapper" v-show="istitActive == 0">
            <div class="filter-list filter-list-college">
              <span class="filter-list-title"> 院校层次 </span>
              <div class="filter-list-tags">
                <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in Type31List" :key="it.Id"
                  @click="selectGradation(it, index)">
                  {{ it.Name }}
                </span>
              </div>
            </div>
            <div class="filter-list tzy-filter-list-college">
              <div class="filter-list-tags" style="margin-left: 0">
                <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in Type32List" :key="it.Id"
                  @click="selectGradation2(it, index)">
                  {{ it.Name }}
                </span>
              </div>
            </div>
          </div>
          <div class="filter-list" v-show="istitActive == 0">
            <span class="filter-list-title"> 院校搜索 </span>
            <div class="filter-list-tags">
              <!-- 搜索 -->
              <div class="customer-college">
                <el-input size="small" v-model="studentData.Name" placeholder="请输入学校名称" @keyup.enter.native="getList()"
                  suffix-icon="el-icon-search"></el-input>
              </div>
            </div>
          </div>
          <div class="filter-list" v-if="tags.length !== 0">
            <span class="filter-list-title"> 已选条件 </span>
            <div class="filter-list-tags">
              <div class="tags">
                <el-tag v-for="tag in Type1" :key="tag.Name" closable type="info" @close="regionClose(tag)">
                  {{ tag.Name }}
                </el-tag>
                <el-tag v-for="tag in Type2" :key="tag.Name" closable type="info" @close="typeClose(tag)">
                  {{ tag.Name }}
                </el-tag>
                <el-tag v-for="tag in Type31" :key="tag.Name" closable type="info" @close="gradationClose(tag)">
                  {{ tag.Name }}
                </el-tag>
                <el-tag v-for="tag in Type32" :key="tag.Name" closable type="info" @close="gradation2Close(tag)">
                  {{ tag.Name }}
                </el-tag>
              </div>
            </div>
            <div class="button-actions pr10" @click="rest()">清空</div>
          </div>
        </div>
      </div>
      <div class="tzy-page-center">
        <div class="left">
          <div class="el-dropdown tzy-page-center_button-default">
            <el-dropdown @command="ishandleCommand" trigger="hover">
              <span class="el-dropdown-link" style="cursor: pointer">
                {{ isleibiestr
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="it.name + it.num" v-for="(it, idx) in cwbList" :key="idx">{{ it.name }} {{
              it.num }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="zntj" @click="Aitk()">
            <i class="el-icon-tickets"></i>
            智能推荐
          </div>
        </div>
        <div class="right" style="width: 400px">
          <el-form ref="form" label-width="80px">
            <el-form-item style="margin: 0" label="推荐范围:">
              <input-number-range :disabled="false" :precision="0" v-model="numberRange"></input-number-range>
            </el-form-item>
          </el-form>
          <div class="btn" @click="getList()">确定</div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableBox">
        <div class="tableBoxTop">
          <div class="recommendTabList">
            <div class="recommendList">
              <div class="recommendItem">
                <span>院校</span>
                <!-- <i
                  v-if="sort3 && isSort == 2"
                  class="el-icon-sort"
                  style="color: #187cff; cursor: pointer"
                  @click="PxSort2(!sort3)"
                ></i>
                <i
                  v-else
                  class="el-icon-sort"
                  style="cursor: pointer"
                  @click="PxSort2(!sort3)"
                ></i> -->
              </div>
              <div class="recommend-header">
                <span>2024年招生计划</span>
                <i v-if="sort2 && isSort == 1" class="el-icon-sort" style="color: #187cff; cursor: pointer"
                  @click="PxSort1(!sort2)"></i>
                <i v-else class="el-icon-sort" style="cursor: pointer" @click="PxSort1(!sort2)"></i>
              </div>
              <div class="recommend-xheader">
                <div class="recommend-title">历年</div>
                <div class="year-title">2024</div>
                <div class="year-title">2023</div>
                <div class="year-title">2022</div>
              </div>
              <div class="recommend-Tb">填报</div>
            </div>
          </div>
          <div class="aa">
            <template v-if="list.length !== 0">
              <div v-for="(it, idx) in list" :key="idx">
                <div class="recom-Item element" style="background: #fff">
                  <div class="recommendItem">
                    <div class="sch-name___afuQF" @click="goSchoolDetails(it.uId)">
                      <div>{{ it.name }}</div>
                      <div :class="it.type == 0
              ? 'c cwb'
              : it.type == 1
                ? 'w cwb'
                : 'b cwb'
              ">
                        {{ it.type == 0 ? "冲" : it.type == 1 ? "冲稳" : "保" }}
                      </div>
                    </div>
                    <div style="font-size: 14px">
                      (
                      {{ it.ownership }}
                      {{ it.city }}
                      {{ it.educationCategory }}
                      )
                    </div>
                    <div class="zydm">
                      <span>院校代码：{{ it.collegeCode }}</span>
                      |
                      <span v-for="(its, idxs) in it.features" :key="idxs">{{ its }}/</span>
                      <span>排名{{ it.rank }} </span>
                    </div>
                  </div>
                  <div class="recommend-header">
                    <div class="plan-count___UrtQc">
                      <div class="plan-count-num">
                        <div>
                          <span class="rs">{{ it.planCount }}</span>人
                        </div>
                        <div style="font-size: 14px">
                          (减扩人数
                          <span style="color: #02b38a">+7</span>
                          )
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="recommend-xheader">
                    <div class="admission-line___q58Dc">
                      <div class="recommend-title hs">录取人数</div>
                      <div class="year-title" v-for="(item, index) in it.childItems" :key="index">
                        {{ item.planCount }}人
                      </div>
                    </div>
                    <div class="admission-line___q58Dc">
                      <div class="recommend-title hs">线差</div>
                      <div class="year-title" v-for="(item, index) in it.childItems" :key="index">
                        <span v-if="item.rankLine === batchScore">
                          不相上下
                        </span>
                        <span v-else-if="item.score > batchScore">
                          高于 {{ item.score - batchScore }}分
                          <img style="width: 13px; margin-left: 4px" src="./images/ss.png" alt="高图标" />
                        </span>
                        <span v-else>
                          低于 {{ batchScore - item.score }}分
                          <img style="width: 13px; margin-left: 4px" src="./images/xj.png" alt="低图标" />
                        </span>
                      </div>
                    </div>
                    <div class="admission-line___q58Dc">
                      <div class="recommend-title hs">最低分</div>
                      <div class="year-title" v-for="(item, index) in it.childItems" :key="index">
                        {{ item.score }}分
                      </div>
                    </div>
                    <div class="admission-line___q58Dc">
                      <div class="recommend-title hs">最低位次</div>
                      <div class="year-title" v-for="(item, index) in it.childItems" :key="index">
                        {{ item.rankLine }}名
                      </div>
                    </div>
                    <div class="admission-line___q58Dc">
                      <div class="recommend-title hs">位次差</div>
                      <div class="year-title" style="display: flex; align-items: center"
                        v-for="(item, index) in it.childItems" :key="index">
                        <span v-if="item.rankLine === basicInfo.rank">
                          不相上下
                        </span>
                        <span v-else-if="item.rankLine > basicInfo.rank">
                          高于 {{ item.rankLine - basicInfo.rank }}
                          <img style="width: 13px; margin-left: 4px" src="./images/ss.png" alt="高图标" />
                        </span>
                        <span v-else>
                          低于 {{ basicInfo.rank - item.rankLine }}
                          <img style="width: 13px; margin-left: 4px" src="./images/xj.png" alt="低图标" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="recommend-Tb">
                    <div class="count-box___NYb3B">
                      <div class="count___QftTC" @click="fn(it.chenck, idx)">
                        专业 {{ it.items.length }}&nbsp;
                        <i class="el-icon-arrow-down major-icon active" v-if="!it.chenck"></i>
                        <i class="el-icon-arrow-up major-icon active" v-else></i>
                      </div>
                      <div class="fill-tb">已报 {{ it.registered }}</div>
                    </div>
                  </div>
                </div>
                <div class="recommendTabList" v-if="it.chenck">
                  <div class="recommendList">
                    <div class="recommendItem">
                      <span>专业</span>
                    </div>
                    <div class="recommend-header">
                      <span>招生计划</span>
                    </div>
                    <div class="recommend-xheader">
                      <div class="recommend-title">历年</div>
                      <div class="year-title">2024</div>
                      <div class="year-title">2023</div>
                      <div class="year-title">2022</div>
                    </div>
                    <div class="recommend-Tb">填报</div>
                  </div>
                </div>
                <transition name="fade" v-for="(item, index) in it.items" :key="index">
                  <div class="recom-Item" style="margin-bottom: 0" v-if="it.chenck">
                    <div class="recommendItem">
                      <div style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #272727;
                          line-height: 20px;
                          padding: 17px 0;
                        ">
                        {{ item.major }}
                      </div>
                      <div class="zydm">代码 {{ item.majorCode }}</div>
                    </div>
                    <div class="recommend-header">
                      <div class="plan-count___UrtQc">
                        <div class="plan-count-num">
                          <div>
                            <span class="rs">{{ item.planCount }}</span>人
                          </div>
                          <div style="font-size: 14px">
                            (减扩人数
                            <span style="color: #eb5241">-2</span>
                            )
                          </div>
                        </div>
                      </div>
                      <div class="plan-info___kz4Ip">
                        <span>￥{{ item.fee }}/年</span>
                        <span>
                          {{
              item.subjectClam ? item.subjectClam : "不限"
            }}</span>
                      </div>
                    </div>
                    <div class="recommend-xheader">
                      <div class="admission-line___q58Dc">
                        <div class="recommend-title hs">录取人数</div>
                        <div class="year-title" v-for="(itt, idd) in item.items" :key="idd">
                          {{ itt.count }}
                        </div>
                      </div>
                      <div class="admission-line___q58Dc">
                        <div class="recommend-title hs">线差</div>
                        <div class="year-title" v-for="(itt, idd) in item.items" :key="idd">
                          <span v-if="itt.rankLine === batchScore">
                            不相上下
                          </span>
                          <span v-else-if="itt.score > batchScore">
                            高于 {{ itt.score - batchScore }}分
                            <img style="width: 13px; margin-left: 4px" src="./images/ss.png" alt="高图标" />
                          </span>
                          <span v-else>
                            低于 {{ batchScore - itt.score }}分
                            <img style="width: 13px; margin-left: 4px" src="./images/xj.png" alt="低图标" />
                          </span>
                        </div>
                      </div>
                      <div class="admission-line___q58Dc">
                        <div class="recommend-title hs">最低分</div>
                        <div class="year-title" v-for="(itt, idd) in item.items" :key="idd">
                          {{ itt.score }}
                        </div>
                      </div>
                      <div class="admission-line___q58Dc">
                        <div class="recommend-title hs">最低位次</div>
                        <div class="year-title" v-for="(itt, idd) in item.items" :key="idd">
                          {{ itt.rankLine }}
                        </div>
                      </div>
                      <div class="admission-line___q58Dc">
                        <div class="recommend-title hs">位次差</div>
                        <div class="year-title" style="display: flex; align-items: center"
                          v-for="(item, index) in item.items" :key="index">
                          <span v-if="item.rankLine === basicInfo.rank">
                            不相上下
                          </span>
                          <span v-else-if="item.rankLine > basicInfo.rank">
                            高于 {{ item.rankLine - basicInfo.rank }}
                            <img style="width: 13px; margin-left: 4px" src="./images/ss.png" alt="高图标" />
                          </span>
                          <span v-else>
                            低于 {{ basicInfo.rank - item.rankLine }}
                            <img style="width: 13px; margin-left: 4px" src="./images/xj.png" alt="低图标" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="recommend-Tb" @click="informant(item, idx)">
                      <div class="fill-tbBox">
                        <div v-if="!item.chencks" class="fill-tb">填报</div>
                        <div v-else class="fill-tb" style="background: #187cff; color: #fff">
                          已填报 {{ filteredList.length }}
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </template>
            <div v-if="list.length == 0 && !isLoading">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <!-- 加载 -->
            <div class="loading_wrap" v-show="isLoading">
              <div class="loading">
                <div class="container"></div>
              </div>
              <div>正在加载中</div>
            </div>
          </div>

          <!-- 右侧边栏 -->
          <div id="tzy-selected-pop" :class="isshopCar ? '' : 'slide-out'" cart-list="">
            <div class="shoppingCarLeft pull-left">
              <div class="foldBtn" @click="shopCar()">
                <p class="f16">展开志愿表</p>
                <span class="f18">{{ filteredList.length }}</span>
              </div>
              <button class="yzy-button save" @click="gozybEditor()">
                保存
              </button>
              <button class="yzy-button save" style="border-top: 0px" @click="empty()">
                清空
              </button>
              <button class="yzy-button save safe" style="border-top: 0px" @click="toTop()">
                顶部
              </button>
            </div>
            <div class="shoppingCarRight pull-right" style="height: 87vh">
              <div class="searchInput el-input el-input--suffix">
                <input type="text" v-model="keyword" autocomplete="off" placeholder="院校/专业搜索"
                  class="el-input__inner" /><span class="el-input__suffix"><span class="el-input__suffix-inner">
                    <i class="el-input__icon el-icon-search f16" style="cursor: pointer"></i> </span></span>
              </div>
              <div class="cartList" style="height: 70vh" v-if="filteredList.length !== 0">
                <div class="cartListItem" v-for="(its, idxs) in filteredList" :key="idxs">
                  <div class="cartCollege">
                    <div class="college-title dataType pull-left chong">
                      <span class="college-num">{{ idxs + 1 }}</span>
                    </div>
                    <span class="cartCollegeName">{{ its.name }}</span>
                    <i class="el-icon-delete delicon" style="cursor: pointer; color: rgb(155, 155, 155)"
                      @click="deleteSelective(idxs, its.name, its.major)"></i>
                  </div>
                  <div class="professionItem">
                    <p class="professionName ml30">
                      <span>{{ its.major }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="cartList" style="height: 70vh" v-else>
                <el-empty description="暂时还没有哦~"></el-empty>
              </div>
              <button class="yzy-button creatsuccess" @click="gozybEditor()">
                <p>保存志愿表</p>
                <span>( 已填{{ filteredList.length }}个志愿 )</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- ai弹框 -->
      <el-dialog title="AI智能填报" :visible.sync="AIVisible" width="35%" center v-if="universities !== ''">
        <span>
          <span>{{ basicInfo.expectedScore }}分</span>
          ·
          <span>{{
              basicInfo.subjectGroup !== undefined
                ? basicInfo.subjectGroup.split(",").join("/")
                : ""
            }}</span>
          ·
          <span>{{ leibiestr }}</span>
        </span>
        <div class="CanvasContent">
          <Aizntb :homeDatas="universities.pieChats" />
        </div>
        <div class="table-fixed">
          <table border-spacing="0" class="pcl-table">
            <thead>
              <th width="0.06%" v-for="(it, idx) in universities.universities" :key="idx">{{ it.name }}</th>
            </thead>
          </table>
        </div>
        <table border-spacing="0" class="pcl-table">
          <tbody>
            <tr>
              <td width="0.06%" align="center" v-for="(it, idx) in universities.universities" :key="idx">{{ it.count }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="sm">
          <div class="smTitle">*说明</div>
          <div class="smContent">
            <p>
              1.
              本平台基于历史分数及等效位次进行志愿推荐和风险评估，由于志愿填报本身存在不确定性，请谨慎参考。
            </p>
            <p>
              2.在正式填报时，院校/专业名称及代码请务必与官方信息平台核对，若发现差异则以官方数据为准，本平台数据仅供参考。
            </p>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Aizntb from "@/components/aizntb";
import {
  GetRegionList,
  getAiUniversity,
  GetCustomerInfoById,
  aiPerview
} from "@/api/home";
import inputNumberRange from "@/components/inputNumberRange";
import { PostPcVolunteer } from "@/api/zybEditor";
import { request2 } from "@/utils/request";

export default {
  name: "VoluntaryReportingIndex",
  components: {
    inputNumberRange,
    Aizntb,
  },
  data() {
    return {
      isLoading: true, // 展示loading
      universities: '',
      AIVisible: false,
      istitActive: 0,
      batchDataUrl: "",
      batchScore: '',
      batchDataUrlList: [],
      leibiestr: "",
      isleibiestr: "全部 0",
      keyword: "", // 存放输入的关键字
      isSort: 0,
      sort1: false,
      sort2: false,
      sort3: false,
      // tab切换
      typShow: 0,

      // 冲击 稳妥 保底
      cwbList: [
        {
          name: "全部 ",
          num: 0,
        },
        {
          name: "冲 ",
          num: 0,
        },
        {
          name: "稳 ",
          num: 0,
        },
        {
          name: "保 ",
          num: 0,
        },
      ],

      //列表数量
      dataCount: 0,
      basicInfo: "",
      //提交获取学校及专业列表数据
      studentData: {
        Year: 2023,
        Name: "",
        Province: [],
        SchoolType: [],
        Nature: [],
        Ascription: [],
      },

      // 范围选择框
      numberRange: [null, null],

      // 选择列表
      selectiveListing: [],

      // 学校专业列表
      list: [],

      // 全部学校专业列表
      listQ: [],

      // 可冲击学校专业列表
      listC: [],

      // 较稳妥学校专业列表
      listW: [],

      // 可保底学校专业列表
      listB: [],

      // 已选择的院校省份
      Type1: [],

      // 已选择的院校类型
      Type2: [],

      // 已选择的院校层次（1）
      Type31: [],

      // 已选择的院校层次（2）
      Type32: [],

      // 已选条件数组
      tags: [],

      // 省份类型
      Type1List: [],

      // 院校类型
      Type2List: [
        { Id: -2, Code: "-2", Name: "不限", chenck: true },
        { Id: 0, Code: "0", Name: "综合", chenck: false },
        { Id: 1, Code: "1", Name: "理工类", chenck: false },
        { Id: 2, Code: "2", Name: "医学类", chenck: false },
        { Id: 3, Code: "3", Name: "军事类", chenck: false },
        { Id: 4, Code: "4", Name: "语言类", chenck: false },
        { Id: 5, Code: "5", Name: "师范类", chenck: false },
        { Id: 6, Code: "6", Name: "财经类", chenck: false },
        { Id: 7, Code: "7", Name: "政法类", chenck: false },
        { Id: 8, Code: "8", Name: "民族类", chenck: false },
        { Id: 9, Code: "9", Name: "农林类", chenck: false },
        { Id: 10, Code: "10", Name: "艺术类", chenck: false },
        { Id: 11, Code: "11", Name: "体育类", chenck: false },
        { Id: 12, Code: "12", Name: "其他", chenck: false },
      ],

      // 院校层次（1）
      Type31List: [
        { Id: -3, Code: "-1", Name: "不限", chenck: true },
        { Id: 0, Code: "0", Name: "公办", chenck: false },
        { Id: 1, Code: "1", Name: "民办", chenck: false },
        { Id: 2, Code: "2", Name: "中外合作", chenck: false },
        { Id: 3, Code: "3", Name: "港澳台", chenck: false },
      ],

      // 院校层次（2）
      Type32List: [
        { Id: 1, Code: "1", Name: "教育部", chenck: false },
        { Id: 2, Code: "2", Name: "省政府", chenck: false },
        { Id: 3, Code: "3", Name: "其他部委", chenck: false },
        { Id: 4, Code: "4", Name: "军校", chenck: false },
      ],

      // 右侧侧边栏展开收起
      isshopCar: true,
    };
  },
  computed: {
    filteredList() {
      if (this.keyword === "") {
        return this.selectiveListing; // 如果没有输入关键字则返回全部数据
      } else {
        const regExp = new RegExp(this.keyword, "i"); // 创建正则表达式对象，不区分大小写

        return this.selectiveListing.filter((item) => {
          return regExp.test(item.major) || regExp.test(item.name); // 根据名称进行匹配
        });
      }
    },
  },
  mounted() {
    this.getUserInfo();
    this.getRegionList();
  },

  methods: {
    async getTag() {
      const { subjectGroup, provinceCode, sp, expectedScore } = this.basicInfo;
      const obj = {
        location: provinceCode,
        p: sp,
        score: expectedScore,
        subjects: subjectGroup.split(","),
      };
      const res = await aiPerview({
        ...obj
      })
      this.universities = res.data.result
    },
    // ai弹框
    Aitk() {
      this.AIVisible = true;
    },
    //获取批次线政策
    getBatchDataUrlList(typ) {
      var that = this;
      request2
        .get(this.batchDataUrl)
        .then((res) => {
          let list = res.data.result[0].batches;
          if (that.basicInfo.sp == 0 || that.basicInfo.sp == 3) {
            // 使用正则表达式提取 "物" 或 "史"
            let regex = /(物|史)/; // 正则表达式匹配 "物" 或 "史"
            let match = that.basicInfo.subjectGroup.match(regex);
            if (match) {
              console.log(match[0]); // 输出匹配到的第一个字符串，如 "物"
            } else {
              console.log("没有找到匹配的科目");
            }

            if (match[0] === "物") {
              // 筛选出 '理科' 数据
              that.batchDataUrlList = list.filter(
                (item) => item.course === "理科"
              );
            } else if (match[0] === "史") {
              // 筛选出 '文科' 数据
              that.batchDataUrlList = list.filter(
                (item) => item.course === "文科"
              );
            }
          } else {
            that.batchDataUrlList = list;
          }
          let stop = false; // 停止标志

          that.batchDataUrlList.forEach((its) => {
            if (stop) return; // 如果已经找到符合条件的分数，停止遍历
            if (that.basicInfo.expectedScore >= its.score) {
              that.leibiestr = its.batch;
              its.recommend = true;
              stop = true; // 设置停止标志
            }
          });
          that.batchDataUrlList.forEach((its) => {
            if (that.basicInfo.expectedScore < its.score) {
              its.price =
                `线差` + (its.score - that.basicInfo.expectedScore) + `分`;
            } else {
              its.price =
                `线高` + (that.basicInfo.expectedScore - its.score) + `分`;
            }
          });
          that.getList(typ);
          that.getTag()
        })
        .catch((error) => {
          console.error("Request failed:", error); // 打印错误信息
        });
    },

    //切换段位类别
    handleCommand(command) {
      this.leibiestr = command;
      this.getList();
    },
    //切换段位类别
    ishandleCommand(command) {
      this.isleibiestr = command;
      // 使用对象映射方式
      const listMap = {
        全部: this.listQ,
        冲: this.listC,
        稳: this.listW,
        保: this.listB,
      };

      // 设置 list 为对应的数组，默认值是 this.listQ
      this.list = listMap[command.split(" ")[0]] || this.listQ;
    },
    // 跳转学校详情
    goSchoolDetails(Id) {
      const routeData = this.$router.resolve({
        path: "/Home/SchoolDetails",
        query: { Id },
      });
      window.open(routeData.href, "_blank");
    },

    // 清空右侧以选择的列表数据
    empty() {
      // 清空 selectiveListing
      this.selectiveListing = [];

      // 遍历 list，清除 chenck 和 registered，并重置 items 中的 chencks
      this.list.forEach((item) => {
        this.$set(item, "chenck", false);
        this.$set(item, "registered", 0);

        // 重置每个 item 中的 chencks
        item.items.forEach((it) => this.$set(it, "chencks", false));
      });
    },

    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.basicInfo = res.data.result.userExtend;
      this.batchDataUrl = res.data.result.batchDataUrl;
      this.batchScore = res.data.result.batchScore;
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
      this.getBatchDataUrlList(true);
      this.setDate();
    },

    // 回填数据
    setDate() {
      // 志愿表数据回填
      this.numberRange = [
        this.basicInfo.expectedScore - 15,
        this.basicInfo.expectedScore + 15,
      ];
    },

    // 切换
    cwb(typ) {
      this.typShow = typ;

      // 使用对象映射方式
      const listMap = {
        0: this.listQ,
        1: this.listC,
        2: this.listW,
        3: this.listB,
      };

      // 设置 list 为对应的数组，默认值是 this.listQ
      this.list = listMap[typ] || this.listQ;
    },

    // 保存志愿提交
    async gozybEditor() {
      if (this.selectiveListing.length === 0) {
        return this.$message.error("请选择专业后再保存");
      }

      const obj = {
        score: this.basicInfo.expectedScore,
        subjectClaim: this.basicInfo.subjectGroup,
        batchName: this.leibiestr,
        type: "智能填报",
        vTbDetails: this.selectiveListing.map((it, idx) => {
          const objItem = {
            sort: idx + 1,
            type: it.type,
            unCode: it.collegeCode,
            unId: it.uId,
            vItems: [
              {
                sort: idx + 1,
                code: it.majorCode,
                name: it.major,
                percentAge: it.percentAge,
                type: it.type,
                _pId: it.planId,
              },
            ],
          };
          return objItem;
        }),
      };

      try {
        const res = await PostPcVolunteer(obj);
        this.$router.push({
          path: "/Home/zybEditorTwo",
          query: { VId: res.data.result },
        });
      } catch (error) {
        console.error("Error during request:", error);
      }
    },

    // 右侧列表删除
    deleteSelective(idx, Name, major) {
      // 删除 selectiveListing 中的元素
      this.selectiveListing.splice(idx, 1);

      // 查找对应的 list 项
      const listIndex = this.list.findIndex((item) => item.name === Name);
      if (listIndex !== -1) {
        const listItem = this.list[listIndex];
        listItem.registered -= 1; // 减少注册人数

        // 查找对应的 major 项
        const itemIndex = listItem.items.findIndex(
          (item) => item.major === major
        );
        if (itemIndex !== -1) {
          listItem.items[itemIndex].chencks = false; // 取消选中
        }
      }
    },

    // 点击填报加入到右侧列表中
    informant(item, idx) {
      const index = this.selectiveListing.findIndex(
        (itt) => itt.major === item.major
      );

      item.chencks = true;

      if (index === -1) {
        const obj = {
          ...item, // 展开 item 属性
          collegeCode: this.list[idx].collegeCode,
          name: this.list[idx].name,
          uId: this.list[idx].uId,
        };

        this.selectiveListing.push(obj);
        this.list[idx].registered += 1; // 增加已注册数量
      }
    },
    // 获取冲稳保院校
    async getList(typ) {
      const { subjectGroup, provinceCode, sp, expectedScore } = this.basicInfo;
      const obj = {
        batchname: this.leibiestr,
        location: provinceCode,
        majors: this.studentData.SchoolType,
        nature: [...this.studentData.Nature, ...this.studentData.Ascription],
        p: sp,
        pageIndex: 1,
        pageSize: 999,
        province: this.studentData.Province,
        score: expectedScore,
        subjects: subjectGroup.split(","),
        type: "",
        startscore: this.numberRange[0],
        endscore: this.numberRange[1],
      };

      try {
        const res = await getAiUniversity(obj);
        const rows = res.data.result.rows;
        this.list = rows;
        this.cwbList[0].num = rows.length;

        // 初始化分类数组
        this.listB = [];
        this.listW = [];
        this.listC = [];
        this.listQ = [];

        // 遍历数据并分类处理
        rows.forEach((item) => {
          // 使用 $set 保证 chenck 和 registered 的响应式
          this.$set(item, "chenck", false);
          this.$set(item, "registered", 0);
          this.listQ.push(item);

          // 根据 item.type 分类
          if (item.type === 0) {
            this.listC.push(item);
          } else if (item.type === 1) {
            this.listW.push(item);
          } else {
            this.listB.push(item);
          }

          // 遍历 item.items 并初始化 chencks
          item.items.forEach((it) => {
            this.$set(it, "chencks", false);
          });
        });

        // 更新分类后的计数
        this.cwbList[1].num = this.listC.length;
        this.cwbList[2].num = this.listW.length;
        this.cwbList[3].num = this.listB.length;
        this.isleibiestr = `全部 ` + this.cwbList[0].num;
        this.isLoading = false;
        if (typ) {
          this.AIVisible = true;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    // 选择院校省份
    selectRegion(it, idx) {
      this.disport(it, idx, this.Type1List, this.Type1, 0);
    },

    // 选择院校类型
    selectType(it, idx) {
      this.disport(it, idx, this.Type2List, this.Type2, 0);
    },

    // 选择院校层次1
    selectGradation(it, idx) {
      this.disport(it, idx, this.Type31List, this.Type31, 1);
    },

    // 选择院校层次2
    selectGradation2(it, idx) {
      this.disport(it, idx, this.Type32List, this.Type32, 1);
    },

    // 获取省份数据
    async getRegionList() {
      const res = await GetRegionList({
        code: 0,
      });
      this.Type1List = res.data.response;
      this.Type1List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type1List.unshift({
        Id: -1,
        Code: "-1",
        Name: "不限",
        chenck: true,
      });
    },

    // 录取概率排序
    PxSort(text) {
      this.isSort = 0;
      this.sort1 = text;
      if (text) {
        this.list = this.list.sort(
          (a, b) => parseFloat(b.percentAge) - parseFloat(a.percentAge)
        );
      } else {
        this.list = this.list.sort(
          (a, b) => parseFloat(a.percentAge) - parseFloat(b.percentAge)
        );
      }
    },

    // 人数排序
    PxSort1(text) {
      this.isSort = 1;
      this.sort2 = text;
      if (text) {
        this.list = this.list.sort((a, b) => b.planCount - a.planCount);
      } else {
        this.list = this.list.sort((a, b) => a.planCount - b.planCount);
      }
    },

    //排名排序
    PxSort2(text) {
      this.isSort = 2;
      this.sort3 = text;
      if (text) {
        this.list = this.list.sort((a, b) => b.rank - a.rank);
      } else {
        this.list = this.list.sort((a, b) => a.rank - b.rank);
      }
    },

    // 合并
    mergeARR() {
      this.studentData.Province = [];
      this.studentData.SchoolType = [];
      this.studentData.Nature = [];
      this.studentData.Ascription = [];
      this.Type1.forEach((item) => {
        this.studentData.Province.push(item.Name);
      });
      this.Type2.forEach((item) => {
        this.studentData.SchoolType.push(item.Name);
      });
      this.Type31.forEach((item) => {
        this.studentData.Nature.push(item.Name);
      });
      this.Type32.forEach((item) => {
        this.studentData.Ascription.push(item.Name);
      });
      this.tags = [
        ...this.Type1,
        ...this.Type31,
        ...this.Type32,
        ...this.Type2,
      ];
      this.list = [];
      this.getList();
    },

    // 重置
    rest() {
      this.tags = [];
      this.Type1 = [];
      this.Type31 = [];
      this.Type32 = [];
      this.Type2 = [];
      this.studentData.Province = [];
      this.studentData.SchoolType = [];
      this.studentData.Nature = [];
      this.studentData.Ascription = [];
      this.numberRange = [
        this.basicInfo.expectedScore - 15,
        this.basicInfo.expectedScore + 15,
      ];
      this.Type1List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type2List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type31List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type32List.forEach((item) => {
        this.$set(item, "chenck", false);
      });
      this.Type1List[0].chenck = true;
      this.Type2List[0].chenck = true;
      this.Type31List[0].chenck = true;
      this.getList();
    },

    // 处理
    disport(it, idx, list, Arr, type) {
      if (it.Name !== "不限") {
        list[0].chenck = false;
        var index = Arr.findIndex((item) => item.Name == it.Name);
        // 没有就添加
        if (index == -1) {
          Arr.push(it);
          list[idx].chenck = true;
          this.mergeARR();
          // 有就删除
        } else {
          Arr.splice(index, 1);
          list[idx].chenck = false;
          this.mergeARR();
        }
        if (type == 1) {
          const b = this.Type31List.every((item) => item.chenck == false);
          const c = this.Type32List.every((item) => item.chenck == false);
          if (b && c) {
            this.Type31List[0].chenck = true;
          } else {
            this.Type31List[0].chenck = false;
          }
        } else {
          const b = list.some((item) => item.chenck == true);
          if (b) {
            list[0].chenck = false;
          } else {
            list[0].chenck = true;
          }
        }
      } else {
        if (type == 1) {
          this.Type31 = [];
          this.Type32 = [];
          this.Type31List.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          this.Type32List.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          this.Type31List[0].chenck = true;
          this.mergeARR();
        } else {
          Arr.splice(0, Arr.length); //清空数组
          list.forEach((item) => {
            this.$set(item, "chenck", false);
          });
          list[0].chenck = true;
          this.mergeARR();
        }
      }
    },

    // 标签删除处理
    Close(List, Arr, Tag, Type) {
      List[List.indexOf(Tag)].chenck = false;
      Arr.splice(Arr.indexOf(Tag), 1);
      this.mergeARR();
      if (Type == 1) {
        const b = this.Type31List.every((item) => item.chenck == false);
        const c = this.Type32List.every((item) => item.chenck == false);
        if (b && c) {
          this.Type31List[0].chenck = true;
        } else {
          this.Type31List[0].chenck = false;
        }
      } else {
        const b = List.every((item) => item.chenck == false);
        if (b) {
          List[0].chenck = true;
        }
      }
    },

    regionClose(tag) {
      this.Close(this.Type1List, this.Type1, tag, 0);
    },
    typeClose(tag) {
      this.Close(this.Type2List, this.Type2, tag, 0);
    },
    gradationClose(tag) {
      this.Close(this.Type31List, this.Type31, tag, 1);
    },
    gradation2Close(tag) {
      this.Close(this.Type32List, this.Type32, tag, 1);
    },

    fn(check, idx) {
      this.list[idx].chenck = !check;
    },

    shopCar() {
      this.isshopCar = !this.isshopCar;
    },

    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
          50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
}

.content {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 24px;
}

img {
  width: 100%;
  height: 100%;
}

.el-checkbox {
  padding: 10px;
  margin-right: 10px;
  width: 100%;
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  width: 160px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tzy-user-info {
  position: relative;
  height: 30px;

  .toys {
    display: flex;
    height: 30px;
    align-items: center;
  }

  .customer-college {
    position: absolute;
    right: 0;
    top: 5px;
    font-size: 12px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 30px;
    height: 30px;
    z-index: 2;
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      width: 70px;
      height: 20px;
      border-radius: 4px;
      text-align: center;
      line-height: 20px;
      background-color: #fff;
      color: #187cff;
      border: 1px solid #187cff;
      margin-left: 20px;
    }
  }
}

.tabs {
  position: relative;
  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  background: #ffffff;

  .tabTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #eeeeee;

    .tabTitleLeft {
      display: flex;

      .tit {
        margin-right: 20px;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
      }

      .istitActive {
        color: #187cff !important;
        font-weight: bold;
      }
    }
  }

  .tzy-filter-wrapper {
    box-sizing: border-box;
    display: block;

    .filter-list {
      display: flex;
      align-items: flex-start;
      margin-top: 10px;

      .filter-list-title {
        font-size: 14px;
        text-align: center;
        font-weight: bold;
      }

      .filter-list-tags {
        margin-left: 20px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .tags {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          ::v-deep .el-tag {
            margin-right: 10px;
            font-size: 14px;
            height: 24px;
            line-height: 24px;
          }
        }

        .tag {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          font-style: normal;
          margin: 0 0 8px 10px;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
          box-sizing: border-box;
          min-width: 58px;
          text-align: left;
        }

        .tag:hover {
          color: #187cff;
        }

        .active {
          color: #187cff;
        }
      }

      .button-actions {
        font-size: 16px;
        color: #ed5151;
        cursor: pointer;
      }
    }

    .college-level-wrapper {
      display: flex;
    }
  }
}

.tzy-page-center {
  position: relative;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #187cff;

  .left {
    display: flex;
    align-items: center;

    .zntj {
      margin-left: 5px;
      display: inline-block;
      position: relative;
      color: #606266;
      font-size: 14px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: inline-block;
      height: 40px;
      padding: 0 10px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      border-bottom: none;
      color: #187cff;
    }
  }

  .el-dropdown {
    display: inline-block;
    position: relative;
    color: #606266;
    font-size: 14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-block;
    height: 40px;
    padding: 0 10px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border-bottom: none;
    color: #ffffff;
  }

  .tzy-page-center_button-default {
    background-color: #187cff;
    color: #fff;
  }

  .btn {
    position: absolute;
    right: 0;
    top: 5px;
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 10px;
    color: #fff;
    background: #187cff;
    cursor: pointer;
  }
}

.tableBox {
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tableBoxTop {
  height: 100%;
  position: relative;

  .recommendTabList {
    position: relative;
    height: 39px;

    .recommendList {
      display: flex;
      width: 100%;
      height: 39px;
      border-width: 1px 1px 0;
      line-height: 39px;
      font-size: 14px;
      font-weight: 600;
      color: #333333;

      .recommendItem {
        flex: 4 1;
      }

      .recommend-Tb {
        flex: 2 1;
        justify-content: center;
      }

      .recommend-data-eu-table-ratio___TwQdX {
        width: 125px;
      }

      .recommend-header {
        width: 155px;
      }

      .recommend-xheader {
        flex: 8 1;
        min-width: 339px;
        display: flex;
        padding: 0 !important;
        border-right-width: 0 !important;

        .recommend-title {
          flex: 58 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }

        .year-title {
          flex: 89 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }
      }
    }

    .recommendList>div {
      padding: 0 15px;
      border-right: 1px solid #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .recom-Item>div:first-of-type {
    align-items: flex-start;
  }

  .recom-Item {
    width: 100%;
    min-height: 145px;
    border: 1px solid #efefef;
    position: relative;
    display: flex;

    .recommendItem {
      flex: 4 1;
      justify-content: center;

      .sch-name___afuQF {
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        color: #272727;
        line-height: 25px;
        margin-bottom: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cwb {
          width: 30px;
          height: 24px;
          border-radius: 4px 4px 4px 4px;
          font-size: 14px;
          color: #ffffff;
          font-weight: normal;
          text-align: center;
          line-height: 24px;
        }

        .c {
          background: #eb5241;
        }

        .w {
          background: #fa8e23;
        }

        .b {
          background: #15c496;
        }
      }

      .sch-name___afuQF:hover {
        color: #187cff;
      }

      .zydm {
        font-size: 12px;
        font-weight: 400;
        color: #86909c;
        line-height: 20px;
        margin-bottom: 8px;
      }
    }

    .recommend-Tb {
      flex: 2 1;
      align-items: center;
      justify-content: center;
      min-width: 145px;

      .fill-tbBox {
        width: 80px;
        border-radius: 6px;
        border: 1px solid #efefef;
        margin-bottom: 5px;

        .fill-tb {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #187cff;
          line-height: 1;
          cursor: pointer;
          user-select: none;
          width: 90px;
          height: 34px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #187cff;
        }
      }

      .count-box___NYb3B {
        .count___QftTC {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 1;
          font-size: 14px;
          font-weight: 400;
          color: #9d9d9d;
          white-space: pre;
          width: 90px;
          height: 34px;
          background: #187cff;
          border-radius: 4px 4px 4px 4px;
          margin-bottom: 27px;
          color: #ffffff;
          cursor: pointer;
        }

        .fill-tb {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #86909c;
          line-height: 1;
          cursor: pointer;
          user-select: none;
        }
      }
    }

    .recommend-data-eu-table-ratio___TwQdX {
      width: 125px;

      .ratio-num___gAwOo {
        font-size: 20px;
        font-family: DIN Alternate Bold;
        color: #272727;
        line-height: 24px;
        margin-bottom: 5px;
      }
    }

    .recommend-header {
      width: 155px;
      justify-content: center;
      align-items: center;

      .plan-count___UrtQc {
        display: flex;
        width: max-content;

        .plan-count-num {
          flex: 1 1;
          color: #272727;
          line-height: 39px;
          text-align: center;
          padding-right: 3px;
          word-break: keep-all;

          .rs {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }

      .plan-info___kz4Ip {
        font-size: 12px;
        color: #86909c;
      }
    }

    .recommend-xheader {
      flex: 8 1;
      min-width: 339px;
      display: flex;
      padding: 0 !important;
      border-right-width: 0 !important;
      justify-content: space-between;

      .recommend-title,
      .year-title {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .admission-line___q58Dc:last-child {
        border: none !important;
      }

      .admission-line___q58Dc {
        border-bottom: 1px solid #efefef;
        flex: 1 1;
        display: flex;
        width: 100%;

        .year-title {
          flex: 89 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }

        .hs {
          color: #86909c;
        }

        .recommend-title {
          flex: 58 1;
          border-right: 1px solid #efefef;
        }
      }
    }
  }

  .recom-Item>div {
    padding: 15px;
    border-right: 1px solid #efefef;
    height: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

#tzy-selected-pop.slide-out {
  transform: translateX(0);
}

#tzy-selected-pop {
  right: 0;
  position: fixed;
  top: 120px;
  width: 408px;
  height: 270px;
  z-index: 2000;
  transition: all 1s;
  transform: translateX(366px);
  display: flex;

  .shoppingCarLeft {
    width: 42px;
    height: 270px;
    cursor: pointer;

    .foldBtn {
      color: #fff;
      background: #2577e3;
      display: inline-block;
      border: 1px solid transparent;
      border-right: none;
      border-top-left-radius: 4px;

      .f16 {
        font-size: 16px !important;
        line-height: 22px;
        padding: 16px 13px;
        display: inline-block;
      }

      .f18 {
        font-size: 18px !important;
        line-height: 25px;
        text-align: center;
        margin-bottom: 16px;
        display: inline-block;
        width: 100%;
      }
    }

    .save {
      border: 1px solid #2577e3;
      background-color: #fff;
      color: #2577e3;
      font-size: 14px;
      width: 42px;
      height: 42px;
      cursor: pointer;
    }

    .safe {
      border-bottom-left-radius: 4px;
    }
  }

  .shoppingCarRight {
    background: #fff;
    border: 1px solid #e6e6e6;
    width: 366px;
    background: #fff;
    height: 100%;
    border-radius: 0 0 0 4px;
    overflow: hidden;

    .searchInput {
      margin: 15px 20px 0;
      height: 40px;
      font-size: 12px;
      line-height: 18px;
      width: 326px;
    }

    .el-input {
      position: relative;
      font-size: 14px;
      display: inline-block;
    }

    .cartList {
      width: 100%;
      padding: 0 20px;
      overflow-y: auto;

      .cartListItem {
        border-bottom: 1px solid #e6e6e6;
        padding: 20px 0;

        .cartCollege {
          width: 100%;
          line-height: 18px;
          overflow: hidden;
          height: 18px;
          display: flex;

          .delicon {
            font-size: 14px;
          }

          .chong.dataType {
            color: #fff;
            border-color: #e9302d;
            background-color: #e9302d;
            border: 1px solid #e6e6e6;
            height: 18px;
            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            padding: 0 4px;

            .college-num {
              display: block;
              min-width: auto;
              min-height: 16px;
              line-height: normal;
            }
          }

          .college-title {
            min-width: 18px;
          }

          .cartCollegeName {
            display: inline-block;
            margin-left: 12px;
            width: 280px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #212121;
          }
        }

        .professionItem {
          font-size: 14px;
          line-height: 22px;
          margin-top: 12px;

          .professionName {
            width: 294px;
            display: inline-block;
            display: flex;
            flex-wrap: nowrap;
            margin-left: 40px !important;
          }
        }
      }
    }

    .creatsuccess {
      cursor: pointer;
      margin-left: 20px;
      width: 336px;
      height: 44px;
      border: none;
      line-height: 44px;
      display: flex;
      justify-content: center;
      border-radius: 4px;
      color: #fff;
      background-color: #2577e3;
      margin-top: 30px;
    }
  }
}

::v-deep .el-dialog {
  border-radius: 12px 12px 12px 12px;
}

::v-deep .el-submenu__icon-arrow {
  font-size: 16px;
  font-weight: bold;
}

::v-deep .el-dialog {
  display: flex;
  display: -ms-flex;
  /* 兼容IE */
  flex-direction: column;
  -ms-flex-direction: column;
  /* 兼容IE */
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  border-radius: 12px 12px 12px 12px;
}

::v-deep .el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  /* 兼容IE */
  overflow-y: auto;
  overflow-x: hidden;
}

::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 32px;
}

::v-deep .el-dialog__header {
  margin-top: 32px !important;
  padding-top: 0;
}

::v-deep .el-dialog__body {
  padding-top: 0 !important;
}

.CanvasContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-fixed {
  .pcl-table {
    width: 100%;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-bottom: none;
    border-spacing: 0 !important;
    background-color: #fff;
  }

  thead {
    height: 42px;
    background: #f6f6f6;

    th {
      border-right: 1px solid #ebeef5;
      border-bottom: 1px solid #ebeef5;
      padding: 14px 20px;
      color: #000;
      font-size: 14px;
    }
  }
}

.pcl-table {
  width: 100%;
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-bottom: none;
  border-spacing: 0 !important;
  background-color: #fff;

  tbody tr {
    height: 48px;

    td {
      border-right: 1px solid #ebeef5;
      border-bottom: 1px solid #ebeef5;
      padding: 14px 20px;
      font-size: 14px;
    }
  }

  .remark-row {
    height: 42px;

    td {
      padding: 10px 54px;
      font-size: 12px;
      padding-left: 74px;

      .text-666 {
        color: #666 !important;
      }
    }
  }
}

.sm {
  margin-top: 23px;
  width: 100%;
  background: #fef0f0;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ffeaea;
  padding: 16px;
  box-sizing: border-box;
  color: #f56c6c;

  .smTitle {
    margin-bottom: 12px;
    font-size: 20px;
  }

  .smContent {
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

// ------loading样式---------
.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  padding: 20px 0;

  .loading {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
    margin-right: 10px;
  }

  .container {
    position: relative;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #fff;
  }
}

/*------loading动画---------*/
@keyframes rotate {
  from {
    transform-origin: center center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center center;
    transform: rotate(360deg);
  }
}
</style>
