<template>
  <div class="box">
    <div style="max-width: 1280px; margin: 0 auto">
      <div class="articleCont clearfix">
        <div class="articleCont_left">
          <p class="article_list_title">{{ content.Title }}</p>
          <p class="article_list_shuxing">
            <span>发布时间：{{ content.CreateTime }}</span>
          </p>
          <div id="bbb" class="article_nei">
            <h1 deep="12">
              <div v-html="content.Detail"></div>
              <br />
              <div style="font-size: medium; text-align: right">
                <span style="color: inherit">{{ content.Author }}</span>
              </div>
              <div style="text-align: right">
                <span><br /></span>
              </div>
              <span>
                <div style="text-align: right">
                  <span style="color: inherit">
                    {{ content.CreateTime.split(" ")[0] }}
                  </span>
                </div>
              </span>
              <p></p>
            </h1>
          </div>
          <div class="laiyuan">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAAwUlEQVQYV1XOIQ7CQBAF0D9bhaBcAIEg4Q5cAAEK0t1VtdWQYEDVkJAQDkBS3WybYrFwAySaExQBqdkdsiQVfPlfZvIJAKqq6ltrNwBeADpN02zjOH6TR2PMvq7rNEmST57ngyAI5lLKY4sHpdQaAGdZ1g3DcBVFUfrDsizHzjlFRFdmnhFRKqV8tpcLIcTEOTcSQtyttTet9ZmMMUtmfmitL/6LT1EUUwBDjzullF/6F997PBFRDcB6ZWYiIgGg9wUc+lGOnpbi2QAAAABJRU5ErkJggg=="
            />
            来源：教育部
          </div>
          <!-- <div class="article_tuijian">
                        <p>推荐文章</p>
                        <div class="no-recmend">暂无数据</div>
                    </div> -->
        </div>
        <!--  -->
        <div class="articleCont_right">
          <div class="banes">
            <div class="item" @click="goUrl('/Home/FirstPage')">
              <img src="./images/Frame 1000015157@2x.png" alt="" />
            </div>
            <div class="item" @click="goUrl('/Home/ZhanJiaList')">
              <img src="./images/Frame 1000015157@2x(1).png" alt="" />
            </div>
          </div>
          <div class="articleCont_right_zhengce">
            <div class="title">
              <div class="tit">院校推荐</div>
              <div class="more" @click="goUrl('/Home/ListRanking')">
                查看更多
                <i
                  class="el-icon-arrow-right"
                  style="color: #aeaeae; font-size: 14px"
                ></i>
              </div>
            </div>
            <div class="school-box">
              <div
                class="school-item"
                v-for="(it, index) in list"
                :key="index"
                @click="goSchoolDetails(it.Uid)"
              >
                <div class="schoolPhoto">
                  <img :src="it.Logo" alt="" />
                </div>
                <div class="schoolName">
                  <span class="name"> {{ it.Name }}</span>
                  <span class="type">北京.综合类</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPcUniversityRank } from "@/api/ListRanking";
import { GetDetail } from "@/api/News";
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      content: {},
      id: "",
      list: [],
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getList();
    this.getNewS(this.id);
  },

  methods: {
    // 跳转学校详情
    goSchoolDetails(Id) {
      const routeData = this.$router.resolve({
        path: "/Home/SchoolDetails",
        query: { Id },
      });
      window.open(routeData.href, "_blank");
    },
    goUrl(url) {
      this.$router.push(url);
    },
    async getList() {
      const res = await GetPcUniversityRank({
        Type: 0,
        PageIndex: 1,
        PageSize: 8,
      });
      this.list = [];
      this.list = res.data.response;
    },
    // 获取详情数据
    async getNewS(id) {
      const res = await GetDetail({
        Id: id,
      });
      this.content = res.data.response;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: Microsoft YaHei;
}

.box {
  background: #f5f4f8;
  padding-bottom: 10px;
  min-height: 90vh;
}

.clearfix {
  zoom: 1;
}

.hidden-alink-spider-only {
  display: none;
}

a,
a:hover {
  color: #999;
  text-decoration: none;
}

li {
  list-style: none;
}

.articleCont {
  padding-top: 42px;
  display: flex;
  .articleCont_left {
    flex: 1;
    background-color: #fff;
    float: left;
    padding-bottom: 28px;
    border-radius: 14px;
    margin-bottom: 150px;
    min-height: 80vh;
    .article_list_title {
      font-size: 28px;
      font-weight: 600;
      color: #333;
      line-height: 32px;
      margin-left: 50px;
      margin-top: 40px;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      padding-bottom: 1px;
      text-align: center;
    }

    .article_list_shuxing {
      width: 765px;
      font-size: 16px;
      font-weight: 400;
      color: #888;
      line-height: 24px;
      margin-top: 15px;
      margin-bottom: 38px;
      margin-left: 50px;
    }

    .article_nei {
      width: 780px;
      margin: auto;
      margin-bottom: 40px;
      color: #666;
      font-size: 16px;
      line-height: 24px;

      h1 {
        font-size: 16px;
      }
    }

    .laiyuan {
      width: 665px;
      margin: auto;
      text-align: right;
      font-size: 16px;
      font-weight: 200;
      color: #888;

      img {
        width: 11px;
      }
    }

    .article_tuijian > p {
      height: 24px;
      font-size: 24px;
      font-weight: 600;
      color: #333;
      line-height: 24px;
      margin-left: 50px;
      margin-top: 60px;
    }

    .no-recmend {
      font-size: 20px;
      color: #666;
      width: 100%;
      height: 100px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .articleCont_right {
    width: 308px;
    float: left;
    margin-left: 16px;
    .banes {
      width: 100%;
      .item {
        cursor: pointer;
        width: 308px;
        height: 142px;
        margin-bottom: 16px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .articleCont_right_zhengce > p {
      height: 21px;
      font-size: 20px;
      font-weight: 700;
      color: #333;
      margin-left: 24px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .articleCont_right_zhengce {
      width: 100%;
      background-color: #fff;
      border-radius: 14px;
      overflow: hidden;
      margin-bottom: 20px;
      padding-bottom: 6px;
      padding: 24px;
      box-sizing: border-box;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        .tit {
          font-weight: 600;
          font-size: 20px;
          color: #000000;
        }
        .more {
          font-size: 14px;
          color: #aeaeae;
          cursor: pointer;
        }
      }
      .school-box {
        margin-top: 38px;
        padding: 0 24px;
        div,
        .school-box .school-item {
          display: flex;
          align-items: center;
        }
        .school-item:hover .name {
          color: #187cff !important;
        }
        .school-item {
          margin-bottom: 24px;
          cursor: pointer;
          .schoolPhoto {
            width: 48px;
            height: 48px;
            margin-right: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .schoolName {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            .name {
              color: #333;
              font-size: 18px;
              margin-bottom: 10px;
              max-width: 180px;
              overflow: hidden;
              word-break: break-all;
              white-space: nowrap; /* 不换行 */
              overflow: hidden; /* 超出部分隐藏 */
              text-overflow: ellipsis; /* 超出部分显示省略号 */
            }
            .type {
              font-size: 14px;
              color: #888;
            }
          }
        }
      }
      .policy_title:hover {
        color: #187cff !important;
        cursor: pointer;
      }
    }
  }
}
</style>
