<template>
  <div class="box">
    <div class="nav-box">
      <img src="./images/newbj.18317a2.png" />
      <div class="tab-box">
        <div class="tabs__header">
          <div
            :class="
              obj.Type == it.Id ? 'is-active el-tabs__item' : 'el-tabs__item'
            "
            v-for="(it, idx) in RankingType"
            :key="idx"
            @click="clickRankingType(it.Id)"
          >
            {{ it.Name }}
          </div>
        </div>
        <div class="el-tabs__content" v-show="obj.Type == 0">
          <div class="title">
            <span>2023-2024中国大学排名800强完整榜单</span>
          </div>
          <div class="tables">
            <el-table
              :data="list"
              border
              style="width: 100%"
              height="450"
              :header-cell-style="{
                background: 'rgb(247, 247, 247)',
                color: 'rgb(51, 51, 51)',
              }"
            >
              <el-table-column
                prop="Sort"
                label="排名"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="Name"
                label="院校名称"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="AreaName" label="所在地区" align="center">
              </el-table-column>
              <el-table-column prop="Score" label="综合得分" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="el-tabs__content" v-show="obj.Type == 1">
          <div class="title">
            <span>2024武书连中国大学排名800强排行榜</span>
          </div>
          <div class="tables">
            <el-table
              :data="list"
              border
              style="width: 100%"
              height="400"
              :header-cell-style="{
                background: 'rgb(247, 247, 247)',
                color: 'rgb(51, 51, 51)',
              }"
            >
              <el-table-column
                prop="Sort"
                label="排名"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="Name"
                label="院校名称"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="AreaName" label="所在地区" align="center">
              </el-table-column>
              <el-table-column prop="Score" label="综合得分" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="el-tabs__content" v-show="obj.Type == 2">
          <div class="title">
            <span>2024软科中国大学排名（总榜）</span>
          </div>
          <div class="tables">
            <el-table
              :data="list"
              border
              style="width: 100%"
              height="400"
              :header-cell-style="{
                background: 'rgb(247, 247, 247)',
                color: 'rgb(51, 51, 51)',
              }"
            >
              <el-table-column
                prop="Sort"
                label="排名"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="Name"
                label="院校名称"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="AreaName" label="所在地区" align="center">
              </el-table-column>
              <el-table-column prop="Score" label="综合得分" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="el-tabs__content" v-show="obj.Type == 3">
          <div class="title">
            <span>2024QS世界大学排名1000强榜单</span>
          </div>
          <div class="tables">
            <el-table
              :data="list"
              border
              style="width: 100%"
              height="400"
              :header-cell-style="{
                background: 'rgb(247, 247, 247)',
                color: 'rgb(51, 51, 51)',
              }"
            >
              <el-table-column
                prop="Sort"
                label="排名"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="Name"
                label="院校名称"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="AreaName" label="所在地区" align="center">
              </el-table-column>
              <el-table-column prop="Score" label="综合得分" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPcUniversityRank } from "@/api/ListRanking";
export default {
  name: "VoluntaryReportingIndex",

  data() {
    return {
      values: "",
      obj: {
        Type: 0,
        Name: "",
        SubjectType: "",
      },
      list: [],
      RankingType: [
        { Id: 0, Name: "校友会" },
        { Id: 1, Name: "武书连" },
        { Id: 2, Name: "软科" },
        { Id: 3, Name: "QS" },
        // { Id: 4, Name: "泰晤士河报" },
      ],
      typeList: [
        { Id: "", Name: "综合排序" },
        { Id: 1, Name: "理工类" },
        { Id: 2, Name: "医学类" },
        { Id: 3, Name: "军事类" },
        { Id: 4, Name: "语言类" },
        { Id: 5, Name: "师范类" },
        { Id: 6, Name: "财经类" },
        { Id: 7, Name: "政法类" },
        { Id: 8, Name: "民族类" },
        { Id: 9, Name: "农林类" },
        { Id: 10, Name: "艺术类" },
        { Id: 11, Name: "体育类" },
        { Id: 12, Name: "其他" },
      ],
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    // 跳转学校详情
    goSchoolDetails(Id) {
      this.$router.push({
        path: "/Home/SchoolDetails",
        query: { Id },
      });
    },
    clickRankingType(id) {
      this.obj.Type = id;
      this.getList();
    },
    clickSubjectType(id) {
      this.obj.SubjectType = id;
      this.getList();
    },
    async getList() {
      const res = await GetPcUniversityRank({
        ...this.obj,
      });
      this.list = res.data.response;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
}
img {
  width: 100%;
  height: 100%;
}
.nav-box {
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: 36px;
  .tab-box {
    width: 100%;
    height: 44px;
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 1300px;
    background-color: transparent;
    .tabs__header {
      width: 1300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 36px;
      .el-tabs__item {
        width: 260px;
        text-align: center;
        height: 44px;
        line-height: 44px;
        font-size: 18px;
        font-weight: 500;
        color: #b7944d;
        opacity: 0.6;
      }
      .el-tabs__item:hover {
        color: #b7944d;
        opacity: 1;
      }
      .is-active {
        color: #b7944d;
        opacity: 1;
      }
    }
  }
}
.el-tabs__content {
  max-width: 1300px;
  padding-bottom: 20px;
  margin: 0 auto;
  .title {
    text-align: center;
    font-size: 20px;
    color: #333;
    font-weight: 500;
    margin-bottom: 24px;
    margin-right: 2px;
  }

  .el-table tr {
    border-top-left-radius: 5px; /* 左上角圆角 */
    border-top-right-radius: 5px; /* 右上角圆角 */
    background-color: #fff; /* 设置背景色，避免圆角被遮挡 */
  }
}
</style>
