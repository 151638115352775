<template>
    <div>
        <div class="list">
            <div class="item">
                <div class="title">专业介绍</div>
                <div class="introduceTit">
                    <div class="tit">专业简介</div>
                    <div class="content">
                        {{ list5.zyjs }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">培养目标</div>
                    <div class="content">
                        {{ list5.pymb }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">培养要求</div>
                    <div class="content">
                        {{ list5.pyyq }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">名人学者</div>
                    <div class="content">
                        {{ list5.shmr }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">课程要求</div>
                <div class="introduceTit">
                    <div class="tit">主干课程</div>
                    <div class="content">
                        {{ list5.zykc }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">学科要求</div>
                    <div class="content">
                        {{ list5.xkyq }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">知识能力</div>
                    <div class="content" v-html="list5.zsnl"></div>
                </div>
            </div>
            <div class="item">
                <div class="title">详细课程列表</div>
                <div class="table">
                    <el-table :data="list1" style="width: 100%"
                        :header-cell-style="{ background: '#EEEEEE', color: '#333333' }">
                        <el-table-column align="center" prop="kcmc" label="课程名称">
                        </el-table-column>
                        <el-table-column align="center" prop="difficulty" label="课程难易度">
                        </el-table-column>
                        <el-table-column align="center" prop="practicality" label="课程实用度">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VoluntaryReportingIndex",
    props: {
        list: {
            type: Object,
            required: true,
        },
        list1: {
            type: Array,
            required: true,
        },
        list5: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tableData: [{
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }, {
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }, {
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }, {
                date: '当代欧陆哲学',
                name: '2',
                address: '3'
            }]
        };
    },

    mounted() { },

    methods: {},
};
</script>

<style lang="less">
.list {
    .item {
        .title {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 36px;
        }

        .introduceTit {
            .tit {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 16px;
            }

            .content {
                font-size: 16px;
                color: #666666;
                margin-bottom: 32px;
            }
        }

        .table {
            margin-bottom: 50px;
        }
    }
}
</style>
