// src/utils/token.js
export function parseToken(token) {
    try {
      const parts = token.split('.');
      if (parts.length !== 3) throw new Error('Token 格式无效');
  
      const payload = JSON.parse(
        atob(parts[1].replace(/-/g, '+').replace(/_/g, '/'))
      );
  
      const currentTime = Math.floor(Date.now() / 1000);
      console.log('过期时间', new Date(payload.exp * 1000).toLocaleString());
      return {
        isExpired: currentTime >= payload.exp,
        expirationTime: new Date(payload.exp * 1000).toLocaleString(),
      };
    } catch (error) {
      console.error('Token 解析失败:', error.message);
      return { isExpired: true };
    }
  }
  