<template>
  <div id="app">
    <div id="chart" style="width: 100%; height: 600px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "App",
  props: {
    userScore: {
      type: Number,
      required: true,
      default: 0,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      yfyds: [
        {
          maxScore: 750,
          minScore: 697,
          sameCount: 45,
          highestRank: 1,
          lowestRank: 45,
        },
        {
          maxScore: 696,
          minScore: 696,
          sameCount: 5,
          highestRank: 46,
          lowestRank: 50,
        },
        {
          maxScore: 695,
          minScore: 695,
          sameCount: 11,
          highestRank: 51,
          lowestRank: 61,
        },
        {
          maxScore: 694,
          minScore: 694,
          sameCount: 18,
          highestRank: 62,
          lowestRank: 79,
        },
        {
          maxScore: 693,
          minScore: 693,
          sameCount: 25,
          highestRank: 80,
          lowestRank: 104,
        },
        {
          maxScore: 692,
          minScore: 692,
          sameCount: 10,
          highestRank: 105,
          lowestRank: 114,
        },
        {
          maxScore: 691,
          minScore: 691,
          sameCount: 19,
          highestRank: 115,
          lowestRank: 133,
        },
        {
          maxScore: 690,
          minScore: 690,
          sameCount: 19,
          highestRank: 134,
          lowestRank: 152,
        },
        {
          maxScore: 689,
          minScore: 689,
          sameCount: 14,
          highestRank: 153,
          lowestRank: 166,
        },
        {
          maxScore: 688,
          minScore: 688,
          sameCount: 16,
          highestRank: 167,
          lowestRank: 182,
        },
        {
          maxScore: 687,
          minScore: 687,
          sameCount: 27,
          highestRank: 183,
          lowestRank: 209,
        },
        {
          maxScore: 686,
          minScore: 686,
          sameCount: 19,
          highestRank: 210,
          lowestRank: 228,
        },
        {
          maxScore: 685,
          minScore: 685,
          sameCount: 25,
          highestRank: 229,
          lowestRank: 253,
        },
        {
          maxScore: 684,
          minScore: 684,
          sameCount: 19,
          highestRank: 254,
          lowestRank: 272,
        },
        {
          maxScore: 683,
          minScore: 683,
          sameCount: 24,
          highestRank: 273,
          lowestRank: 296,
        },
        {
          maxScore: 682,
          minScore: 682,
          sameCount: 28,
          highestRank: 297,
          lowestRank: 324,
        },
        {
          maxScore: 681,
          minScore: 681,
          sameCount: 38,
          highestRank: 325,
          lowestRank: 362,
        },
        {
          maxScore: 680,
          minScore: 680,
          sameCount: 36,
          highestRank: 363,
          lowestRank: 398,
        },
        {
          maxScore: 679,
          minScore: 679,
          sameCount: 40,
          highestRank: 399,
          lowestRank: 438,
        },
        {
          maxScore: 678,
          minScore: 678,
          sameCount: 44,
          highestRank: 439,
          lowestRank: 482,
        },
        {
          maxScore: 677,
          minScore: 677,
          sameCount: 41,
          highestRank: 483,
          lowestRank: 523,
        },
        {
          maxScore: 676,
          minScore: 676,
          sameCount: 48,
          highestRank: 524,
          lowestRank: 571,
        },
        {
          maxScore: 675,
          minScore: 675,
          sameCount: 49,
          highestRank: 572,
          lowestRank: 620,
        },
        {
          maxScore: 674,
          minScore: 674,
          sameCount: 62,
          highestRank: 621,
          lowestRank: 682,
        },
        {
          maxScore: 673,
          minScore: 673,
          sameCount: 62,
          highestRank: 683,
          lowestRank: 744,
        },
        {
          maxScore: 672,
          minScore: 672,
          sameCount: 74,
          highestRank: 745,
          lowestRank: 818,
        },
        {
          maxScore: 671,
          minScore: 671,
          sameCount: 54,
          highestRank: 819,
          lowestRank: 872,
        },
        {
          maxScore: 670,
          minScore: 670,
          sameCount: 72,
          highestRank: 873,
          lowestRank: 944,
        },
        {
          maxScore: 669,
          minScore: 669,
          sameCount: 84,
          highestRank: 945,
          lowestRank: 1028,
        },
        {
          maxScore: 668,
          minScore: 668,
          sameCount: 70,
          highestRank: 1029,
          lowestRank: 1098,
        },
        {
          maxScore: 667,
          minScore: 667,
          sameCount: 74,
          highestRank: 1099,
          lowestRank: 1172,
        },
        {
          maxScore: 666,
          minScore: 666,
          sameCount: 70,
          highestRank: 1173,
          lowestRank: 1242,
        },
        {
          maxScore: 665,
          minScore: 665,
          sameCount: 88,
          highestRank: 1243,
          lowestRank: 1330,
        },
        {
          maxScore: 664,
          minScore: 664,
          sameCount: 97,
          highestRank: 1331,
          lowestRank: 1427,
        },
        {
          maxScore: 663,
          minScore: 663,
          sameCount: 107,
          highestRank: 1428,
          lowestRank: 1534,
        },
        {
          maxScore: 662,
          minScore: 662,
          sameCount: 115,
          highestRank: 1535,
          lowestRank: 1649,
        },
        {
          maxScore: 661,
          minScore: 661,
          sameCount: 103,
          highestRank: 1650,
          lowestRank: 1752,
        },
        {
          maxScore: 660,
          minScore: 660,
          sameCount: 131,
          highestRank: 1753,
          lowestRank: 1883,
        },
        {
          maxScore: 659,
          minScore: 659,
          sameCount: 114,
          highestRank: 1884,
          lowestRank: 1997,
        },
        {
          maxScore: 658,
          minScore: 658,
          sameCount: 136,
          highestRank: 1998,
          lowestRank: 2133,
        },
        {
          maxScore: 657,
          minScore: 657,
          sameCount: 157,
          highestRank: 2134,
          lowestRank: 2290,
        },
        {
          maxScore: 656,
          minScore: 656,
          sameCount: 150,
          highestRank: 2291,
          lowestRank: 2440,
        },
        {
          maxScore: 655,
          minScore: 655,
          sameCount: 138,
          highestRank: 2441,
          lowestRank: 2578,
        },
        {
          maxScore: 654,
          minScore: 654,
          sameCount: 155,
          highestRank: 2579,
          lowestRank: 2733,
        },
        {
          maxScore: 653,
          minScore: 653,
          sameCount: 147,
          highestRank: 2734,
          lowestRank: 2880,
        },
        {
          maxScore: 652,
          minScore: 652,
          sameCount: 164,
          highestRank: 2881,
          lowestRank: 3044,
        },
        {
          maxScore: 651,
          minScore: 651,
          sameCount: 177,
          highestRank: 3045,
          lowestRank: 3221,
        },
        {
          maxScore: 650,
          minScore: 650,
          sameCount: 165,
          highestRank: 3222,
          lowestRank: 3386,
        },
        {
          maxScore: 649,
          minScore: 649,
          sameCount: 179,
          highestRank: 3387,
          lowestRank: 3565,
        },
        {
          maxScore: 648,
          minScore: 648,
          sameCount: 161,
          highestRank: 3566,
          lowestRank: 3726,
        },
        {
          maxScore: 647,
          minScore: 647,
          sameCount: 191,
          highestRank: 3727,
          lowestRank: 3917,
        },
        {
          maxScore: 646,
          minScore: 646,
          sameCount: 201,
          highestRank: 3918,
          lowestRank: 4118,
        },
        {
          maxScore: 645,
          minScore: 645,
          sameCount: 195,
          highestRank: 4119,
          lowestRank: 4313,
        },
        {
          maxScore: 644,
          minScore: 644,
          sameCount: 201,
          highestRank: 4314,
          lowestRank: 4514,
        },
        {
          maxScore: 643,
          minScore: 643,
          sameCount: 231,
          highestRank: 4515,
          lowestRank: 4745,
        },
        {
          maxScore: 642,
          minScore: 642,
          sameCount: 212,
          highestRank: 4746,
          lowestRank: 4957,
        },
        {
          maxScore: 641,
          minScore: 641,
          sameCount: 241,
          highestRank: 4958,
          lowestRank: 5198,
        },
        {
          maxScore: 640,
          minScore: 640,
          sameCount: 231,
          highestRank: 5199,
          lowestRank: 5429,
        },
        {
          maxScore: 639,
          minScore: 639,
          sameCount: 265,
          highestRank: 5430,
          lowestRank: 5694,
        },
        {
          maxScore: 638,
          minScore: 638,
          sameCount: 273,
          highestRank: 5695,
          lowestRank: 5967,
        },
        {
          maxScore: 637,
          minScore: 637,
          sameCount: 254,
          highestRank: 5968,
          lowestRank: 6221,
        },
        {
          maxScore: 636,
          minScore: 636,
          sameCount: 255,
          highestRank: 6222,
          lowestRank: 6476,
        },
        {
          maxScore: 635,
          minScore: 635,
          sameCount: 293,
          highestRank: 6477,
          lowestRank: 6769,
        },
        {
          maxScore: 634,
          minScore: 634,
          sameCount: 294,
          highestRank: 6770,
          lowestRank: 7063,
        },
        {
          maxScore: 633,
          minScore: 633,
          sameCount: 278,
          highestRank: 7064,
          lowestRank: 7341,
        },
        {
          maxScore: 632,
          minScore: 632,
          sameCount: 290,
          highestRank: 7342,
          lowestRank: 7631,
        },
        {
          maxScore: 631,
          minScore: 631,
          sameCount: 294,
          highestRank: 7632,
          lowestRank: 7925,
        },
        {
          maxScore: 630,
          minScore: 630,
          sameCount: 332,
          highestRank: 7926,
          lowestRank: 8257,
        },
        {
          maxScore: 629,
          minScore: 629,
          sameCount: 316,
          highestRank: 8258,
          lowestRank: 8573,
        },
        {
          maxScore: 628,
          minScore: 628,
          sameCount: 287,
          highestRank: 8574,
          lowestRank: 8860,
        },
        {
          maxScore: 627,
          minScore: 627,
          sameCount: 347,
          highestRank: 8861,
          lowestRank: 9207,
        },
        {
          maxScore: 626,
          minScore: 626,
          sameCount: 365,
          highestRank: 9208,
          lowestRank: 9572,
        },
        {
          maxScore: 625,
          minScore: 625,
          sameCount: 364,
          highestRank: 9573,
          lowestRank: 9936,
        },
        {
          maxScore: 624,
          minScore: 624,
          sameCount: 359,
          highestRank: 9937,
          lowestRank: 10295,
        },
        {
          maxScore: 623,
          minScore: 623,
          sameCount: 391,
          highestRank: 10296,
          lowestRank: 10686,
        },
        {
          maxScore: 622,
          minScore: 622,
          sameCount: 387,
          highestRank: 10687,
          lowestRank: 11073,
        },
        {
          maxScore: 621,
          minScore: 621,
          sameCount: 396,
          highestRank: 11074,
          lowestRank: 11469,
        },
        {
          maxScore: 620,
          minScore: 620,
          sameCount: 406,
          highestRank: 11470,
          lowestRank: 11875,
        },
        {
          maxScore: 619,
          minScore: 619,
          sameCount: 440,
          highestRank: 11876,
          lowestRank: 12315,
        },
        {
          maxScore: 618,
          minScore: 618,
          sameCount: 437,
          highestRank: 12316,
          lowestRank: 12752,
        },
        {
          maxScore: 617,
          minScore: 617,
          sameCount: 430,
          highestRank: 12753,
          lowestRank: 13182,
        },
        {
          maxScore: 616,
          minScore: 616,
          sameCount: 421,
          highestRank: 13183,
          lowestRank: 13603,
        },
        {
          maxScore: 615,
          minScore: 615,
          sameCount: 492,
          highestRank: 13604,
          lowestRank: 14095,
        },
        {
          maxScore: 614,
          minScore: 614,
          sameCount: 474,
          highestRank: 14096,
          lowestRank: 14569,
        },
        {
          maxScore: 613,
          minScore: 613,
          sameCount: 431,
          highestRank: 14570,
          lowestRank: 15000,
        },
        {
          maxScore: 612,
          minScore: 612,
          sameCount: 500,
          highestRank: 15001,
          lowestRank: 15500,
        },
        {
          maxScore: 611,
          minScore: 611,
          sameCount: 513,
          highestRank: 15501,
          lowestRank: 16013,
        },
        {
          maxScore: 610,
          minScore: 610,
          sameCount: 502,
          highestRank: 16014,
          lowestRank: 16515,
        },
        {
          maxScore: 609,
          minScore: 609,
          sameCount: 540,
          highestRank: 16516,
          lowestRank: 17055,
        },
        {
          maxScore: 608,
          minScore: 608,
          sameCount: 550,
          highestRank: 17056,
          lowestRank: 17605,
        },
        {
          maxScore: 607,
          minScore: 607,
          sameCount: 553,
          highestRank: 17606,
          lowestRank: 18158,
        },
        {
          maxScore: 606,
          minScore: 606,
          sameCount: 555,
          highestRank: 18159,
          lowestRank: 18713,
        },
        {
          maxScore: 605,
          minScore: 605,
          sameCount: 576,
          highestRank: 18714,
          lowestRank: 19289,
        },
        {
          maxScore: 604,
          minScore: 604,
          sameCount: 656,
          highestRank: 19290,
          lowestRank: 19945,
        },
        {
          maxScore: 603,
          minScore: 603,
          sameCount: 623,
          highestRank: 19946,
          lowestRank: 20568,
        },
        {
          maxScore: 602,
          minScore: 602,
          sameCount: 631,
          highestRank: 20569,
          lowestRank: 21199,
        },
        {
          maxScore: 601,
          minScore: 601,
          sameCount: 659,
          highestRank: 21200,
          lowestRank: 21858,
        },
        {
          maxScore: 600,
          minScore: 600,
          sameCount: 690,
          highestRank: 21859,
          lowestRank: 22548,
        },
        {
          maxScore: 599,
          minScore: 599,
          sameCount: 653,
          highestRank: 22549,
          lowestRank: 23201,
        },
        {
          maxScore: 598,
          minScore: 598,
          sameCount: 688,
          highestRank: 23202,
          lowestRank: 23889,
        },
        {
          maxScore: 597,
          minScore: 597,
          sameCount: 706,
          highestRank: 23890,
          lowestRank: 24595,
        },
        {
          maxScore: 596,
          minScore: 596,
          sameCount: 705,
          highestRank: 24596,
          lowestRank: 25300,
        },
        {
          maxScore: 595,
          minScore: 595,
          sameCount: 745,
          highestRank: 25301,
          lowestRank: 26045,
        },
        {
          maxScore: 594,
          minScore: 594,
          sameCount: 733,
          highestRank: 26046,
          lowestRank: 26778,
        },
        {
          maxScore: 593,
          minScore: 593,
          sameCount: 749,
          highestRank: 26779,
          lowestRank: 27527,
        },
        {
          maxScore: 592,
          minScore: 592,
          sameCount: 812,
          highestRank: 27528,
          lowestRank: 28339,
        },
        {
          maxScore: 591,
          minScore: 591,
          sameCount: 810,
          highestRank: 28340,
          lowestRank: 29149,
        },
        {
          maxScore: 590,
          minScore: 590,
          sameCount: 782,
          highestRank: 29150,
          lowestRank: 29931,
        },
        {
          maxScore: 589,
          minScore: 589,
          sameCount: 793,
          highestRank: 29932,
          lowestRank: 30724,
        },
        {
          maxScore: 588,
          minScore: 588,
          sameCount: 791,
          highestRank: 30725,
          lowestRank: 31515,
        },
        {
          maxScore: 587,
          minScore: 587,
          sameCount: 867,
          highestRank: 31516,
          lowestRank: 32382,
        },
        {
          maxScore: 586,
          minScore: 586,
          sameCount: 845,
          highestRank: 32383,
          lowestRank: 33227,
        },
        {
          maxScore: 585,
          minScore: 585,
          sameCount: 889,
          highestRank: 33228,
          lowestRank: 34116,
        },
        {
          maxScore: 584,
          minScore: 584,
          sameCount: 883,
          highestRank: 34117,
          lowestRank: 34999,
        },
        {
          maxScore: 583,
          minScore: 583,
          sameCount: 899,
          highestRank: 35000,
          lowestRank: 35898,
        },
        {
          maxScore: 582,
          minScore: 582,
          sameCount: 967,
          highestRank: 35899,
          lowestRank: 36865,
        },
        {
          maxScore: 581,
          minScore: 581,
          sameCount: 951,
          highestRank: 36866,
          lowestRank: 37816,
        },
        {
          maxScore: 580,
          minScore: 580,
          sameCount: 979,
          highestRank: 37817,
          lowestRank: 38795,
        },
        {
          maxScore: 579,
          minScore: 579,
          sameCount: 960,
          highestRank: 38796,
          lowestRank: 39755,
        },
        {
          maxScore: 578,
          minScore: 578,
          sameCount: 972,
          highestRank: 39756,
          lowestRank: 40727,
        },
        {
          maxScore: 577,
          minScore: 577,
          sameCount: 1030,
          highestRank: 40728,
          lowestRank: 41757,
        },
        {
          maxScore: 576,
          minScore: 576,
          sameCount: 1055,
          highestRank: 41758,
          lowestRank: 42812,
        },
        {
          maxScore: 575,
          minScore: 575,
          sameCount: 1070,
          highestRank: 42813,
          lowestRank: 43882,
        },
        {
          maxScore: 574,
          minScore: 574,
          sameCount: 1054,
          highestRank: 43883,
          lowestRank: 44936,
        },
        {
          maxScore: 573,
          minScore: 573,
          sameCount: 1145,
          highestRank: 44937,
          lowestRank: 46081,
        },
        {
          maxScore: 572,
          minScore: 572,
          sameCount: 1083,
          highestRank: 46082,
          lowestRank: 47164,
        },
        {
          maxScore: 571,
          minScore: 571,
          sameCount: 1145,
          highestRank: 47165,
          lowestRank: 48309,
        },
        {
          maxScore: 570,
          minScore: 570,
          sameCount: 1101,
          highestRank: 48310,
          lowestRank: 49410,
        },
        {
          maxScore: 569,
          minScore: 569,
          sameCount: 1109,
          highestRank: 49411,
          lowestRank: 50519,
        },
        {
          maxScore: 568,
          minScore: 568,
          sameCount: 1126,
          highestRank: 50520,
          lowestRank: 51645,
        },
        {
          maxScore: 567,
          minScore: 567,
          sameCount: 1271,
          highestRank: 51646,
          lowestRank: 52916,
        },
        {
          maxScore: 566,
          minScore: 566,
          sameCount: 1231,
          highestRank: 52917,
          lowestRank: 54147,
        },
        {
          maxScore: 565,
          minScore: 565,
          sameCount: 1235,
          highestRank: 54148,
          lowestRank: 55382,
        },
        {
          maxScore: 564,
          minScore: 564,
          sameCount: 1285,
          highestRank: 55383,
          lowestRank: 56667,
        },
        {
          maxScore: 563,
          minScore: 563,
          sameCount: 1288,
          highestRank: 56668,
          lowestRank: 57955,
        },
        {
          maxScore: 562,
          minScore: 562,
          sameCount: 1229,
          highestRank: 57956,
          lowestRank: 59184,
        },
        {
          maxScore: 561,
          minScore: 561,
          sameCount: 1316,
          highestRank: 59185,
          lowestRank: 60500,
        },
        {
          maxScore: 560,
          minScore: 560,
          sameCount: 1368,
          highestRank: 60501,
          lowestRank: 61868,
        },
        {
          maxScore: 559,
          minScore: 559,
          sameCount: 1366,
          highestRank: 61869,
          lowestRank: 63234,
        },
        {
          maxScore: 558,
          minScore: 558,
          sameCount: 1322,
          highestRank: 63235,
          lowestRank: 64556,
        },
        {
          maxScore: 557,
          minScore: 557,
          sameCount: 1386,
          highestRank: 64557,
          lowestRank: 65942,
        },
        {
          maxScore: 556,
          minScore: 556,
          sameCount: 1461,
          highestRank: 65943,
          lowestRank: 67403,
        },
        {
          maxScore: 555,
          minScore: 555,
          sameCount: 1458,
          highestRank: 67404,
          lowestRank: 68861,
        },
        {
          maxScore: 554,
          minScore: 554,
          sameCount: 1465,
          highestRank: 68862,
          lowestRank: 70326,
        },
        {
          maxScore: 553,
          minScore: 553,
          sameCount: 1529,
          highestRank: 70327,
          lowestRank: 71855,
        },
        {
          maxScore: 552,
          minScore: 552,
          sameCount: 1560,
          highestRank: 71856,
          lowestRank: 73415,
        },
        {
          maxScore: 551,
          minScore: 551,
          sameCount: 1482,
          highestRank: 73416,
          lowestRank: 74897,
        },
        {
          maxScore: 550,
          minScore: 550,
          sameCount: 1638,
          highestRank: 74898,
          lowestRank: 76535,
        },
        {
          maxScore: 549,
          minScore: 549,
          sameCount: 1574,
          highestRank: 76536,
          lowestRank: 78109,
        },
        {
          maxScore: 548,
          minScore: 548,
          sameCount: 1649,
          highestRank: 78110,
          lowestRank: 79758,
        },
        {
          maxScore: 547,
          minScore: 547,
          sameCount: 1667,
          highestRank: 79759,
          lowestRank: 81425,
        },
        {
          maxScore: 546,
          minScore: 546,
          sameCount: 1668,
          highestRank: 81426,
          lowestRank: 83093,
        },
        {
          maxScore: 545,
          minScore: 545,
          sameCount: 1688,
          highestRank: 83094,
          lowestRank: 84781,
        },
        {
          maxScore: 544,
          minScore: 544,
          sameCount: 1652,
          highestRank: 84782,
          lowestRank: 86433,
        },
        {
          maxScore: 543,
          minScore: 543,
          sameCount: 1695,
          highestRank: 86434,
          lowestRank: 88128,
        },
        {
          maxScore: 542,
          minScore: 542,
          sameCount: 1744,
          highestRank: 88129,
          lowestRank: 89872,
        },
        {
          maxScore: 541,
          minScore: 541,
          sameCount: 1755,
          highestRank: 89873,
          lowestRank: 91627,
        },
        {
          maxScore: 540,
          minScore: 540,
          sameCount: 1747,
          highestRank: 91628,
          lowestRank: 93374,
        },
        {
          maxScore: 539,
          minScore: 539,
          sameCount: 1788,
          highestRank: 93375,
          lowestRank: 95162,
        },
        {
          maxScore: 538,
          minScore: 538,
          sameCount: 1827,
          highestRank: 95163,
          lowestRank: 96989,
        },
        {
          maxScore: 537,
          minScore: 537,
          sameCount: 1868,
          highestRank: 96990,
          lowestRank: 98857,
        },
        {
          maxScore: 536,
          minScore: 536,
          sameCount: 1865,
          highestRank: 98858,
          lowestRank: 100722,
        },
        {
          maxScore: 535,
          minScore: 535,
          sameCount: 1879,
          highestRank: 100723,
          lowestRank: 102601,
        },
        {
          maxScore: 534,
          minScore: 534,
          sameCount: 1920,
          highestRank: 102602,
          lowestRank: 104521,
        },
        {
          maxScore: 533,
          minScore: 533,
          sameCount: 1903,
          highestRank: 104522,
          lowestRank: 106424,
        },
        {
          maxScore: 532,
          minScore: 532,
          sameCount: 1962,
          highestRank: 106425,
          lowestRank: 108386,
        },
        {
          maxScore: 531,
          minScore: 531,
          sameCount: 1891,
          highestRank: 108387,
          lowestRank: 110277,
        },
        {
          maxScore: 530,
          minScore: 530,
          sameCount: 2029,
          highestRank: 110278,
          lowestRank: 112306,
        },
        {
          maxScore: 529,
          minScore: 529,
          sameCount: 2036,
          highestRank: 112307,
          lowestRank: 114342,
        },
        {
          maxScore: 528,
          minScore: 528,
          sameCount: 2097,
          highestRank: 114343,
          lowestRank: 116439,
        },
        {
          maxScore: 527,
          minScore: 527,
          sameCount: 2049,
          highestRank: 116440,
          lowestRank: 118488,
        },
        {
          maxScore: 526,
          minScore: 526,
          sameCount: 2075,
          highestRank: 118489,
          lowestRank: 120563,
        },
        {
          maxScore: 525,
          minScore: 525,
          sameCount: 2103,
          highestRank: 120564,
          lowestRank: 122666,
        },
        {
          maxScore: 524,
          minScore: 524,
          sameCount: 2191,
          highestRank: 122667,
          lowestRank: 124857,
        },
        {
          maxScore: 523,
          minScore: 523,
          sameCount: 2083,
          highestRank: 124858,
          lowestRank: 126940,
        },
        {
          maxScore: 522,
          minScore: 522,
          sameCount: 2098,
          highestRank: 126941,
          lowestRank: 129038,
        },
        {
          maxScore: 521,
          minScore: 521,
          sameCount: 2193,
          highestRank: 129039,
          lowestRank: 131231,
        },
        {
          maxScore: 520,
          minScore: 520,
          sameCount: 2138,
          highestRank: 131232,
          lowestRank: 133369,
        },
        {
          maxScore: 519,
          minScore: 519,
          sameCount: 2182,
          highestRank: 133370,
          lowestRank: 135551,
        },
        {
          maxScore: 518,
          minScore: 518,
          sameCount: 2241,
          highestRank: 135552,
          lowestRank: 137792,
        },
        {
          maxScore: 517,
          minScore: 517,
          sameCount: 2249,
          highestRank: 137793,
          lowestRank: 140041,
        },
        {
          maxScore: 516,
          minScore: 516,
          sameCount: 2302,
          highestRank: 140042,
          lowestRank: 142343,
        },
        {
          maxScore: 515,
          minScore: 515,
          sameCount: 2333,
          highestRank: 142344,
          lowestRank: 144676,
        },
        {
          maxScore: 514,
          minScore: 514,
          sameCount: 2294,
          highestRank: 144677,
          lowestRank: 146970,
        },
        {
          maxScore: 513,
          minScore: 513,
          sameCount: 2330,
          highestRank: 146971,
          lowestRank: 149300,
        },
        {
          maxScore: 512,
          minScore: 512,
          sameCount: 2237,
          highestRank: 149301,
          lowestRank: 151537,
        },
        {
          maxScore: 511,
          minScore: 511,
          sameCount: 2292,
          highestRank: 151538,
          lowestRank: 153829,
        },
        {
          maxScore: 510,
          minScore: 510,
          sameCount: 2310,
          highestRank: 153830,
          lowestRank: 156139,
        },
        {
          maxScore: 509,
          minScore: 509,
          sameCount: 2345,
          highestRank: 156140,
          lowestRank: 158484,
        },
        {
          maxScore: 508,
          minScore: 508,
          sameCount: 2387,
          highestRank: 158485,
          lowestRank: 160871,
        },
        {
          maxScore: 507,
          minScore: 507,
          sameCount: 2397,
          highestRank: 160872,
          lowestRank: 163268,
        },
        {
          maxScore: 506,
          minScore: 506,
          sameCount: 2390,
          highestRank: 163269,
          lowestRank: 165658,
        },
        {
          maxScore: 505,
          minScore: 505,
          sameCount: 2424,
          highestRank: 165659,
          lowestRank: 168082,
        },
        {
          maxScore: 504,
          minScore: 504,
          sameCount: 2411,
          highestRank: 168083,
          lowestRank: 170493,
        },
        {
          maxScore: 503,
          minScore: 503,
          sameCount: 2426,
          highestRank: 170494,
          lowestRank: 172919,
        },
        {
          maxScore: 502,
          minScore: 502,
          sameCount: 2408,
          highestRank: 172920,
          lowestRank: 175327,
        },
        {
          maxScore: 501,
          minScore: 501,
          sameCount: 2427,
          highestRank: 175328,
          lowestRank: 177754,
        },
        {
          maxScore: 500,
          minScore: 500,
          sameCount: 2478,
          highestRank: 177755,
          lowestRank: 180232,
        },
        {
          maxScore: 499,
          minScore: 499,
          sameCount: 2472,
          highestRank: 180233,
          lowestRank: 182704,
        },
        {
          maxScore: 498,
          minScore: 498,
          sameCount: 2373,
          highestRank: 182705,
          lowestRank: 185077,
        },
        {
          maxScore: 497,
          minScore: 497,
          sameCount: 2554,
          highestRank: 185078,
          lowestRank: 187631,
        },
        {
          maxScore: 496,
          minScore: 496,
          sameCount: 2492,
          highestRank: 187632,
          lowestRank: 190123,
        },
        {
          maxScore: 495,
          minScore: 495,
          sameCount: 2473,
          highestRank: 190124,
          lowestRank: 192596,
        },
        {
          maxScore: 494,
          minScore: 494,
          sameCount: 2526,
          highestRank: 192597,
          lowestRank: 195122,
        },
        {
          maxScore: 493,
          minScore: 493,
          sameCount: 2636,
          highestRank: 195123,
          lowestRank: 197758,
        },
        {
          maxScore: 492,
          minScore: 492,
          sameCount: 2557,
          highestRank: 197759,
          lowestRank: 200315,
        },
        {
          maxScore: 491,
          minScore: 491,
          sameCount: 2567,
          highestRank: 200316,
          lowestRank: 202882,
        },
        {
          maxScore: 490,
          minScore: 490,
          sameCount: 2505,
          highestRank: 202883,
          lowestRank: 205387,
        },
        {
          maxScore: 489,
          minScore: 489,
          sameCount: 2499,
          highestRank: 205388,
          lowestRank: 207886,
        },
        {
          maxScore: 488,
          minScore: 488,
          sameCount: 2569,
          highestRank: 207887,
          lowestRank: 210455,
        },
        {
          maxScore: 487,
          minScore: 487,
          sameCount: 2466,
          highestRank: 210456,
          lowestRank: 212921,
        },
        {
          maxScore: 486,
          minScore: 486,
          sameCount: 2554,
          highestRank: 212922,
          lowestRank: 215475,
        },
        {
          maxScore: 485,
          minScore: 485,
          sameCount: 2512,
          highestRank: 215476,
          lowestRank: 217987,
        },
        {
          maxScore: 484,
          minScore: 484,
          sameCount: 2545,
          highestRank: 217988,
          lowestRank: 220532,
        },
        {
          maxScore: 483,
          minScore: 483,
          sameCount: 2541,
          highestRank: 220533,
          lowestRank: 223073,
        },
        {
          maxScore: 482,
          minScore: 482,
          sameCount: 2535,
          highestRank: 223074,
          lowestRank: 225608,
        },
        {
          maxScore: 481,
          minScore: 481,
          sameCount: 2522,
          highestRank: 225609,
          lowestRank: 228130,
        },
        {
          maxScore: 480,
          minScore: 480,
          sameCount: 2558,
          highestRank: 228131,
          lowestRank: 230688,
        },
        {
          maxScore: 479,
          minScore: 479,
          sameCount: 2519,
          highestRank: 230689,
          lowestRank: 233207,
        },
        {
          maxScore: 478,
          minScore: 478,
          sameCount: 2611,
          highestRank: 233208,
          lowestRank: 235818,
        },
        {
          maxScore: 477,
          minScore: 477,
          sameCount: 2575,
          highestRank: 235819,
          lowestRank: 238393,
        },
        {
          maxScore: 476,
          minScore: 476,
          sameCount: 2449,
          highestRank: 238394,
          lowestRank: 240842,
        },
        {
          maxScore: 475,
          minScore: 475,
          sameCount: 2496,
          highestRank: 240843,
          lowestRank: 243338,
        },
        {
          maxScore: 474,
          minScore: 474,
          sameCount: 2520,
          highestRank: 243339,
          lowestRank: 245858,
        },
        {
          maxScore: 473,
          minScore: 473,
          sameCount: 2559,
          highestRank: 245859,
          lowestRank: 248417,
        },
        {
          maxScore: 472,
          minScore: 472,
          sameCount: 2527,
          highestRank: 248418,
          lowestRank: 250944,
        },
        {
          maxScore: 471,
          minScore: 471,
          sameCount: 2335,
          highestRank: 250945,
          lowestRank: 253279,
        },
        {
          maxScore: 470,
          minScore: 470,
          sameCount: 2502,
          highestRank: 253280,
          lowestRank: 255781,
        },
        {
          maxScore: 469,
          minScore: 469,
          sameCount: 2442,
          highestRank: 255782,
          lowestRank: 258223,
        },
        {
          maxScore: 468,
          minScore: 468,
          sameCount: 2500,
          highestRank: 258224,
          lowestRank: 260723,
        },
        {
          maxScore: 467,
          minScore: 467,
          sameCount: 2567,
          highestRank: 260724,
          lowestRank: 263290,
        },
        {
          maxScore: 466,
          minScore: 466,
          sameCount: 2465,
          highestRank: 263291,
          lowestRank: 265755,
        },
        {
          maxScore: 465,
          minScore: 465,
          sameCount: 2504,
          highestRank: 265756,
          lowestRank: 268259,
        },
        {
          maxScore: 464,
          minScore: 464,
          sameCount: 2510,
          highestRank: 268260,
          lowestRank: 270769,
        },
        {
          maxScore: 463,
          minScore: 463,
          sameCount: 2494,
          highestRank: 270770,
          lowestRank: 273263,
        },
        {
          maxScore: 462,
          minScore: 462,
          sameCount: 2444,
          highestRank: 273264,
          lowestRank: 275707,
        },
        {
          maxScore: 461,
          minScore: 461,
          sameCount: 2428,
          highestRank: 275708,
          lowestRank: 278135,
        },
        {
          maxScore: 460,
          minScore: 460,
          sameCount: 2454,
          highestRank: 278136,
          lowestRank: 280589,
        },
        {
          maxScore: 459,
          minScore: 459,
          sameCount: 2464,
          highestRank: 280590,
          lowestRank: 283053,
        },
        {
          maxScore: 458,
          minScore: 458,
          sameCount: 2433,
          highestRank: 283054,
          lowestRank: 285486,
        },
        {
          maxScore: 457,
          minScore: 457,
          sameCount: 2530,
          highestRank: 285487,
          lowestRank: 288016,
        },
        {
          maxScore: 456,
          minScore: 456,
          sameCount: 2383,
          highestRank: 288017,
          lowestRank: 290399,
        },
        {
          maxScore: 455,
          minScore: 455,
          sameCount: 2335,
          highestRank: 290400,
          lowestRank: 292734,
        },
        {
          maxScore: 454,
          minScore: 454,
          sameCount: 2402,
          highestRank: 292735,
          lowestRank: 295136,
        },
        {
          maxScore: 453,
          minScore: 453,
          sameCount: 2410,
          highestRank: 295137,
          lowestRank: 297546,
        },
        {
          maxScore: 452,
          minScore: 452,
          sameCount: 2404,
          highestRank: 297547,
          lowestRank: 299950,
        },
        {
          maxScore: 451,
          minScore: 451,
          sameCount: 2349,
          highestRank: 299951,
          lowestRank: 302299,
        },
        {
          maxScore: 450,
          minScore: 450,
          sameCount: 2391,
          highestRank: 302300,
          lowestRank: 304690,
        },
        {
          maxScore: 449,
          minScore: 449,
          sameCount: 2361,
          highestRank: 304691,
          lowestRank: 307051,
        },
        {
          maxScore: 448,
          minScore: 448,
          sameCount: 2445,
          highestRank: 307052,
          lowestRank: 309496,
        },
        {
          maxScore: 447,
          minScore: 447,
          sameCount: 2366,
          highestRank: 309497,
          lowestRank: 311862,
        },
        {
          maxScore: 446,
          minScore: 446,
          sameCount: 2365,
          highestRank: 311863,
          lowestRank: 314227,
        },
        {
          maxScore: 445,
          minScore: 445,
          sameCount: 2370,
          highestRank: 314228,
          lowestRank: 316597,
        },
        {
          maxScore: 444,
          minScore: 444,
          sameCount: 2338,
          highestRank: 316598,
          lowestRank: 318935,
        },
        {
          maxScore: 443,
          minScore: 443,
          sameCount: 2414,
          highestRank: 318936,
          lowestRank: 321349,
        },
        {
          maxScore: 442,
          minScore: 442,
          sameCount: 2308,
          highestRank: 321350,
          lowestRank: 323657,
        },
        {
          maxScore: 441,
          minScore: 441,
          sameCount: 2271,
          highestRank: 323658,
          lowestRank: 325928,
        },
        {
          maxScore: 440,
          minScore: 440,
          sameCount: 2201,
          highestRank: 325929,
          lowestRank: 328129,
        },
        {
          maxScore: 439,
          minScore: 439,
          sameCount: 2314,
          highestRank: 328130,
          lowestRank: 330443,
        },
        {
          maxScore: 438,
          minScore: 438,
          sameCount: 2363,
          highestRank: 330444,
          lowestRank: 332806,
        },
        {
          maxScore: 437,
          minScore: 437,
          sameCount: 2322,
          highestRank: 332807,
          lowestRank: 335128,
        },
        {
          maxScore: 436,
          minScore: 436,
          sameCount: 2276,
          highestRank: 335129,
          lowestRank: 337404,
        },
        {
          maxScore: 435,
          minScore: 435,
          sameCount: 2212,
          highestRank: 337405,
          lowestRank: 339616,
        },
        {
          maxScore: 434,
          minScore: 434,
          sameCount: 2263,
          highestRank: 339617,
          lowestRank: 341879,
        },
        {
          maxScore: 433,
          minScore: 433,
          sameCount: 2120,
          highestRank: 341880,
          lowestRank: 343999,
        },
        {
          maxScore: 432,
          minScore: 432,
          sameCount: 2173,
          highestRank: 344000,
          lowestRank: 346172,
        },
        {
          maxScore: 431,
          minScore: 431,
          sameCount: 2230,
          highestRank: 346173,
          lowestRank: 348402,
        },
        {
          maxScore: 430,
          minScore: 430,
          sameCount: 2365,
          highestRank: 348403,
          lowestRank: 350767,
        },
        {
          maxScore: 429,
          minScore: 429,
          sameCount: 2228,
          highestRank: 350768,
          lowestRank: 352995,
        },
        {
          maxScore: 428,
          minScore: 428,
          sameCount: 2237,
          highestRank: 352996,
          lowestRank: 355232,
        },
        {
          maxScore: 427,
          minScore: 427,
          sameCount: 2163,
          highestRank: 355233,
          lowestRank: 357395,
        },
        {
          maxScore: 426,
          minScore: 426,
          sameCount: 2143,
          highestRank: 357396,
          lowestRank: 359538,
        },
        {
          maxScore: 425,
          minScore: 425,
          sameCount: 2218,
          highestRank: 359539,
          lowestRank: 361756,
        },
        {
          maxScore: 424,
          minScore: 424,
          sameCount: 2193,
          highestRank: 361757,
          lowestRank: 363949,
        },
        {
          maxScore: 423,
          minScore: 423,
          sameCount: 2205,
          highestRank: 363950,
          lowestRank: 366154,
        },
        {
          maxScore: 422,
          minScore: 422,
          sameCount: 2149,
          highestRank: 366155,
          lowestRank: 368303,
        },
        {
          maxScore: 421,
          minScore: 421,
          sameCount: 2173,
          highestRank: 368304,
          lowestRank: 370476,
        },
        {
          maxScore: 420,
          minScore: 420,
          sameCount: 2154,
          highestRank: 370477,
          lowestRank: 372630,
        },
        {
          maxScore: 419,
          minScore: 419,
          sameCount: 2140,
          highestRank: 372631,
          lowestRank: 374770,
        },
        {
          maxScore: 418,
          minScore: 418,
          sameCount: 2155,
          highestRank: 374771,
          lowestRank: 376925,
        },
        {
          maxScore: 417,
          minScore: 417,
          sameCount: 2172,
          highestRank: 376926,
          lowestRank: 379097,
        },
        {
          maxScore: 416,
          minScore: 416,
          sameCount: 2131,
          highestRank: 379098,
          lowestRank: 381228,
        },
        {
          maxScore: 415,
          minScore: 415,
          sameCount: 2180,
          highestRank: 381229,
          lowestRank: 383408,
        },
        {
          maxScore: 414,
          minScore: 414,
          sameCount: 2107,
          highestRank: 383409,
          lowestRank: 385515,
        },
        {
          maxScore: 413,
          minScore: 413,
          sameCount: 2111,
          highestRank: 385516,
          lowestRank: 387626,
        },
        {
          maxScore: 412,
          minScore: 412,
          sameCount: 2123,
          highestRank: 387627,
          lowestRank: 389749,
        },
        {
          maxScore: 411,
          minScore: 411,
          sameCount: 2046,
          highestRank: 389750,
          lowestRank: 391795,
        },
        {
          maxScore: 410,
          minScore: 410,
          sameCount: 2122,
          highestRank: 391796,
          lowestRank: 393917,
        },
        {
          maxScore: 409,
          minScore: 409,
          sameCount: 2059,
          highestRank: 393918,
          lowestRank: 395976,
        },
        {
          maxScore: 408,
          minScore: 408,
          sameCount: 2136,
          highestRank: 395977,
          lowestRank: 398112,
        },
        {
          maxScore: 407,
          minScore: 407,
          sameCount: 2024,
          highestRank: 398113,
          lowestRank: 400136,
        },
        {
          maxScore: 406,
          minScore: 406,
          sameCount: 2054,
          highestRank: 400137,
          lowestRank: 402190,
        },
        {
          maxScore: 405,
          minScore: 405,
          sameCount: 2027,
          highestRank: 402191,
          lowestRank: 404217,
        },
        {
          maxScore: 404,
          minScore: 404,
          sameCount: 2021,
          highestRank: 404218,
          lowestRank: 406238,
        },
        {
          maxScore: 403,
          minScore: 403,
          sameCount: 2056,
          highestRank: 406239,
          lowestRank: 408294,
        },
        {
          maxScore: 402,
          minScore: 402,
          sameCount: 2018,
          highestRank: 408295,
          lowestRank: 410312,
        },
        {
          maxScore: 401,
          minScore: 401,
          sameCount: 1966,
          highestRank: 410313,
          lowestRank: 412278,
        },
        {
          maxScore: 400,
          minScore: 400,
          sameCount: 2136,
          highestRank: 412279,
          lowestRank: 414414,
        },
        {
          maxScore: 399,
          minScore: 399,
          sameCount: 2003,
          highestRank: 414415,
          lowestRank: 416417,
        },
        {
          maxScore: 398,
          minScore: 398,
          sameCount: 2000,
          highestRank: 416418,
          lowestRank: 418417,
        },
        {
          maxScore: 397,
          minScore: 397,
          sameCount: 2051,
          highestRank: 418418,
          lowestRank: 420468,
        },
        {
          maxScore: 396,
          minScore: 396,
          sameCount: 2126,
          highestRank: 420469,
          lowestRank: 422594,
        },
        {
          maxScore: 395,
          minScore: 395,
          sameCount: 2054,
          highestRank: 422595,
          lowestRank: 424648,
        },
        {
          maxScore: 394,
          minScore: 394,
          sameCount: 2006,
          highestRank: 424649,
          lowestRank: 426654,
        },
        {
          maxScore: 393,
          minScore: 393,
          sameCount: 1983,
          highestRank: 426655,
          lowestRank: 428637,
        },
        {
          maxScore: 392,
          minScore: 392,
          sameCount: 1963,
          highestRank: 428638,
          lowestRank: 430600,
        },
        {
          maxScore: 391,
          minScore: 391,
          sameCount: 2041,
          highestRank: 430601,
          lowestRank: 432641,
        },
        {
          maxScore: 390,
          minScore: 390,
          sameCount: 1944,
          highestRank: 432642,
          lowestRank: 434585,
        },
        {
          maxScore: 389,
          minScore: 389,
          sameCount: 1992,
          highestRank: 434586,
          lowestRank: 436577,
        },
        {
          maxScore: 388,
          minScore: 388,
          sameCount: 1977,
          highestRank: 436578,
          lowestRank: 438554,
        },
        {
          maxScore: 387,
          minScore: 387,
          sameCount: 2012,
          highestRank: 438555,
          lowestRank: 440566,
        },
        {
          maxScore: 386,
          minScore: 386,
          sameCount: 2038,
          highestRank: 440567,
          lowestRank: 442604,
        },
        {
          maxScore: 385,
          minScore: 385,
          sameCount: 1985,
          highestRank: 442605,
          lowestRank: 444589,
        },
        {
          maxScore: 384,
          minScore: 384,
          sameCount: 1974,
          highestRank: 444590,
          lowestRank: 446563,
        },
        {
          maxScore: 383,
          minScore: 383,
          sameCount: 1981,
          highestRank: 446564,
          lowestRank: 448544,
        },
        {
          maxScore: 382,
          minScore: 382,
          sameCount: 1966,
          highestRank: 448545,
          lowestRank: 450510,
        },
        {
          maxScore: 381,
          minScore: 381,
          sameCount: 1938,
          highestRank: 450511,
          lowestRank: 452448,
        },
        {
          maxScore: 380,
          minScore: 380,
          sameCount: 1984,
          highestRank: 452449,
          lowestRank: 454432,
        },
        {
          maxScore: 379,
          minScore: 379,
          sameCount: 1867,
          highestRank: 454433,
          lowestRank: 456299,
        },
        {
          maxScore: 378,
          minScore: 378,
          sameCount: 1939,
          highestRank: 456300,
          lowestRank: 458238,
        },
        {
          maxScore: 377,
          minScore: 377,
          sameCount: 1907,
          highestRank: 458239,
          lowestRank: 460145,
        },
        {
          maxScore: 376,
          minScore: 376,
          sameCount: 1955,
          highestRank: 460146,
          lowestRank: 462100,
        },
        {
          maxScore: 375,
          minScore: 375,
          sameCount: 1967,
          highestRank: 462101,
          lowestRank: 464067,
        },
        {
          maxScore: 374,
          minScore: 374,
          sameCount: 1993,
          highestRank: 464068,
          lowestRank: 466060,
        },
        {
          maxScore: 373,
          minScore: 373,
          sameCount: 1886,
          highestRank: 466061,
          lowestRank: 467946,
        },
        {
          maxScore: 372,
          minScore: 372,
          sameCount: 1922,
          highestRank: 467947,
          lowestRank: 469868,
        },
        {
          maxScore: 371,
          minScore: 371,
          sameCount: 1920,
          highestRank: 469869,
          lowestRank: 471788,
        },
        {
          maxScore: 370,
          minScore: 370,
          sameCount: 1972,
          highestRank: 471789,
          lowestRank: 473760,
        },
        {
          maxScore: 369,
          minScore: 369,
          sameCount: 1933,
          highestRank: 473761,
          lowestRank: 475693,
        },
        {
          maxScore: 368,
          minScore: 368,
          sameCount: 1861,
          highestRank: 475694,
          lowestRank: 477554,
        },
        {
          maxScore: 367,
          minScore: 367,
          sameCount: 1868,
          highestRank: 477555,
          lowestRank: 479422,
        },
        {
          maxScore: 366,
          minScore: 366,
          sameCount: 1929,
          highestRank: 479423,
          lowestRank: 481351,
        },
        {
          maxScore: 365,
          minScore: 365,
          sameCount: 1963,
          highestRank: 481352,
          lowestRank: 483314,
        },
        {
          maxScore: 364,
          minScore: 364,
          sameCount: 1876,
          highestRank: 483315,
          lowestRank: 485190,
        },
        {
          maxScore: 363,
          minScore: 363,
          sameCount: 1980,
          highestRank: 485191,
          lowestRank: 487170,
        },
        {
          maxScore: 362,
          minScore: 362,
          sameCount: 1911,
          highestRank: 487171,
          lowestRank: 489081,
        },
        {
          maxScore: 361,
          minScore: 361,
          sameCount: 1908,
          highestRank: 489082,
          lowestRank: 490989,
        },
        {
          maxScore: 360,
          minScore: 360,
          sameCount: 1918,
          highestRank: 490990,
          lowestRank: 492907,
        },
        {
          maxScore: 359,
          minScore: 359,
          sameCount: 1825,
          highestRank: 492908,
          lowestRank: 494732,
        },
        {
          maxScore: 358,
          minScore: 358,
          sameCount: 1815,
          highestRank: 494733,
          lowestRank: 496547,
        },
        {
          maxScore: 357,
          minScore: 357,
          sameCount: 1752,
          highestRank: 496548,
          lowestRank: 498299,
        },
        {
          maxScore: 356,
          minScore: 356,
          sameCount: 1836,
          highestRank: 498300,
          lowestRank: 500135,
        },
        {
          maxScore: 355,
          minScore: 355,
          sameCount: 1809,
          highestRank: 500136,
          lowestRank: 501944,
        },
        {
          maxScore: 354,
          minScore: 354,
          sameCount: 1820,
          highestRank: 501945,
          lowestRank: 503764,
        },
        {
          maxScore: 353,
          minScore: 353,
          sameCount: 1870,
          highestRank: 503765,
          lowestRank: 505634,
        },
        {
          maxScore: 352,
          minScore: 352,
          sameCount: 1869,
          highestRank: 505635,
          lowestRank: 507503,
        },
        {
          maxScore: 351,
          minScore: 351,
          sameCount: 1834,
          highestRank: 507504,
          lowestRank: 509337,
        },
        {
          maxScore: 350,
          minScore: 350,
          sameCount: 1835,
          highestRank: 509338,
          lowestRank: 511172,
        },
        {
          maxScore: 349,
          minScore: 349,
          sameCount: 1870,
          highestRank: 511173,
          lowestRank: 513042,
        },
        {
          maxScore: 348,
          minScore: 348,
          sameCount: 1815,
          highestRank: 513043,
          lowestRank: 514857,
        },
        {
          maxScore: 347,
          minScore: 347,
          sameCount: 1794,
          highestRank: 514858,
          lowestRank: 516651,
        },
        {
          maxScore: 346,
          minScore: 346,
          sameCount: 1811,
          highestRank: 516652,
          lowestRank: 518462,
        },
        {
          maxScore: 345,
          minScore: 345,
          sameCount: 1739,
          highestRank: 518463,
          lowestRank: 520201,
        },
        {
          maxScore: 344,
          minScore: 344,
          sameCount: 1720,
          highestRank: 520202,
          lowestRank: 521921,
        },
        {
          maxScore: 343,
          minScore: 343,
          sameCount: 1740,
          highestRank: 521922,
          lowestRank: 523661,
        },
        {
          maxScore: 342,
          minScore: 342,
          sameCount: 1718,
          highestRank: 523662,
          lowestRank: 525379,
        },
        {
          maxScore: 341,
          minScore: 341,
          sameCount: 1775,
          highestRank: 525380,
          lowestRank: 527154,
        },
        {
          maxScore: 340,
          minScore: 340,
          sameCount: 1703,
          highestRank: 527155,
          lowestRank: 528857,
        },
        {
          maxScore: 339,
          minScore: 339,
          sameCount: 1627,
          highestRank: 528858,
          lowestRank: 530484,
        },
        {
          maxScore: 338,
          minScore: 338,
          sameCount: 1724,
          highestRank: 530485,
          lowestRank: 532208,
        },
        {
          maxScore: 337,
          minScore: 337,
          sameCount: 1754,
          highestRank: 532209,
          lowestRank: 533962,
        },
        {
          maxScore: 336,
          minScore: 336,
          sameCount: 1749,
          highestRank: 533963,
          lowestRank: 535711,
        },
        {
          maxScore: 335,
          minScore: 335,
          sameCount: 1720,
          highestRank: 535712,
          lowestRank: 537431,
        },
        {
          maxScore: 334,
          minScore: 334,
          sameCount: 1734,
          highestRank: 537432,
          lowestRank: 539165,
        },
        {
          maxScore: 333,
          minScore: 333,
          sameCount: 1672,
          highestRank: 539166,
          lowestRank: 540837,
        },
        {
          maxScore: 332,
          minScore: 332,
          sameCount: 1639,
          highestRank: 540838,
          lowestRank: 542476,
        },
        {
          maxScore: 331,
          minScore: 331,
          sameCount: 1700,
          highestRank: 542477,
          lowestRank: 544176,
        },
        {
          maxScore: 330,
          minScore: 330,
          sameCount: 1618,
          highestRank: 544177,
          lowestRank: 545794,
        },
        {
          maxScore: 329,
          minScore: 329,
          sameCount: 1699,
          highestRank: 545795,
          lowestRank: 547493,
        },
        {
          maxScore: 328,
          minScore: 328,
          sameCount: 1674,
          highestRank: 547494,
          lowestRank: 549167,
        },
        {
          maxScore: 327,
          minScore: 327,
          sameCount: 1606,
          highestRank: 549168,
          lowestRank: 550773,
        },
        {
          maxScore: 326,
          minScore: 326,
          sameCount: 1646,
          highestRank: 550774,
          lowestRank: 552419,
        },
        {
          maxScore: 325,
          minScore: 325,
          sameCount: 1615,
          highestRank: 552420,
          lowestRank: 554034,
        },
        {
          maxScore: 324,
          minScore: 324,
          sameCount: 1551,
          highestRank: 554035,
          lowestRank: 555585,
        },
        {
          maxScore: 323,
          minScore: 323,
          sameCount: 1631,
          highestRank: 555586,
          lowestRank: 557216,
        },
        {
          maxScore: 322,
          minScore: 322,
          sameCount: 1571,
          highestRank: 557217,
          lowestRank: 558787,
        },
        {
          maxScore: 321,
          minScore: 321,
          sameCount: 1564,
          highestRank: 558788,
          lowestRank: 560351,
        },
        {
          maxScore: 320,
          minScore: 320,
          sameCount: 1564,
          highestRank: 560352,
          lowestRank: 561915,
        },
        {
          maxScore: 319,
          minScore: 319,
          sameCount: 1529,
          highestRank: 561916,
          lowestRank: 563444,
        },
        {
          maxScore: 318,
          minScore: 318,
          sameCount: 1558,
          highestRank: 563445,
          lowestRank: 565002,
        },
        {
          maxScore: 317,
          minScore: 317,
          sameCount: 1570,
          highestRank: 565003,
          lowestRank: 566572,
        },
        {
          maxScore: 316,
          minScore: 316,
          sameCount: 1473,
          highestRank: 566573,
          lowestRank: 568045,
        },
        {
          maxScore: 315,
          minScore: 315,
          sameCount: 1497,
          highestRank: 568046,
          lowestRank: 569542,
        },
        {
          maxScore: 314,
          minScore: 314,
          sameCount: 1511,
          highestRank: 569543,
          lowestRank: 571053,
        },
        {
          maxScore: 313,
          minScore: 313,
          sameCount: 1446,
          highestRank: 571054,
          lowestRank: 572499,
        },
        {
          maxScore: 312,
          minScore: 312,
          sameCount: 1462,
          highestRank: 572500,
          lowestRank: 573961,
        },
        {
          maxScore: 311,
          minScore: 311,
          sameCount: 1510,
          highestRank: 573962,
          lowestRank: 575471,
        },
        {
          maxScore: 310,
          minScore: 310,
          sameCount: 1417,
          highestRank: 575472,
          lowestRank: 576888,
        },
        {
          maxScore: 309,
          minScore: 309,
          sameCount: 1381,
          highestRank: 576889,
          lowestRank: 578269,
        },
        {
          maxScore: 308,
          minScore: 308,
          sameCount: 1368,
          highestRank: 578270,
          lowestRank: 579637,
        },
        {
          maxScore: 307,
          minScore: 307,
          sameCount: 1378,
          highestRank: 579638,
          lowestRank: 581015,
        },
        {
          maxScore: 306,
          minScore: 306,
          sameCount: 1435,
          highestRank: 581016,
          lowestRank: 582450,
        },
        {
          maxScore: 305,
          minScore: 305,
          sameCount: 1423,
          highestRank: 582451,
          lowestRank: 583873,
        },
        {
          maxScore: 304,
          minScore: 304,
          sameCount: 1349,
          highestRank: 583874,
          lowestRank: 585222,
        },
        {
          maxScore: 303,
          minScore: 303,
          sameCount: 1404,
          highestRank: 585223,
          lowestRank: 586626,
        },
        {
          maxScore: 302,
          minScore: 302,
          sameCount: 1336,
          highestRank: 586627,
          lowestRank: 587962,
        },
        {
          maxScore: 301,
          minScore: 301,
          sameCount: 1274,
          highestRank: 587963,
          lowestRank: 589236,
        },
        {
          maxScore: 300,
          minScore: 300,
          sameCount: 1308,
          highestRank: 589237,
          lowestRank: 590544,
        },
        {
          maxScore: 299,
          minScore: 299,
          sameCount: 1308,
          highestRank: 590545,
          lowestRank: 591852,
        },
        {
          maxScore: 298,
          minScore: 298,
          sameCount: 1322,
          highestRank: 591853,
          lowestRank: 593174,
        },
        {
          maxScore: 297,
          minScore: 297,
          sameCount: 1362,
          highestRank: 593175,
          lowestRank: 594536,
        },
        {
          maxScore: 296,
          minScore: 296,
          sameCount: 1318,
          highestRank: 594537,
          lowestRank: 595854,
        },
        {
          maxScore: 295,
          minScore: 295,
          sameCount: 1225,
          highestRank: 595855,
          lowestRank: 597079,
        },
        {
          maxScore: 294,
          minScore: 294,
          sameCount: 1258,
          highestRank: 597080,
          lowestRank: 598337,
        },
        {
          maxScore: 293,
          minScore: 293,
          sameCount: 1211,
          highestRank: 598338,
          lowestRank: 599548,
        },
        {
          maxScore: 292,
          minScore: 292,
          sameCount: 1179,
          highestRank: 599549,
          lowestRank: 600727,
        },
        {
          maxScore: 291,
          minScore: 291,
          sameCount: 1237,
          highestRank: 600728,
          lowestRank: 601964,
        },
        {
          maxScore: 290,
          minScore: 290,
          sameCount: 1243,
          highestRank: 601965,
          lowestRank: 603207,
        },
        {
          maxScore: 289,
          minScore: 289,
          sameCount: 1167,
          highestRank: 603208,
          lowestRank: 604374,
        },
        {
          maxScore: 288,
          minScore: 288,
          sameCount: 1190,
          highestRank: 604375,
          lowestRank: 605564,
        },
        {
          maxScore: 287,
          minScore: 287,
          sameCount: 1191,
          highestRank: 605565,
          lowestRank: 606755,
        },
        {
          maxScore: 286,
          minScore: 286,
          sameCount: 1158,
          highestRank: 606756,
          lowestRank: 607913,
        },
        {
          maxScore: 285,
          minScore: 285,
          sameCount: 1136,
          highestRank: 607914,
          lowestRank: 609049,
        },
        {
          maxScore: 284,
          minScore: 284,
          sameCount: 1131,
          highestRank: 609050,
          lowestRank: 610180,
        },
        {
          maxScore: 283,
          minScore: 283,
          sameCount: 1095,
          highestRank: 610181,
          lowestRank: 611275,
        },
        {
          maxScore: 282,
          minScore: 282,
          sameCount: 1111,
          highestRank: 611276,
          lowestRank: 612386,
        },
        {
          maxScore: 281,
          minScore: 281,
          sameCount: 1092,
          highestRank: 612387,
          lowestRank: 613478,
        },
        {
          maxScore: 280,
          minScore: 280,
          sameCount: 1092,
          highestRank: 613479,
          lowestRank: 614570,
        },
        {
          maxScore: 279,
          minScore: 279,
          sameCount: 1090,
          highestRank: 614571,
          lowestRank: 615660,
        },
        {
          maxScore: 278,
          minScore: 278,
          sameCount: 1111,
          highestRank: 615661,
          lowestRank: 616771,
        },
        {
          maxScore: 277,
          minScore: 277,
          sameCount: 992,
          highestRank: 616772,
          lowestRank: 617763,
        },
        {
          maxScore: 276,
          minScore: 276,
          sameCount: 1010,
          highestRank: 617764,
          lowestRank: 618773,
        },
        {
          maxScore: 275,
          minScore: 275,
          sameCount: 1099,
          highestRank: 618774,
          lowestRank: 619872,
        },
        {
          maxScore: 274,
          minScore: 274,
          sameCount: 987,
          highestRank: 619873,
          lowestRank: 620859,
        },
        {
          maxScore: 273,
          minScore: 273,
          sameCount: 992,
          highestRank: 620860,
          lowestRank: 621851,
        },
        {
          maxScore: 272,
          minScore: 272,
          sameCount: 988,
          highestRank: 621852,
          lowestRank: 622839,
        },
        {
          maxScore: 271,
          minScore: 271,
          sameCount: 953,
          highestRank: 622840,
          lowestRank: 623792,
        },
        {
          maxScore: 270,
          minScore: 270,
          sameCount: 996,
          highestRank: 623793,
          lowestRank: 624788,
        },
        {
          maxScore: 269,
          minScore: 269,
          sameCount: 994,
          highestRank: 624789,
          lowestRank: 625782,
        },
        {
          maxScore: 268,
          minScore: 268,
          sameCount: 896,
          highestRank: 625783,
          lowestRank: 626678,
        },
        {
          maxScore: 267,
          minScore: 267,
          sameCount: 928,
          highestRank: 626679,
          lowestRank: 627606,
        },
        {
          maxScore: 266,
          minScore: 266,
          sameCount: 880,
          highestRank: 627607,
          lowestRank: 628486,
        },
        {
          maxScore: 265,
          minScore: 265,
          sameCount: 923,
          highestRank: 628487,
          lowestRank: 629409,
        },
        {
          maxScore: 264,
          minScore: 264,
          sameCount: 856,
          highestRank: 629410,
          lowestRank: 630265,
        },
        {
          maxScore: 263,
          minScore: 263,
          sameCount: 847,
          highestRank: 630266,
          lowestRank: 631112,
        },
        {
          maxScore: 262,
          minScore: 262,
          sameCount: 879,
          highestRank: 631113,
          lowestRank: 631991,
        },
        {
          maxScore: 261,
          minScore: 261,
          sameCount: 802,
          highestRank: 631992,
          lowestRank: 632793,
        },
        {
          maxScore: 260,
          minScore: 260,
          sameCount: 846,
          highestRank: 632794,
          lowestRank: 633639,
        },
        {
          maxScore: 259,
          minScore: 259,
          sameCount: 783,
          highestRank: 633640,
          lowestRank: 634422,
        },
        {
          maxScore: 258,
          minScore: 258,
          sameCount: 801,
          highestRank: 634423,
          lowestRank: 635223,
        },
        {
          maxScore: 257,
          minScore: 257,
          sameCount: 813,
          highestRank: 635224,
          lowestRank: 636036,
        },
        {
          maxScore: 256,
          minScore: 256,
          sameCount: 770,
          highestRank: 636037,
          lowestRank: 636806,
        },
        {
          maxScore: 255,
          minScore: 255,
          sameCount: 750,
          highestRank: 636807,
          lowestRank: 637556,
        },
        {
          maxScore: 254,
          minScore: 254,
          sameCount: 722,
          highestRank: 637557,
          lowestRank: 638278,
        },
        {
          maxScore: 253,
          minScore: 253,
          sameCount: 741,
          highestRank: 638279,
          lowestRank: 639019,
        },
        {
          maxScore: 252,
          minScore: 252,
          sameCount: 745,
          highestRank: 639020,
          lowestRank: 639764,
        },
        {
          maxScore: 251,
          minScore: 251,
          sameCount: 707,
          highestRank: 639765,
          lowestRank: 640471,
        },
        {
          maxScore: 250,
          minScore: 250,
          sameCount: 689,
          highestRank: 640472,
          lowestRank: 641160,
        },
        {
          maxScore: 249,
          minScore: 249,
          sameCount: 662,
          highestRank: 641161,
          lowestRank: 641822,
        },
        {
          maxScore: 248,
          minScore: 248,
          sameCount: 706,
          highestRank: 641823,
          lowestRank: 642528,
        },
        {
          maxScore: 247,
          minScore: 247,
          sameCount: 677,
          highestRank: 642529,
          lowestRank: 643205,
        },
        {
          maxScore: 246,
          minScore: 246,
          sameCount: 643,
          highestRank: 643206,
          lowestRank: 643848,
        },
        {
          maxScore: 245,
          minScore: 245,
          sameCount: 645,
          highestRank: 643849,
          lowestRank: 644493,
        },
        {
          maxScore: 244,
          minScore: 244,
          sameCount: 607,
          highestRank: 644494,
          lowestRank: 645100,
        },
        {
          maxScore: 243,
          minScore: 243,
          sameCount: 607,
          highestRank: 645101,
          lowestRank: 645707,
        },
        {
          maxScore: 242,
          minScore: 242,
          sameCount: 616,
          highestRank: 645708,
          lowestRank: 646323,
        },
        {
          maxScore: 241,
          minScore: 241,
          sameCount: 579,
          highestRank: 646324,
          lowestRank: 646902,
        },
        {
          maxScore: 240,
          minScore: 240,
          sameCount: 550,
          highestRank: 646903,
          lowestRank: 647452,
        },
        {
          maxScore: 239,
          minScore: 239,
          sameCount: 555,
          highestRank: 647453,
          lowestRank: 648007,
        },
        {
          maxScore: 238,
          minScore: 238,
          sameCount: 542,
          highestRank: 648008,
          lowestRank: 648549,
        },
        {
          maxScore: 237,
          minScore: 237,
          sameCount: 537,
          highestRank: 648550,
          lowestRank: 649086,
        },
        {
          maxScore: 236,
          minScore: 236,
          sameCount: 531,
          highestRank: 649087,
          lowestRank: 649617,
        },
        {
          maxScore: 235,
          minScore: 235,
          sameCount: 525,
          highestRank: 649618,
          lowestRank: 650142,
        },
        {
          maxScore: 234,
          minScore: 234,
          sameCount: 505,
          highestRank: 650143,
          lowestRank: 650647,
        },
        {
          maxScore: 233,
          minScore: 233,
          sameCount: 470,
          highestRank: 650648,
          lowestRank: 651117,
        },
        {
          maxScore: 232,
          minScore: 232,
          sameCount: 470,
          highestRank: 651118,
          lowestRank: 651587,
        },
        {
          maxScore: 231,
          minScore: 231,
          sameCount: 469,
          highestRank: 651588,
          lowestRank: 652056,
        },
        {
          maxScore: 230,
          minScore: 230,
          sameCount: 453,
          highestRank: 652057,
          lowestRank: 652509,
        },
        {
          maxScore: 229,
          minScore: 229,
          sameCount: 450,
          highestRank: 652510,
          lowestRank: 652959,
        },
        {
          maxScore: 228,
          minScore: 228,
          sameCount: 422,
          highestRank: 652960,
          lowestRank: 653381,
        },
        {
          maxScore: 227,
          minScore: 227,
          sameCount: 410,
          highestRank: 653382,
          lowestRank: 653791,
        },
        {
          maxScore: 226,
          minScore: 226,
          sameCount: 381,
          highestRank: 653792,
          lowestRank: 654172,
        },
        {
          maxScore: 225,
          minScore: 225,
          sameCount: 417,
          highestRank: 654173,
          lowestRank: 654589,
        },
        {
          maxScore: 224,
          minScore: 224,
          sameCount: 410,
          highestRank: 654590,
          lowestRank: 654999,
        },
        {
          maxScore: 223,
          minScore: 223,
          sameCount: 402,
          highestRank: 655000,
          lowestRank: 655401,
        },
        {
          maxScore: 222,
          minScore: 222,
          sameCount: 385,
          highestRank: 655402,
          lowestRank: 655786,
        },
        {
          maxScore: 221,
          minScore: 221,
          sameCount: 388,
          highestRank: 655787,
          lowestRank: 656174,
        },
        {
          maxScore: 220,
          minScore: 220,
          sameCount: 369,
          highestRank: 656175,
          lowestRank: 656543,
        },
        {
          maxScore: 219,
          minScore: 219,
          sameCount: 333,
          highestRank: 656544,
          lowestRank: 656876,
        },
        {
          maxScore: 218,
          minScore: 218,
          sameCount: 332,
          highestRank: 656877,
          lowestRank: 657208,
        },
        {
          maxScore: 217,
          minScore: 217,
          sameCount: 299,
          highestRank: 657209,
          lowestRank: 657507,
        },
        {
          maxScore: 216,
          minScore: 216,
          sameCount: 328,
          highestRank: 657508,
          lowestRank: 657835,
        },
        {
          maxScore: 215,
          minScore: 215,
          sameCount: 309,
          highestRank: 657836,
          lowestRank: 658144,
        },
        {
          maxScore: 214,
          minScore: 214,
          sameCount: 314,
          highestRank: 658145,
          lowestRank: 658458,
        },
        {
          maxScore: 213,
          minScore: 213,
          sameCount: 286,
          highestRank: 658459,
          lowestRank: 658744,
        },
        {
          maxScore: 212,
          minScore: 212,
          sameCount: 248,
          highestRank: 658745,
          lowestRank: 658992,
        },
        {
          maxScore: 211,
          minScore: 211,
          sameCount: 263,
          highestRank: 658993,
          lowestRank: 659255,
        },
        {
          maxScore: 210,
          minScore: 210,
          sameCount: 254,
          highestRank: 659256,
          lowestRank: 659509,
        },
        {
          maxScore: 209,
          minScore: 209,
          sameCount: 267,
          highestRank: 659510,
          lowestRank: 659776,
        },
        {
          maxScore: 208,
          minScore: 208,
          sameCount: 241,
          highestRank: 659777,
          lowestRank: 660017,
        },
        {
          maxScore: 207,
          minScore: 207,
          sameCount: 230,
          highestRank: 660018,
          lowestRank: 660247,
        },
        {
          maxScore: 206,
          minScore: 206,
          sameCount: 208,
          highestRank: 660248,
          lowestRank: 660455,
        },
        {
          maxScore: 205,
          minScore: 205,
          sameCount: 228,
          highestRank: 660456,
          lowestRank: 660683,
        },
        {
          maxScore: 204,
          minScore: 204,
          sameCount: 217,
          highestRank: 660684,
          lowestRank: 660900,
        },
        {
          maxScore: 203,
          minScore: 203,
          sameCount: 177,
          highestRank: 660901,
          lowestRank: 661077,
        },
        {
          maxScore: 202,
          minScore: 202,
          sameCount: 207,
          highestRank: 661078,
          lowestRank: 661284,
        },
        {
          maxScore: 201,
          minScore: 201,
          sameCount: 200,
          highestRank: 661285,
          lowestRank: 661484,
        },
        {
          maxScore: 200,
          minScore: 200,
          sameCount: 187,
          highestRank: 661485,
          lowestRank: 661671,
        },
        {
          maxScore: 199,
          minScore: 199,
          sameCount: 175,
          highestRank: 661672,
          lowestRank: 661846,
        },
        {
          maxScore: 198,
          minScore: 198,
          sameCount: 150,
          highestRank: 661847,
          lowestRank: 661996,
        },
        {
          maxScore: 197,
          minScore: 197,
          sameCount: 169,
          highestRank: 661997,
          lowestRank: 662165,
        },
        {
          maxScore: 196,
          minScore: 196,
          sameCount: 158,
          highestRank: 662166,
          lowestRank: 662323,
        },
        {
          maxScore: 195,
          minScore: 195,
          sameCount: 154,
          highestRank: 662324,
          lowestRank: 662477,
        },
        {
          maxScore: 194,
          minScore: 194,
          sameCount: 133,
          highestRank: 662478,
          lowestRank: 662610,
        },
        {
          maxScore: 193,
          minScore: 193,
          sameCount: 142,
          highestRank: 662611,
          lowestRank: 662752,
        },
        {
          maxScore: 192,
          minScore: 192,
          sameCount: 139,
          highestRank: 662753,
          lowestRank: 662891,
        },
        {
          maxScore: 191,
          minScore: 191,
          sameCount: 123,
          highestRank: 662892,
          lowestRank: 663014,
        },
        {
          maxScore: 190,
          minScore: 190,
          sameCount: 129,
          highestRank: 663015,
          lowestRank: 663143,
        },
        {
          maxScore: 189,
          minScore: 189,
          sameCount: 123,
          highestRank: 663144,
          lowestRank: 663266,
        },
        {
          maxScore: 188,
          minScore: 188,
          sameCount: 126,
          highestRank: 663267,
          lowestRank: 663392,
        },
        {
          maxScore: 187,
          minScore: 187,
          sameCount: 118,
          highestRank: 663393,
          lowestRank: 663510,
        },
        {
          maxScore: 186,
          minScore: 186,
          sameCount: 108,
          highestRank: 663511,
          lowestRank: 663618,
        },
        {
          maxScore: 185,
          minScore: 185,
          sameCount: 89,
          highestRank: 663619,
          lowestRank: 663707,
        },
        {
          maxScore: 184,
          minScore: 184,
          sameCount: 108,
          highestRank: 663708,
          lowestRank: 663815,
        },
        {
          maxScore: 183,
          minScore: 183,
          sameCount: 102,
          highestRank: 663816,
          lowestRank: 663917,
        },
        {
          maxScore: 182,
          minScore: 182,
          sameCount: 80,
          highestRank: 663918,
          lowestRank: 663997,
        },
        {
          maxScore: 181,
          minScore: 181,
          sameCount: 94,
          highestRank: 663998,
          lowestRank: 664091,
        },
        {
          maxScore: 180,
          minScore: 180,
          sameCount: 113,
          highestRank: 664092,
          lowestRank: 664204,
        },
        {
          maxScore: 179,
          minScore: 179,
          sameCount: 87,
          highestRank: 664205,
          lowestRank: 664291,
        },
        {
          maxScore: 178,
          minScore: 178,
          sameCount: 75,
          highestRank: 664292,
          lowestRank: 664366,
        },
        {
          maxScore: 177,
          minScore: 177,
          sameCount: 77,
          highestRank: 664367,
          lowestRank: 664443,
        },
        {
          maxScore: 176,
          minScore: 176,
          sameCount: 54,
          highestRank: 664444,
          lowestRank: 664497,
        },
        {
          maxScore: 175,
          minScore: 175,
          sameCount: 75,
          highestRank: 664498,
          lowestRank: 664572,
        },
        {
          maxScore: 174,
          minScore: 174,
          sameCount: 69,
          highestRank: 664573,
          lowestRank: 664641,
        },
        {
          maxScore: 173,
          minScore: 173,
          sameCount: 77,
          highestRank: 664642,
          lowestRank: 664718,
        },
        {
          maxScore: 172,
          minScore: 172,
          sameCount: 65,
          highestRank: 664719,
          lowestRank: 664783,
        },
        {
          maxScore: 171,
          minScore: 171,
          sameCount: 77,
          highestRank: 664784,
          lowestRank: 664860,
        },
        {
          maxScore: 170,
          minScore: 170,
          sameCount: 60,
          highestRank: 664861,
          lowestRank: 664920,
        },
        {
          maxScore: 169,
          minScore: 169,
          sameCount: 60,
          highestRank: 664921,
          lowestRank: 664980,
        },
        {
          maxScore: 168,
          minScore: 168,
          sameCount: 67,
          highestRank: 664981,
          lowestRank: 665047,
        },
        {
          maxScore: 167,
          minScore: 167,
          sameCount: 61,
          highestRank: 665048,
          lowestRank: 665108,
        },
        {
          maxScore: 166,
          minScore: 166,
          sameCount: 69,
          highestRank: 665109,
          lowestRank: 665177,
        },
        {
          maxScore: 165,
          minScore: 165,
          sameCount: 65,
          highestRank: 665178,
          lowestRank: 665242,
        },
        {
          maxScore: 164,
          minScore: 164,
          sameCount: 52,
          highestRank: 665243,
          lowestRank: 665294,
        },
        {
          maxScore: 163,
          minScore: 163,
          sameCount: 42,
          highestRank: 665295,
          lowestRank: 665336,
        },
        {
          maxScore: 162,
          minScore: 162,
          sameCount: 55,
          highestRank: 665337,
          lowestRank: 665391,
        },
        {
          maxScore: 161,
          minScore: 161,
          sameCount: 61,
          highestRank: 665392,
          lowestRank: 665452,
        },
        {
          maxScore: 160,
          minScore: 160,
          sameCount: 48,
          highestRank: 665453,
          lowestRank: 665500,
        },
        {
          maxScore: 159,
          minScore: 159,
          sameCount: 52,
          highestRank: 665501,
          lowestRank: 665552,
        },
        {
          maxScore: 158,
          minScore: 158,
          sameCount: 53,
          highestRank: 665553,
          lowestRank: 665605,
        },
        {
          maxScore: 157,
          minScore: 157,
          sameCount: 45,
          highestRank: 665606,
          lowestRank: 665650,
        },
        {
          maxScore: 156,
          minScore: 156,
          sameCount: 55,
          highestRank: 665651,
          lowestRank: 665705,
        },
        {
          maxScore: 155,
          minScore: 155,
          sameCount: 31,
          highestRank: 665706,
          lowestRank: 665736,
        },
        {
          maxScore: 154,
          minScore: 154,
          sameCount: 47,
          highestRank: 665737,
          lowestRank: 665783,
        },
        {
          maxScore: 153,
          minScore: 153,
          sameCount: 63,
          highestRank: 665784,
          lowestRank: 665846,
        },
        {
          maxScore: 152,
          minScore: 152,
          sameCount: 60,
          highestRank: 665847,
          lowestRank: 665906,
        },
        {
          maxScore: 151,
          minScore: 151,
          sameCount: 56,
          highestRank: 665907,
          lowestRank: 665962,
        },
        {
          maxScore: 150,
          minScore: 150,
          sameCount: 38,
          highestRank: 665963,
          lowestRank: 666000,
        },
      ],
      QjData: "",
      // userScore: 500, // 用户当前分数
      firstSegment: 0, // 1段分数
      secondSegment: 0, // 2段分数
    };
  },
  watch: {
    userScore() {
      this.initChart(); // 数据变化时重新渲染图表
    },
    list() {
      this.initChart(); // 数据变化时重新渲染图表
    },
  },
  methods: {
    initChart() {
      // maxScore: 750,
      //     minScore: 697,
      //     sameCount: 45,
      //     highestRank: 1,
      //     lowestRank: 45,
      const xData = this.list.map(item => item.score); // X 轴数据
      const yData = this.list.map(item => item.currentCount); // Y 轴数据

      const userIndex = xData.indexOf(this.userScore); // 找到用户分数的索引
      const markPointData =
        userIndex !== -1
          ? [
            {
              name: "用户分数",
              value: this.userScore,
              coord: [userIndex, yData[userIndex]], // 确保数据坐标正确
              symbolSize: 60,
              label: {
                formatter: "{c}",
              },
            },
          ]
          : [];

      const chart = echarts.init(document.getElementById("chart"));
      chart.setOption({
        tooltip: {
          trigger: "axis",
          formatter: params => {
            const dataIndex = params[0].dataIndex;
            const item = this.list[dataIndex];
            return `
            分数: ${params[0].axisValue}<br>
            同分人数: ${params[0].data}<br>
            位次区间: ${item.startRank} - ${item.endRank}
          `;
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          name: "分数",
        },
        yAxis: {
          type: "value",
          name: "人数",
        },
        series: [
          {
            type: "line",
            data: yData,
            smooth: false,
            lineStyle: { width: 1 },
            areaStyle: {},
            markPoint: {
              data: markPointData, // 动态更新 markPoint 数据
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.initChart(); // 初始渲染
  },

};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
</style>
