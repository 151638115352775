import { request } from "@/utils/request";
import { request2 } from "@/utils/request";

// 回跳参数内容时将参数给后端
export const Callback = (params) => {
  return request({
    method: "get",
    url: `/api/oauth/callback`,
    params,
  });
};

// 获取个人信息
export const GetCustomerInfoById = (params) => {
  return request2({
    method: "get",
    url: `/api/weChatUserEx/userInfo`,
    params,
  });
};

// 获取首页省份选科列表
export const GetSeVolunteerInitialization = () => {
  return request({
    method: "get",
    url: `/api/SeVolunteerInitialization/Get`,
    params: {},
  });
};

// 修改个人信息
export const UpdateCustomer = (data) => {
  return request({
    method: "post",
    url: `/api/front/Customer/UpdateCustomer`,
    data,
  });
};

// 获取省市区
export const GetRegionList = (params) => {
  return request({
    method: "get",
    url: `/api/SysRegion/GetRegionList`,
    params,
  });
};

// AI智能填报 (Auth)
export const getAiUniversity = (data) => {
  return request2({
    method: "POST",
    url: `/api/PlanPro/aiUniversity`,
    data,
  });
};
// 修改用户头像与名称
export const UpdateCustomerAvatarUrl = (data) => {
  return request2({
    method: "post",
    url: `/api/weChatUserEx/userAvatar`,
    data,
  });
};
// 上传图片
export const UploadImg = (data) => {
  return request2({
    method: "post",
    url: `/api/sysFile/uploadAvatar`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// 获取省市区
export const GteBatchList = (params) => {
  return request2({
    method: "get",
    url: `/api/zhiYuan/batchList`,
    params,
  });
};
// 获取省市区
export const GteScoreSection = (params) => {
  return request2({
    method: "get",
    url: `/api/ScoreSection/scoreSection/V2`,
    params,
  });
};
export const aiPerview = (data) => {
  return request2({
    method: "post",
    url: `/api/PlanPro/aiPerview`,
    data,
  });
};