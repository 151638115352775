var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"nav-box"},[_c('img',{attrs:{"src":require("./images/newbj.18317a2.png")}}),_c('div',{staticClass:"tab-box"},[_c('div',{staticClass:"tabs__header"},_vm._l((_vm.RankingType),function(it,idx){return _c('div',{key:idx,class:_vm.obj.Type == it.Id ? 'is-active el-tabs__item' : 'el-tabs__item',on:{"click":function($event){return _vm.clickRankingType(it.Id)}}},[_vm._v(" "+_vm._s(it.Name)+" ")])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.obj.Type == 0),expression:"obj.Type == 0"}],staticClass:"el-tabs__content"},[_vm._m(0),_c('div',{staticClass:"tables"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","height":"450","header-cell-style":{
              background: 'rgb(247, 247, 247)',
              color: 'rgb(51, 51, 51)',
            }}},[_c('el-table-column',{attrs:{"prop":"Sort","label":"排名","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Name","label":"院校名称","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"AreaName","label":"所在地区","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Score","label":"综合得分","align":"center"}})],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.obj.Type == 1),expression:"obj.Type == 1"}],staticClass:"el-tabs__content"},[_vm._m(1),_c('div',{staticClass:"tables"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","height":"400","header-cell-style":{
              background: 'rgb(247, 247, 247)',
              color: 'rgb(51, 51, 51)',
            }}},[_c('el-table-column',{attrs:{"prop":"Sort","label":"排名","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Name","label":"院校名称","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"AreaName","label":"所在地区","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Score","label":"综合得分","align":"center"}})],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.obj.Type == 2),expression:"obj.Type == 2"}],staticClass:"el-tabs__content"},[_vm._m(2),_c('div',{staticClass:"tables"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","height":"400","header-cell-style":{
              background: 'rgb(247, 247, 247)',
              color: 'rgb(51, 51, 51)',
            }}},[_c('el-table-column',{attrs:{"prop":"Sort","label":"排名","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Name","label":"院校名称","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"AreaName","label":"所在地区","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Score","label":"综合得分","align":"center"}})],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.obj.Type == 3),expression:"obj.Type == 3"}],staticClass:"el-tabs__content"},[_vm._m(3),_c('div',{staticClass:"tables"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","height":"400","header-cell-style":{
              background: 'rgb(247, 247, 247)',
              color: 'rgb(51, 51, 51)',
            }}},[_c('el-table-column',{attrs:{"prop":"Sort","label":"排名","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Name","label":"院校名称","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"AreaName","label":"所在地区","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Score","label":"综合得分","align":"center"}})],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("2023-2024中国大学排名800强完整榜单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("2024武书连中国大学排名800强排行榜")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("2024软科中国大学排名（总榜）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("2024QS世界大学排名1000强榜单")])])
}]

export { render, staticRenderFns }