<template>
  <!-- 顶部轮播图 -->
  <div class="slideshowBox">
    <div class="navBox">
      <div class="logo" @click="refresh()">
        <div style="height: 47px">
          <img src="./images/logo@2x.png" alt="" />
        </div>
      </div>
      <div class="dq" @click.stop="xzsf()">
        <i class="el-icon-location-outline" style="font-size: 16px; margin-right: 5px; color: #333"></i>
        <div style="font-size: 16px">
          {{ ProvinceName || "选择地区" }}
        </div>
        <i style="margin-left: 3px" class="el-icon-arrow-down"></i>
      </div>
      <div class="navBar">
        <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#fff" text-color="#333" active-text-color="#187CFF">
          <el-menu-item index="/Home/FirstPage">首页</el-menu-item>
          <!-- <el-menu-item index="/Home/Homepage">AI志愿</el-menu-item> -->
          <el-submenu index="3">
            <template slot="title">查数据</template>
            <el-menu-item index="/Home/SearchUniversity">找大学</el-menu-item>
            <el-menu-item index="/Home/CheckTheMajor">查专业</el-menu-item>
            <el-menu-item index="/Home/Occupation">看职业</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="4">专业测评</el-menu-item> -->
          <el-submenu index="5">
            <template slot="title">填报工具箱</template>
            <el-menu-item index="/Home/BatchLine">批次线</el-menu-item>
            <el-menu-item index="/Home/RankLookup">查位次</el-menu-item>
            <el-menu-item index="/Home/ExpansionAndContraction">扩缩招</el-menu-item>
            <el-menu-item index="/Home/ListRanking">院校排行</el-menu-item>
            <el-menu-item index="/Home/News">高考资讯</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="6">强基/综评</el-menu-item> -->
          <el-menu-item index="9">课程学习</el-menu-item>
          <!-- <el-menu-item index="8">生涯规划</el-menu-item> -->
          <el-menu-item index="7">出国留学</el-menu-item>
          <!-- <el-menu-item index="8"><a style="text-decoration: none;" href="https://www.baidu.com"
              target="_blank">生涯规划</a></el-menu-item> -->
        </el-menu>
      </div>

      <div class="userInfo">
        <div style="height: 30px; margin-right: 20px" @click="go()">
          <img src="./images/ktvip.png" alt="" />
        </div>

        <div v-if="userInfo.avatar == null" class="avatar" style="
            width: 36px;
            height: 36px;
            background: #e8e8e8;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 8px;
          "></div>
        <div v-else class="avatar" style="
            width: 36px;
            height: 36px;
            background: #e8e8e8;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 8px;
          ">
          <img :src="userInfo.avatar" alt="" />
        </div>
        <div class="info" v-if="userInfoObj.nickName == null && userInfoObj.mobile == null">
          <div style="
              vertical-align: middle;
              display: inline-block;
              width: 100px;
              height: 30px;
              border-radius: 10px 10px 10px 10px;
              border: 1px solid #202003 !important;
              font-weight: 400;
              font-size: 16px;
              color: #202003 !important;
              text-align: center;
              line-height: 30px;
              margin-left: 18px;
            " @click="getUserInfo()">
            登录/注册
          </div>
        </div>
        <el-popover v-else placement="bottom" trigger="click">
          <div class="user-menu___OA9iU" v-if="userInfoObj.nickName !== '' &&
        userInfoObj.mobile !== '' &&
        userInfoObj.customerType == 0
        ">
            <div class="user-menu-item___SfQSJ" @click="goPersonalCenter(0)">
              <i class="el-icon-s-custom"></i>
              <span style="margin-left: 10px">账号信息</span>
            </div>
            <!-- <div class="user-menu-item___SfQSJ" style="display: block;">我的成绩</div> -->
            <div class="user-menu-item___SfQSJ" @click="goTab()">
              <i class="el-icon-s-order"></i>
              <span style="margin-left: 10px">我的志愿表</span>
            </div>
            <!-- <div class="user-menu-item___SfQSJ" style="display: block;">我的测评</div> -->
            <div class="user-menu-item___SfQSJ" @click="goPersonalCenter(1)">
              <i class="el-icon-s-grid"></i>
              <span style="margin-left: 10px">我的订单</span>
            </div>
            <div class="user-menu-item___SfQSJ" style="color: #f11717" @click="laouyt()">
              <i class="el-icon-switch-button"></i>
              <span style="margin-left: 10px">退出登录</span>
            </div>
          </div>

          <div class="user-menu___OA9iU" v-else>
            <div class="user-menu-item___SfQSJ" @click="goPersonalCenter(0)">
              <i class="el-icon-s-custom"></i>
              <span style="margin-left: 10px">账号信息</span>
            </div>
            <div class="user-menu-item___SfQSJ" @click="goTab(1)">
              <i class="el-icon-s-marketing"></i>
              <span style="margin-left: 10px">专家志愿表</span>
            </div>
            <!-- <div
              class="user-menu-item___SfQSJ"
              @click="goTab()"
            >
              <i class="el-icon-s-order"></i>
              <span style="margin-left: 10px">我的志愿表</span>
            </div> -->
            <div class="user-menu-item___SfQSJ" @click="goPersonalCenter(1)">
              <i class="el-icon-s-grid"></i>
              <span style="margin-left: 10px">我的订单</span>
            </div>

            <div class="user-menu-item___SfQSJ" style="display: block; color: #f11717" @click="laouyt()">
              <i class="el-icon-switch-button"></i>
              <span style="margin-left: 10px">退出登录</span>
            </div>
          </div>

          <div slot="reference" class="info">
            <div class="name" style="max-width: 200px; overflow: hidden">
              {{
        userInfoObj.nickName !== "" ? userInfoObj.nickName : "新用户"
      }}
              <span style="font-weight: normal; font-size: 12px">
                {{ userInfoObj.customerType == 1 ? `(专家组)` : "" }}</span>
              <i style="margin-left: 3px" class="el-icon-arrow-down"></i>
            </div>
          </div>
        </el-popover>
      </div>

      <!-- 省份选择框 -->
      <div class="cityFrame">
        <el-dialog title="请选择你所在的高考省份" :modal-append-to-body="false" :visible.sync="provinceIsShow" width="50%" center>
          <div class="home-city">
            <ul class="font0">
              <li :class="it.ProvinceName == ProvinceName ? 'city-btn-bg' : ''" v-for="it in listInit" :key="it.Code"
                @click="selectState(it)">
                {{ it.ProvinceName }}
              </li>
            </ul>
            <div slot="footer" class="dialog-footer" style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
              ">
              <el-button @click="outfirms()">取 消</el-button>
              <el-button type="primary" @click="confirms()">确 定</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { resetUnauthorizedHandled } from "@/utils/request";
import { GetSeVolunteerInitialization } from "@/api/home";
import { GetCustomerInfoById } from "@/api/home";
export default {
  name: "OCRrecognitionIndex",
  components: {},
  data() {
    return {
      ProvinceName: "",

      listInit: [],

      userInfo: "",

      activeIndex2: "",

      provinceIsShow: false,
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.activeIndex2 = val.path;
      },
      // 深度观察监听
      immediate: true,
    },
    //弹框显示隐藏
    provinceShow: {
      immediate: true,
      handler: function (newval) {
        if (newval !== "") {
          this.provinceIsShow = newval;
        }
      },
    },
    //用户信息数据
    userInfoObj: {
      immediate: true,
      handler: function (newval) {
        if (newval !== "") {
          this.userInfo = newval;
          if (newval.userExtend !== null) {
            this.ProvinceName = newval.userExtend.provinceName;
          }
        }
      },
    },
  },
  //1.在computed中把数据返回
  computed: {
    provinceShow() {
      return this.$store.state.user.provinceShow;
    },
    userInfoObj() {
      return this.$store.state.user.studentInfo;
    },
  },

  mounted() {
    this.getSeVolunteerList();
  },

  methods: {
    go() {
      if (this.userInfo == "") {
        this.getUserInfo();
        return;
      }
      this.$router.push("/Home/ClubCard");
    },
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.userInfo = res.data.result;

      // 用户登录成功后清除 unauthorizedHandled 标志
      resetUnauthorizedHandled();

      if (this.userInfo.batchDataUrl == "") {
        this.$store.commit("user/setProvinceShow", true);
      }

      this.$store.dispatch("user/removeStudentInfo");
      this.$store.commit("user/setStudentInfo", res.data.result);
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
      sessionStorage.setItem("visited", "true"); // 设置标记
    },
    // 刷新当前页面
    refresh() {
      this.$router.go(0);
    },

    // 退出登录
    laouyt() {
      this.$confirm("确定退出登录？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("user/removeStudentInfo"); // 调用 `user` 模块下的 `logout` action
          this.$store.dispatch("user/logout"); // 调用 `user` 模块下的 `logout` action
          this.$router.push("/Home/FirstPage");
          location.reload(); // 确保清除缓存的状态
          // window.location.href = process.env.VUE_APP_SSO_URL;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },

    // 跳转目标页面路径
    goPersonalCenter(types) {
      this.$router.push({
        path: "/Home/PersonalCenter",
        query: {
          IsShow: types,
        },
      });
    },

    goTab(type) {
      if (type !== undefined) {
        this.$router.push("/Home/expertZyTable");
      } else {
        this.$router.push("/Home/zyTable");
      }
    },
    outfirms() {
      this.$store.commit("user/setProvinceShow", false);
    },
    // 更新省份信息
    async confirms() {
      this.$store.commit("user/setProvinceShow", false);
      this.$store.commit("user/setProvinceName", this.ProvinceName);
      this.$router.push("/Home/FirstPage");
    },

    // 选择省份
    selectState(it) {
      this.ProvinceName = it.ProvinceName;
    },

    // 获取高考年份type
    async getSeVolunteerList() {
      const res = await GetSeVolunteerInitialization();
      const { response } = res.data; // 解构赋值简化访问
      this.listInit = response;
    },

    //选择省份
    xzsf() {
      if(this.ProvinceName==''){
        this.provinceIsShow = !this.provinceIsShow;
        this.$store.commit("user/setProvinceShow", this.provinceIsShow);
      }
    },

    //tbs
    handleSelect(key, keyPath) {
      if (key == 7) {
        this.activeIndex2 = key;
        window.open("https://www.ycymedu.com/Home/StudyingAbroad", "_blank");
        return;
      }
      if (key == 8) {
        if (this.userInfo == "") {
          this.getUserInfo();
          return;
        }
        this.activeIndex2 = key;
        window.open("https://cp.ycymedu.com", "_blank");
        return;
      }
      if (key == 9) {
        this.activeIndex2 = key;
        window.open(
          "https://kp.ycymedu.com/web/special/special_cate.html",
          "_blank"
        );
        return;
      }
      this.activeIndex2 = key;
      this.$router.push(key);
      console.log("路由键：", key, "路径：", keyPath);
    },
  },
};
</script>
<style lang="less" scoped>
.el-menu--horizontal>.el-menu-item {
  border-bottom: none !important;
}

::v-deep .el-dialog {
  border-radius: 18px;
}

::v-deep .el-submenu__icon-arrow {
  font-size: 16px;
  font-weight: bold;
}

.user-menu___OA9iU {
  .user-menu-item___SfQSJ {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    line-height: 28px;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .user-menu-line___mwoOJ {
    width: 190px;
    height: 1px;
    border-bottom: 1px solid #efefef;
  }

  .logout___m8_S5 {
    font-size: 16px;
    font-weight: 400;
    color: #272727;
    line-height: 28px;
    margin-top: 25px;
    text-align: center;
    cursor: pointer;
  }
}

.cityFrame {
  width: 100%;
  height: 100%;

  .home-city {
    margin: 0 auto;
    color: #333;
    background-color: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=rgba(127, 0, 0, 0), endcolorstr=rgba(127, 0, 0, 0));
    border-radius: 18px;

    h1 {
      font-weight: 500;
      font-size: 24px;
      color: #333;
      text-align: center;
      margin-top: 40px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 7px;

      li {
        display: inline-block;
        font-size: 16px;
        border: 1px solid #eee;
        border-radius: 8px;
        width: 10%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        background-color: rgb(248, 248, 248);
        color: #333;
        cursor: pointer;
      }

      li:hover {
        color: #409eff !important;
      }

      .city-btn-bg {
        color: #409eff !important;
        border: 1px solid #409eff !important;
      }
    }

    .city-btn {
      background-color: #409eff;
      color: #fff;
      text-align: center;
      line-height: 51px;
      font-size: 18px;
      margin: auto;
      margin-top: 40px;
      border-radius: 12px;
      width: 191px;
      height: 51px;
      opacity: 1;
      cursor: pointer;
    }
  }
}

::v-deep .el-submenu__title:hover {
  background-color: #fff !important;
  color: #187cff !important;
}

::v-deep.el-menu-item:hover {
  color: #187cff !important;
  background-color: #fff !important;
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}

::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  color: #303133;
  font-weight: 600;
  border: none !important;

  .el-submenu__title i {
    color: #187cff !important;
  }
}

::v-deep .el-menu--horizontal>.el-submenu.is-active {
  color: #303133;
  border: none !important;
  font-weight: 600;

  .el-submenu__title i {
    color: #187cff !important;
  }
}

::v-deep .el-menu--horizontal>.el-menu-item {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  background-color: rgb(255, 255, 255) !important;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 50px;
  line-height: 50px;
  border: none !important;
  font-size: 16px;
}

.dq {
  cursor: pointer;
  position: absolute;
  top: 30%;
  left: 200px;
  text-align: center;
  line-height: 30px;
  padding: 0 5px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  width: 100%;
  height: 100%;
}

.slideshowBox {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
  background: #fff;
  height: 75px;
  z-index: 22;

  .navBox {
    position: relative;
    width: 1280px;
    margin: 0 auto;
    background: #fff;
    height: 100%;
  }

  .logo {
    cursor: pointer;
    z-index: 23;
    position: absolute;
    top: 20%;
    left: 0;
    height: 50px;
  }

  .userInfo {
    position: absolute;
    top: 30%;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 222;
    cursor: pointer;

    .info {
      display: flex;
      align-items: center;
      justify-content: center;

      .name {
        font-size: 20px;
        font-weight: 600;
        margin-right: 5px;
      }

      .NoVip {
        width: 25px;
        height: 21px;
      }

      .NoVips {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 88px;
        height: 35px;
        background: #f46a3e;
        border-radius: 5px;
      }
    }
  }

  .navBar .el-submenu.is-active {
    color: #187cff;
    font-weight: bold;
    position: relative;
    /* 为伪元素定位做准备 */
  }

  .navBar .el-submenu.is-active::after {
    content: "";
    /* 使用伪元素生成下划线 */
    position: absolute;
    bottom: 0;
    left: 41%;
    /* 居中对齐 */
    transform: translateX(-50%);
    width: 20px;
    /* 下划线宽度为菜单项宽度的一半 */
    height: 2px;
    background-color: #187cff;
  }

  .navBar .el-menu-item.is-active {
    color: #187cff;
    font-weight: bold;
    position: relative;
    /* 为伪元素定位做准备 */
  }

  .navBar .el-menu-item.is-active::after {
    content: "";
    /* 使用伪元素生成下划线 */
    position: absolute;
    bottom: 0;
    left: 50%;
    /* 居中对齐 */
    transform: translateX(-50%);
    width: 20px;
    /* 下划线宽度为菜单项宽度的一半 */
    height: 2px;
    background-color: #187cff;
  }

  .navBar {
    cursor: pointer;
    z-index: 22;
    position: absolute;
    top: 30%;
    transform: translate(-50%, 0);
    left: 50%;
    height: 30px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    .nav {
      margin-left: 72px;
    }

    .nav:nth-child(1) {
      margin-left: 82px !important;
    }

    .nav:hover {
      /*  */
      color: #187cff;
    }

    .navActive {
      color: #187cff;
      font-weight: bold;
    }
  }
}
</style>
