<template>
  <div class="box">
    <div class="headlineBacImg">
      <div class="headline">
        <div class="school">
          <div class="schoolLogo">
            <img :src="universityResult.Logo" />
          </div>
          <div class="schoolInfo">
            <h3 class="schoolName">
              <em>{{ universityResult.Name }}</em>
            </h3>
            <div class="tags">
              <span>{{ universityResult.AscriptionName }}</span>
              <span v-if="universityResult.Nhef">985</span>
              <span v-if="universityResult.Sff">211</span>
              <span v-if="universityResult.Syl">双一流</span>
              <span v-if="universityResult.Qjjh">强基计划</span>
            </div>
            <div class="information">
              <div class="URL">
                <div class="ico">
                  <img src="./images/网址@2x.png" />
                </div>
                <span v-if="universityResult.Web == '暂无'">暂无数据</span>
                <span v-else>官网网址：
                  <a :href="universityResult.Web">{{
              universityResult.Web
            }}</a></span>
              </div>
              <div class="phone">
                <div class="ico">
                  <img src="./images/3.1电话@2x.png" />
                </div>

                <span style="color: #666" v-if="universityResult.Phone == null">暂无数据</span>
                <span style="color: #666" v-else>{{
              universityResult.Phone
            }}</span>
              </div>
              <div class="site">
                <div class="ico">
                  <img src="./images/401位置备份@2x.png" />
                </div>
                <span style="color: #666" v-if="universityResult.Address == null">暂无数据</span>
                <span style="color: #666">{{ universityResult.Address }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="function">
                    <div class="sc">
                        <div class="ico">
                            <img src="./images/收藏@2x.png">
                        </div>
                        <span>收藏</span>
                    </div>
                    <div class="db">
                        <div class="ico">
                            <img src="./images/加减组件 加号@2x.png">
                        </div>
                        <span>对比</span>
                    </div>
                </div> -->
        </div>
        <div class="schoolMessage">
          <div class="ta">
            <div class="mesItem">
              <div class="title">
                {{
              universityResult.BuildDate == null
                ? "-"
                : universityResult.BuildDate
            }}
              </div>
              <div>创建时间</div>
            </div>
          </div>
          <div class="ta">
            <div class="mesItem">
              <div class="title">
                {{ universityResult.Nature == 0 ? "公办" : "民办" }}
              </div>
              <div>办学性质</div>
            </div>
          </div>
          <div class="ta">
            <div class="mesItem">
              <div class="title">{{ universityResult.AreaName }}</div>
              <div>院校所在地</div>
            </div>
          </div>
          <div class="ta">
            <div class="mesItem">
              <div class="title">{{ universityResult.AscriptionName }}</div>
              <div>隶属于</div>
            </div>
          </div>
          <div class="ta">
            <div class="mesItem">
              <div class="title">
                {{ universityResult.SubjectLevel == 0 ? "本科" : "专科" }}
              </div>
              <div>学科层次</div>
            </div>
          </div>
          <div class="ta">
            <div class="mesItem">
              <div class="title">
                {{
              {
                0: "综合",
                1: "理工类",
                2: "医学类",
                3: "军事类",
                4: "语言类",
                5: "师范类",
                6: "财经类",
                7: "政法类",
                8: "民族类",
                9: "农林类",
                10: "艺术类",
                11: "体育类",
              }[universityResult.UniversityType] || "其他"
            }}
              </div>
              <div>院校类型</div>
            </div>
          </div>
          <div class="ta">
            <div class="mesItem">
              <div class="title">
                {{ universityResult.DoctorateCount || "-" }}
              </div>
              <div>博士点</div>
            </div>
          </div>

          <div class="ta">
            <div class="mesItem">
              <div class="title">
                {{ universityResult.MasterCount || "-" }}
              </div>
              <div>硕士点</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tablist">
      <div :class="it.name == showHtml ? 'active tabItem' : 'tabItem'" v-for="(it, idx) in tabList" :key="idx"
        @click="Active_AreaShow(it.name)">
        <span class="Icon" v-if="it.name == showHtml"><img :src="it.Acitveicon" alt="" /></span>
        <span class="Icon" v-else><img :src="it.icon" alt="" /></span>
        <span> {{ it.name }}</span>
      </div>
    </div>
    <div class="content">
      <div class="leftContent">
        <div class="Active_Area" v-if="showHtml == '院校简介'">
          <SchoolParticulars :Description="universityResult" :Imgs="universityResult.Imglist" @clickYxfg="clickYxfg" />
        </div>
        <div class="Active_Area" v-if="showHtml == '招生计划'">
          <EnrollmentPlan :schoolId="schoolId" :name="universityResult.Name" />
        </div>
        <div class="Active_Area" v-if="showHtml == '招生简章'">
          <EnrollmentGuide :schoolId="schoolId" :isShow="show" />
        </div>
        <div class="Active_Area" v-if="showHtml == '录取分数线'">
          <EnrollmentMark :schoolId="schoolId" :name="universityResult.Name" :code="universityResult.UniversityCode" />
        </div>
        <div class="Active_Area" v-if="showHtml == '查扩缩招'">
          <Augment :schoolId="schoolId" :name="universityResult.Name" :code="universityResult.UniversityCode" />
        </div>
        <div class="Active_Area" v-if="showHtml == '院校风光'">
          <CollegeStyle :Img="universityResult.Imglist" />
        </div>
      </div>

      <div class="rightContent">
        <div class="banes">
          <div class="item" @click="goUrl('/Home/FirstPage')">
            <img src="./images/Frame 1000015157@2x.png" alt="" />
          </div>
          <div class="item" @click="goUrl('/Home/ZhanJiaList')">
            <img src="./images/Frame 1000015157@2x(1).png" alt="" />
          </div>
        </div>
        <div class="articleCont_right_zhengce">
          <div class="title">
            <div class="tit">院校推荐</div>
            <div class="more" @click="goUrl('/Home/ListRanking')">
              查看更多
              <i class="el-icon-arrow-right" style="color: #aeaeae; font-size: 14px"></i>
            </div>
          </div>
          <div class="school-box">
            <div class="school-item" v-for="(it, index) in list" v-show="index <= 8" :key="index"
              @click="goSchoolDetails(it.Id)">
              <div class="schoolPhoto">
                <img :src="it.Logo" alt="" />
              </div>
              <div class="schoolName">
                <span class="name"> {{ it.Name }}</span>
                <span class="type">北京.综合类</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SchoolParticulars from "./components/schoolParticulars";
import EnrollmentGuide from "./components/EnrollmentGuide";
import EnrollmentPlan from "./components/EnrollmentPlan";
import CollegeStyle from "./components/CollegeStyle";
import EnrollmentMark from "./components/EnrollmentMark";
import Augment from "./components/Augment";
import { GetUniversityDetails } from "@/api/schoolDetails";
import { GetUniversitys } from "@/api/SearchUniversity";
export default {
  name: "VoluntaryReportingIndex",
  components: {
    SchoolParticulars,
    EnrollmentGuide,
    EnrollmentPlan,
    CollegeStyle,
    EnrollmentMark,
    Augment,
  },
  data() {
    return {
      show: true,
      schoolId: "",
      list: [],
      graduateModels: {},
      relatedMajors: [],
      relatespMajors: [],
      universityResult: {},
      showHtml: "院校简介",
      tabList: [
        {
          name: "院校简介",
          icon: require("./images/222.png"),
          Acitveicon: require("./images/22.png"),
        },
        {
          name: "招生计划",
          icon: require("./images/111.png"),
          Acitveicon: require("./images/11.png"),
        },
        {
          name: "招生简章",
          icon: require("./images/444.png"),
          Acitveicon: require("./images/44.png"),
        },
        {
          name: "录取分数线",
          icon: require("./images/333.png"),
          Acitveicon: require("./images/33.png"),
        },
        {
          name: "查扩缩招",
          icon: require("./images/555.png"),
          Acitveicon: require("./images/55.png"),
        },
      ],
    };
  },

  mounted() {
    //其他页面学校id
    this.schoolId = this.$route.query.Id;
    // 其他页面高亮
    if (this.$route.query.showName !== undefined) {
      this.showHtml = this.$route.query.showName;
    }
    //获取学校详情
    this.getList();
    this.getList1();
  },

  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    // 获取冲稳保院校
    async getList1() {
      this.isLoading = true;
      const res = await GetUniversitys({
        AreaName: [],
        Ascription: [],
        Name: "",
        Nature: [],
        PageIndex: 1,
        PageSize: 8,
        Type: [],
      });
      this.list = res.data.response.data
    },
    clickYxfg() {
      this.showHtml = "院校风光";
    },
    Active_AreaShow(name) {
      this.showHtml = name;
      this.show = !this.show;
    },
    async getList() {
      const res = await GetUniversityDetails({
        Id: this.schoolId,
      });
      this.graduateModels = res.data.response.graduateModels;
      this.relatedMajors = res.data.response.relatedMajors;
      this.relatespMajors = res.data.response.relatespMajors;
      this.universityResult = res.data.response.universityResult;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #fff;
  min-height: 100vh;
}

img {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  width: 1280px;
  margin: 0 auto;
  min-height: 100vh;
}

.headlineBacImg {
  background-image: url("./images/bjk.png");
  background-size: 100% 100%;
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  width: 1280px;
  margin: 0 auto;
  margin-top: 88px;
  background: linear-gradient(180deg,
      #ffffff 0%,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 100%);
  border-radius: 24px 24px 0 0;
  padding: 32px;
  box-sizing: border-box;

  .school {
    display: flex;
    position: relative;

    .schoolLogo {
      width: 120px;
      height: 120px;
      margin-right: 24px;
    }

    .schoolInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .schoolName {
        font-size: 36px;
      }

      .tags {
        display: flex;
        align-items: center;

        span {
          padding: 0 13px;
          height: 20px;
          background: #f8f8f8;
          border-radius: 5px;
          font-size: 14px;
          color: #666666;
          text-align: center;
          line-height: 20px;
          margin-right: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }

      .information {
        display: flex;
        align-items: center;

        .URL,
        .phone,
        .site {
          display: flex;
          align-items: center;
          margin-right: 20px;
          color: #5e8de8;
          font-size: 14px;

          .ico {
            width: 12px;
            margin-right: 5px;
          }

          a {
            color: #5e8de8;
          }
        }
      }
    }

    .function {
      position: absolute;
      right: 34px;
      bottom: 13px;
      display: flex;

      .sc,
      .db {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #979797;
        margin-left: 29px;
        color: #333333;
        font-size: 14px;

        .ico {
          width: 14px;
          height: 13px;
          margin-right: 8px;
        }
      }
    }
  }

  .schoolMessage {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;

    .ta {
      display: flex;
      align-items: center;
      justify-content: center;

      .mesItem {
        min-width: 100px;
        height: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 14px;
          color: #999999;
        }

        .title {
          text-align: center;
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 20px;
          font-weight: 600;
          color: #333;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.tablist {
  width: 1280px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;

  .tabItem {
    text-align: center;
    font-size: 18px;
    color: #86909c;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    margin-right: 40px;
    display: flex;
    align-items: center;

    .Icon {
      display: inline-block;
      width: 32px;
      height: 28px;
    }
  }

  .active {
    color: #187cff;
  }
}

.leftContent {
  flex: 1;
  min-width: 956px;
  margin-right: 16px;
}

.rightContent {
  width: 308px;
  overflow: hidden;
  float: left;

  .banes {
    width: 100%;

    .item {
      cursor: pointer;
      width: 308px;
      height: 154px;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .articleCont_right_zhengce>p {
    height: 21px;
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .articleCont_right_zhengce {
    width: 100%;
    background-color: #fff;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 6px;
    padding: 24px;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .tit {
        font-weight: 600;
        font-size: 20px;
        color: #000000;
      }

      .more {
        font-size: 14px;
        color: #aeaeae;
        cursor: pointer;
      }
    }

    .school-box {
      margin-top: 38px;
      padding: 0 24px;

      div,
      .school-box .school-item {
        display: flex;
        align-items: center;
      }

      .school-item:hover .name {
        color: #187cff !important;
      }

      .school-item {
        margin-bottom: 24px;
        cursor: pointer;

        .schoolPhoto {
          width: 48px;
          height: 48px;
          margin-right: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .schoolName {
          display: flex;
          flex-direction: column;
          align-items: baseline;

          .name {
            color: #333;
            font-size: 18px;
            margin-bottom: 10px;
            max-width: 180px;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 超出部分隐藏 */
            text-overflow: ellipsis;
            /* 超出部分显示省略号 */
          }

          .type {
            font-size: 14px;
            color: #888;
          }
        }
      }
    }

    .policy_title:hover {
      color: #187cff !important;
      cursor: pointer;
    }
  }
}
</style>
