<template>
  <div ref="main" class="container" />
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "SportsFrontendWebMasterAge",
  props: {
    list2: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },

  watch: {
    list2(newVal) {
      if (newVal !== '') {
        this.init()
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var myChart = echarts.init(this.$refs.main);
      var ydata = []
      var salvProValue = []
      this.list2.forEach((it) => {
        ydata.push(it.ssmc)
        salvProValue.push(it.count)
      })


      var option = {
        backgroundColor: '#fff',
        grid: {
          left: '2%',
          right: '4%',
          bottom: '2%',
          top: '8%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          formatter: '{b}:{c}',
        },
        yAxis: {
          name: '单位：个',
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        xAxis: {
          type: "category",
          data: ydata,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true
          },
        },
        series: [
          {
            name: 'value',
            type: 'bar',
            zlevel: 1,
            showBackground: false,
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 0, 0], // 圆角（左上、右上、右下、左下）
                color: 'transparent', // 渐变
              },
            },
            label: {
              normal: {
                color: '#000',
                show: true,
                position: 'top',
              },
            },
            barWidth: 8,
            data: salvProValue,
          },
          {
            z: 2,
            name: '数量',
            type: 'bar',
            barWidth: 8,
            zlevel: 2,
            data: salvProValue,
            barGap: '-100%',
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 0, 0], // 圆角（左上、右上、右下、左下）
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  '#337dff', '#60b5ff'
                ].map((color, offset) => ({
                  color,
                  offset
                }))), // 渐变
              },
            },
            label: {
              show: true,
              position: 'insideTop',
              distance: 0,
              formatter: () => {
                return `{z|}{a|}`
              },
              rich: {
                a: {
                  widht: 20,
                  height: 20,
                  backgroundColor: {
                    image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKUSURBVHgBpVPPaxNBFH4zszubTTY2sSRSrFCC1dIepChoD9JgKwiem6s3vYl4lyb/QS/+wJPnBPQ/0LQ91KsHe5BaWo3Yupqk6WZ3Z3Z2xt2tKUUNHnww8OB775tv3nwP4D8DDQOqqoqh9iuPjlpeVkkxQurvBAoiREGlUcHbpUWcHaWkoGUivAWOPyUt5iiYgbABS/IkCTp5YxMAF1rTui36Rt70acipFmOEcqE8n+vaWZbf74jnl++KAQlOLlcKNZuA6X7JcPw9K2uSPOd68bY1NXtJL0zGeWiO5h3fsbxxk5abNRIrPlawVK8TmGtRx89ZJIVy93NzC9et0sMU6JMxzkF+XXc/1Fbab1+bmuq2RNcZ39jg9UojTBTkSx3spIu6oaHMLevC9E3r4tNBcxwU8NhCeurZg9y1GwRYJucX9e2lRYwi5TiW8nmEYe6FusIsfWfkyjIMiauZ0j2PozRJ92lxi2E1mAGcB8jwjoakQS1EZ4cRxBg1McUMkzNaFqEBgbeTTWYhlZChUs4wghCUw5gh43xnIv75mACBKkxkpKFbARiYtUNnbRhBW7hrUvicUCoK73flsQK7uSlZCgXI892X7uZjEU3992auwr1Xh++eEKr3fY8E9sx8YiiSyHlRhrFvPQT0NOyGP3puwDfO0XwuYseRf9mnoFtfOVx9tM6/fGShOPRhxy/ndsWbWvPI3rGRKtDAkWspQM8MkUpHtk6L0NIlOkAIpRhnro8Mo38Kep493guaUA3j5ydPiCpUAyrS3toMbEFcQke6ppWxteDAlszYV9L7zk9Bh4mWmzTXqlLB0Tr8uY3RTpQjWxcK09gx9hLcYm1l2/OyXF6VVVSViY0RKPhHHJGrpHTo2v8EGgI2uB3r9kQAAAAASUVORK5CYII="
                  }
                }
              }
            }
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 360px;
  // background: skyblue;
  background: #fff;
}
</style>
