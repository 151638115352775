<template>
  <div class="box">
    <div id="pdfcontent" style="max-width: 1280px; margin: 0 auto">
      <div class="titleBox">
        <div class="left">
          <div class="bl">
            <!-- <zCircle :value="'24'" text="24%" :duration="'1'" :size="'40'" :strokeWidth="'10'"
                        :textSize="'20'" /> -->
          </div>
          <div class="zybG">
            <div class="title">志愿表</div>
            <div class="cj">
              我的成绩：
              <span>{{ userInfo.batchname }}</span>
              <span>{{ userInfo.score }}分</span>
              {{ jsonb }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="all">
            <div class="cbw">
              <div class="cbw_all">
                <div class="cbw_c"></div>
                <span>冲({{ dataCount.c }})</span>
              </div>
              <div class="cbw_all">
                <div class="cbw_b"></div>
                <span>稳({{ dataCount.w }})</span>
              </div>
              <div class="cbw_all">
                <div class="cbw_w"></div>
                <span>保({{ dataCount.b }})</span>
              </div>
            </div>
            <div class="action-all">
              <!-- <span class="action-button">
                            修改
                        </span> -->
              <span class="action-button" @click="gozyTable()"> 保存 </span>
              <span class="action-button" @click="downloadPdf('print')">
                下载
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableBox">
        <div class="recommend-data-eu-table___J2Nqt" id="table_count">
          <div class="recommend-data-eu-table-hd-box___UexWu">
            <div class="recommend-data-eu-table-hd___Ep8R8">
              <div class="recommend-data-eu-table-ratio___TwQdX">
                <span>排序</span>
              </div>
              <div class="recommend-data-eu-table-ratio___TwQdX">
                <span>录取概率</span>
              </div>
              <div class="recommend-data-eu-table-major-count___LhMrX">
                专业
              </div>
              <div class="recommend-data-eu-table-eu-name___fRm_H">
                <span>院校</span>
              </div>
              <div class="recommend-data-eu-table-plan___sNM8l">
                <span>2024年招生计划</span>
              </div>
              <div class="recommend-data-eu-table-admission-data___a50VQ">
                <div class="year-hd___eE2lN">往年</div>
                <div class="year-item___NKiGo">2023</div>
                <div class="year-item___NKiGo">2022</div>
                <div class="year-item___NKiGo">2021</div>
              </div>
              <div class="recommend-data-eu-table-major-count___Zy">操作</div>
            </div>
          </div>
          <template v-if="list.length !== 0">
            <div v-for="(it, idx) in list" :key="idx" class="item">
              <div class="recommend-data-eu-table-item___BXnAP element">
                <div class="recommend-data-eu-table-ratio___TwQdX">
                  <el-popover placement="bottom" width="200" trigger="click">
                    <div class="zy-sort-wrapper">
                      <div class="pp">
                        第
                        <div class="el-input el-input--small">
                          <input
                            type="text"
                            autocomplete="off"
                            v-model="value"
                            placeholder="N"
                            controls-position="right"
                            min="1"
                            class="el-input__inner"
                          />
                        </div>
                        <span>志愿</span>
                      </div>
                      <button
                        type="button"
                        class="el-button el-button--primary el-button--small"
                        @click="alter(idx + 1)"
                      >
                        <span>确定</span>
                      </button>
                    </div>
                    <div
                      slot="reference"
                      class="ratio-num___gAwOo"
                      style="cursor: pointer"
                    >
                      {{ idx + 1 }}
                      <span>
                        <i class="el-icon-arrow-down major-icon"></i>
                      </span>
                    </div>
                  </el-popover>
                </div>
                <div class="recommend-data-eu-table-ratio___TwQdX">
                  <div class="ratio-num___gAwOo">{{ it.percentAge }}</div>
                  <p :class="it.type == 2 ? 'c' : it.type == 1 ? 'w' : 'b'">
                    <span>{{
                      it.type == 2 ? "冲" : it.type == 1 ? "稳" : "保"
                    }}</span>
                  </p>
                </div>
                <div class="recommend-data-eu-table-major-count___LhMrX">
                  <div class="count-box___NYb3B">
                    <div
                      style="
                        font-size: 14px;
                        font-weight: 400;
                        color: #272727;
                        line-height: 20px;
                        padding: 17px 0;
                      "
                    >
                      {{ it.major }}
                    </div>
                    <div
                      style="font-size: 14px; font-weight: 400; color: #272727"
                    >
                      {{ it.majorGroup == null ? "-" : it.majorGroup }}
                    </div>
                    <div class="sch-line___oPJFW">
                      专业代码 {{ it.majorCode }}
                    </div>
                  </div>
                </div>
                <div class="recommend-data-eu-table-eu-name___fRm_H">
                  <div class="sch-name___afuQF">
                    <a href="javascript:void(0)" target="_blank">{{
                      it.UniversityName
                    }}</a>
                  </div>
                  <div class="sch-line___oPJFW">{{ it.universityName }}</div>
                  <div class="sch-line___oPJFW">
                    <span
                      style="margin-right: 3px"
                      v-for="(its, idxs) in it.features"
                      :key="idxs"
                      >{{ its }}</span
                    >
                  </div>
                  <div class="sch-line___oPJFW">代码 {{ it.collegeCode }}</div>
                </div>
                <div class="recommend-data-eu-table-plan___sNM8l">
                  <div class="plan-count___UrtQc">
                    <div class="plan-count-num___IspKS">
                      {{ it.planCount }}人
                    </div>
                  </div>
                  <div class="plan-info___kz4Ip">
                    {{ it.academic + "年" }} {{ "￥" + it.fee }}/年
                  </div>
                  <div class="plan-subject___wsd5H">
                    {{ it.subjectClam == null ? "不限" : it.subjectClam }}
                  </div>
                </div>
                <div class="recommend-data-eu-table-admission-data___a50VQ">
                  <div class="admission-line___q58Dc">
                    <div class="year-hd___eE2lN">录取人数</div>
                    <div
                      class="year-item___NKiGo"
                      v-for="(itt, idd) in it.planItems"
                      :key="idd"
                    >
                      {{ itt.count }}
                    </div>
                  </div>
                  <div class="admission-line___q58Dc">
                    <div class="year-hd___eE2lN">计划人数</div>
                    <div
                      class="year-item___NKiGo"
                      v-for="(itt, idd) in it.planItems"
                      :key="idd"
                    >
                      {{ itt.planCount }}
                    </div>
                  </div>
                  <div class="admission-line___q58Dc">
                    <div class="year-hd___eE2lN">最低分</div>
                    <div
                      class="year-item___NKiGo"
                      v-for="(itt, idd) in it.planItems"
                      :key="idd"
                    >
                      {{ itt.score }}
                    </div>
                  </div>
                  <div class="admission-line___q58Dc">
                    <div class="year-hd___eE2lN">最低位次</div>
                    <div
                      class="year-item___NKiGo"
                      v-for="(itt, idd) in it.planItems"
                      :key="idd"
                    >
                      {{ itt.rankLine }}
                    </div>
                  </div>
                </div>
                <div class="recommend-data-eu-table-major-count___Zy">
                  <div class="count-box___NYb3B">
                    <div class="count___QftTC">
                      <span
                        class="handle"
                        style="cursor: pointer; margin-right: 30px"
                      >
                        <i class="el-icon-rank" style="font-size: 20px"></i>
                      </span>
                      <el-popconfirm
                        title="删除后无法恢复，确定删除吗？"
                        @confirm="deld(idx)"
                      >
                        <span slot="reference" style="cursor: pointer">
                          <i class="el-icon-delete" style="font-size: 20px"></i>
                        </span>
                      </el-popconfirm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else>
            <el-empty description="暂无推荐数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OneSubmitGo } from "@/api/oneKey";
import { PostPcVolunteer } from "@/api/zybEditor";
// import zCircle from '@/components/zCircle'
import PdfLoader from "@/utils/html2pdf";
import Sortable from "sortablejs";
export default {
  name: "VoluntaryReportingIndex",
  components: {
    // zCircle
  },
  data() {
    return {
      dataCount: "",

      // 学校专业列表
      list: [],

      value: "", //手动排序改变的值

      // 排序列表
      sortData: [],

      userInfo: "",
      jsonb: "",
    };
  },

  mounted() {
    this.rowDrop();

    this.getList();
    const xxx = document.getElementById("pdfcontent"); // 需要导出部分页面的id名
    this.pdfDownLoader = new PdfLoader(xxx, "fileName", "question-table"); // fileName -->导出文件名,  question-table -->防止被截断的class名
  },
  methods: {
    // Pdf
    downloadPdf() {
      this.pdfDownLoader.outPutPdfFn(
        JSON.parse(sessionStorage.getItem("setStudentInfo")).NickName +
          " - " +
          this.subMitObj.volunteerTableName
      );
    },

    // 获取冲稳保院校
    async getList() {
      this.userInfo = JSON.parse(this.$route.query.str);
      console.log(JSON.parse(this.$route.query.str));
      this.jsonb = this.userInfo.subjects.join(",");
      const res = await OneSubmitGo({
        ...this.userInfo,
      });

      const mergedItems = [];

      // 遍历 rows 数组
      res.data.result.rows.forEach((university) => {
        // 获取大学的基本信息，去除 uId 和 type
        const { majorItems, ...universityData } = university;

        // 将每个专业信息与大学的基本信息合并
        majorItems.forEach((major) => {
          // 合并大学的基本信息与每个专业项，添加到 mergedItems 中
          mergedItems.push({
            ...universityData,
            ...major,
          });
        });
      });
      this.list = mergedItems;
      this.dataCount = res.data.result.tModel;
    },

    //保存
    async gozyTable() {
      var abs = {
        score: this.userInfo.score,
        subjectClaim: this.userInfo.subjects.join(","),
        type: "一键填报",
        batchName: this.userInfo.batchname,
        vTbDetails: [],
      };
      var arr = [];
      this.list.forEach((it, idx) => {
        var obj = {};
        obj.sort = idx + 1;
        obj.unCode = it.collegeCode;
        obj.unId = it.uId;
        obj.unName = it.universityName;

        obj.vItems = [];
        var obj1 = {};
        obj1 = {};
        obj1.sort = 1;
        obj1.code = it.majorCode;
        obj1.name = it.major;
        obj1.percentAge = it.percentAge;
        obj1.type = it.type;
        obj1._pId = it.planId;
        obj.vItems.push(obj1);
        arr.push(obj);
      });
      abs.vTbDetails = arr;
      console.log("abs", abs);
      await PostPcVolunteer({
        ...abs,
      });
      this.$router.push("/Home/zyTable");
    },

    // 拖拽
    rowDrop() {
      const el = document.getElementById("table_count");
      Sortable.create(el, {
        sort: true, //是否可进行拖拽排序
        animation: 150,
        // Class name for the drop placeholder,
        handle: ".handle",
        //拖拽结束执行,evt执向拖拽的参数
        onEnd: ({ newIndex, oldIndex }) => {
          console.log(newIndex, oldIndex);
          let arr = this.list;
          let temp = arr[oldIndex - 1];
          arr.splice(oldIndex - 1, 1);
          arr.splice(newIndex - 1, -1, temp);
          this.sortData = arr;
          this.list = [];
          this.$nextTick(function () {
            this.list = this.sortData;
            // 重新赋值，用新数据来刷新视图
          });
        },
      });
    },

    // 手动更改排序
    alter(oldIndex) {
      console.log(this.value, oldIndex);
      let arr = this.list;
      let temp = arr[oldIndex - 1];
      arr.splice(oldIndex - 1, 1);
      arr.splice(this.value - 1, -1, temp);
      this.sortData = [];
      this.sortData = arr;
      this.list = [];
      this.$nextTick(function () {
        this.list = this.sortData;
        // 重新赋值，用新数据来刷新视图
        this.value = "";
      });
    },

    // 删除
    deld(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #fff;
  min-height: 100vh;
}
.zy-sort-wrapper {
  display: flex;
  align-items: center;

  .pp {
    width: 182px;
    justify-content: center;
    word-break: keep-all;
    margin-right: 5px;
    display: flex;
    align-items: center;

    .el-input--small {
      font-size: 13px;
    }
  }

  .pp > div {
    display: inline-block;
    margin: 0 5px;
  }

  .el-button--primary {
    background-color: #2577e3 !important;
    border-color: #2577e3 !important;
    color: #fff !important;
  }
}

.titleBox {
  padding-top: 30px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .bl {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .zybG {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        height: 45px;
        font-size: 24px !important;
      }

      .cj {
        margin-top: 10px !important;
        font-size: 12px !important;
      }
    }
  }

  .right {
    display: flex;
    padding: 20px 20px 0 0;
    float: right !important;

    .all {
      width: auto;
      display: flex;
      flex-wrap: nowrap;

      .cbw {
        height: 36px;

        .cbw_all {
          height: 100%;
          flex-wrap: nowrap;
          margin-right: 10px;

          .cbw_w {
            width: 10px;
            height: 10px;
            background-color: #06c687;
            border-radius: 10px;
          }

          .cbw_b {
            width: 10px;
            height: 10px;
            background-color: #ff9423;
            border-radius: 10px;
          }

          .cbw_c {
            width: 10px;
            height: 10px;
            background-color: #f53f3f;
            border-radius: 10px;
          }

          span {
            min-width: 40px;
            max-width: auto;
            height: 100%;
            line-height: 36px;
            font-size: 12px;
            padding-left: 10px;
          }
        }
      }

      .action-all {
        width: auto;
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .action-button {
          display: block;
          min-width: 45px;
          max-width: auto;
          text-align: center;
          margin-right: 10px;
          padding: 5px 16px;
          border: 1px solid #ccc;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          color: #4a4a4a;
          letter-spacing: 0;
          user-select: none;
        }
      }

      .cbw,
      .cbw_all {
        width: auto;
        display: flex;
        align-items: center;
      }
    }
  }

  .right > div:first-of-type {
    flex: 1;
  }
}

.tableBox {
  border-radius: 5px;
  padding: 22px 0 20px 0;
}

.recommend-data-eu-table___J2Nqt {
  height: 100%;
  position: relative;

  .recommend-data-eu-table-hd-box___UexWu {
    position: relative;
    height: 39px;

    .recommend-data-eu-table-hd___Ep8R8 {
      display: flex;
      width: 100%;
      height: 39px;
      background-color: #f4f6f8;
      border: solid #efefef;
      border-width: 1px 1px 0;
      line-height: 39px;
      font-size: 14px;
      font-weight: 400;
      color: #9d9d9d;

      .recommend-data-eu-table-eu-name___fRm_H {
        flex: 5 1;
      }

      .recommend-data-eu-table-major-count___LhMrX {
        flex: 1 1;
        min-width: 145px;
        justify-content: center;
      }

      .recommend-data-eu-table-major-count___Zy {
        width: 80px;
        justify-content: center;
      }

      .recommend-data-eu-table-ratio___TwQdX {
        width: 60px;
      }

      .recommend-data-eu-table-plan___sNM8l {
        width: 110px;
      }

      .recommend-data-eu-table-admission-data___a50VQ {
        flex: 4 1;
        min-width: 339px;
        display: flex;
        padding: 0 !important;
        border-right-width: 0 !important;

        .year-hd___eE2lN {
          flex: 58 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }

        .year-item___NKiGo {
          flex: 89 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }
      }
    }

    .recommend-data-eu-table-hd___Ep8R8 > div {
      padding: 0 15px;
      border-right: 1px solid #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .recommend-data-eu-table-item___BXnAP > div:nth-child(4) {
    align-items: flex-start;
  }

  .recommend-data-eu-table-item___BXnAP {
    width: 100%;
    min-height: 190px;
    border: 1px solid #efefef;
    margin-bottom: 11px;
    position: relative;
    display: flex;

    .recommend-data-eu-table-eu-name___fRm_H {
      flex: 5 1;

      .sch-name___afuQF {
        font-size: 18px;
        font-weight: 600;
        color: #272727;
        line-height: 25px;
        margin-bottom: 5px;
        cursor: pointer;

        a {
          color: #272727;
          text-decoration: none;
          background-color: transparent;
          outline: none;
          cursor: pointer;
          transition: color 0.3s;
        }
      }

      .sch-line___oPJFW {
        font-size: 14px;
        font-weight: 400;
        color: #646464;
        line-height: 20px;
        margin-bottom: 5px;
        white-space: pre-wrap;
      }
    }

    .recommend-data-eu-table-major-count___LhMrX {
      flex: 1 1;
      justify-content: center;
      min-width: 145px;

      .count-box___NYb3B {
        width: 100%;

        .sch-line___oPJFW {
          font-size: 14px;
          font-weight: 400;
          color: #646464;
          line-height: 20px;
          margin-bottom: 5px;
          white-space: pre-wrap;
        }
      }
    }

    .recommend-data-eu-table-major-count___Zy {
      justify-content: center;
      width: 80px;

      .count-box___NYb3B {
        .count___QftTC {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .recommend-data-eu-table-ratio___TwQdX {
      width: 60px;

      .ratio-num___gAwOo {
        font-size: 20px;
        font-family: DIN Alternate Bold;
        color: #272727;
        line-height: 24px;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        user-select: none;
        color: #fff !important;
        letter-spacing: 0;
        line-height: 18px;
        width: 24px;
        height: 24px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .c {
        background-color: #f53f3f;
      }

      .w {
        background-color: #ff9423;
      }

      .b {
        background-color: #06c687;
      }
    }

    .recommend-data-eu-table-plan___sNM8l {
      width: 110px;

      .plan-count___UrtQc {
        display: flex;
        width: max-content;

        .plan-count-num___IspKS {
          flex: 1 1;
          font-size: 20px;
          font-family: DIN Alternate Bold;
          color: #272727;
          line-height: 39px;
          text-align: right;
          padding-right: 3px;
          word-break: keep-all;
        }
      }

      .plan-info___kz4Ip,
      .plan-subject___wsd5H {
        font-size: 14px;
        font-weight: 400;
        color: #646464;
        line-height: 20px;
      }
    }

    .recommend-data-eu-table-admission-data___a50VQ {
      flex: 4 1;
      min-width: 339px;
      display: flex;
      padding: 0 !important;
      border-right-width: 0 !important;
      justify-content: space-between;

      .year-hd___eE2lN,
      .year-item___NKiGo {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .admission-line___q58Dc {
        border-bottom: 1px solid #efefef;
        flex: 1 1;
        display: flex;
        width: 100%;

        .year-item___NKiGo {
          flex: 89 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }

        .year-hd___eE2lN {
          flex: 58 1;
          border-right: 1px solid #efefef;
          text-align: center;
        }
      }
    }
  }

  .recommend-data-eu-table-item___BXnAP > div {
    padding: 15px;
    border-right: 1px solid #efefef;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

#tzy-selected-pop.slide-out {
  transform: translateX(0);
}
</style>
