import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/rem";
import "lib-flexible";

// 所有全局的内容，全局的样式
import "@/styles/reset.css";
import "@/styles/iconfont.css";
import "@/plugins/element.js";


//引入自定义指令，方便全局使用
import myDirective from "@/utils/myDirective"; //按钮节流
Vue.use(myDirective);

import "lib-flexible";
import scroll from "vue-seamless-scroll";
window.addEventListener("popstate", function () {
  history.pushState(null, null, document.URL);
});
Vue.use(scroll);

// 返回顶部
import BackToTop from "@/components/BackToTop";
Vue.component("back-to-top", BackToTop);

// 引入ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
