<template>
  <div class="box">
    <div class="bane">
      <div class="vipContent">
        <div class="left">
          <img src="./images/vipka.png" alt="" />
        </div>
        <div class="right">
          <div class="title">六纬VIP志愿卡</div>
          <div class="priceBox">
            <div class="price">¥298.00</div>
            <div class="gmBtn">立即购买</div>
          </div>
          <div class="introduce">
            <p>
              <span>下单须知</span>
            </p>
            <p>
              <span>1.适用对象：</span
              >普通类文理科考生、新高考选科类考生（提前批次及艺术体育类考生暂不适用）
            </p>
            <p>
              <span>2.使用时效：</span
              >有效期截止到用户自行选择高考年的8月31日为止
            </p>
            <p>
              <span>3.适用平台：</span
              >使用购买账号(手机号)登录六纬志愿小程序、六纬志愿官网(www.xxx.com)、均可享受志愿填报智能卡VIP服务。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">六纬志愿VIP权益</div>
      <div class="table-container">
        <table class="custom-table">
          <!-- 表头 -->
          <thead>
            <tr>
              <th>模块</th>
              <th>功能</th>
              <th
                class="vip-column"
                style="
                  background: #feebc0;
                  border: 1px solid #feebc0 !important;
                "
              >
                <div class="vipTio">
                  <img src="./images/image 24@2x.png" alt="" />
                </div>
              </th>
              <th>普通用户</th>
            </tr>
          </thead>

          <!-- 表格内容 -->
          <tbody>
            <tr v-for="(item, index) in tableData" :key="index">
              <!-- 左侧模块列（合并单元格） -->
              <td
                v-if="item.rowspan > 0"
                :rowspan="item.rowspan"
                style="font-size: 16px; font-weight: bold"
              >
                {{ item.module }}
              </td>

              <!-- 功能 -->
              <td>{{ item.feature }}</td>

              <!-- VIP用户列 -->
              <td class="vip-column">
                <i v-if="item.vip" class="icon-vip">&#10004;</i>
              </td>

              <!-- 普通用户列 -->
              <td>
                <span v-if="item.normal === '支持'" class="icon-normal"
                  >&#10004;</span
                >
                <span v-else>不支持</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCustomerInfoById } from "@/api/home";
import { resetUnauthorizedHandled } from "@/utils/request";
export default {
  name: "VoluntaryReportingIndex",
  data() {
    return {
      busSpecialistGrouList: [],
      userInfo: "",
      tableData: [
        {
          module: "课程学习",
          feature: "志愿填报知识课程",
          vip: true,
          normal: "支持",
          rowspan: 3,
        },
        {
          module: "",
          feature: "志愿填报高级课程",
          vip: true,
          normal: "不支持",
          rowspan: 0,
        },
        {
          module: "",
          feature: "考前心理健康课程",
          vip: true,
          normal: "不支持",
          rowspan: 0,
        },
        {
          module: "个人测评",
          feature: "MBTI职业性格评估",
          vip: true,
          normal: "不支持",
          rowspan: 4,
        },
        {
          module: "",
          feature: "霍兰德职业兴趣评估",
          vip: true,
          normal: "不支持",
          rowspan: 0,
        },
        {
          module: "",
          feature: "智能选科测评",
          vip: true,
          normal: "不支持",
          rowspan: 0,
        },
        {
          module: "",
          feature: "职业偏好测评",
          vip: true,
          normal: "支持",
          rowspan: 0,
        },
        {
          module: "AI填报",
          feature: "志愿填报AI大模型",
          vip: true,
          normal: "不支持",
          rowspan: 1,
        },
      ],
    };
  },

  mounted() {},

  methods: {
    goUrl(url) {
      if (this.userInfo == "") {
        this.getUserInfo();
        return;
      }
      window.open(`https://cp.ycymedu.com/` + url, "_blank");
    },
    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById();
      this.userInfo = res.data.result;

      // 用户登录成功后清除 unauthorizedHandled 标志
      resetUnauthorizedHandled();

      if (this.userInfo.batchDataUrl == "") {
        this.$store.commit("user/setProvinceShow", true);
      }

      this.$store.dispatch("user/removeStudentInfo");
      this.$store.commit("user/setStudentInfo", res.data.result);
      sessionStorage.setItem("setStudentInfo", JSON.stringify(res.data.result));
      sessionStorage.setItem("visited", "true"); // 设置标记
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}
.box {
  width: 100%;
  padding-bottom: 20px;
  .bane {
    min-width: 1280px;
    height: 480px;
    /* 设置一个元素的背景图片 */
    background-image: url("./images/image\ 22@2x.png"); /* 替换 'image-path.jpg' 为你的图片路径 */
    background-size: cover; /* 背景图片覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图片不重复 */
    background-position: center; /* 背景图片居中 */
    .vipContent {
      margin: 0 auto;
      width: 1280px;
      padding-top: 99px;
      display: flex;
      .left {
        width: 385px;
        height: 231px;
        margin-right: 32px;
      }
      .right {
        flex: 1;
        .title {
          font-weight: 600;
          font-size: 28px;
        }
        .priceBox {
          display: flex;
          align-items: center;
          margin: 32px 0 26px 0;
          .price {
            font-weight: 600;
            font-size: 20px;
            color: #e02828;
            margin-right: 24px;
          }
          .gmBtn {
            width: 160px;
            height: 46px;
            background: #341e01;
            border-radius: 8px 8px 8px 8px;
            text-align: center;
            line-height: 46px;
            font-size: 16px;
            color: #fce3be;
            cursor: pointer;
          }
        }
        .introduce {
          font-size: 13px;
          color: #333333;
          p {
            margin-bottom: 5px;
          }
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
  .content {
    margin: 0 auto;
    width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 20px;
      color: #333333;
      font-weight: bold;
    }
    .table-container {
      width: 100%;
    }
    /* 表格整体样式 */
    .custom-table {
      width: 100%;
      border-collapse: collapse;
      text-align: center;
      font-size: 14px;
      margin: 20px 0;
    }

    /* 表头样式 */
    .custom-table th {
      background-color: #f8f8f8;
      padding: 10px;
      border: 1px solid #e8e8e8;
      font-weight: bold;
      height: 50px;
      box-sizing: border-box;
      font-size: 16px;
    }
    .vipTio {
      margin: 0 auto;
      width: 59px;
      height: 41px;
    }
    /* 表格单元格样式 */
    .custom-table td {
      padding: 10px;
      border: 1px solid #e8e8e8;
      height: 50px;
      font-size: 16px;
    }

    /* VIP用户列背景色 */
    .vip-column {
      background-color: #feebc0;
      border: none !important;
      width: 113px;
    }

    /* 勾选图标样式 */
    .icon-vip {
      color: #f3b43a; /* 金黄色 */
      font-weight: bold;
    }

    .icon-normal {
      color: #67c23a; /* 绿色 */
      font-weight: bold;
    }
  }
}
</style>
