<template>
    <div>
        <div class="list">
            <div class="Stitle">{{ list.career }}</div>
            <div class="Scontent">
                {{ list.intro }}
            </div>
            <div class="item">
                <div class="title">职业定义</div>
                <div class="introduceTit">
                    <div class="content">
                        {{ list.definition }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">职业技能</div>
                <div class="introduceTit">
                    <div class="tit">专业技能</div>
                    <div class="content">
                        {{ list.json_skill.skill_pro }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">通用技能</div>
                    <div class="content">
                        {{ list.json_skill.skill_general }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">从业资格</div>
                <div class="introduceTit">
                    <div class="tit">必要项</div>
                    <div class="content">
                        {{ list.json_qualification.necessary }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">附加项</div>
                    <div class="content">
                        {{ list.json_qualification.addition }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">任务职责</div>
                <div class="introduceTit">
                    <div class="tit">工作内容、任务、职责</div>
                    <div class="content">
                        {{ list.json_task.task }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">工作考核要求</div>
                    <div class="content">
                        {{ list.json_task.check }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">工作环境</div>
                <div class="introduceTit">
                    <div class="tit">工作场所</div>
                    <div class="content">
                        {{ list.json_environment.site }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">环境具体描述</div>
                    <div class="content">
                        {{ list.json_environment.desc }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">知识背景</div>
                <div class="introduceTit">
                    <div class="tit">学历要求</div>
                    <div class="content">
                        {{ list.json_knowledge.diplomas }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">行业知识背景</div>
                    <div class="content">
                        {{ list.json_knowledge.trade }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">专业知识背景</div>
                    <div class="content">
                        {{ list.json_knowledge.pro }}
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="title">大学课程</div>
                <div class="introduceTit">
                    <div class="tit">重要的大学课程</div>
                    <div class="content">
                        {{ list.json_curriculum.important }}
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="title">发展前景</div>
                <div class="introduceTit">
                    <div class="tit">行业发展前景</div>
                    <div class="content">
                        {{ list.json_prospect.industry }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">个人发展路径</div>
                    <div class="content">
                        {{ list.json_prospect.person }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">职业的就业趋势</div>
                    <div class="content">
                        {{ list.json_prospect.career }}
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="title">薪酬待遇</div>
                <div class="introduceTit">
                    <div class="tit">薪酬结构</div>
                    <div class="content">
                        {{ list.json_salary.structure }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">满意度</div>
                    <div class="content">
                        {{ list.json_salary.satisfaction }}
                    </div>
                </div>
                <div class="introduceTit">
                    <div class="tit">福利制度</div>
                    <div class="content">
                        {{ list.json_salary.welfare }}
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="title">职业道德</div>
                <div class="introduceTit">
                    <div class="content">
                        {{ list.ethics }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VoluntaryReportingIndex",
    props: {
        list: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        };
    },

    mounted() { },

    methods: {},
};
</script>

<style lang="less">
.list {
    .Stitle {
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 36px;
    }

    .Scontent {
        margin-bottom: 58px;
        font-size: 16px;
        color: #666666;
    }

    .item {
        .title {
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 20px;
        }

        .introduceTit {
            .tit {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 16px;
            }

            .content {
                font-size: 16px;
                color: #666666;
                margin-bottom: 32px;
            }
        }

        .table {
            margin-bottom: 50px;
        }
    }
}
</style>
