<template>
    <div class="box" ref="container">
        <div class="setting"> </div>

        <div class="content">
            <div class="leftContent">
                <div class="tabs">
                    <div class="tzy-filter-wrapper">
                        <div class="filter-list">
                            <span class="filter-list-title"> 院校省份 </span>
                            <div class="filter-list-tags">
                                <span :class="it.check == true ? 'tag active' : 'tag'" v-for="it in regionList"
                                    :key="it.Id" @click="selectRegion(it)">
                                    {{ it.Name }}
                                </span>
                            </div>
                        </div>
                        <div class="filter-list">
                            <span class="filter-list-title"> 院校搜索 </span>
                            <div class="filter-list-tags">
                                <!-- 搜索 -->
                                <div class="customer-college">
                                    <el-input size="small" v-model="listObj.name" placeholder="请输入学校名称"
                                        @keyup.enter.native="servelist()" suffix-icon="el-icon-search"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="TableList">
                    <el-col :span="24" class="table-row">
                        <table class="pcl-table">
                            <thead>
                                <th width="50%" align="center">大学名称</th>
                                <th width="30%" align="center">办学地点</th>
                                <th width="20%" align="center">院校鉴别</th>

                            </thead>
                        </table>
                        <table class="pcl-table">
                            <tbody>
                                <tr v-for="(item, index) in list" :key="index">
                                    <td width="50%" align="center">
                                        {{ item.UniversityName }}
                                    </td>
                                    <td width="30%" align="center">{{ item.Location }}</td>
                                    <td width="20%" align="center" style="color: red;">虚假大学</td>
                                </tr>
                            </tbody>
                        </table>
                    </el-col>
                </div>
            </div>

            <!-- bander -->
            <div class="rightContent">
                <div class="banes">
                    <div class="item" @click="goUrl('/Home/FirstPage')">
                        <img src="./images/Frame 1000015157@2x.png" alt="" />
                    </div>
                    <div class="item" @click="goUrl('/Home/ZhanJiaList')">
                        <img src="./images/Frame 1000015157@2x(1).png" alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { GetRegionList, } from "@/api/home";
import { GetFackUniversitys } from "@/api/diplomaMill";
export default {
    name: "SearchUniversityIndex",
    components: {},
    data() {
        return {
            listObj: {
                locationCode: "",
                name: "",
            },
            list: [],
            regionList: [],
        };
    },
    mounted() {
        this.getRegionList();
    },
    destroyed() { },
    methods: {
        servelist() {
            this.getList();
        },
        async getList() {
            const res = await GetFackUniversitys()
            this.list = res.data.response
            this.status = res.data.status
        },
        // 选择院校省份
        selectRegion(it) {
            this.regionList.forEach((item) => {
                item.check = item.Name === it.Name;
            });
            this.listObj.locationCode = it.Code;
            // 获取列表数据
            this.getList();
        },


        // 获取省份数据
        async getRegionList() {
            try {
                // 调用接口获取区域列表
                const res = await GetRegionList({ code: 0 });
                this.regionList = res.data.response || [];
                this.regionList.forEach((item) => {
                    this.$set(item, "check", false);
                });
                this.regionList.unshift({
                    Id: -1,
                    Code: "-1",
                    Name: "不限",
                    check: true,
                });

                // 获取列表数据
                this.getList();
            } catch (error) {
                console.error("获取区域列表失败:", error);
            }
        },

        goUrl(url) {
            this.$router.push(url);
        },
    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    margin: 0 auto;
    min-height: 100vh;
}

.setting {
    background-image: url("./images/bjk.png");
    background-size: 100% 100%;
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    margin-top: 20px;
}

.leftContent {
    flex: 1;
    min-width: 956px;
    margin-right: 16px;

    .tabs {
        position: relative;
        width: 100%;
        padding: 16px 24px;
        box-sizing: border-box;
        border-radius: 8px 8px 8px 8px;
        background: #ffffff;

        .tabTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #eeeeee;

            .tit {
                font-size: 20px;
                color: #333333;
                font-weight: bold;
            }

            .zk {
                font-size: 14px;
                cursor: pointer;
            }
        }

        .tzy-filter-wrapper {
            box-sizing: border-box;
            display: block;

            .filter-list {
                display: flex;
                align-items: flex-start;
                margin-top: 10px;

                .filter-list-title {
                    min-width: 56px;
                    font-size: 14px;
                    text-align: left;
                    font-weight: bold;
                }

                .filter-list-tags {
                    margin-left: 20px;
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;

                    .tag {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        font-style: normal;
                        margin: 0 0 8px 10px;
                        text-align: center;
                        border-radius: 4px;
                        cursor: pointer;
                        box-sizing: border-box;
                        min-width: 58px;
                        text-align: left;
                    }

                    .tag:hover {
                        color: #187cff;
                    }

                    .active {
                        color: #187cff;
                    }
                }
            }

            .college-level-wrapper {
                display: flex;

                .sx {
                    font-weight: 100;
                    height: 47px;
                    display: flex;
                    align-items: center;
                    color: #e6e6e6;
                    transform: translateY(-2px);
                }
            }
        }
    }

    .TableList {
        margin: 20px 0;
        padding: 24px;
        border-radius: 8px 8px 8px 8px;
        background: #fff;

        .title {
            margin-bottom: 24px;
            font-weight: 600;
            font-size: 24px;
        }

        .table-row {
            margin-bottom: 13px;
            float: inherit !important;

            .pcl-title {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .pcl-table {
                width: 100%;
                border-top: 1px solid #ebeef5;
                border-left: 1px solid #ebeef5;
                border-bottom: none;
                background-color: #fff;
            }

            thead th {
                height: 42px;
                background: #f6f6f6;

                th {
                    border-right: 1px solid #ebeef5;
                    border-bottom: 1px solid #ebeef5;
                    padding: 14px 20px;
                    color: #000;
                    font-size: 14px;
                }
            }

            .pcl-table {
                width: 100%;
                border-top: 1px solid #ebeef5;
                border-left: 1px solid #ebeef5;
                border-bottom: none;
                background-color: #fff;

                tbody tr {
                    height: 48px;

                    td {
                        border-right: 1px solid #ebeef5;
                        border-bottom: 1px solid #ebeef5;
                        padding: 13px 20px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

}

.rightContent {
    width: 308px;
    overflow: hidden;

    .banes {
        width: 100%;

        .item {
            cursor: pointer;
            width: 308px;
            height: 154px;
            margin-bottom: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>