<template>
  <div class="compBox">
    <div class="title">{{ name }} - {{ year }} 招生计划（{{ Location }}）</div>
    <div class="table">
      <el-table :data="list" style="width: 100%" :header-cell-style="{ background: '#EEEEEE', color: '#333333' }">
        <el-table-column label="院校名称" align="center" width="190">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.universityName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="院校代码" align="center">
          <template slot-scope="scope">
            <div><span>{{ scope.row.universityCode }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="招生专业" align="center" width="277">
          <template slot-scope="scope">
            <div><span>{{ scope.row.major }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="批次" align="center">
          <template slot-scope="scope">
            <div><span>{{ scope.row.batchName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="计划数" align="center">
          <template slot-scope="scope">
            <div><span>{{ scope.row.plancount }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学制" align="center">
          <template slot-scope="scope">
            <div><span>{{ scope.row.academic }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学费" align="center">
          <template slot-scope="scope">
            <div><span>{{ scope.row.fee }}</span>
              <span></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { planListPro, } from "@/api/schoolDetails";
export default {
  name: "VoluntaryReportingIndex",
  props: {
    schoolId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Location: "",
      locationCode:'',
      year: '',
      list: [],
    };
  },

  mounted() {
    this.locationCode = this.$store.state.user.studentInfo.userExtend.provinceCode || "370000";
    this.Location =
      this.$store.state.user.studentInfo.userExtend.provinceName || "山东省";
    this.getList();
  },

  methods: {
    // 获取专业数据
    async getList() {
      const res = await planListPro({
        uid: this.schoolId,
        locationCode: this.locationCode,
      });
      this.year = res.data.result.year
      this.list = res.data.result.items
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.compBox {
  margin-top: 48px;

  .title {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 20px;
  }
}
</style>
